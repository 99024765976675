import * as React from 'react';
import { connect } from 'react-redux';
import ApplicationHeader from '@chegg-tutors-chat/shared/components/ApplicationHeader';
import ChatAvatar from '@chegg-tutors-chat/shared/components/Avatar';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { showModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import * as selectors from '@chegg-tutors-chat/shared/redux/modules/user/selectors';

export function mapStateToProps(state: GlobalState) {
  const lessonId = getSelectedLessonId(state);
  const isLoggedIn = selectors.isLoggedIn(state);
  const user = selectors.getUser(state);
  const { firstName = '', lastName = '' } = user || {};
  const userNameProps = {
    firstName,
    lastName
  };

  const HeaderChatAvatar: React.FunctionComponent = () => (
    <ChatAvatar loggedInUser={true} {...userNameProps} />
  );

  return {
    isLoggedIn,
    lessonId,
    HeaderChatAvatar
  };
}

const mapDispatchToProps = {
  onLogoClick: (lessonId: string) =>
    showModal({
      modalName: MODAL.CLOSE_LESSON_REDIRECT_STUDENT,
      modalOptions: {
        lessonId
      }
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationHeader);
