import styled from 'styled-components';
import { VisuallyHidden } from '@chegg/ui-agnostic';

const SkipLinkWrapper = styled.button`
  ${VisuallyHidden.visuallyHiddenCSS}
  top: 0;
  right: 0;
  /* important is used here because we 'fighting' with VisuallyHidden for specificity */
  &:focus {
    clip: auto !important;
    clip-path: initial;
    height: auto !important;
    margin: auto !important;
    overflow: auto !important;
    padding: initial !important;
    width: auto !important;
  }
`;

export { SkipLinkWrapper };
