import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { endLesson } from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import * as modalActions from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import CloseLesson from './CloseLesson';

export function mapStateToProps(_state: GlobalState, passedProps: ModalsState) {
  const { modalOptions } = passedProps;

  return {
    isOpen: true,
    modalOptions
  };
}

export const mapDispatchToProps = (dispatch: Dispatch, passedProps: ModalsState) => {
  const modalOptions = passedProps.modalOptions || {};
  const handleEndLesson = () => {
    dispatch(
      endLesson({
        id: modalOptions.lessonId
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  return {
    primaryButtonClick: handleEndLesson,
    secondaryButtonClick: handleCloseModal
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseLesson);
