import moment from 'moment';
import { createLogic } from 'redux-logic';
import { ArgumentAction } from 'redux-logic/definitions/action';
import NewMessageNotificationSound from '@chegg-tutors-chat/shared/assets/audio/tutor-notify.mp3';
import { STATUS } from '@chegg-tutors-chat/shared/constants';
import { isErrorAction, SoundUtil, urlToFile } from '@chegg-tutors-chat/shared/utils';
import { getLessons, getSelectedLessonId } from '../lessons/selectors';
import { getLessonMessages } from '../messages/selectors';
import { getUser } from '../user/selectors';
import {
  addAttachment,
  AttachmentPayload,
  increaseUnreadMessageCount,
  setUploadingError,
  setUploadingSuccess,
  UnreadMessageCount,
  updateInterval,
  updateStudentStatus,
  UpdateStudentStatusPayload,
  updateWaitDuration,
  UpdateWaitDurationPayload,
  UploadingErrorPayload,
  UploadingSuccessPayload
} from './actions';

interface GetStateType {
  getState: () => GlobalState;
}

/**
 * Number used to calculate the online status of student in minutes.
 */
const ONLINE_TIME_LIMIT: number = 10;

/**
 * Number used to calculate the away/offline status of student in minutes.
 */
const AWAY_TIME_LIMIT: number = 20;

/**
 * Error codes from sendbird
 * https://docs.sendbird.com/javascript/error_codes#3_client_error_codes
 *
 */
const UPLOAD_ERROR = {
  code: 1738,
  message: 'ERROR: File size exceeds 5 Mbs',
  name: 'File Upload Error'
};

/**
 * Used as a name for the logic that invokes SoundUtil
 */
export const PLAY_NEW_MESSAGE_CHIME_LOGIC = 'playNewMessageChime';

/**
 * Maximum upload file size is 5mb for now, which is 5000000 bytes
 */
export const MAX_FILE_UPLOAD_SIZE = 5000000;

/**
 * Loops through an array of messages and return the last first
 * message from student
 * @param messages
 * @param loggedinUserId
 *
 * @returns message
 */
const getlastFirstStudentMessage = (
  messages: readonly Message[],
  loggedinUserId: string
): Message | null => {
  let positionOfLastMessage = messages.length - 1;
  let lastFirstStudentMessage: Message | null = null;

  // Iterate backwards from the end of array of messages and find the lastFirstStudent message
  while (positionOfLastMessage > -1) {
    const tempMessage = messages[positionOfLastMessage];
    if (tempMessage.sender.userId !== loggedinUserId) {
      lastFirstStudentMessage = tempMessage;
      break;
    }
    positionOfLastMessage--;
  }

  return lastFirstStudentMessage;
};

/**
 * Gets waitDuration for a particular lessonId
 * @param messages
 * @param loggedinUserId
 * @param lessonId
 * @param dispatch
 *
 * @returns waitDuration
 */
const processWaitDuration = (
  messages: readonly Message[],
  loggedinUserId: string,
  lessonId: string,
  dispatch: DispatchType<UpdateWaitDurationPayload>
) => {
  const positionOfLastMessage = messages.length - 1;
  const payload: UpdateWaitDurationPayload = {
    lessonId
  };
  // If user who sent last message in the conversation is tutor
  if (messages[positionOfLastMessage].sender.userId === loggedinUserId) {
    payload.waitDuration = moment.duration(moment().diff(moment.now()));
    dispatch(updateWaitDuration(payload));
  } else {
    const lastFirstStudentMessage = getlastFirstStudentMessage(messages, loggedinUserId);

    if (lastFirstStudentMessage) {
      payload.waitDuration = moment.duration(
        moment().diff(lastFirstStudentMessage.createdAt)
      );
      dispatch(updateWaitDuration(payload));
    }
  }
};

function getStudentStatus(minutes: number): Status {
  let studentStatus: Status = STATUS.OFFLINE;
  if (minutes <= ONLINE_TIME_LIMIT) {
    studentStatus = STATUS.ONLINE;
  } else if (minutes <= AWAY_TIME_LIMIT) {
    studentStatus = STATUS.AWAY;
  }
  return studentStatus;
}

/**
 * Gets waitDuration for a particular lessonId
 * and updates student status if certain conditions met
 * @param waitDuration
 * @param lessonId
 * @param dispatch
 */
const processStudentStatus = (
  lessonId: string,
  dispatch: DispatchType<UpdateStudentStatusPayload>,
  messages: readonly Message[],
  loggedinUserId: string
) => {
  const lastFirstStudentMessage = getlastFirstStudentMessage(messages, loggedinUserId);
  if (!lastFirstStudentMessage) {
    return;
  }
  const waitDuration = moment.duration(moment().diff(lastFirstStudentMessage.createdAt));

  let studentStatus: Status = STATUS.OFFLINE;
  if (waitDuration) {
    studentStatus = getStudentStatus(waitDuration.asMinutes());
  }

  const payload: UpdateStudentStatusPayload = {
    lessonId,
    studentStatus
  };

  dispatch(updateStudentStatus(payload));
};

/**
 *
 * Updates the duration for how long a student has been waiting
 */
export const waitDurationLogic = createLogic<GlobalState, UpdateWaitDurationPayload>({
  debounce: 500 /* ms */,
  latest: true /* take latest only */,
  type: updateInterval.type,
  process(
    { getState }: GetStateType,
    dispatch: DispatchType<UpdateWaitDurationPayload | UpdateStudentStatusPayload>,
    done: DoneType
  ) {
    const state = getState();
    const loggedinUser = getUser(state);
    const lessons = getLessons(state);

    // Iterate over all conversations that happened betwen tutor and student
    Object.keys(lessons).map(lessonId => {
      const lessonMessages = getLessonMessages(state, lessonId);
      if (lessonMessages && lessonMessages.length > 0) {
        // update waitDuration times for all lessons
        processWaitDuration(lessonMessages, loggedinUser.userId, lessonId, dispatch);
        // update studentStatus for all lessons
        processStudentStatus(lessonId, dispatch, lessonMessages, loggedinUser.userId);
      } else {
        const payload: UpdateStudentStatusPayload = {
          lessonId,
          studentStatus: STATUS.OFFLINE
        };
        dispatch(updateStudentStatus(payload) as ArgumentAction);
      }
    });

    done();
  }
});

/**
 * Logic that plays a sound when client receives a new message that wasn't read yet
 */
export const playNewMessageChime = createLogic<GlobalState, UnreadMessageCount>({
  process: ({ action }, _dispatch, done: DoneType) => {
    const { lessonId } = action.payload;

    if (lessonId) {
      SoundUtil(NewMessageNotificationSound).play();
    }
    done();
  },
  type: increaseUnreadMessageCount.type,
  name: PLAY_NEW_MESSAGE_CHIME_LOGIC
});

/**
 * This listens for the addAttachment action and if the attachment
 * is a string it is assumed to be a dataUrl. It then transforms the
 * dataUrl into a File Object.
 *
 * This will also default the lessonId to the currently selected lessonId
 * and set the payload type.
 *
 * This also acts as a validate, so if there is no lessonId
 * then the action won't procced
 */
export const transformAttachmentToFile = createLogic<GlobalState, AttachmentPayload>({
  process: (
    { action },
    dispatch: DispatchType<UploadingErrorPayload | UploadingSuccessPayload>,
    done: DoneType
  ) => {
    if (!isErrorAction(action)) {
      const { payload } = action;
      const file = payload.fileSrc;
      if (file && file.size > MAX_FILE_UPLOAD_SIZE) {
        // if file is larger than the maximum, send an action that indicates an error.
        // This action is important for visual updates and aria-live updates to let the user know what happened.
        dispatch(
          setUploadingError({
            lessonId: payload.lessonId || '',
            uploadingError: {
              ...UPLOAD_ERROR,
              timestamp: moment().unix()
            }
          })
        );
      } else {
        // if file is not too big, we allow the upload and it's a success.
        // This action is important for visual updates and aria-live updates to let the user know what happened.
        dispatch(
          setUploadingSuccess({
            lessonId: payload.lessonId || '',
            type: payload.type || ''
          })
        );
      }
    }
    done();
  },
  transform: async ({ action, getState }, next) => {
    if (!isErrorAction(action)) {
      const lessonId = getSelectedLessonId(getState());
      /**
       * If there is no lessonId we cant do anything. Potentially we should
       * have an error action for missing lessonId.
       */
      if (!lessonId) {
        return;
      }

      const { payload } = action;
      let file = payload.fileSrc;

      if (typeof file === 'string') {
        file = await urlToFile(payload.fileSrc);
      }

      if (file) {
        const updatedPayload = {
          lessonId,
          type: file.type,
          ...payload,
          fileSrc: file
        };

        action.payload = updatedPayload;
      }
    }
    next(action);
  },
  type: addAttachment.type
});

export default [waitDurationLogic, transformAttachmentToFile, playNewMessageChime];
