import * as React from 'react';
import { captureError } from '@chegg-tutors-chat/shared/utils';
import { PreviousMessage } from './Conversation';
import CountDownMessage from './CountDownMessage';
import Message from './Message';

interface ConversationMessageProps {
  message: Message;
  previousMessage: PreviousMessage;
  loggedInUser: UserState;
}

// Supports rendering of various custom messages
interface ConversationMap {
  [key: string]: {
    Component: React.ComponentType<any>;
    /**
     * These are used to extract only whats needed from passedProps to the component to avoid passing unused props
     */
    computePropsFromMsg: (message: Message) => {};
  };
}

const conversationMap: ConversationMap = {
  timerMessage: {
    Component: CountDownMessage,
    // takes the entire message and derives it's own props from it
    computePropsFromMsg: (message: Message) => ({
      timeRemaining: +message.data.numberOfMinutesLeftInLesson
    })
  }
};

// Returns truthy if multiple messages have been sent by different users or if the message types differ
function getDisplayInMessageGrouping(
  message: Message,
  previousMessage: PreviousMessage
): boolean {
  const messageSender = message.sender.userId;
  const messageType = message.messageType;
  const displayInNewMessageGrouping =
    previousMessage.sender !== messageSender || messageType !== previousMessage.type;
  previousMessage.sender = messageSender;
  previousMessage.type = messageType;
  return displayInNewMessageGrouping;
}

const ConversationMessage: React.FunctionComponent<ConversationMessageProps> = ({
  message,
  loggedInUser,
  previousMessage
}) => {
  const { customType } = message;
  let conversationComponent: null | JSX.Element = null;
  if (customType) {
    // check if key exists in conversation map
    if (conversationMap[message.customType]) {
      const { Component, computePropsFromMsg } = conversationMap[customType];
      const props = computePropsFromMsg(message);
      conversationComponent = <Component {...props} />;
    } else {
      const error = new Error(`Cannot find custom message named ${customType}`);
      captureError(error);
    }
  } else {
    conversationComponent = (
      <Message
        displayInNewMessageGrouping={getDisplayInMessageGrouping(
          message,
          previousMessage
        )}
        loggedInUser={loggedInUser}
        message={message}
        key={message.messageId}
      />
    );
  }
  return conversationComponent;
};

export default ConversationMessage;
