import { HTTP_REQUEST_TYPES } from '@chegg-tutors-chat/shared/constants';
import bffRequest from '@chegg-tutors-chat/shared/providers/BFF/bffRequest';
import { BFF_API_ENDPOINTS } from '@chegg-tutors-chat/shared/providers/BFF/constants';
import {
  SubmitCloseLessonRequest,
  SubmitLessonFeedBackRequest
} from '@chegg-tutors-chat/shared/providers/BFF/interfaces';

const { POST, PUT } = HTTP_REQUEST_TYPES;

/* STUDENT API HELPERS */

export interface CreateLessonData {
  question: string;
  subject: string;
  summary: string;
}

// We wrap each API request in a method to strictly type any request parameters,
// and to make the calls reusable.
// for now putting these all in one file, once we get too many we can split them into separate files.

export async function createLesson(
  idToken: string,
  { question, subject, summary }: CreateLessonData
) {
  // TODO https://jira.cheggnet.com/browse/CT-2240
  return await bffRequest({
    data: {
      question,
      subject,
      summary
    },
    headers: {
      'X-CHEGG-UUID': idToken
    },
    method: POST,
    url: BFF_API_ENDPOINTS.CREATE_LESSON
  });
}

/**
 * @param request contains data for lesson feedBack
 * @param onError
 */
export async function submitLessonFeedback({
  feedback,
  lessonId
}: SubmitLessonFeedBackRequest) {
  bffRequest<void>({
    data: {
      feedback,
      ticketId: lessonId
    },
    method: POST,
    url: BFF_API_ENDPOINTS.LESSON_FEEDBACK
  });
}

/**
 * Submits close lesson for a student.
 *
 */
export async function submitCloseLessonStudent(
  closeLessonRequest: SubmitCloseLessonRequest
) {
  bffRequest<void>({
    data: {
      ticketId: closeLessonRequest.ticketId
    },
    method: PUT,
    url: BFF_API_ENDPOINTS.CLOSE_LESSON_STUDENT
  });
}

/**
 * get the lesson information for a student
 */
export async function studentLessonInfo() {
  const response = await bffRequest({
    method: POST,
    url: BFF_API_ENDPOINTS.LESSON_INFO_STUDENT
  });
  return response ? response.data : null;
}

export default {
  createLesson,
  studentLessonInfo,
  submitCloseLessonStudent,
  submitLessonFeedback
};
