import {
  ASSIGNMENT_COMPLEXITY,
  ASSIGNMENT_TYPE
} from '@chegg-tutors-chat/shared/constants';

// Default value for select form inputs
export const NONE_SELECTED = 'NONE_SELECTED';

// Used to map enum values to keys when populating the form with initial values
const assignmentTypeKeysByValue: string[] = [];
Object.keys(ASSIGNMENT_TYPE).map(
  key => (assignmentTypeKeysByValue[ASSIGNMENT_TYPE[key]] = key)
);
const complexityKeysByValue: string[] = [];
Object.keys(ASSIGNMENT_COMPLEXITY).map(
  key => (complexityKeysByValue[ASSIGNMENT_COMPLEXITY[key]] = key)
);
export { assignmentTypeKeysByValue, complexityKeysByValue };

// keys for Formik to use to access form values
export const complexity = 'complexity';
export const subtopic = 'subtopic';
export const assignmentType = 'assignmentType';
export const moreInfo = 'moreInfo';
