import MessageInputContainer from './container';
import MessageInput, {
  MessageInputDispatchProps as DispatchProps,
  MessageInputProps as Props
} from './MessageInput';

export type MessageInputProps = Props;
export type MessageInputDispatchProps = DispatchProps;

export { MessageInput };
export default MessageInputContainer;
