import * as React from 'react';
import styled from 'styled-components';
import {
  ATTACHMENT_ORIGIN_TYPE,
  SCRATCHPAD_TOOL_NAME as TOOL_NAME
} from '@chegg-tutors-chat/shared/constants';
import {
  AttachmentPayload,
  SectionToolPayload,
  ToggleToolPayload
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  captureError,
  focusMessageInput,
  isCrossOriginImg,
  noop,
  scratchPadStorage
} from '@chegg-tutors-chat/shared/utils';
import FileTarget from '../FileTarget';
import FabricCanvas from './FabricCanvas';
import Formula from './Formula';
import HelpOverlay from './HelpOverlay';
import ScratchPadManager from './manager';
import { CanvasStateConfigType, redo, undo, update } from './scratchpadUtils';
import { ScratchPadContainer } from './styled';
import ScratchToolbar from './Toolbar';

// TODO: actually fix the type defs in fabric.js
// and import a type from there.
interface FabricCanvasLayerType {
  angle: number;
  backgroundColor: string; // hex code
  clipTo: any;
  fill?: string;
  fillRule?: string;
  flipX: boolean;
  flipY: boolean;
  globalCompositeOperation: string;
  height: number;
  left: number;
  opacity: number;
  originX: 'left' | 'right' | 'top' | 'bottom';
  originY: 'top';
  paintFirst: string;
  path: (string | number)[][];
  scaleX: number;
  scaleY: number;
  shadow: any;
  skewX: number;
  skewY: number;
  stroke: string; // hex code
  strokeDashArray: any;
  strokeDashOffset: number;
  strokeLineCap: string;
  strokeLineJoin: string;
  strokeMiterLimit: number;
  strokeWidth: number;
  top: number;
  transformMatrix: any;
  type: string;
  version: string; // a number in string form, like '2.2.4'
  visible: boolean;
  width: number;
}

// TODO: actually fix the type defs in fabric.js
// and import a type from there.
export interface FabricCanvasStateType {
  background: '#fff';
  objects: FabricCanvasLayerType[];
  version: string;
}

/**
 * @prop fabricCanvas - instance of fabric canvas.
 * @prop showMath - boolean indicating whether math component is shown.
 * @prop shapeSelected - boolean indicating whether a shape is selected.
 * @prop type - type of the screenshot (svg).
 */
interface State {
  currentCanvasStateIndex: number;
  fabricCanvas: any;
  fabricCanvasStates: FabricCanvasStateType[];
  showMath: boolean;
  shapeSelected: boolean;
  type: string;
  showOverlay: boolean;
}

/**
 * @prop addAttachment - action to add attachments.
 * @prop showSectionTool - action to show section tools.
 * @prop lessonId - lesson id for the current scratch pad instance.
 */
export interface Props {
  addAttachment: (payload: AttachmentPayload) => void;
  showMathTypeTool: (payload: ToggleToolPayload) => void;
  showSectionTool: (payload: SectionToolPayload) => void;
  sendImageToScratchPad: (payload: SendImageToScratchPadPayload) => void;
  selectScratchPadTool: (payload: SelectScratchPadToolPayload) => void;
  selectScratchPadShape: (payload: SelectScratchPadShapePayload) => void;
  selectScratchPadPenColor: (payload: SelectScratchPadPenColorPayload) => void;
  lessonId: string;
  active: boolean;
}

/**
 * @prop showMath - boolean to show math keyboard.
 */
interface MathTypeLayoutProps {
  showMath: boolean;
}

const CanvasLayout = styled.div`
  height: 100%;
  overflow-y: scroll;
  &:focus {
    outline: none;
  }
`;

function scaleImage(width: number, height: number, maxdim: number) {
  const scale = width > height ? maxdim / width : maxdim / height;
  return [scale * width, scale * height, scale];
}

const MathTypeLayout = styled.div`
  display: ${(props: MathTypeLayoutProps) => (props.showMath ? 'block' : 'none')};
  flex: ${(props: MathTypeLayoutProps) => (props.showMath ? 2 : 0)};
  padding-bottom: 20px;
`;

const fileTargetStyles = {
  borderRight: '1px solid #cccccc',
  display: 'flex',
  flexFlow: 'column',
  height: '100%'
};

class ScratchPad extends React.Component<Props, State> {
  public static defaultProps: Props = {
    active: false,
    addAttachment: noop,
    lessonId: '',
    selectScratchPadPenColor: noop,
    selectScratchPadShape: noop,
    selectScratchPadTool: noop,
    sendImageToScratchPad: noop,
    showMathTypeTool: noop,
    showSectionTool: noop
  };
  private fabricCanvas = React.createRef<FabricCanvas>();
  private focusEle = React.createRef<HTMLInputElement>();
  private canvasLayout = React.createRef<HTMLDivElement>();
  private toolbar = React.createRef<ScratchToolbar>();
  private onImageQueue: HTMLImageElement[] = [];
  constructor(props: Props) {
    super(props);
    this.state = {
      currentCanvasStateIndex: 0,
      fabricCanvas: null,
      fabricCanvasStates: [],
      shapeSelected: false,
      showMath: false,
      showOverlay: true,
      type: 'svg'
    };
  }
  public componentDidMount() {
    /**
     * Sets a reference that can be used by other components to
     * add images to the scratchpad.
     */
    ScratchPadManager.set(this.props.lessonId, this);
    const fabricCanvasCurrent = this.fabricCanvas.current;
    this.setState({
      fabricCanvas: fabricCanvasCurrent
    });
    if (fabricCanvasCurrent) {
      // save a copy of the blank canvas state
      fabricCanvasCurrent.provideCanvasState(canvasState => {
        this.setState({
          fabricCanvasStates: [canvasState]
        });
      });

      // start in the canvas in the drawing mode
      fabricCanvasCurrent.turnOnDrawingMode();

      // we listen for all changes to the canvas
      // and save each update for our undo/redo records
      fabricCanvasCurrent.onCanvasUpdate(this.saveFabricCanvasState);
    }
    this.hydrateStateWithLocalStorage();
  }

  public componentWillUnmount() {
    const { lessonId } = this.props;
    scratchPadStorage.clear(lessonId);
    ScratchPadManager.delete(lessonId);
  }
  public addImage = (image: any) => {
    this.onImage(image);
    const toolbar = this.toolbar.current;
    if (toolbar) {
      toolbar.setSelectedTool('select');
    }
  };
  /**
   * Queue up any image adds. This is because we need the canvasHeight and
   * that will be 0 if the canvas isn't visable. The queue will be cleared
   * when the component updates.
   */
  public onImage = (imgEle: HTMLImageElement) => {
    this.onImageQueue.push(imgEle);
  };

  public componentDidUpdate() {
    let img = this.onImageQueue.pop();
    while (img) {
      this.onImageHandle(img);
      img = this.onImageQueue.pop();
    }
  }

  public render() {
    let toolbar = null;
    if (this.state.fabricCanvas) {
      toolbar = this.getToolbar();
    }

    return (
      <ScratchPadContainer>
        <FileTarget
          handleWindowPaste={true}
          onImage={this.onImageHandle}
          onText={this.onText}
          style={fileTargetStyles}
        >
          {toolbar}
          <input
            tabIndex={-1}
            aria-hidden={true}
            style={{ position: 'absolute', top: '200px', zIndex: -1 }}
            ref={this.focusEle}
          />
          <CanvasLayout ref={this.canvasLayout} tabIndex={-1} onKeyDown={this.keyPress}>
            <FabricCanvas
              onScreenShotCrop={this.onScreenShotCrop}
              onSelection={this.shapeSelected}
              onFocus={this.focus}
              onSelectionCleared={this.shapeSelectionCleared}
              ref={this.fabricCanvas}
            />
          </CanvasLayout>
          <MathTypeLayout showMath={this.state.showMath}>
            {this.state.showMath ? (
              <Formula
                addAttachment={this.props.addAttachment}
                lessonId={this.props.lessonId}
                onSVG={this.onSVG}
                sendImageToScratchPad={this.props.sendImageToScratchPad}
              />
            ) : null}
          </MathTypeLayout>
        </FileTarget>
        {this.state.showOverlay ? <HelpOverlay onClose={this.hideOverlay} /> : null}
      </ScratchPadContainer>
    );
  }
  private hideOverlay = () => {
    this.setState({ showOverlay: false });
    focusMessageInput();
  };
  private focus = () => {
    if (this.focusEle.current) {
      this.focusEle.current.focus();
    }
  };
  private keyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      this.state.fabricCanvas.deleteSelectedObject();
    }
  };

  /**
   * Function that saves the last 5 canvasStates to an array.
   *
   * @param newCanvasState
   */
  private saveFabricCanvasState = (newCanvasState: FabricCanvasStateType) => {
    const { fabricCanvasStates, currentCanvasStateIndex } = this.state;
    const updatedStateData = update(
      fabricCanvasStates,
      currentCanvasStateIndex,
      newCanvasState
    );
    const { stack: stateArrayCopy, currentIndex: updatedIndex } = updatedStateData;
    this.setState(
      {
        currentCanvasStateIndex: updatedIndex,
        fabricCanvasStates: stateArrayCopy
      },
      () => {
        scratchPadStorage.set(this.props.lessonId, newCanvasState);
      }
    );
  };
  private onImageHandle = (imgEle: HTMLImageElement) => {
    /**
     * Adding an image to the canvas from an origin separate from our own creates a security risk.
     * This includes images hosted by our 3rd party chat service, Sendbird.
     * Canvas knows this and will 'taint' the canvas, preventing certain functionality that could
     * put the user at risk. In order to prevent a bad user experience we should just prevent bad
     * images from being added to the Scratchpad until we can host chat images on a safe domain.
     */
    if (isCrossOriginImg(imgEle)) {
      return;
    }
    const { fabricCanvas } = this.state.fabricCanvas;
    this.setSectionTool(TOOL_NAME.DROP);
    let top = 0;
    let left = 0;
    let scale = 1;
    let defaultWidth = 0;
    let defaultHeight = 0;
    if (this.state.fabricCanvas) {
      if (fabricCanvas) {
        const canvasLayout = this.canvasLayout.current;
        if (!canvasLayout) {
          // this shouldn't happen  this is only called in this element after render and update.
          return;
        }
        const { height, width } = fabricCanvas;
        const canvasHeight = canvasLayout.clientHeight || height;
        const halfClientWidth = Math.floor(width / 2);
        const halfClientHeight = Math.floor(canvasHeight / 2);

        [defaultWidth, defaultHeight, scale] = scaleImage(
          imgEle.width,
          imgEle.height,
          Math.min(halfClientWidth, halfClientHeight)
        );
        top = halfClientHeight - Math.floor(defaultHeight / 2);
        left = halfClientWidth - Math.floor(defaultWidth / 2);
      }
      this.state.fabricCanvas.addImage(imgEle, top, left, scale);
    }
  };
  /**
   * We need to rehydrate the state with something after we refresh the browser.
   */
  private hydrateStateWithLocalStorage = () => {
    const localStorageState = scratchPadStorage.get(
      this.props.lessonId
    ) as FabricCanvasStateType;
    if (localStorageState) {
      this.setState({ fabricCanvasStates: [localStorageState] }, () =>
        this.forceUpdateFabricCanvas()
      );
    }
  };

  /**
   * We all hate boolean flags, so this is
   * used under the hood in two clearly named methods.
   * see https://www.martinfowler.com/bliki/FlagArgument.html
   */
  private _implementationHandleUndoOrRedo = (
    operation: (stack: object[], currentIndex: number) => CanvasStateConfigType
  ) => {
    return () => {
      const { fabricCanvasStates, currentCanvasStateIndex } = this.state;
      const {
        stack: updatedFabricCanvasStates,
        currentIndex: updatedCurrentCanvasStateIndex
      } = operation(fabricCanvasStates, currentCanvasStateIndex);
      this.setState(
        {
          currentCanvasStateIndex: updatedCurrentCanvasStateIndex,
          fabricCanvasStates: updatedFabricCanvasStates
        },
        this.forceUpdateFabricCanvas
      );
    };
  };
  private handleUndo = (_: React.MouseEvent) => {
    return this._implementationHandleUndoOrRedo(undo);
  };
  private handleRedo = (_: React.MouseEvent) => {
    return this._implementationHandleUndoOrRedo(redo);
  };
  /**
   * After an 'undo' or 'redo' action
   * we need to force the canvas to reset
   * using the earlier or later canvas state.
   */
  private forceUpdateFabricCanvas = () => {
    const updatedCanvasState = this.state.fabricCanvasStates[
      this.state.currentCanvasStateIndex
    ];
    if (typeof updatedCanvasState !== 'object') {
      // silently fail and log an error
      // don't need to crash the app over failed 'undo'
      const error = new Error(`FAILED TO FORCE UPDATE CANVAS ${updatedCanvasState}`);
      captureError(error);
      return;
    }
    this.state.fabricCanvas.resetState(updatedCanvasState);
  };
  private showMath = (showMath: boolean = true) => {
    if (showMath) {
      this.setSectionTool(TOOL_NAME.MATH);
      if (this.fabricCanvas.current) {
        this.fabricCanvas.current.setMathTool();
      }
      this.props.showMathTypeTool({
        lessonId: this.props.lessonId,
        toolName: TOOL_NAME.MATH
      });
    }
    this.setState({
      showMath
    });
  };
  private onScreenShotCrop = (img: string) => {
    this.props.addAttachment({
      fileSrc: img,
      lessonId: this.props.lessonId,
      originType: ATTACHMENT_ORIGIN_TYPE.SCRATCH_PAD,
      type: 'image'
    });
    const toolbar = this.toolbar.current;
    if (toolbar) {
      toolbar.setSelectedTool('select');
      if (this.fabricCanvas.current) {
        this.fabricCanvas.current.pointerTool();
      }
    }
  };
  private shapeSelected = () => {
    this.setState({ shapeSelected: true });
  };
  private shapeSelectionCleared = () => {
    this.setState({ shapeSelected: false });
  };
  private onText = (text: string) => {
    if (this.props.active && this.state.fabricCanvas) {
      const canvasElement = this.canvasLayout.current;
      if (canvasElement) {
        const { clientHeight, clientWidth } = canvasElement;
        const halfClientWidth = Math.floor(clientWidth / 2);
        const halfClientHeight = Math.floor(clientHeight / 2);
        this.state.fabricCanvas.addText(text, halfClientHeight, halfClientWidth);
      }
    }
  };

  private onSVG = (svg: string) => {
    const canvasElement = this.canvasLayout.current;
    let top = 0;
    let left = 0;
    if (canvasElement) {
      const { scrollTop, clientHeight, clientWidth } = canvasElement;
      top = scrollTop + Math.floor(clientHeight / 2);
      left = Math.floor(clientWidth / 2);
    }
    this.state.fabricCanvas.addSVG(svg, top, left);
  };

  private setSectionTool(toolName: string, options: { [key: string]: string } = {}) {
    this.props.showSectionTool({
      lessonId: this.props.lessonId,
      options,
      section: TOOL_NAME.SECTION,
      toolName
    });
  }
  private showTool = (toolName: string, toolFunc: (...toolFuncArgs: any[]) => void) => {
    return (...args: any[]) => {
      const options = {
        color: ''
      };
      if (toolName === TOOL_NAME.PEN) {
        options.color = args[0];
      }
      this.setSectionTool(toolName, options);
      toolFunc.apply(this.state.fabricCanvas, args);
    };
  };

  private getToolbar() {
    const {
      currentCanvasStateIndex,
      fabricCanvas,
      fabricCanvasStates,
      shapeSelected
    } = this.state;
    const tools = fabricCanvas;
    const showTool = this.showTool;

    const redoEnabled = currentCanvasStateIndex > 0;
    const undoEnabled = currentCanvasStateIndex < fabricCanvasStates.length - 1;

    return (
      <ScratchToolbar
        ref={this.toolbar}
        addCircle={showTool(TOOL_NAME.CIR, tools.addCircle)}
        addImage={showTool(TOOL_NAME.UPLD, this.onImageHandle)}
        addLine={showTool(TOOL_NAME.LINE, tools.addLine)}
        addMath={this.showMath}
        addRect={showTool(TOOL_NAME.RECT, tools.addRect)}
        addText={showTool(TOOL_NAME.TXT, tools.addText)}
        addTriangle={showTool(TOOL_NAME.TRI, tools.addTriangle)}
        handleUndo={this.handleUndo}
        handleRedo={this.handleRedo}
        clearCanvas={showTool(TOOL_NAME.CLR, tools.clearCanvas)}
        pointerTool={showTool(TOOL_NAME.PTR, tools.pointerTool)}
        screenShot={showTool(TOOL_NAME.SCRN, tools.setScreenShot)}
        setScreenShot={showTool(TOOL_NAME.SCRN, tools.setScreenShot)}
        selectScratchPadTool={this.props.selectScratchPadTool}
        selectScratchPadShape={this.props.selectScratchPadShape}
        selectScratchPadPenColor={this.props.selectScratchPadPenColor}
        shapeSelected={shapeSelected}
        toggleFreeDrawing={showTool(TOOL_NAME.PEN, tools.turnOnDrawingMode)}
        turnOnDelete={showTool(TOOL_NAME.DEL, tools.deleteSelectedObject)}
        redoEnabled={redoEnabled}
        undoEnabled={undoEnabled}
      />
    );
  }
}

export default ScratchPad;
