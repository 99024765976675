import * as React from 'react';
import { focusMessageInput } from '../../utils/focusMessageInput';
import { SkipLinkWrapper } from './styled';

interface SkipLinksProps {
  label: string;
}

const SkipLink = ({ label }: SkipLinksProps) => (
  <SkipLinkWrapper onClick={focusMessageInput}>{label}</SkipLinkWrapper>
);

export default SkipLink;
