import * as React from 'react';
import { ModalWrapper as ModalWrapperStyed } from '@chegg-tutors-chat/shared/components/Modal/styled';
import ToastWrapper from '../Toast';

/**
 * @prop children - Modal
 */

export interface BaseModalProps {
  isOpen: boolean;
  onClose?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  shouldCloseOnPressEsc?: boolean;
  shouldCloseOnClickOutside?: boolean;
  hasBackgroundOverlay?: boolean;
  headerText?: string;
  subHeaderText?: string;
  role?: 'dialog' | 'alertdialog';
  primaryButtonText?: string;
  isPrimaryButtonLoading?: boolean;
  primaryButtonClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonClick?: () => void;
  hasCloseButton?: boolean;
}

export type PayloadType = Lesson['id'];

export interface ModalCallbackProps {
  primaryButtonClick?: (payload?: PayloadType) => void;
  secondaryButtonClick?: (payload?: PayloadType) => void;
  onClose?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const DefaultModalProps: BaseModalProps = {
  hasBackgroundOverlay: true,
  hasCloseButton: true,
  isOpen: false,
  role: 'dialog',
  shouldCloseOnClickOutside: false,
  shouldCloseOnPressEsc: true
};
// To prevent it to get mutated
Object.freeze(DefaultModalProps);

export interface ModalWrapperProps {
  children?: React.ReactNode | React.ReactNode[];
  modalName?: string;
  asToast?: boolean;
}

/**
 * ModalWrapper - It wraps modal and manage state to show and hide modal.
 * @props - children
 */
const ModalWrapper: React.FunctionComponent<ModalWrapperProps> = ({
  children = null,
  modalName,
  asToast
}) => {
  // check for toast
  if (asToast) {
    return <ToastWrapper>{children}</ToastWrapper>;
  }
  return children ? (
    <ModalWrapperStyed data-testid={modalName}>{children}</ModalWrapperStyed>
  ) : null;
};

export default ModalWrapper;
