import * as SendBird from 'sendbird';
import config from '@chegg-tutors-chat/shared/config';
import { getGroupChannel } from '@chegg-tutors-chat/shared/providers/sendbird/sendbird';
import { scratchPadStorage } from '@chegg-tutors-chat/shared/utils';

/**
 * Call back function that will be passed to initSendBird to
 * get group a group channel by channel_url and lesson to the store.
 *
 * @param user
 * @param channelUrl
 */
export const onConnect = async (user?: SendBird.User, channelUrl?: string) => {
  if (!channelUrl || !user) {
    return;
  }

  /**
   * Sets sendbird group channel messages limit
   */
  config.set('PREVIOUS_MESSAGE_LIMIT', 0);
  const groupChannel = await getGroupChannel(channelUrl);
  if (groupChannel) {
    scratchPadStorage.clearOld([groupChannel.url]);
  }
};
