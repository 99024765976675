import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { Lead } from '@chegg-tutors-chat/shared/styled/sunkist/Typography';
import { Modal } from '@chegg/fanta';
import * as React from 'react';

/**
 * @prop modalOptions - object containing a string with the tutor's name
 */
export interface CloseLessonProps extends BaseModalProps {
  modalOptions: {
    tutorName: string;
  };
}

/**
 * Modal that displays a prompt to end the lesson
 *
 * @props CloseLessonProps
 */
const CloseLesson: React.SFC<CloseLessonProps> = ({
  isOpen,
  modalOptions = { tutorName: '' },
  ...restProps
}) => {
  const { tutorName } = modalOptions;
  return (
    <Modal
      isOpen={isOpen}
      headerText={'Ready to end chat?'}
      primaryButtonText={'Yes'}
      secondaryButtonText={'Not yet'}
      {...restProps}
    >
      <Lead>
        We will tell {tutorName || 'this expert'} you don{"'"}t have any more questions.
      </Lead>
    </Modal>
  );
};

export default CloseLesson;
