import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('user');

export interface SetAvailabilityPayload {
  available: UserState['available'];
}

export const addUser = actionCreator<Partial<UserState>>('ADD_USER');
// REFACTOR see CT-1136
export const addAvailableTutor = actionCreator<UserState>('ADD_AVAILABLE_TUTOR');
export const setTutorAvailability = actionCreator<SetAvailabilityPayload>(
  'SET_TUTOR_AVAILABILITY'
);

export const getTutorAvailability = actionCreator('GET_TUTOR_AVAILABILITY');
