import SendBird from 'sendbird';
import { CHAT_CONNECTION } from '@chegg-tutors-chat/shared/constants';
import {
  ChatConnectStatuses,
  ChatReconnectStatuses,
  setChatConnectionStatus,
  setChatReconnectionStatus
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import rootStore from '@chegg-tutors-chat/shared/redux/rootStore';
import { captureError, captureTimeoutError } from '@chegg-tutors-chat/shared/utils';
import genericRequest from '@chegg-tutors-chat/shared/utils/genericRequest';
import * as Sentry from '@sentry/browser';
import {
  addLessonAction,
  addMessageAction,
  addUserAction,
  setOtherUserTypingStatusAction,
  setUserLeftAction
} from './actions';
import { GLOBAL_HANDLER, SEND_BIRD_APP_ID } from './config';
import { FormattedSendBirdMessage, SendBirdMessage } from './helper';

export interface UserData {
  userId: string;
  accessToken?: string;
  email?: string;
  name?: string;
  question?: string;
}

const isOffline = process.env.REACT_APP_IS_OFFLINE;

const SB = new SendBird({ appId: SEND_BIRD_APP_ID });
const ChannelHandler = new SB.ChannelHandler();
const ConnectionHandler = new SB.ConnectionHandler();

/**
 * Dispatches action to add user to the store.
 *
 * @param user
 */
const dispatchAddUser = (user: SendBird.User) => {
  const store = rootStore.getStore();

  store.dispatch(addUserAction(user));
};

/**
 * Dispatches action to add message with or without file to the store.
 *
 * @param message
 */
export const dispatchAddMessage = (
  message: SendBirdMessage | FormattedSendBirdMessage
) => {
  const store = rootStore.getStore();

  store.dispatch(addMessageAction(message));
};

/**
 * Dispatches action to add lesson to store.
 *
 * @param groupChannel
 * @param user
 */
export const dispatchAddLesson = (
  groupChannel: SendBird.GroupChannel,
  user: SendBird.User
) => {
  const store = rootStore.getStore();
  store.dispatch(addLessonAction(groupChannel, user));
};

/**
 * Function that dispatches setOtherUserTypingStatusAction action to redux store
 * @param groupChannelUrl
 * @param isTyping
 */
const dispatchSetOtherUserTypingStatus = (groupChannelUrl: string, isTyping: boolean) => {
  const store = rootStore.getStore();

  store.dispatch(setOtherUserTypingStatusAction(groupChannelUrl, isTyping));
};

/**
 * Function that dispatches setChatConnectionStatus action to redux store
 * @param connectionType
 */
export const dispatchConnect = (connectionType: ChatConnectStatuses) => {
  const store = rootStore.getStore();
  store.dispatch(setChatConnectionStatus({ status: connectionType }));
};

/**
 * Function that dispatches setChatReconnectionStatus action to redux store
 * @param connectionType
 */
export const dispatchReconnect = (connectionType: ChatReconnectStatuses) => {
  const store = rootStore.getStore();
  store.dispatch(setChatReconnectionStatus({ status: connectionType }));
};

/**
 * Disptaches an action when a user leaves the lesson (chat channel)
 *
 * @param groupChannelUrl
 * @param user
 */
export const dispatchSetUserLeftAction = (
  groupChannelUrl: string,
  user: SendBird.User
) => {
  const store = rootStore.getStore();
  store.dispatch(setUserLeftAction(groupChannelUrl, user));
};

/**
 * Docs https://docs.sendbird.com/javascript/group_channel#3_retrieve_a_group_channel_by_its_url
 *
 * @param channelUrl
 */
export const getGroupChannel = async (
  channelUrl: string
): Promise<SendBird.GroupChannel | void> => {
  return await genericRequest<SendBird.GroupChannel>((): Promise<
    SendBird.GroupChannel
  > => {
    return new Promise((resolve, reject) => {
      SB.GroupChannel.getChannel(
        channelUrl,
        (groupChannel: SendBird.GroupChannel, errorVal: SendBird.SendBirdError) => {
          if (errorVal) {
            return reject(errorVal);
          }
          return resolve(groupChannel);
        }
      );
    });
  }, captureTimeoutError('getGroupChannelSendbird'));
};

/**
 * Function to initialize sendbird *channel* handlers
 * and add appropriate items to store such as messages.
 * Docs: https://docs.sendbird.com/javascript/event_handler#3_channel_handler
 *
 * Creates Channelhandler to listen to sendbird channel events.
 *
 */
const initChannelHandlers = () => {
  ChannelHandler.onMessageReceived = (
    _channel: SendBird.BaseChannel,
    message: SendBirdMessage
  ) => {
    if (message) {
      dispatchAddMessage(message);
    }
  };

  ChannelHandler.onUserJoined = (
    groupChannel: SendBird.GroupChannel,
    user: SendBird.User
  ) => {
    dispatchAddLesson(groupChannel, user);
  };

  /**
   *
   * The event handler handles typing status from the student side. Due to the tutor being
   * a invited user we have to set tutor typing manually so this handles only student side.
   *
   */
  ChannelHandler.onTypingStatusUpdated = (groupChannel: SendBird.GroupChannel) => {
    dispatchSetOtherUserTypingStatus(groupChannel.url, groupChannel.isTyping());
  };

  ChannelHandler.onUserLeft = (
    groupChannel: SendBird.GroupChannel,
    user: SendBird.User
  ) => {
    dispatchSetUserLeftAction(groupChannel.url, user);
  };

  SB.addChannelHandler(GLOBAL_HANDLER, ChannelHandler);
};

/**
 * Initializes sendbird *connection* event handlers with
 * a purpose to keep informed of changes related to the SendBird server connection.
 * Defines and registers multiple connection event handlers.
 * Docs: https://docs.sendbird.com/javascript/event_handler#3_add_and_remove_a_connection_event_handler
 *
 */
const initConnectionHandlers = () => {
  ConnectionHandler.onReconnectStarted = () => {
    dispatchReconnect(CHAT_CONNECTION.RECONNECTION_STARTED);
  };

  ConnectionHandler.onReconnectSucceeded = () => {
    dispatchReconnect(CHAT_CONNECTION.RECONNECTION_SUCCEEDED);
  };

  ConnectionHandler.onReconnectFailed = () => {
    Sentry.withScope(scope => {
      const error = new Error(
        `SENDBIRD CONNECTION ${CHAT_CONNECTION.RECONNECTION_FAILED}`
      );
      scope.setTag('SENDBIRD_CONNECTION', CHAT_CONNECTION.RECONNECTION_FAILED);
      Sentry.captureException(error);
    });
    dispatchReconnect(CHAT_CONNECTION.RECONNECTION_FAILED);
  };
  SB.addConnectionHandler(GLOBAL_HANDLER, ConnectionHandler);
};

/**
 * SendBird function that takes a store and queryparams containing user data.
 * Creates sendBird instance.
 * Connects user and adds to store.
 *
 * Docs: https://docs.sendbird.com/javascript/authentication#2_authentication
 * Docs: https://docs.sendbird.com/javascript/event_handler#2_event_handler
 *
 * @param store
 * @param queryParams
 */

export const initSendbird = (
  userData: UserData,
  onConnect: (user?: SendBird.User, channelUrl?: string) => void,
  channelUrl?: string
) => {
  if (isOffline) {
    return;
  }
  /** if we are already in a connection attempt or connected don't allow
   * another connection.
   */

  const connectionState = SB.getConnectionState();
  if (connectionState === 'OPEN' || connectionState === 'CONNECTING') {
    return;
  }
  const { userId, accessToken } = userData;
  if (!userId) {
    const error = new Error('Error: Missing userId in initSendbird.');
    captureError(error);
    return;
  }

  /**
   * Initialize *channel* event handlers
   */
  initChannelHandlers();

  /**
   * Initialize *connection* handlers
   */
  initConnectionHandlers();

  /**
   * Right now the students should have an accessToken which will be refreshed
   * every time they create a ticket. The code below says if we have an accessToken
   * then use it, otherwise - don't. Tutors won't have an accessToken.
   */
  dispatchConnect(CHAT_CONNECTION.CONNECTION_STARTED);
  if (accessToken) {
    SB.connect(
      userId,
      accessToken,
      (user: SendBird.User, error: SendBird.SendBirdError) => {
        if (error) {
          dispatchConnect(CHAT_CONNECTION.CONNECTION_FAILED);
          captureError(error);
          return;
        }
        onConnect(user, channelUrl);
        dispatchConnect(CHAT_CONNECTION.CONNECTION_SUCCEEDED);
      }
    );
  } else {
    SB.connect(userId, (user: SendBird.User, error: SendBird.SendBirdError) => {
      if (error) {
        dispatchConnect(CHAT_CONNECTION.CONNECTION_FAILED);
        captureError(error);
        return;
      }
      dispatchAddUser(user);
      onConnect(user, channelUrl);
      dispatchConnect(CHAT_CONNECTION.CONNECTION_SUCCEEDED);
    });
  }
};

/**
 * Function that return SendBird instance
 */
export const getSendBirdInstance = (): SendBird.SendBirdInstance => {
  return SB;
};
