import * as Sentry from '@sentry/browser';

const PREFIX = 'scratch_pad_';

const prependPrefixToKey = (keys: string[]) => {
  return keys.map(key => PREFIX.concat(key));
};

/**
 * A helper function that clears localStorage.
 * If you provide an array of keys, the function will skip those from clearing.
 *
 * @params keys - an array of keys that needs to be skipped from clearing
 */

const clearOld = (keys: string[]) => {
  if (!window.localStorage) {
    // localStorage is NOT supported
    return;
  }
  const prependedKeys = prependPrefixToKey(keys);
  const setKeys = new Set(prependedKeys);
  const localStorageKeys = Object.keys(localStorage);
  localStorageKeys.forEach(key => {
    if (!setKeys.has(key) && key.indexOf(PREFIX) === 0) {
      localStorage.removeItem(key);
    }
  });
};

/**
 * A helper function that clears localStorage by key.
 *
 * @params key - a string key
 */
const clear = (key: string) => {
  if (!window.localStorage || localStorage.length === 0) {
    return;
  }

  localStorage.removeItem(PREFIX.concat(key));
};

/**
 * A helper function that takes a key and a value and sets that in local storage.
 * Additionally we prefix 'scratchPad' to the lessonId to make a unique key for scratchpad only.
 *
 * @params key - a string key
 * @params value - a value to store in local storage
 */
const set = <T>(key: string, value: T) => {
  if (!window.localStorage) {
    // localStorage is NOT supported
    return;
  }

  try {
    const stringifiedVal = JSON.stringify(value);
    localStorage.setItem(PREFIX.concat(key), stringifiedVal);
  } catch (e) {
    const error = new Error('Error: Can not save data to local storage.');
    Sentry.captureException(error);
    console.error(e);
  }
};

/**
 * A helper function that gets an item from localStorage by key.
 *
 * @params key - a string key
 */
const get = <T>(key: string): T | null => {
  let item = null;
  if (!window.localStorage) {
    return item;
  }

  try {
    item = localStorage.getItem(PREFIX.concat(key)) as string;
    item = JSON.parse(item) as T;
  } catch (e) {
    const error = new Error('Error: Can not get data from local storage.');
    Sentry.captureException(error);
    console.error(e);
  }

  return item as T;
};

export default {
  clear,
  clearOld,
  get,
  set
};
