import styled from 'styled-components';

/*
 * LayoutProps
 * @props display - string that shows how to show the LessonArea layout.
 */
interface LayoutProps {
  display: string;
}

export const Layout = styled.div`
  border-left: 1px solid #cccccc;
  flex: 0 0 60px;
  display: ${(props: LayoutProps) => props.display};
`;

export const ToolbarLayout = styled.div`
  flex: 0 0 60px;
`;
