import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FileTarget from '@chegg-tutors-chat/shared/components/FileTarget';
import { addAttachment } from '@chegg-tutors-chat/shared/redux/modules/client/actions';

/**
 * @prop style - style props to be applied to the element
 */
export interface DragAttachmentMapStateProps {
  style?: { [key: string]: any };
}

/**
 * @prop onImage - callback to be executed when an image is uploaded
 * @prop onFile - callback to be executed when a file is uploaded
 */
export interface DragAttachmentDispatchProps {
  onImage: (i: HTMLImageElement) => void;
  onFile: (i: File) => void;
}

function mapStateToProps(_state: GlobalState): DragAttachmentMapStateProps {
  return {
    style: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%'
    }
  };
}
export const mapDispatchToProps = (dispatch: Dispatch): DragAttachmentDispatchProps => {
  const addFileAttachment = (file: File | HTMLImageElement) => {
    dispatch(addAttachment({ fileSrc: file }));
  };

  return {
    onFile: addFileAttachment,
    onImage: addFileAttachment
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileTarget);
