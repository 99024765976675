import { connect } from 'react-redux';
import config from '@chegg-tutors-chat/shared/config';
import * as lessonsSelectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { isMessagePinned } from '@chegg-tutors-chat/shared/redux/modules/pins/selectors';
import ConversationMessage, { MessageProps } from './Message';

export function mapStateToProps(state: GlobalState, ownProps: MessageProps) {
  const { message } = ownProps;
  const currentLessonId = lessonsSelectors.getSelectedLessonId(state);
  const isPinned = isMessagePinned(state, currentLessonId, message.messageId);
  const showPinIcon: boolean = config.get('TOOLS').PINNED !== null;

  return { isPinned, showPinIcon };
}

export default connect(mapStateToProps, null)(ConversationMessage);
