import moment from 'moment';

/**
 * Takes firstName and lastName and returns them in
 * format: Firstname L.
 *
 * @param firstName
 * @param lastName
 */
export const formatDisplayName = (firstName?: string, lastName?: string): string => {
  if (!lastName && !firstName) {
    return '';
  }

  if (!firstName && lastName) {
    return lastName.charAt(0).toUpperCase() + lastName.slice(1);
  }

  if (!lastName && firstName) {
    return firstName.charAt(0).toUpperCase() + firstName.slice(1);
  }

  return `${firstName &&
    firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${lastName &&
    lastName.charAt(0).toUpperCase()}.`;
};

/**
 * Converts first letter of a string to Uppercase
 *
 * @param str - a string to format
 */
export const formatStatus = (str: string) => {
  return str && str.charAt(0).toUpperCase() + str.substr(1);
};

/*
 * @function formatTime
 *
 * @param time - number in miliseconds
 */
export const formatTime = (time: number): string => {
  return moment
    .utc(time)
    .local()
    .format('hh:mm A');
};
