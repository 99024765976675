import config from '@chegg-tutors-chat/shared/config';
import { AnalyticsClickEvent } from '@chegg-tutors-chat/shared/providers/analytics/interfaces';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { CLICK_EVENT_CONFIG, EL_REGION, EL_TYPE, EVENT_NAME } from '../constants';

export interface FormatAnalyticsClickEventPayload {
  eventState: GlobalState;
  eventName: EVENT_NAME;
  eventRegion: EL_REGION;
  eventType: EL_TYPE;
  lessonId?: Lesson['id'];
}

export const formatAnalyticsClickEvent = (
  data: FormatAnalyticsClickEventPayload
): AnalyticsClickEvent => {
  const getOtherUserId = config.get('getOtherUserId');
  const currentLessonId = data.lessonId || getSelectedLessonId(data.eventState);
  return {
    ...CLICK_EVENT_CONFIG,
    eventName: data.eventName,
    eventValue: [
      {
        elName: data.eventName,
        elRegion: data.eventRegion,
        elType: data.eventType,
        elValue: getSelectedLessonId(data.eventState)
      }
    ],
    studentOrTutorsID: getOtherUserId && getOtherUserId(data.eventState, currentLessonId)
  };
};
