import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { updateYourNote } from '@chegg-tutors-chat/shared/redux/modules/notes/actions';
import UpdateYourNoteForm, {
  UpdateYourNoteFormDispatchProps
} from '../UpdateYourNoteForm';

export const mapDispatchToProps = (
  dispatch: Dispatch
): UpdateYourNoteFormDispatchProps => {
  const onSubmit = (lessonId: Lesson['id'], note: Note) => {
    dispatch(updateYourNote({ lessonId, note }));
  };

  return {
    onSubmit
  };
};

export default connect(null, mapDispatchToProps)(UpdateYourNoteForm);
