import * as React from 'react';
import {
  NoteData,
  NoteItem,
  NoteLabel,
  NoteMoreInfo,
  NoteMoreInfoWrapper
} from './styled';

export interface LatestNoteProps {
  note: Note;
}

const LatestNote: React.SFC<LatestNoteProps> = ({ note }) => {
  const { complexity, subtopic, assignmentType, moreInfo, tutor } = note;
  const { firstName = '', lastName = '' } = tutor || {};
  return (
    <>
      <NoteItem>
        <NoteLabel>
          Note from {firstName} {lastName.charAt(0)}
        </NoteLabel>
      </NoteItem>
      <NoteItem>
        <NoteLabel>Lesson Complexity: </NoteLabel>
        <NoteData>{complexity}</NoteData>
      </NoteItem>
      <NoteItem>
        <NoteLabel>Subtopic: </NoteLabel>
        <NoteData>{subtopic}</NoteData>
      </NoteItem>
      <NoteItem>
        <NoteLabel>Assignment type: </NoteLabel>
        <NoteData>{assignmentType}</NoteData>
      </NoteItem>
      <NoteMoreInfoWrapper>
        <NoteMoreInfo>{moreInfo}</NoteMoreInfo>
      </NoteMoreInfoWrapper>
    </>
  );
};

export default LatestNote;
