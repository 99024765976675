import * as React from 'react';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import { noop } from '@chegg-tutors-chat/shared/utils';
import { PrimaryButton } from '@chegg/fanta';
import { Layout } from './styled';

/**
 * @interface AddEditYourNoteButtonDispatchProps
 *
 * @prop showModal - action to show a modal
 */
export interface AddEditYourNoteButtonDispatchProps {
  showModal?: any;
}

/**
 * @interface AddEditYourNoteButtonProps
 *
 * @prop yourNoteAdded - indicates whether you have already added a note
 */
export interface AddEditYourNoteButtonProps {
  yourNoteAdded: boolean;
}

/**
 * @component AddEditYourNoteButton - displays a button that opens the EditNoteModal
 *
 * @props AddEditYourNoteButtonProps
 */
const AddEditYourNoteButton: React.SFC<AddEditYourNoteButtonProps &
  AddEditYourNoteButtonDispatchProps> = ({ showModal = noop, yourNoteAdded }) => {
  const buttonText = `${yourNoteAdded ? 'Edit' : 'Add'} your note`;
  return (
    <Layout>
      <PrimaryButton
        size={'medium'}
        onClick={() => showModal({ modalName: MODAL.UPDATE_YOUR_NOTE_ASSIGNMENT_PANEL })}
      >
        {buttonText}
      </PrimaryButton>
    </Layout>
  );
};

export default AddEditYourNoteButton;
