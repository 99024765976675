import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as lessonsSelectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as modalActions from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { captureError } from '@chegg-tutors-chat/shared/utils';
import AcceptRejectLessonRemovedManager, {
  AcceptRejectLessonRemovedManagerPropsType
} from './AcceptRejectLessonRemovedManager';

export function mapStateToProps(
  state: GlobalState,
  passedProps: AcceptRejectLessonRemovedManagerPropsType
): AcceptRejectLessonRemovedManagerPropsType {
  const id =
    (passedProps && passedProps.modalOptions && passedProps.modalOptions.lessonId) ||
    lessonsSelectors.getSelectedLessonId(state);
  const ticketInfo = lessonsSelectors.getLessonTicket(state, id);

  if (!ticketInfo) {
    const error = new Error('In Accept/Reject modal we are missing ticket information');
    captureError(error);
  }
  return {
    ...passedProps,
    ticketInfo
  };
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  return {
    handleCloseModal
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptRejectLessonRemovedManager);
