import { connect } from 'react-redux';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import * as clientActions from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import * as lessonsSelectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as actions from '@chegg-tutors-chat/shared/redux/modules/messages/actions';
import MessageInput, {
  MessageInputDispatchProps,
  MessageInputProps
} from './MessageInput';

export function mapStateToProps(state: GlobalState): MessageInputProps {
  const lessonId = lessonsSelectors.getSelectedLessonId(state) || '';
  const disabled =
    !lessonId ||
    lessonsSelectors.getLessonStatus(state, lessonId) === LESSON_STATUS.CLOSED;
  const clientLessonInfo = getClientInfo(state, lessonId);
  const { attachments = [], inputText = '', uploading = false, uploadingError = null } =
    clientLessonInfo || {};

  return {
    attachment: attachments[0] ? attachments[0].fileSrc : undefined,
    disabled,
    inputText,
    lessonId,
    uploadingError,
    uploadingStatus: uploading
  };
}

export const mapDispatchToProps: MessageInputDispatchProps = {
  updateClientHasFocus: clientActions.updateClientHasFocus,
  addAttachment: clientActions.addAttachment,
  removeAttachment: clientActions.removeAttachment,
  sendMessage: actions.sendMessage,
  setClientUserTyping: clientActions.setClientUserTyping,
  updateInputText: clientActions.updateInputText
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);
