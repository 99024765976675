import * as React from 'react';
import ChatAvatar from '@chegg-tutors-chat/shared/components/Avatar';
import MessageAttachment from '@chegg-tutors-chat/shared/components/MessageAttachment';
import PinUnPinButton from '@chegg-tutors-chat/shared/components/Pins';
import { formatDisplayName, formatTime, linkify } from '@chegg-tutors-chat/shared/utils';
import { scrollToBottom } from '../utils';
import {
  AttachmentLayout,
  AvatarWrapper,
  FirstMessageInGrouping,
  MessageLayout,
  Name,
  SenderWrapper,
  Text,
  Time
} from './styled';

/**
 * @prop message - a message
 * @prop loggedInUser - the user who is currently logged into the console
 * @prop displayInNewMessageGrouping - whether to display the message in a new grouping
 */
export interface MessageProps {
  message: Message;
  loggedInUser: UserState | undefined;
  displayInNewMessageGrouping: boolean;
  isPinned?: boolean;
  showPinIcon?: boolean;
}

/**
 * ConversationMessage - renders a message, visually grouped by user
 *
 * @param MessageProps
 */
function ConversationMessage({
  message,
  loggedInUser,
  isPinned,
  showPinIcon,
  displayInNewMessageGrouping = true
}: MessageProps) {
  const {
    sender,
    createdAt,
    fileName,
    fileType,
    fileUrl,
    messageId,
    messageType
  } = message;
  const { firstName, lastName, profileUrl, userId } = sender;
  const displayName = formatDisplayName(firstName, lastName);

  const MessageBoxWrapper = displayInNewMessageGrouping
    ? FirstMessageInGrouping
    : MessageLayout;
  let isLoggedInUser = false;
  if (loggedInUser) {
    isLoggedInUser = loggedInUser.userId === userId;
  }

  return (
    <MessageBoxWrapper tabIndex={0} isPinned={isPinned} key={messageId}>
      {displayInNewMessageGrouping && (
        <SenderWrapper>
          <AvatarWrapper>
            <ChatAvatar
              {...{ firstName, lastName, profileUrl }}
              loggedInUser={isLoggedInUser}
              messageType={messageType}
            />
          </AvatarWrapper>
          {displayName && <Name>{displayName}</Name>}
          <Time>{formatTime(createdAt)}</Time>
        </SenderWrapper>
      )}
      <Text>{linkify(message.message, { openInNewTab: true })}</Text>
      {fileUrl && (
        <AttachmentLayout>
          <MessageAttachment
            fileName={fileName}
            fileType={fileType}
            fileUrl={fileUrl}
            onAttachmentLoad={scrollToBottom}
          />
        </AttachmentLayout>
      )}
      {showPinIcon && <PinUnPinButton message={message} />}
    </MessageBoxWrapper>
  );
}

export default ConversationMessage;
