import * as React from 'react';
import LessonExitLinks from 'src/components/LessonExitLinks';
import { LESSON_STATUS, NO_NAME } from '@chegg-tutors-chat/shared/constants';
import { formatDisplayName, formatStatus } from '@chegg-tutors-chat/shared/utils';
import { Heading5 } from '@chegg/fanta';
import { LessonHeaderPropsFromState } from './container';
import { LessonHeader, LessonHeaderMessageWrapper, StatusBall } from './styled';

/**
 * @prop lessonStatus - status of the current lesson
 * @prop tutorInfo - info about the tutor
 * @prop status - status of the other user (tutor)
 */
export interface LessonHeaderMessageProps {
  lessonStatus: LessonHeaderProps['lessonStatus'];
  tutorInfo: LessonHeaderProps['tutorInfo'];
  status?: Status;
}

/**
 * LessonHeaderMessage - renders the message to be displayed in the LessonHeader
 * @props LessonHeaderMessage Props
 */
export const LessonHeaderMessage: React.FunctionComponent<LessonHeaderMessageProps> = ({
  lessonStatus,
  tutorInfo,
  status = ''
}) => {
  if (lessonStatus === LESSON_STATUS.CLOSED) {
    return (
      <div>
        <Heading5 as="h1">Chat ended</Heading5>
      </div>
    );
  } else if (tutorInfo && tutorInfo.userId) {
    const { firstName, lastName } = tutorInfo;
    const tutorName = formatDisplayName(firstName, lastName) || NO_NAME;
    return (
      <LessonHeaderMessageWrapper>
        <Heading5 as="h1">Chat with {tutorName}</Heading5>
        {status && <StatusBall status={status} /> && (
          <Heading5>{formatStatus(status)}</Heading5>
        )}
      </LessonHeaderMessageWrapper>
    );
  } else {
    return (
      <div>
        <Heading5 as="h1">Finding you the right expert</Heading5>
      </div>
    );
  }
};

export type LessonHeaderProps = LessonHeaderPropsFromState;

/**
 * LessonHeaderDisplay - renders the header containing info about the Lesson
 * @props LessonHeaderProps
 * @props LessonHeaderDispatchProps
 */
const LessonHeaderDisplay: React.FunctionComponent<LessonHeaderProps> = ({
  currentLessonId = '',
  lessonStatus = LESSON_STATUS.FINDING_TUTOR,
  tutorInfo
}) => {
  if (currentLessonId === '') {
    return null;
  }

  return (
    <LessonHeader>
      <LessonHeaderMessage tutorInfo={tutorInfo} lessonStatus={lessonStatus} />
      <LessonExitLinks />
    </LessonHeader>
  );
};

export default LessonHeaderDisplay;
