import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { HCV_REPORT_ON } from '@chegg-tutors-chat/shared/featureFlags/index';
import { closeModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { getModal } from '@chegg-tutors-chat/shared/redux/modules/modals/selectors';
import { captureError } from '@chegg-tutors-chat/shared/utils';
import ErrorBanner from '../ErrorBanner';
import AcceptReject from './content/AcceptReject';
import AcceptRejectLessonRemovedManager from './content/AcceptRejectLessonRemovedManager';
import { CloseLessonStudent, CloseLessonTutor } from './content/CloseLesson';
import CloseLessonAndRedirectToHome from './content/CloseLessonAndRedirectToHome';
import ImageModal from './content/ImageModal';
import LessonFeedbackForm from './content/LessonFeedback';
import LessonRemoved from './content/LessonRemoved';
import LogoutModal from './content/Logout';
import RejectModal from './content/RejectModal';
import ReportModal from './content/ReportModal';
import RerouteModal from './content/RerouteModal';
import UpdateYourNote from './content/UpdateYourNote';
import Modal, { BaseModalProps, DefaultModalProps } from './Modal';

interface ModalMap {
  [key: string]: {
    Component: React.ComponentType<any>;
    /**
     * These are used to default the props.
     * For example hasCloseButton would be set here and then
     * passed to the component in mapStateToProps.
     */
    props: Partial<BaseModalProps & ModalRef>;
    /**
     * If a component needs customized focus behavior. Set this
     * true and component will receive ref object as prop.
     */
    hasFocusRef?: boolean;
  };
}

interface StateProps {
  modal: ModalsState;
}

interface DispatchProps {
  handleCloseModal: () => void;
}

export const modalMap: ModalMap = {
  acceptRejectLessonRemovedManager: {
    Component: AcceptRejectLessonRemovedManager,
    props: {
      ...DefaultModalProps
    }
  },
  acceptReject: {
    Component: AcceptReject,
    props: {
      ...DefaultModalProps,
      hasCloseButton: false,
      shouldCloseOnClickOutside: false,
      shouldCloseOnPressEsc: false
    }
  },
  closeLessonAndRedirectToHome: {
    Component: CloseLessonAndRedirectToHome,
    props: {
      ...DefaultModalProps
    }
  },
  closeLessonStudent: {
    Component: CloseLessonStudent,
    props: {
      ...DefaultModalProps
    }
  },
  closeLessonTutor: {
    Component: CloseLessonTutor,
    props: {
      ...DefaultModalProps
    }
  },
  imageModal: {
    Component: ImageModal,
    props: {
      ...DefaultModalProps
    }
  },
  lessonFeedBack: {
    Component: LessonFeedbackForm,
    props: {
      ...DefaultModalProps,
      hasCloseButton: false,
      shouldCloseOnClickOutside: false,
      shouldCloseOnPressEsc: false
    },
    hasFocusRef: true
  },
  lessonRemoved: {
    Component: LessonRemoved,
    props: {
      ...DefaultModalProps
    }
  },
  rejectModal: {
    Component: RejectModal,
    props: {
      ...DefaultModalProps
    }
  },
  rerouteModal: {
    Component: RerouteModal,
    props: {
      ...DefaultModalProps
    }
  },
  // This adds reportModal to the map when inside dev environment.
  reportModal: HCV_REPORT_ON && {
    Component: ReportModal,
    props: {
      ...DefaultModalProps
    }
  },
  updateYourNoteAssignmentPanel: {
    Component: UpdateYourNote.AssignmentPanelFlow,
    props: {
      ...DefaultModalProps
    }
  },
  logoutModal: {
    Component: LogoutModal,
    props: {
      ...DefaultModalProps
    }
  }
};

const toastMap = {
  errorBanner: {
    Component: ErrorBanner,
    props: {
      ...DefaultModalProps,
      hasCloseButton: false,
      shouldCloseOnClickOutside: false,
      shouldCloseOnPressEsc: false
    }
  }
};

export function mapStateToProps(state: GlobalState) {
  const modal = getModal(state);
  return {
    modal
  };
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  return {
    handleCloseModal
  };
};

export function mergeProps(stateProps: StateProps, dispatchProps: DispatchProps) {
  const modal = stateProps.modal;
  /**
   * This is for anytime we don't hava modal to show.
   * we can't get the Component and props.
   */
  if (!modal.modalName) {
    return {};
  } else if (!modalMap[modal.modalName]) {
    const error = new Error(`Cannot find modal named ${modal.modalName}`);
    captureError(error);
  }
  // handle toasts
  let children = null;
  const focusRef = React.createRef() as React.RefObject<HTMLElement>;
  const modalOptions = modal.modalOptions || {};
  // asToast is needed for the Modal component to know when to add title + description
  if (modal.asToast && toastMap[modal.modalName]) {
    const { Component, props, hasFocusRef } = toastMap[modal.modalName];
    children = (
      <Component
        title={modalOptions.title}
        description={modalOptions.description}
        {...props}
        focusRef={hasFocusRef && focusRef}
        isOpen
      />
    );
  } else if (modalMap[modal.modalName]) {
    const { Component, props, hasFocusRef } = modalMap[modal.modalName];
    let { hasCloseButton, shouldCloseOnClickOutside, shouldCloseOnPressEsc } = props;

    /**
     * If modal doesn't have close button, it should not close
     * with escape or clicking outside of modal.
     */
    if (modalOptions.hasCloseButton === false) {
      hasCloseButton = false;
      shouldCloseOnClickOutside = false;
      shouldCloseOnPressEsc = false;
    }

    children = (
      <Component
        {...props}
        focusRef={hasFocusRef && focusRef}
        isOpen
        hasCloseButton={hasCloseButton}
        onClose={hasCloseButton && dispatchProps.handleCloseModal}
        shouldCloseOnClickOutside={shouldCloseOnClickOutside}
        shouldCloseOnPressEsc={shouldCloseOnPressEsc}
        modalOptions={modalOptions}
      />
    );
  } else {
    const error = new Error(
      `Cannot find modal named ${modal.modalName} or associated component`
    );
    captureError(error);
  }

  return {
    children,
    modalName: modal.modalName,
    asToast: modal.asToast
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Modal);
