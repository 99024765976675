import styled from 'styled-components';
import { TextArea } from '@chegg-tutors-chat/shared/components/Forms/styled';
import color from '@chegg-tutors-chat/shared/styled/constants/colors';

export const RequiredErrorMessage = styled.h3`
  color: ${color.D3332A};
`;

export const AdditionalInfo = styled(TextArea)`
  height: 88px;
`;

export const FooterButtonsWrapper = styled.div`
  padding-top: 10px;
`;

export const Form = styled.form`
  line-height: 1rem;
  padding: 2px;
`;
