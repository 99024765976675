import * as React from 'react';
import TypingIndicatorText from './styled';

/**
 * @prop isTyping - whether or not the other user is typing
 * @prop name - name of user
 */
export interface TypingIndicatorProps {
  isTyping?: boolean;
  name?: string;
}

const TypingIndicator = ({ isTyping, name }: TypingIndicatorProps) => {
  const indicatorText = isTyping ? `${name} is responding...` : '';
  return (
    <TypingIndicatorText aria-live="polite" aria-atomic="true" role="log">
      {indicatorText}
    </TypingIndicatorText>
  );
};

export default TypingIndicator;
