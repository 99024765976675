import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

/**
 * @prop status - the user status
 */
export interface Status {
  status: string;
}

const config = {
  border: `solid 2px ${COLORS.CCCCCC}`,
  color: {
    away: COLORS.FFB70D,
    default: COLORS.FFFFFF,
    offline: COLORS.FFFFFF,
    online: COLORS['5CD068']
  }
};

const getBackgroundColor = (props: Status) => {
  return props.status ? config.color[props.status] : config.color.default;
};

const getBorder = (props: Status) => {
  return !props.status || props.status === 'offline' ? config.border : null;
};

/**
 * StatusIndicator - renders the status of user.
 */
const StatusIndicator = styled.span`
  height: 10px;
  width: 10px;
  border: ${(props: Status) => getBorder(props)};
  background-color: ${(props: Status) => getBackgroundColor(props)};
  border-radius: 50%;
`;

export default Object.assign(StatusIndicator, {});
