// env variables are strings so the only way this would be false is if it was an empty string. Adding this check will allow us to read whether it's "true" or "false"
// This flag is meant to the control HCV report feature that allows tutors to flag a student
export const HCV_REPORT_ON = process.env.REACT_APP_IS_OFFLINE === 'true';

// this flag is meant to on control whether we show the updated reroute modal or not
export const SHOW_UPDATED_REROUTE_FORM = process.env.REACT_APP_IS_OFFLINE === 'true';

// this flag is meant to control whether we should get tutor userId from query string params
export const READ_USERID_FROM_QUERYPARAMS = process.env.CHEGG_ENV !== 'prod';

// This flag is meant to the control of whether we redirect tutors to the signin page
// when we receive unauthorized from API calls to the BFF
export const NEW_TUTOR_AUTH_FLAG = 'enableNewTutorAuth';
