import { createSelector } from 'reselect';

export const getNotes = (state: Partial<GlobalState>): NotesState =>
  state.notes || ({} as NotesState);

/**
 * Function that returns lesson notes sorted with the most recent on top.
 *
 * @param state
 * @param lessonId
 */
export const getLessonNotes = (
  state: Partial<GlobalState>,
  lessonId: Lesson['id']
): Note[] => {
  const notesState = getNotes(state);

  if (!lessonId || !notesState || !notesState[lessonId] || !notesState[lessonId].notes) {
    return [];
  }

  const currentNotes = notesState[lessonId].notes;
  const sortedNotes = currentNotes
    .slice()
    .sort((a: Note, b: Note) => {
      return a.createdAt > b.createdAt ? 1 : -1;
    })
    .reverse();
  return sortedNotes;
};

/**
 * Function to return the most recent note which is at the beginning of the array.
 *
 */
export const getLatestNote = createSelector([getLessonNotes], (notes: Note[]):
  | Note
  | undefined => {
  return notes[0];
});

/**
 * Function that gets the the note set by the tutor.
 *
 * @param state
 * @param lessonId
 */
export const getYourNote = (
  state: Partial<GlobalState>,
  lessonId: Lesson['id']
): Note | undefined => {
  const notesState = getNotes(state);
  if (!lessonId || !notesState) {
    return undefined;
  }
  return notesState[lessonId] ? notesState[lessonId].yourNote : undefined;
};
