import * as React from 'react';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import AcceptRejectModal from '../AcceptReject';
import LessonRemovedModal from '../LessonRemoved';

export interface AcceptRejectLessonRemovedManagerProps {
  ticketInfo: TicketInfo;
  modalOptions: {
    lessonId: Lesson['id'];
  };
}

export interface AcceptRejectLessonRemovedManagerDispatchProps {
  handleCloseModal: () => void;
}

export type AcceptRejectLessonRemovedManagerPropsType = AcceptRejectLessonRemovedManagerDispatchProps &
  AcceptRejectLessonRemovedManagerProps;

const AcceptRejectLessonRemovedManager: React.FunctionComponent<AcceptRejectLessonRemovedManagerPropsType> = ({
  ticketInfo,
  handleCloseModal
}) => {
  return (
    <>
      {ticketInfo ? (
        <AcceptRejectModal modalName={MODAL.ACCEPT_REJECT} />
      ) : (
        <LessonRemovedModal isOpen={true} onClose={handleCloseModal} />
      )}
    </>
  );
};

export default AcceptRejectLessonRemovedManager;
