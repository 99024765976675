import { connect } from 'react-redux';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import { getSelectedLesson } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import LessonHeader from './LessonHeader';

/**
 * @prop lessonId - id of the current lesson
 * @prop lessonStatus - status of the current lesson
 * @prop status - status of the other user (tutor)
 * @prop tutorInfo - info about the tutor
 */
export interface LessonHeaderPropsFromState {
  currentLessonId?: Lesson['id'];
  lessonStatus?: Lesson['status'];
  status?: Status | '';
  tutorInfo?: Lesson['userData']['tutor'];
}

export function mapStateToProps(state: GlobalState): LessonHeaderPropsFromState {
  const currentLesson = getSelectedLesson(state) || {
    id: '',
    status: LESSON_STATUS.YET_TO_BE_DETERMINED,
    userData: {
      tutor: undefined
    }
  };
  const currentLessonId = currentLesson.id;
  const tutorInfo = currentLesson.userData.tutor;
  const lessonStatus = currentLesson.status;
  const clientLessonInfo = getClientInfo(state, currentLessonId);

  return {
    currentLessonId,
    lessonStatus,
    status: clientLessonInfo ? clientLessonInfo.studentStatus : '',
    tutorInfo
  };
}

export default connect(mapStateToProps, null)(LessonHeader);
