import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const Text = styled.p`
  color: ${COLORS['222222']};
  font-size: 12px;
  line-height: 1.6;
  word-break: break-word;
`;

export const Layout = styled.div`
  width: 100%;
  padding: 0px 20px 20px 20px;
`;
