import * as React from 'react';
import uploadIcon from '@chegg-tutors-chat/shared/assets/images/ic-upload.svg';
import { DEFAULT_FILE_UPLOAD_TYPES } from '@chegg-tutors-chat/shared/constants';
import { handleFiles, noop } from '@chegg-tutors-chat/shared/utils';
import { IconButton } from '@chegg/ui-agnostic';
import { FileUploadIcon } from './styled';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

/**
 * @prop hidden - hidden prop is used to toggle the display.
 * @prop onFile - callback to be executed when a file is uploaded.
 * @prop acceptedTypes - types of files that can be uploaded.
 */
interface FileUploadProps {
  acceptedTypes?: string;
  hidden?: boolean;
  onFile?: (i: File) => void;
  onImage?: (i: HTMLImageElement) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

/**
 * FileUpload - component which handles different kinds of file uploads.
 */
const FileUpload: React.FunctionComponent<FileUploadProps> = ({
  acceptedTypes = DEFAULT_FILE_UPLOAD_TYPES,
  hidden = false,
  onFile = noop,
  onImage = noop,
  inputRef
}) => {
  const fileReader = new FileReader();
  const form = React.createRef<HTMLFormElement>();

  const triggerUpload = (event: React.MouseEvent) => {
    const input = inputRef.current;
    event.preventDefault();
    if (input) {
      input.focus();
      input.click();
    }
  };

  const onSubmit = (event: React.MouseEvent & React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const fileRead = (event: any) => {
    const imgEle = document.createElement('img');
    if (onImage !== undefined) {
      imgEle.onload = () => {
        onImage(imgEle);
      };
    }
    imgEle.src = event.target.result;
    if (form.current) {
      form.current.reset();
    }
  };

  fileReader.onload = fileRead;
  const onChange = (e: HTMLInputEvent & React.FormEvent<HTMLFormElement>) => {
    if (e && e.target && e.target.files) {
      const files = e.target.files;
      if (files.length) {
        handleFiles(files, fileReader, onFile);
      }
    }
  };

  const style = { display: 'initial' };
  if (hidden) {
    style.display = 'none';
  }

  return (
    <form ref={form} style={style} onChange={onChange} onSubmit={onSubmit}>
      <IconButton onClick={triggerUpload} screenReaderOnlyText="Upload a file">
        <FileUploadIcon src={uploadIcon} alt="Upload a file" />
      </IconButton>
      <input
        accept={acceptedTypes}
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        aria-hidden={true}
        tabIndex={-1}
      />
    </form>
  );
};

export default FileUpload;
