import * as React from 'react';
import Link from '../../styled/sunkist/Link';
import Linkify from './linkifyjs';

type LinkType = 'Lead' | 'Small' | 'Secondary';

export interface LinkifyOptions {
  linkType?: LinkType;
  openInNewTab?: boolean;
}
/**
 * This was pulled from the linkify code. It runs the linkify tokenizer and
 * adds the components around it.
 * @param str
 * @param opts
 */
function stringToElements(str: string, opts: any) {
  const tokens = Linkify.tokenize(str);
  const El = opts.element;
  const elements = [];
  let linkId = 0;

  const linkifOpts = new Linkify.options.Options();

  for (const token of tokens) {
    if (token.type === 'nl' && opts.nl2br) {
      elements.push(React.createElement('br', { key: `linkified-${++linkId}` }));
      continue;
    } else if (!token.isLink || !linkifOpts.check(token)) {
      // Regular text
      elements.push(token.toString());
      continue;
    }

    const { formatted, formattedHref, target } = linkifOpts.resolve(token);

    const props = {
      href: formattedHref,
      key: `linkified-${++linkId}`,
      target: target || '_blank'
    };

    elements.push(<El {...props}>{formatted}</El>);
  }

  return elements;
}
/**
 * Take a string and turn all links and email into an anchor tag. Using
 * our styled components.
 */
const linkify = (textInput: string = '', opts: LinkifyOptions = {}) => {
  const InlineLink = opts.linkType ? Link[opts.linkType] : Link;
  const target = opts.openInNewTab ? '_blank' : '_self';

  return [stringToElements(textInput, { element: InlineLink, target })];
};

export default linkify;
