/**
 * This file validates the action payload using redux-logic validate method.
 * Validator does the following:
 * It gets the type guard from `actionPayloadChecks` if there is one for this action.type.
 * If there is no type guard found, accept the action.
 * If there is a type guard found, check that the type of the payload passes the type guard.
 * If it passes, accept the action. If it fails, reject the action and throw an informative error.
 *
 * Typical signature of the action is like
 * {type: 'namespace/ACTION_TYPE', payload: {foo: 'bar'}}
 */

import { createLogic } from 'redux-logic';
import actionPayloadChecks from '@chegg-tutors-chat/shared/redux/actionPayloadChecks';
import * as Sentry from '@sentry/browser';

interface GenericPayloadType {
  [key: string]: any;
}

export const actionValidatorLogic = createLogic<GlobalState, GenericPayloadType>({
  type: '*',

  validate: ({ action }, allow, reject) => {
    const typeGuard = actionPayloadChecks[action.type];

    if (!typeGuard) {
      // If there is no type guard found, accept the action.
      allow(action);
    } else {
      if (typeGuard(action.payload)) {
        // If payload conforms the type guard
        allow(action);
      } else {
        // If payload DOESN'T conform the type guard, reject the action and throw an informative error
        const error = new Error(
          `'${action.type}' is failed due to mismatched payload type`
        );

        Sentry.withScope(scope => {
          scope.setTag('actionValidatorError', action.type);
          Sentry.captureException(error);
        });

        reject(undefined);
      }
    }
  }
});

export default [actionValidatorLogic];
