import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getSelectedLesson } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { closeModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { getYourNote } from '@chegg-tutors-chat/shared/redux/modules/notes/selectors';
import { noop } from '@chegg-tutors-chat/shared/utils';
import UpdateYourNoteForm, {
  UpdateYourNoteFormDispatchProps,
  UpdateYourNoteFormProps
} from './UpdateYourNoteForm';

export function mapStateToProps(
  state: GlobalState,
  passedProps: Partial<UpdateYourNoteFormProps>
): UpdateYourNoteFormProps {
  const lesson = getSelectedLesson(state);
  const lessonId = lesson ? lesson.id : '';

  const { isOpen = false, onSubmit = noop } = passedProps;

  return {
    isOpen,
    lesson,
    onSubmit,
    yourNote: getYourNote(state, lessonId)
  };
}

export const mapDispatchToProps = (
  dispatch: Dispatch
): UpdateYourNoteFormDispatchProps => {
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return {
    handleCloseModal
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateYourNoteForm);
