import { createSelector } from 'reselect';
import { getLesson } from '../lessons/selectors';
import { initialState } from './reducers';

/**
 * Gets a loggedinUser.
 * @param state
 */

export const getUser = (state: Partial<GlobalState>) => state.user || { ...initialState };

export const isLoggedIn = createSelector([getUser], (user: UserState) => !!user.userId);

/**
 * Gets the current student id.
 *
 * @param state
 * @param lessonId
 */

export const getStudentId = createSelector([getLesson], (currentLesson?: Lesson) => {
  if (
    currentLesson &&
    currentLesson.userData &&
    currentLesson.userData.student &&
    currentLesson.userData.student.userId
  ) {
    return currentLesson.userData.student.userId;
  }
  return '';
});

/**
 * Gets the current tutor id.
 *
 * @param state
 * @param lessonId
 */

export const getTutorId = createSelector([getLesson], (currentLesson?: Lesson) => {
  if (
    currentLesson &&
    currentLesson.userData &&
    currentLesson.userData.tutor &&
    currentLesson.userData.tutor.userId
  ) {
    return currentLesson.userData.tutor.userId;
  }
  return '';
});
