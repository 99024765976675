import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { captureError } from '@chegg-tutors-chat/shared/utils';
import {
  addAvailableTutor,
  addUser,
  SetAvailabilityPayload,
  setTutorAvailability
} from './actions';
import { USER_EXIST_ERROR } from './constants';

/**
 * User
 */
export const initialState: UserState = {
  available: true,
  firstName: '',
  lastName: '',
  userId: ''
};

/**
 * Initializes newUser
 * @param state
 * @param user
 */
export function handleAddUser(state: UserState, newUser: Partial<UserState>): UserState {
  if (!newUser || !newUser.userId) {
    return state;
  }

  if (state.userId && state.userId !== newUser.userId) {
    const error = new Error(USER_EXIST_ERROR);
    captureError(error);
  }

  const newState = {
    ...state,
    ...newUser
  };

  return newState;
}

/**
 * sets tutor's availability
 * @param state
 * @param payload
 */
export function handleSetTutorAvailability(
  state: UserState,
  payload: SetAvailabilityPayload
): UserState {
  const { available } = payload;
  const newState = {
    ...state,
    available
  };

  return newState;
}

export default reducerWithInitialState(initialState)
  .case(addUser, handleAddUser)
  // REFACTOR see CT-1136
  .case(addAvailableTutor, handleAddUser)
  .case(setTutorAvailability, handleSetTutorAvailability)
  .build();
