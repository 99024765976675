import {
  EL_REGION,
  EL_TYPE,
  EVENT_NAME
} from '@chegg-tutors-chat/shared/providers/analytics/constants';
import {
  formatAnalyticsClickEvent,
  FormatAnalyticsClickEventPayload
} from '@chegg-tutors-chat/shared/providers/analytics/utils/formatAnalyticsClickEvent';
import * as scratchPadActions from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import { clickLogicFactory } from '../utils';

/**
 * logs to analytics when a new color is selected for the scratchpad pen tool.
 */
const trackSelectPenToolColor = (trackColor: string, eventName: EVENT_NAME) => {
  return clickLogicFactory<SelectScratchPadPenColorPayload>(
    scratchPadActions.selectScratchPadPenColor.type,
    (state, payload) => {
      const { color } = payload;
      if (color && color === trackColor) {
        const clickEventConfig: FormatAnalyticsClickEventPayload = {
          eventName,
          eventRegion: EL_REGION.scratchpad_toolbar,
          eventState: state,
          eventType: EL_TYPE.button
        };
        return formatAnalyticsClickEvent(clickEventConfig);
      }
      return false;
    }
  );
};

/**
 * logs to analytics when black is selected for the scratchpad pen tool.
 */
export const trackPenColorBlack = trackSelectPenToolColor(
  'black',
  EVENT_NAME.draw_pen_black
);

/**
 * logs to analytics when red is selected for the scratchpad pen tool.
 */
export const trackPenColorRed = trackSelectPenToolColor('red', EVENT_NAME.draw_pen_red);

/**
 * logs to analytics when blue is selected for the scratchpad pen tool.
 */
export const trackPenColorBlue = trackSelectPenToolColor(
  'blue',
  EVENT_NAME.draw_pen_blue
);

export default [trackPenColorBlack, trackPenColorBlue, trackPenColorRed];
