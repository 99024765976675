import * as React from 'react';
import TestConsole from 'src/devTools/TestConsole';
import GlobalStyles from 'src/Theme';
import Conversation from '@chegg-tutors-chat/shared/components/Conversation';
import DragAttachment from '@chegg-tutors-chat/shared/components/DragAttachment';
import LessonAreaList from '@chegg-tutors-chat/shared/components/LessonAreaList';
import MessageInput from '@chegg-tutors-chat/shared/components/MessageInput';
import Modal from '@chegg-tutors-chat/shared/components/Modal';
import ScreenReaderAnnouncer from '@chegg-tutors-chat/shared/components/ScreenReaderAnnouncer';
import config from '@chegg-tutors-chat/shared/config';
import { STATUS } from '@chegg-tutors-chat/shared/constants';
import { Theme } from '@chegg-tutors-chat/shared/styled/chat/ThemeProvider';
import { OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk';
import ApplicationHeader from '../ApplicationHeader';
import ErrorPage from '../ErrorPage';
import LessonHeader from '../LessonHeader';
import TypingIndicator from '../TypingIndicator';
import { LayoutPropsFromDispatch, LayoutPropsFromState } from './container';
import {
  ContainerLayout,
  MessageAreaLayout,
  MessageAreaTop,
  MessageInputLayout
} from './styled';

const isOffline = process.env.REACT_APP_IS_OFFLINE;

// TODO: replace this with more resilient versioning
// See https://jira.cheggnet.com/browse/CT-2933
console.log('Student Chat Client Version 1.0');

export type AppLayoutProps = LayoutPropsFromState & LayoutPropsFromDispatch;

// NOTE: We intentionally put these elements in a certain order
// so that elements are in a logical order for keyboard users.
// We then use CSS to position the "LessonExitLinks" at the top right.
export const LayoutWrapper: React.FC = () => (
  <Theme>
    <GlobalStyles />
    <DragAttachment>
      {isOffline && <TestConsole />}
      <ApplicationHeader statusText={STATUS.ONLINE} />
      <ContainerLayout role="main">
        <MessageAreaLayout>
          <Conversation />
          <TypingIndicator />
          <MessageInputLayout>
            <MessageInput />
          </MessageInputLayout>
          <MessageAreaTop>
            <LessonHeader />
          </MessageAreaTop>
        </MessageAreaLayout>
        <LessonAreaList />
        <ScreenReaderAnnouncer />
      </ContainerLayout>
    </DragAttachment>
    <Modal />
  </Theme>
);

const AppLayout: React.FunctionComponent<AppLayoutProps> = ({
  hydrateAppState,
  hasError,
  isLoading,
  user
}) => {
  const { REACT: optimizely } = config.get('OPTIMIZELY');
  React.useEffect(() => {
    hydrateAppState();
  }, []);
  if (hasError) {
    return <ErrorPage />;
  } else if (!isLoading) {
    return (
      <OptimizelyProvider
        optimizely={optimizely as ReactSDKClient}
        user={{
          id: user.userId
        }}
      >
        <LayoutWrapper />
      </OptimizelyProvider>
    );
  }
  return null;
};

export default AppLayout;
