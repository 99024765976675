import * as React from 'react';
import closeIcon from '@chegg-tutors-chat/shared/assets/images/ic-close.svg';
import { isImg, noop } from '@chegg-tutors-chat/shared/utils';
import { IconButton } from '@chegg/ui-agnostic';
import {
  AttachmentPreview,
  CloseIcon,
  Image,
  Layout,
  Name,
  OuterLayout,
  Text
} from './styled';

/**
 * @prop file - a file to be previewed
 * @prop onClose - callback to be called when close icon clicked
 */
export interface MessageInputAttachmentPreviewProps {
  file: File;
  onClose?: (param?: any) => void;
}

/**
 * @prop fileExtension - file extension of the file
 * @prop fileNameMinusFileExtension - source url of file, minus its file extension
 */
interface PreviewFileNameProps {
  fileExtension: string;
  fileNameMinusFileExtension: string;
}

const getSrc = (file: File) => window.URL.createObjectURL(file);

/**
 * FileNamePreview - displays Preview of the uploaded image and file name in message input box.
 * @props - PreviewFileNameProps
 */
const FileNamePreview = (props: PreviewFileNameProps) => {
  const { fileExtension, fileNameMinusFileExtension } = props;
  const strArr = fileNameMinusFileExtension.split('/');
  const fileName = strArr[strArr.length - 1];
  return (
    <Name>
      <div>
        <Text>{fileName}</Text>
        <span>
          {fileName && fileExtension ? '.' : ''}
          {fileExtension}
        </span>
      </div>
    </Name>
  );
};

/**
 * MessageInputAttachmentPreview - shows a preview of the file being sent
 * @props - MessageInputAttachmentPreviewProps
 */
const MessageInputAttachmentPreview = (props: MessageInputAttachmentPreviewProps) => {
  const { file, onClose = noop } = props;
  if (!file) {
    return null;
  }

  const fileName = file.name || 'upload';
  const strArr = fileName.split('.');
  const fileExtension = strArr[strArr.length - 1].toLowerCase();
  /* If the file is an image, display a thumbnail, otherwise show the filename */
  const PreviewComponent = isImg(fileExtension) ? (
    <Image alt="Preview of uploaded image" src={getSrc(file)} />
  ) : (
    <FileNamePreview
      fileExtension={fileExtension}
      fileNameMinusFileExtension={strArr.slice(0, -1).join('.')}
    />
  );

  return (
    <AttachmentPreview>
      <OuterLayout>
        <Layout>{PreviewComponent}</Layout>

        <IconButton
          onClick={onClose}
          screenReaderOnlyText={'Remove attachment'}
          right={0}
          position={'absolute'}
          top={0}
        >
          <CloseIcon src={closeIcon} alt="Remove attachment" />
        </IconButton>
      </OuterLayout>
    </AttachmentPreview>
  );
};

export default MessageInputAttachmentPreview;
