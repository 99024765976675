import { Field, Formik } from 'formik';
import * as React from 'react';
import { RadioButton } from '@chegg-tutors-chat/shared/components/Forms/Fields/RadioButtons';
import {
  Field as FormField,
  Label
} from '@chegg-tutors-chat/shared/components/Forms/styled';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import ModalFooter from '@chegg-tutors-chat/shared/components/Modal/ModalFooter';
import { RejectRerouteReason } from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { Modal, PrimaryButton } from '@chegg/fanta';
import { AdditionalInfo, FooterButtonsWrapper, Form } from './styled';

export interface WhyRejectRerouteFormProps extends BaseModalProps {
  onSubmit: (reasonsData: RejectRerouteReason) => void;
}

enum FormFieldsMap {
  TooAdvancedRequest = 'tooAdvancedRequest',
  NotFamiliarWithTopic = 'notFamiliarWithTopic',
  StudentSelectedWrongSubject = 'studentSelectedWrongSubject',
  HonorCodeViolation = 'honorCodeViolation',
  InappropriateSpam = 'inappropriateSpam',
  Other = 'other',
  TellUsMore = 'tellUsMore'
}

const ReasonToRejectOrReroute = 'reasonToRejectOrReroute';

const reasonsToRejectOrReroute = [
  {
    buttonText: 'The request is too advanced for my expertise',
    value: FormFieldsMap.TooAdvancedRequest
  },
  {
    buttonText: "I'm not familiar with the subtopic",
    value: FormFieldsMap.NotFamiliarWithTopic
  },
  {
    buttonText: 'The student selected the wrong subject',
    value: FormFieldsMap.StudentSelectedWrongSubject
  },
  {
    buttonText: 'There is an Honor Code violation',
    value: FormFieldsMap.HonorCodeViolation
  },
  {
    buttonText: 'Inappropriate/Spam',
    value: FormFieldsMap.InappropriateSpam
  },
  {
    buttonText: 'Other',
    value: FormFieldsMap.Other
  }
];

const WhyRejectReroute: React.FunctionComponent<WhyRejectRerouteFormProps> = ({
  isOpen,
  onSubmit,
  ...restProps
}) => {
  return (
    <Modal
      headerText={'What happened?'}
      isOpen={isOpen}
      subHeaderText={`Please let us know why you weren't able to help this student.`}
      {...restProps}
    >
      <Formik
        initialValues={{
          reasonToRejectOrReroute: '',
          tellUsMore: null
        }}
        onSubmit={(values, actions) => {
          // additional validation to check if the form has atleast one radioBtn checked
          if (Object.values(values).join('')) {
            onSubmit(values as RejectRerouteReason);
          }
          actions.setSubmitting(false);
        }}
        render={({
          handleSubmit,
          isSubmitting,
          dirty,
          touched,
          handleBlur,
          handleChange
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField>
              {reasonsToRejectOrReroute.map(({ buttonText, value }) => (
                <FormField key={value}>
                  <Field
                    id={value}
                    component={RadioButton}
                    name={ReasonToRejectOrReroute}
                    value={value}
                    type={'radio'}
                  >
                    {buttonText}
                  </Field>
                </FormField>
              ))}
            </FormField>
            <FormField>
              <Label htmlFor={FormFieldsMap.TellUsMore}>Tell us more: (optional)</Label>
              <Field
                component={AdditionalInfo}
                id={FormFieldsMap.TellUsMore}
                name={FormFieldsMap.TellUsMore}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
            <FooterButtonsWrapper>
              <ModalFooter>
                <PrimaryButton
                  size={'large'}
                  data-testid="next-button"
                  type="submit"
                  isDisabled={
                    isSubmitting || !(dirty && touched[ReasonToRejectOrReroute])
                  }
                >
                  Next
                </PrimaryButton>
              </ModalFooter>
            </FooterButtonsWrapper>
          </Form>
        )}
      />
    </Modal>
  );
};

export default WhyRejectReroute;
