import { connect } from 'react-redux';
import * as lessonsSelectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as pinsSelectors from '@chegg-tutors-chat/shared/redux/modules/pins/selectors';
import PinnedPanel from './PinnedPanel';

/**
 * @interface PinnedPanelProps
 *
 * @prop pins - all pinned messages in the channel
 */
export interface PinnedPanelPropsFromState {
  pins: PinnedMessage[];
}

export function mapStateToProps(state: GlobalState): PinnedPanelPropsFromState {
  const currentLessonId = lessonsSelectors.getSelectedLessonId(state);
  const pins = pinsSelectors.getPinsByDate(state, currentLessonId);
  return { pins };
}

export default connect(mapStateToProps, null)(PinnedPanel);
