import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as modalActions from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import LessonRemoved from './LessonRemoved';

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  return {
    handleCloseModal
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LessonRemoved);
