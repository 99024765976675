import config, {
  ANALYTICS_PAGE_NAME,
  ANALYTICS_PAGE_SUB_CAT_3
} from '@chegg-tutors-chat/shared/config';

export interface SetAnalyticsProps {
  messageLimit: number;
  user: 'TUTOR' | 'STUDENT';
  userIdGetter: (state: GlobalState, lessonId: string) => string;
}

/**
 * Sets tutor and student analytics configuration parameters to config window objects
 */
export function setAnalyticsConfig(analyticsData: SetAnalyticsProps) {
  const { messageLimit, user, userIdGetter } = analyticsData;
  config.set('getOtherUserId', userIdGetter);
  config.set('PREVIOUS_MESSAGE_LIMIT', messageLimit);
  config.set('ANALYTICS_PAGE_DATA', {
    pageName: ANALYTICS_PAGE_NAME[user],
    subCategory3: ANALYTICS_PAGE_SUB_CAT_3[user]
  });
  // set on the global digitalData too
  if (window.digitalData && window.digitalData.page && window.digitalData.page.category) {
    window.digitalData.page.category.subCategory3 = ANALYTICS_PAGE_SUB_CAT_3[user];
  }
  if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
    window.digitalData.page.pageInfo.pageName = ANALYTICS_PAGE_NAME[user];
  }
}

/**
 * Sets userId as part of window object for analytics purposes
 * @param userId
 */
export function setUserIdForAnalytics(userId: string) {
  // digitalData is used for Adobe analytics and this check sees if it's available
  if (
    window.digitalData &&
    window.digitalData.user &&
    window.digitalData.user.profileInfo &&
    window.digitalData.user.profileInfo.attributes
  ) {
    window.digitalData.user.profileInfo.attributes.cheggUserUUID = userId;
  }
}
