/**
 * Timer messages announcement
 *
 * @param minutes
 * @param minuteText
 */
export const getMinutesRemainingText = (minutes: number) => {
  const minuteText = minutes === 1 ? 'minute' : 'minutes';
  return `${minutes} ${minuteText} remaining`;
};
