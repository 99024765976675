import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import reducer from '@chegg-tutors-chat/shared/redux/modules/lessons/reducers';

export function getInitialStore(): LessonState {
  return reducer(undefined, { type: '' });
}

export function getMockLesson(s: string): Lesson {
  return {
    chatInfo: { channelUrl: `test${s}` },
    customType: `customType${s}`,
    id: `test${s}`,
    status: LESSON_STATUS.YET_TO_BE_DETERMINED,
    hasSubmittedFeedback: false,
    ticketInfo: {
      id: `test${s}`,
      request: {
        description: `description${s}`,
        name: `student${s}`,
        subject: `subject${s}`
      }
    },
    userData: {
      student: getMockLessonUser(s, 'student'),
      tutor: getMockLessonUser(s, 'tutor')
    }
  };
}

export function getMockLessonUser(s: string, userType?: string): LessonUser {
  userType = userType ? userType : '';
  return {
    firstName: `${userType}first${s}_name${s}`,
    lastName: `${userType}last${s}_name${s}`,
    profileUrl: `${userType}profile${s}_url${s}`,
    userId: `${userType}id${s}`
  };
}
