import startApp from 'src/app';

/**
 * When a lesson already exists,
 * configurations for the chat app to use when starting up.
 * keep this minimal and as small as possible!
 */
export interface StartConfig {
  rootElement: HTMLElement;
  lessonToken: string;
}
const start = (startConfig: StartConfig) => {
  // kick off the rest of the app hydration
  startApp(startConfig);
};

export default start;
