import { connect } from 'react-redux';
import { showModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { getYourNote } from '@chegg-tutors-chat/shared/redux/modules/notes/selectors';
import AddEditYourNoteButton, {
  AddEditYourNoteButtonDispatchProps
} from './AddEditYourNoteButton';

interface Props {
  lessonId: Lesson['id'];
}

export function mapStateToProps(state: GlobalState, passedProps: Props) {
  const { lessonId } = passedProps;
  const yourNoteAdded = getYourNote(state, lessonId) !== undefined;
  return { yourNoteAdded };
}

export const mapDispatchToProps: AddEditYourNoteButtonDispatchProps = {
  showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditYourNoteButton);
