import styled from 'styled-components';
import colors from '@chegg-tutors-chat/shared/styled/constants/colors';

const TypingIndicatorText = styled.p`
  margin: 10px 20px;
  color: ${colors[767676]};
  font-style: italic;
  line-height: 1.5;
`;

export default TypingIndicatorText;
