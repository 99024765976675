import { SCRATCHPAD_TOOL_NAME } from '@chegg-tutors-chat/shared/constants';
import {
  EL_REGION,
  EL_TYPE,
  EVENT_NAME
} from '@chegg-tutors-chat/shared/providers/analytics/constants';
import {
  formatAnalyticsClickEvent,
  FormatAnalyticsClickEventPayload
} from '@chegg-tutors-chat/shared/providers/analytics/utils/formatAnalyticsClickEvent';
import {
  SectionToolPayload,
  showSectionTool
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { clickLogicFactory } from '../utils';

/**
 * logs to analytics when the user selects the 'clear canvas' option in the scratchpad
 */
export const trackClearCanvas = clickLogicFactory<SectionToolPayload>(
  showSectionTool.type,
  (state: GlobalState, payload: SectionToolPayload) => {
    const { toolName } = payload;
    if (toolName && toolName === SCRATCHPAD_TOOL_NAME.CLR) {
      const clickEventConfig: FormatAnalyticsClickEventPayload = {
        eventName: EVENT_NAME.clear_scratchpad,
        eventRegion: EL_REGION.scratchpad_toolbar,
        eventState: state,
        eventType: EL_TYPE.button
      };
      return formatAnalyticsClickEvent(clickEventConfig);
    } else {
      return false;
    }
  }
);

export default trackClearCanvas;
