import styled from 'styled-components';

export const RadioButtonWrapper = styled.div`
  display: table-cell;
  margin-right: 40px;
  margin-bottom: 20px;
  & input,
  & label,
  & label::before {
    /* Do this until we can make a PR onto chegg-styled */
    cursor: pointer;
    text-align: center;
    display: table-cell;
    line-height: normal;
    padding: 5px;
  }
`;
