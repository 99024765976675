import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import Link from '@chegg-tutors-chat/shared/styled/sunkist/Link';
import { Heading5 } from '@chegg/fanta';

export const ConversationWrapper = styled.div`
  pointer-events: none;
`;

export const Layout = styled.div`
  height: 55px;
  border-bottom: solid 1px ${COLORS.CCCCCC};
  display: flex;
  align-items: flex-end;
`;

export const Text = styled(Heading5).attrs({ as: 'h1' })`
  display: inline-block;
  margin: 13px auto 18px 20px;
`;

export const ChatLink = styled(Link)`
  text-align: right;
  padding: 17px 10px 17px 0px;
`;

export const ButtonWrapper = styled.div`
  padding: 10px 20px 11px 0px;
  display: inline-block;
`;
