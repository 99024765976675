type onImageFunc = (i: HTMLImageElement) => void;

/**
 * Checks the item to see if it is a uri.
 * @param item
 */
function isUriItem(item: DataTransferItem): boolean {
  return item.kind === 'string' && item.type === 'text/uri-list';
}

/**
 * Takes a DataTransferItem and loads the uri as a string. Then
 * calls the onImage callback func with the HTMLImageElement
 * @param item
 * @param onImage
 */
function loadImages(item: DataTransferItem, onImage: onImageFunc) {
  item.getAsString((uri: string) => {
    const imgEle = new Image();
    imgEle.onload = () => {
      onImage(imgEle);
    };
    imgEle.src = uri;
  });
}

/**
 * This is to handle a DataTransferItemList, it runs through the list and loads any uri as
 * an HTMLImageElement and onImage gets called for each item.
 * @param items
 * @param onImage
 */
export default function itemHandler(items: DataTransferItemList, onImage: onImageFunc) {
  for (const index in items) {
    if (items.hasOwnProperty(index) && isUriItem(items[index])) {
      loadImages(items[index], onImage);
    }
  }
}
