import * as React from 'react';
import ScratchPadManager from '@chegg-tutors-chat/shared/components/ScratchPad/manager';
import {
  ATTACHMENT_ORIGIN_TYPE,
  SCRATCHPAD_IMAGE_ORIGIN_TYPE,
  TOOLS
} from '@chegg-tutors-chat/shared/constants';
import { AttachmentPayload } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import getDemosCalculator from './getDesmosCalculator';
import { PrimaryButton } from '@chegg/fanta';
import { ButtonWrapper, Panel, SendToChatLayout, SendToPadLayout } from './styled';

/**
 * The target width and height of the screenshot that desmos
 * will output. It needs a height and widht otherwise it doesn't
 * show the lines.
 */
const SCREENSHOT_HEIGHT = 500;
const SCREENSHOT_WIDTH = 500;
const TARGET_PIXEL_RATIO = 2;

interface Props {
  addAttachment: (payload: AttachmentPayload) => void;
  lessonId?: string;
  toggleTool?: any;
  sendImageToScratchPad: (payload: SendImageToScratchPadPayload) => void;
}

const GraphPanel: React.FunctionComponent<Props> = ({
  lessonId,
  toggleTool,
  addAttachment,
  sendImageToScratchPad
}) => {
  const calcRef = React.createRef<HTMLDivElement>();

  let calculator;
  React.useEffect(() => {
    if (calcRef.current) {
      calculator = getDemosCalculator(calcRef.current);
    }
  });

  const getScreenShot = (addImage: (fileSrc: any) => void) => {
    calculator.asyncScreenshot(
      {
        height: SCREENSHOT_HEIGHT,
        targetPixelRatio: TARGET_PIXEL_RATIO,
        width: SCREENSHOT_WIDTH
      },
      addImage
    );
  };
  const sendToScratchPad = () => {
    if (lessonId) {
      /**
       * Need to make sure to open the scratch pad first. otherwise
       * adding the screenshot will not work properly because the
       * height and width of the scratcpad is 0. Ideally
       * this would be handled in scratchpad. The second time
       * this is going to be used, it should be refactored.
       *
       */
      toggleTool({ lessonId, toolName: TOOLS.SCRATCH_PAD.name });
      const Pad = ScratchPadManager.get(lessonId);

      getScreenShot((fileSrc: any) => {
        const img = document.createElement('img');
        img.onload = () => {
          Pad.onImage(img);
          sendImageToScratchPad({
            originType: SCRATCHPAD_IMAGE_ORIGIN_TYPE.GRAPHING_TOOL
          });
        };
        img.src = fileSrc;
      });
    }
  };
  const sendToChat = () => {
    if (addAttachment) {
      getScreenShot((fileSrc: any) => {
        addAttachment({
          fileSrc,
          lessonId,
          originType: ATTACHMENT_ORIGIN_TYPE.GRAPHING_TOOL,
          type: 'image'
        });
      });
    }
  };
  return (
    <Panel>
      <div style={{ flex: 1 }} ref={calcRef} />
      <ButtonWrapper>
        <SendToChatLayout>
          <PrimaryButton size={'medium'} onClick={sendToChat}>
            Send to chat
          </PrimaryButton>
        </SendToChatLayout>
        <SendToPadLayout>
          <PrimaryButton size={'medium'} onClick={sendToScratchPad}>
            Send to pad
          </PrimaryButton>
        </SendToPadLayout>
      </ButtonWrapper>
    </Panel>
  );
};

export default GraphPanel;
