import * as React from 'react';
import PinIcon from '@chegg-tutors-chat/shared/components/PinIcon';
import UnPinIcon from '@chegg-tutors-chat/shared/components/UnPinIcon';
import {
  PinMessagePayload,
  UnPinMessagePayload
} from '@chegg-tutors-chat/shared/redux/modules/pins/actions';

export interface PinsProps {
  /**
   * boolean which says weather message is pinned or unpinned.
   */
  isPinned?: boolean;
  /**
   * message object with all details of currect message.
   */
  message: Message;
  /**
   * lesson Id of the current message.
   */
  lessonId?: string;
}

export interface PinsDispatchProps {
  pinMessage: (payload: PinMessagePayload) => void;
  unPinMessage: (payload: UnPinMessagePayload) => void;
}

/**
 * Pins - component to displays pin and unpin icons when hover over message.
 *
 * @props - PinsProps
 */
const Pins: React.SFC<PinsProps & PinsDispatchProps> = ({
  isPinned = false,
  message,
  lessonId = '',
  pinMessage,
  unPinMessage
}) => {
  const pinMessagePayload: PinMessagePayload = {
    lessonId,
    message
  };

  const unPinMessagePayload: UnPinMessagePayload = {
    lessonId,
    messageId: message.messageId
  };

  if (isPinned) {
    return <UnPinIcon onClick={() => unPinMessage(unPinMessagePayload)} />;
  } else {
    return <PinIcon onClick={() => pinMessage(pinMessagePayload)} />;
  }
};

export default Pins;
