import * as QueryString from 'query-string';
import { ErroneousAction } from 'redux-logic';
import * as SendBird from 'sendbird';
import { TIMEOUT_MESSAGE } from '@chegg-tutors-chat/shared/constants';
import { READ_USERID_FROM_QUERYPARAMS } from '@chegg-tutors-chat/shared/featureFlags';
import * as Sentry from '@sentry/browser';
import { LinkifyOptions as LinkyOptions } from './linkify';

export { fileProcessor, handleFiles } from './fileHandler';
export { formatDisplayName, formatStatus, formatTime } from './formatHelpers';
export { default as linkify } from './linkify';
export { default as scratchPadStorage } from './scratchPadStorage';
export { default as urlToFile } from './urlToFile';
export { default as SoundUtil } from './audio';

export type LinkifyOptions = LinkyOptions;

/**
 * A function that takes in an object or array and creates a deep copy.
 * Use this to avoid modifying original object.
 *
 * @param item
 */
export const deepCopyObject = (item: {} | []) => {
  return JSON.parse(JSON.stringify(item));
};

/**
 * This is a Typescript TypeGuard. Just returns if the action is an
 * ErroneousAction
 * @param action an FSA action
 */
export const isErrorAction = (action: any): action is ErroneousAction => {
  return !!action.error;
};

/**
 * A generic function that takes any number of parameters and does nothing
 *
 * @params args - any arguments
 */
export const noop = (..._args: any[]) => {
  return;
};

export const focusMessageInput = () => {
  const messageInput = document.getElementById('messsage-input');
  if (messageInput) {
    messageInput.focus();
  }
};

/* These are the filetypes supported by the html img tag */
const imgFileExtensions = ['jpeg', 'jpg', 'gif', 'png', 'svg', 'ico', 'svg+xml'];

/**
 * A function that determines whether a file extension is an image file extension
 *
 * @params fileExtension - the file extension of a file
 */
export const isImg = (fileExtension: string) => {
  return imgFileExtensions.indexOf(fileExtension) !== -1;
};

export const isCrossOriginImg = (imgEle: HTMLImageElement) => {
  const { src } = imgEle;
  /**
   * if Image is a base64 encoded image we were probably the ones who created it.
   * This is not foolproof.
   */
  if (src.indexOf('data:image') === 0) {
    return false;
  }
  const protocolDelimiter = '://';
  const srcBits = src.split(protocolDelimiter);
  const imgOrigin = `${srcBits[0]}${protocolDelimiter}${srcBits[1].split(/[/?#]/)[0]}`;
  return imgOrigin !== document.location.origin;
};

/**
 * Logs an error to the console and captures it in Sentry. Optionally can pass Sentry scope in a callback
 * @param errorObject
 * @param withScope
 */
export const captureError = (
  errorObject: Error | SendBird.SendBirdError,
  withScope?: (scope: Sentry.Scope) => void
) => {
  console.error(errorObject.message);
  if (withScope) {
    Sentry.withScope(scope => {
      withScope(scope);
      Sentry.captureException(errorObject);
    });
  } else {
    Sentry.captureException(errorObject);
  }
};

// Common error handler for timeouts
export const captureTimeoutError = (id: string) => {
  const e = new Error(TIMEOUT_MESSAGE);
  return () =>
    captureError(e, scope => {
      scope.setExtra('Request id', id);
    });
};

/**
 * Function that returns parced query-string params object
 * when READ_USERID_FROM_QUERYPARAMS flag is truthy,
 * and an empty object when the flag is falsey
 */
export const getQueryStringParams = (): QueryString.OutputParams =>
  READ_USERID_FROM_QUERYPARAMS ? QueryString.parse(window.location.search) : {};
