import { AnalyticsModalEvent, Page } from './interfaces';

/**
 * Adds the analytics event we want to track and appends it to the
 * event array on window.digitalData object.
 *
 * @param event
 */
export const trackEvent = <T>(event: T) => {
  if (!window.digitalData || !window._satellite) {
    return;
  }

  window.digitalData.event = [];
  window.digitalData.event.push(event);
  window._satellite.track('event');
};

/**
 * Adds page data we want to track to digitalData.
 *
 * @param pageData
 */
export const trackPage = (pageData: Page, modalEvent: AnalyticsModalEvent) => {
  if (!window.digitalData || !window._satellite) {
    return;
  }
  window.digitalData.page = pageData;
  window.digitalData.event = window.digitalData.event || [];
  window.digitalData.event.push(modalEvent);
  window.digitalData.page.pageInfo.pageName = modalEvent.eventValue[0].pageName;
  window._satellite.track(pageData.pageInfo.pageName);
};
