import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { modalMap } from '@chegg-tutors-chat/shared/components/Modal/container';
import {
  ADMIN_MESSAGE,
  CUSTOM_MESSAGE_TYPE,
  LESSON_STATUS,
  MESSAGE_TYPE,
  MODAL
} from '@chegg-tutors-chat/shared/constants';
import {
  getMockMessage,
  getMockUser,
  getUniqueMockLesson,
  testFile,
  testImg
} from '@chegg-tutors-chat/shared/factories';
import { setOtherUserTyping } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  addLesson,
  updateLessonStatus
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { addMessage } from '@chegg-tutors-chat/shared/redux/modules/messages/actions';
import { showModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { addUser } from '@chegg-tutors-chat/shared/redux/modules/user/actions';

let isTyping = false;
interface Dispatch {
  addLesson: any;
  addMessage: any;
  addUser: any;
  setOtherUserTyping: any;
  showModal: any;
  updateLessonStatus: any;
}
interface Props {
  currentLessonId: string;
}
const initalState: State = {
  lessonStatus: LESSON_STATUS.IN_PROGRESS,
  modal: MODAL.NO_MODAL
};
interface State {
  lessonStatus: LESSON_STATUS | '';
  modal: MODAL | '';
}

class TestConsoleComp extends React.Component<Props & Dispatch, State> {
  public static defaultProps = {
    addLesson: () => {
      return 'test';
    },
    addMessage: () => {
      return 'test';
    }
  };
  public readonly state: State = initalState;
  public inputRef = React.createRef<HTMLInputElement>();

  public render() {
    const modalOptions = Object.keys(modalMap).map((k: string) => {
      return (
        <option aria-selected={this.state.modal === k} key={k} value={k}>
          {k}
        </option>
      );
    });
    const lessonStatusOptions = Object.values(LESSON_STATUS).map((k: string) => {
      return (
        <option aria-selected={this.state.lessonStatus === k} key={k} value={k}>
          {k}
        </option>
      );
    });

    return (
      <div>
        <button onClick={this.addUser}> Add User</button>
        <button onClick={this.addLesson}> Add Lesson</button>
        <button onClick={this.addMessage}> Add Message</button>
        <button onClick={this.addTimerMessage}> Add Timer Message</button>
        <button onClick={this.addAdminMessage}> Add Admin Message</button>
        <button onClick={this.addAwayMessage}> Add Away Message</button>
        <button onClick={this.addOfflineMessage}> Add Offline Message</button>
        <button onClick={this.addImgMessage}> Add Img Message</button>
        <button onClick={this.addFileMessage}> Add File Message</button>
        <button onClick={this.addMessageToFirstLesson}> Add To First Lesson</button>
        <select onChange={this.changeModal}>
          <option aria-selected={this.state.modal === ''} value="" />
          {modalOptions}
        </select>
        <input name="modalOptions" ref={this.inputRef} />
        <button onClick={this.showModal}>Show Modal</button>
        <select onChange={this.changeLessonStatus}>
          <option aria-selected={this.state.lessonStatus === ''} value="" />
          {lessonStatusOptions}
        </select>
        <button onClick={this.updateLessonStatus}>ChangeLessonStatus</button>
        <button onClick={this.toggleTyping}>Toggle Typing</button>
      </div>
    );
  }
  private changeModal = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      modal: e.currentTarget.value as MODAL
    });
  };
  private showModal = () => {
    let modalOptions;
    if (this.inputRef.current) {
      const data = this.inputRef.current.value;
      if (data) {
        modalOptions = JSON.parse(data);
      }
    }
    this.props.showModal({ modalName: this.state.modal, modalOptions });
  };

  private changeLessonStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      lessonStatus: e.currentTarget.value as LESSON_STATUS
    });
  };
  private updateLessonStatus = () => {
    this.props.updateLessonStatus({
      id: this.props.currentLessonId,
      status: this.state.lessonStatus
    });
  };

  private addLesson = () => {
    this.props.addLesson(getUniqueMockLesson());
  };

  private addUser = () => {
    this.props.addUser(
      getMockUser({ firstName: 'student_firstName', lastName: 'student_lastName' })
    );
  };

  private addMessage = () => {
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message: getMockMessage({
        channelUrl: this.props.currentLessonId
      })
    });
  };

  private addTimerMessage = () => {
    const data: any = `{"numberOfMinutesLeftInLesson":5}`;
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message: getMockMessage({
        channelUrl: this.props.currentLessonId,
        customType: CUSTOM_MESSAGE_TYPE.TIMER_MESSAGE,
        data
      })
    });
  };

  private addAdminMessage = () => {
    const message = {
      ...getMockMessage({
        channelUrl: this.props.currentLessonId,
        sender: getMockUser({ firstName: ADMIN_MESSAGE.NAME })
      })
    };
    message.messageType = MESSAGE_TYPE.ADMIN;
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message
    });
  };

  private addAwayMessage = () => {
    const awayTime = moment()
      .subtract(10, 'minute')
      .valueOf();
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message: getMockMessage({
        channelUrl: this.props.currentLessonId,
        createdAt: awayTime
      })
    });
  };

  private addOfflineMessage = () => {
    const awayTime = moment()
      .subtract(20, 'minute')
      .valueOf();
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message: getMockMessage({
        channelUrl: this.props.currentLessonId,
        createdAt: awayTime
      })
    });
  };

  private addImgMessage = () => {
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message: {
        ...getMockMessage({ channelUrl: this.props.currentLessonId }),
        ...{
          fileName: 'SBUbHHN.gif',
          fileType: 'image/gif',
          fileUrl: testImg
        }
      }
    });
  };

  private addFileMessage = () => {
    this.props.addMessage({
      lessonId: this.props.currentLessonId,
      message: {
        ...getMockMessage({ channelUrl: this.props.currentLessonId }),
        ...{
          fileName: 'Goodnight_Moon.pdf',
          fileType: 'pdf',
          fileUrl: testFile
        }
      }
    });
  };

  private addMessageToFirstLesson = () => {
    this.props.addMessage({
      lessonId: '1URL',
      message: getMockMessage({ channelUrl: this.props.currentLessonId })
    });
  };

  private toggleTyping = () => {
    isTyping = !isTyping;
    this.props.setOtherUserTyping({
      isTyping,
      lessonId: this.props.currentLessonId
    });
  };
}

export function mapStateToProps(state: GlobalState) {
  return {
    currentLessonId: getSelectedLessonId(state)
  };
}

export const mapDispatchToProps: Dispatch = {
  addLesson,
  addMessage,
  addUser,
  setOtherUserTyping,
  showModal,
  updateLessonStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(TestConsoleComp);
