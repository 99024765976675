import { Action } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import { closeModal, showModal, ToggleModalPayload } from './actions';

/**
 * ModalsState
 */
export const initialState: ModalsState = {
  modalName: MODAL.NO_MODAL
};

/**
 * Handles the state of modals
 * @param state
 * @param action
 */
export function handleToggleModal(
  state: ModalsState,
  action: Action<ToggleModalPayload | void>
): ModalsState {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case closeModal.type:
      return initialState;
    case showModal.type:
      const newState = {
        ...state,
        ...action.payload
      };

      return newState as ModalsState;
    default:
      return state;
  }
}

export default reducerWithInitialState(initialState)
  .casesWithAction([closeModal, showModal], handleToggleModal)
  .build();
