import * as React from 'react';
import LessonInfo from '@chegg-tutors-chat/shared/components/LessonInfo';
import Notes, { AddEditYourNoteButton } from '@chegg-tutors-chat/shared/components/Notes';
import { Layout, Scrollable } from './styled';

/**
 * @prop lesson - all details of the selected lesson.
 */
export interface AssignmentPanelProps {
  lesson: Lesson;
}

/**
 * AssignmentPanel - displays info about the current lesson
 */
const AssignmentPanel: React.SFC<AssignmentPanelProps> = ({ lesson }) => {
  return (
    <Layout>
      <Scrollable>
        <LessonInfo lesson={lesson} />
        <Notes lessonId={lesson.id} />
      </Scrollable>
      <AddEditYourNoteButton lessonId={lesson.id} />
    </Layout>
  );
};

export default AssignmentPanel;
