import styled from 'styled-components';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';
import StatusIndicator from '@chegg-tutors-chat/shared/styled/chat/StatusIndicator';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const LessonHeader = styled.div`
  height: 55px;
  padding: 9px 20px;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 2px solid ${COLORS.F5F5F5};
  > p,
  > span,
  > div {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    height: 45px;
    > p,
    > span,
    > div {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`;

export const LessonHeaderMessageWrapper = styled.div`
  display: flex;
`;

export const StatusBall = styled(StatusIndicator)`
  margin-left: 10px;
`;
