import * as React from 'react';
import PinIconSrc from '@chegg-tutors-chat/shared/assets/images/ic-pin.svg';
import { Image, Layout } from './styled';

export interface PinProps {
  /**
   * @prop onClick - function which calls action to modify store.
   */
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}
/**
 * PinIcon - component that displays Pin icon on hoever over message.
 */
const PinIcon: React.SFC<PinProps> = ({ onClick }) => {
  return (
    <Layout title="Pin" onClick={onClick}>
      <Image src={PinIconSrc} alt="Pin message" />
    </Layout>
  );
};

export default PinIcon;
