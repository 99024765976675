import { connect } from 'react-redux';
import * as lessonsSelectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as messagesSelectors from '@chegg-tutors-chat/shared/redux/modules/messages/selectors';
import { getUser } from '@chegg-tutors-chat/shared/redux/modules/user/selectors';
import Conversation from './Conversation';

/**
 * @interface ConversationProps
 *
 * @prop conversation - all messages in the channel
 * @prop loggedInUser - the user who is currently logged into the console
 */
export interface ConversationPropsFromState {
  conversation?: readonly Message[];
  loggedInUser?: UserState;
}

export function mapStateToProps(state: GlobalState): ConversationPropsFromState {
  const currentLessonId = lessonsSelectors.getSelectedLessonId(state);
  const conversation = messagesSelectors.getLessonMessages(state, currentLessonId);
  const loggedInUser = getUser(state);
  return { conversation, loggedInUser };
}

export default connect(mapStateToProps, null)(Conversation);
