import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('notes');

export interface UpdateNotePayload {
  lessonId: Lesson['id'];
  note: Note;
}

export const updateYourNote = actionCreator<UpdateNotePayload>('UPDATE_NOTE');
