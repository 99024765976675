import { connect } from 'react-redux';
import { noop } from '@chegg-tutors-chat/shared/utils';
import FlaggedWhyRejectRerouteForm from './FlaggedWhyRejectRerouteForm';
import WhyRejectRerouteForm, { WhyRejectRerouteFormProps } from './WhyRejectRerouteForm';

/**
 * env variables are strings so the only way this would be false is if it was an empty string.
 * Adding this check with allow us to read whether it's "true" or "false"
 *
 * when we are in production environment, SHOW_UPDATED_REROUTE_FORM tends to be false and
 * when we are in development environment, SHOW_UPDATED_REROUTE_FORM is true.
 */
const SHOW_UPDATED_REROUTE_FORM = process.env.REACT_APP_IS_OFFLINE === 'true';

export function mapStateToProps(
  _state: GlobalState,
  passedProps: Partial<WhyRejectRerouteFormProps>
): WhyRejectRerouteFormProps {
  const { onSubmit = noop, isOpen = false } = passedProps;

  return {
    isOpen,
    onSubmit
  };
}

// Once we finish working on new "WhyRejectRerouteFormDev" https://chegg.atlassian.net/browse/CT-3605
// we shall remove below lines and feature flag:

const RejectRerouteForm = SHOW_UPDATED_REROUTE_FORM
  ? FlaggedWhyRejectRerouteForm
  : WhyRejectRerouteForm;

export default connect(mapStateToProps, null)(RejectRerouteForm);
