import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModalCallbackProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import * as lessonsActions from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import * as lessonsSelectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { showModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { getLatestNote } from '@chegg-tutors-chat/shared/redux/modules/notes/selectors';
import { captureError, formatDisplayName } from '@chegg-tutors-chat/shared/utils';
import AcceptReject, { AcceptRejectProps } from './AcceptReject';
import RequestDetails from './RequestDetails/RequestDetails';

export const mapDispatchToProps = (dispatch: Dispatch): ModalCallbackProps => {
  const handleRejectLesson = (id: Lesson['id']) => {
    dispatch(
      showModal({
        modalName: MODAL.REJECT,
        modalOptions: {
          hasCloseButton: false,
          lessonId: id
        }
      })
    );
  };

  const handleAcceptLesson = (lessonId: Lesson['id']) => {
    dispatch(lessonsActions.acceptLesson({ id: lessonId }));
  };

  return {
    primaryButtonClick: handleAcceptLesson,
    secondaryButtonClick: handleRejectLesson
  };
};

export const mapStateToProps = (
  state: GlobalState,
  passedProps: ModalsState
): AcceptRejectProps => {
  const modalOptions = passedProps.modalOptions || {};
  // lesson id needs to be an 'id' when firing an action to on lessons
  const id = modalOptions.lessonId || lessonsSelectors.getSelectedLessonId(state);
  const ticketInfo = lessonsSelectors.getLessonTicket(state, id);
  const currentLesson = lessonsSelectors.getSelectedLesson(state);
  let requestData: LessonRequestFormData = ticketInfo && ticketInfo.request;
  let shouldAddHeader = true;
  let studentName = '';
  if (currentLesson && ticketInfo) {
    const lessonStudent = currentLesson.userData.student;
    const { firstName, lastName } = lessonStudent;
    studentName = formatDisplayName(firstName, lastName);
    requestData = {
      ...ticketInfo.request,
      name: studentName
    };
  }

  const latestNote = getLatestNote(state, id);

  const requestDetails = ticketInfo && (
    <RequestDetails request={requestData} note={latestNote} />
  );

  if (!ticketInfo) {
    shouldAddHeader = false;
    const error = new Error('In Accept/Reject modal we are missing ticket information');
    captureError(error);
  }

  return {
    isOpen: true,
    name: studentName,
    primaryCallbackPayload: id,
    requestDetails,
    secondaryCallbackPayload: id,
    shouldAddHeader
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptReject);
