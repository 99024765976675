import * as React from 'react';
import { ToggleToolPayload } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { Icon, Item, Toolbar } from './styled';

/**
 * @prop name - name of the tool.
 * @prop src - icon file scr of the tool.
 */
export interface Tool {
  name: string;
  src: string;
  ariaLabel: string;
}

/**
 * @prop lessonId - id of the current lesson.
 * @prop tools - array of all tools.
 * @prop opentool - tool which is currently open in the toolbar.
 */
export interface ToolbarProps {
  lessonId: string;
  tools: (Tool | null)[];
  openTool?: string;
}

/**
 * @prop toggleTool - callback function to toogle tools in toolbar.
 */
export interface ToolbarDispatchProps {
  toggleTool: (payload: ToggleToolPayload) => void;
}

/**
 * DisplayToolbar - renders toolbar
 * @props - ToolbarProps
 * @props - ToolbarDispatchProps
 */
const DisplayToolbar: React.SFC<ToolbarProps & ToolbarDispatchProps> = props => {
  const { lessonId, tools, openTool, toggleTool } = props;

  return (
    <Toolbar role="toolbar">
      {tools.map(maybeTool => {
        if (maybeTool !== null) {
          const tool = maybeTool;
          return (
            <Item
              key={tool.name}
              onClick={() => toggleTool({ lessonId, toolName: tool.name })}
              title={tool.name}
              selected={tool.name === openTool}
            >
              <Icon alt={tool.name} src={tool.src} aria-label={tool.ariaLabel} />
            </Item>
          );
        } else {
          return null;
        }
      })}
    </Toolbar>
  );
};

export default DisplayToolbar;
