import { connect } from 'react-redux';
import * as clientActions from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as scratchPadActions from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import GraphPanel from './GraphPanel';

export const mapStateToProps = (state: GlobalState) => {
  return {
    lessonId: getSelectedLessonId(state)
  };
};

export const mapDispatchToProps = {
  addAttachment: clientActions.addAttachment,
  sendImageToScratchPad: scratchPadActions.sendImageToScratchPad,
  toggleTool: clientActions.toggleTool
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphPanel);
