import styled from 'styled-components';

export const ModalImage = styled.img`
  width: 100%;
`;

/**
 * With outline-style auto, outline is not visible for links on modal.
 * This will also change the default outline behavior on firefox.
 */
export const ImageLink = styled.a`
  display: inline-block;
  &:focus {
    outline-style: solid;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
`;
