import {
  BaseModalProps,
  ModalCallbackProps,
  PayloadType
} from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { Modal } from '@chegg/fanta';
import * as React from 'react';

export interface AcceptRejectProps extends BaseModalProps {
  name?: string;
  secondaryCallbackPayload: PayloadType;
  primaryCallbackPayload: PayloadType;
  requestDetails?: JSX.Element;
  shouldAddHeader?: boolean;
}

/**
 * Modal that displays a prompt to accept/reject the lesson
 *
 * @props AcceptRejectProps
 */
const AcceptReject: React.SFC<AcceptRejectProps & ModalCallbackProps> = ({
  isOpen,
  name,
  primaryButtonClick,
  primaryCallbackPayload,
  requestDetails,
  secondaryButtonClick,
  shouldAddHeader,
  secondaryCallbackPayload,
  ...restProps
}) => {
  return (
    <Modal
      isOpen={isOpen}
      hasCloseButton={false}
      headerText={shouldAddHeader ? `New request from ${name}` : undefined}
      primaryButtonClick={() => primaryButtonClick(primaryCallbackPayload)}
      primaryButtonText={'Accept'}
      secondaryButtonClick={() => secondaryButtonClick(secondaryCallbackPayload)}
      secondaryButtonText={'Decline'}
      {...restProps}
    >
      {requestDetails}
    </Modal>
  );
};

export default AcceptReject;
