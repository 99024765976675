import actionCreatorFactory from 'typescript-fsa';
import { MODAL, TOAST } from '@chegg-tutors-chat/shared/constants';

const actionCreator = actionCreatorFactory('modals');

export interface ToggleModalPayload {
  modalName: MODAL | TOAST;
  modalOptions?: any;
  asToast?: boolean;
}

export const showModal = actionCreator<ToggleModalPayload>('SHOW_MODAL');
export const closeModal = actionCreator('CLOSE_MODAL');
