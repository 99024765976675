/** Color Constants for Styled Components
 *
 * For colors starting with a letter we use the dot notation,
 * because TS can validate the attribute when we use the dot notation.
 * If the color hex code starts with a number, then we have to use the
 * bracket notation to access it.
 *
 * Example:
 * COLORS.CCCC
 * COLORS['2dsdfsf']
 */
const colors = {
  // LEGEND:
  // /**
  //  * ux-color-name, ux-color-name-as-appears-elsewhere
  //  * $interface-color-00, f-color-00
  //  * [description of color absent from styleguide]
  //  */
  // 'HEXH3X: '#hexh3x',

  /**
   * light-grey
   * $interface-color-14
   */
  CCCCCC: '#cccccc',

  /**
   * white-three
   * f-color-02
   */
  DDDDDD: '#dddddd',

  /**
   * [red color used for form errors]
   */
  D3332A: '#d3332a',

  /**
   * [darker orange color used for Special button hover state]
   */
  D66E00: '#d66e00',

  /**
   * [light grey]
   */
  D8D8D8: '#d8d8d8',

  /**
   * chegg-orange
   */
  EB7100: '#eb7100',

  /**
   * off-white, squealing-grey
   * $interface-color-12
   */
  EEEEEE: '#eeeeee',

  /**
   * very-light-blue, alert-blue
   * $interface-color-10
   */
  EEF5FF: '#eef5ff',

  /**
   * xlight-grey
   * $interface-color-13
   */
  E5E5E5: '#e5e5e5',

  /**
   * Error Red
   */
  E43F32: '#e43f32',

  /**
   * GREY LIGHT
   */
  E7E7E7: '#e7e7e7',

  /**
   * yellow
   * $interface-color-02, f-color-07
   */
  FFB70D: '#ffb70d',

  /**
   * white
   * $interface-color-11, f-color-01
   */
  FFFFFF: '#ffffff',

  /**
   * very-light-pink, alert-red
   * $interface-color-09
   */
  FFF2ED: '#fff2ed',

  /**
   * wild-sand
   */
  F5F5F5: '#f5f5f5',

  /**
   * alert-yellow
   * $interface-color-07
   */
  FFF5C6: '#fff5c6',

  /**
   * LIGHT GREY
   */
  FAFAFA: '#fafafa',

  /**
   * coral light
   */
  FFF3F2: '#fff3f2',

  /**
   * black
   * f-color-06
   */

  '000000': '#000000',

  /**
   * link, ocean
   * f-color-12
   */
  '007C8A': '#007c8a',

  /**
   * dark-pink
   * This is a temporary default color for embedded.
   */
  '5B1566': '#5B1566',

  /**
   * [teal color used for links]
   */
  '00838F': '#00838f',

  /**
   * [teal color used for Primary button hover state]
   */
  '0B8E87': '#0B8E87',

  /**
   * teal, teal-two, tealish
   * $interface-color-06, f-color-11
   */
  '21A29D': '#21a29d',

  /**
   * dark-grey
   * $interface-color-17, f-color-05
   */
  '333333': '#333333',

  /**
   * dark-grey
   */
  '222222': '#222222',

  /**
   * medium-grey
   */

  '999999': '#999999',
  /**
   * visited-link-color
   */
  '4A807A': '#4a807a',

  /**
   * green
   * $interface-color-03
   */
  '4DC66D': '#4dc66d',

  /**
   * [status indicator green]
   */
  '5CD068': '#5cd068',

  /**
   * blue
   * $interface-color-05
   */
  '5C90C7': '#5C90C7',

  /**
   * med-grey
   * $interface-color-16, f-color-04
   */
  '555555': '#555555',

  /**
   * grey, warm-grey, warm-grey-two
   * $interface-color-15, f-color-03
   */
  '767676': '#767676',

  /**
   * Dark blue
   */
  '007db7': '#007db7',

  /**
   * transparent
   */
  TRANSPARENT: 'transparent'
};

export const hexToRgb = (hex: string) => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (_m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  /* r, g, b keys are not sorted alphabetically, so we need to disable tslint */
  /* tslint:disable */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : {
        // If invalid color, return black
        r: 0,
        g: 0,
        b: 0
      };
  /* tslint:enable */
};

export const rgba = (hex: string, alpha: number) => {
  const color = hexToRgb(hex);
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
};

export default colors;
