import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  rejectLesson,
  RerouteRejectLessonPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import RejectRerouteManager from '../RejectRerouteManager';

export const mapDispatchToProps = (dispatch: Dispatch, passedProps: ModalsState) => {
  const { lessonId = '' } = passedProps.modalOptions || {};
  const handleRejectLesson = (rejectRerouteData: RerouteRejectLessonPayload) => {
    const { reason, note } = rejectRerouteData;

    const { reasonToRejectOrReroute, tellUsMore } = reason;

    dispatch(
      rejectLesson({
        id: lessonId,
        note,
        reason: {
          reasonToRejectOrReroute,
          tellUsMore
        }
      })
    );
  };
  return {
    rejectReroute: handleRejectLesson
  };
};

export default connect(null, mapDispatchToProps)(RejectRerouteManager);
