import React from 'react';
import { ThemeProvider } from 'styled-components';
import { publicConfig } from '@chegg-tutors-chat/shared/config';
import { PublicConfigKeys } from '@chegg-tutors-chat/shared/config/public';
import {
  HEADER,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  STUDENT_AVATAR,
  TUTOR_AVATAR
} from '@chegg-tutors-chat/shared/constants';

/**
 * Gets the value from the public config
 * @param configValue
 */
export const getConfigStyle = (configValue: string) =>
  publicConfig.get(PublicConfigKeys.THEME, configValue);

export interface Theme {
  [TUTOR_AVATAR.BG_COLOR]: string;
  [STUDENT_AVATAR.BG_COLOR]: string;
  [PRIMARY_BUTTON.BG_COLOR]: string;
  [PRIMARY_BUTTON.TEXT_COLOR]: string;
  [PRIMARY_BUTTON.HOVER_TEXT_COLOR]: string;
  [PRIMARY_BUTTON.HOVER_BG_COLOR]: string;
  [SECONDARY_BUTTON.BG_COLOR]: string;
  [SECONDARY_BUTTON.TEXT_COLOR]: string;
  [SECONDARY_BUTTON.HOVER_TEXT_COLOR]: string;
  [SECONDARY_BUTTON.HOVER_BG_COLOR]: string;
  [HEADER.BG_COLOR]: string;
  [HEADER.NEW_MESSAGE_BG_COLOR]: string;
}
/**
 * Gets current theme object that uses public config values
 */
export const getTheme = (): Theme => ({
  [TUTOR_AVATAR.BG_COLOR]: getConfigStyle(TUTOR_AVATAR.BG_COLOR),
  [STUDENT_AVATAR.BG_COLOR]: getConfigStyle(STUDENT_AVATAR.BG_COLOR),
  [PRIMARY_BUTTON.BG_COLOR]: getConfigStyle(PRIMARY_BUTTON.BG_COLOR),
  [PRIMARY_BUTTON.TEXT_COLOR]: getConfigStyle(PRIMARY_BUTTON.TEXT_COLOR),
  [PRIMARY_BUTTON.HOVER_TEXT_COLOR]: getConfigStyle(PRIMARY_BUTTON.HOVER_TEXT_COLOR),
  [PRIMARY_BUTTON.HOVER_BG_COLOR]: getConfigStyle(PRIMARY_BUTTON.HOVER_BG_COLOR),
  [SECONDARY_BUTTON.BG_COLOR]: getConfigStyle(SECONDARY_BUTTON.BG_COLOR),
  [SECONDARY_BUTTON.TEXT_COLOR]: getConfigStyle(SECONDARY_BUTTON.TEXT_COLOR),
  [SECONDARY_BUTTON.HOVER_TEXT_COLOR]: getConfigStyle(SECONDARY_BUTTON.HOVER_TEXT_COLOR),
  [SECONDARY_BUTTON.HOVER_BG_COLOR]: getConfigStyle(SECONDARY_BUTTON.HOVER_BG_COLOR),
  [HEADER.BG_COLOR]: getConfigStyle(HEADER.BG_COLOR),
  [HEADER.NEW_MESSAGE_BG_COLOR]: getConfigStyle(HEADER.NEW_MESSAGE_BG_COLOR)
});

/**
 * Global Theme component that extends ThemeProvider and applies global styles
 * @param props
 */
export const Theme: React.FunctionComponent = props => {
  return (
    <ThemeProvider theme={getTheme()}>
      <>{props.children}</>
    </ThemeProvider>
  );
};
