import { SCRATCHPAD_IMAGE_ORIGIN_TYPE } from '@chegg-tutors-chat/shared/constants';
import {
  showMathTypeTool,
  TrackToolPayload,
  updateStudentStatus,
  UpdateStudentStatusPayload
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import {
  acceptLesson,
  LessonPayload,
  rejectLesson,
  rerouteLesson,
  RerouteRejectLessonPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import {
  pinMessage,
  PinMessagePayload,
  unPinMessage,
  UnPinMessagePayload
} from '@chegg-tutors-chat/shared/redux/modules/pins/actions';
import { sendImageToScratchPad } from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import {
  SetAvailabilityPayload,
  setTutorAvailability
} from '@chegg-tutors-chat/shared/redux/modules/user/actions';
import { EVENT_ID, EVENT_NAME, EVENT_TYPE } from '../constants';
import { genericLogicFactory } from '../utils';
import {
  formatAnalyticsGenericEvent,
  FormatAnalyticsGenericEventPayload
} from '../utils/formatAnalyticsGenericEvent';

/**
 * logs to analytics when the user pins a message.
 */
export const trackPinMessage = genericLogicFactory<PinMessagePayload>(
  pinMessage.type,
  (state: GlobalState, _payload: PinMessagePayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.pinMessage,
      eventName: EVENT_NAME.bookmark,
      eventState: state,
      eventType: EVENT_TYPE.BOOKMARKS
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

/**
 * logs to analytics when the user un-pins a message.
 */
export const trackUnPinMessage = genericLogicFactory<UnPinMessagePayload>(
  unPinMessage.type,
  (state: GlobalState, _payload: UnPinMessagePayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.unPinMessage,
      eventName: EVENT_NAME.bookmark,
      eventState: state,
      eventType: EVENT_TYPE.BOOKMARKS
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

/**
 * logs to analytics when the user sends an image to the scratchpad from the graphing tool
 */
export const trackSendImageToScratchPadFromGraphingTool = genericLogicFactory<
  SendImageToScratchPadPayload
>(
  sendImageToScratchPad.type,
  (state: GlobalState, payload: SendImageToScratchPadPayload) => {
    const { originType } = payload;
    if (originType && originType === SCRATCHPAD_IMAGE_ORIGIN_TYPE.GRAPHING_TOOL) {
      const genericEventConfig: FormatAnalyticsGenericEventPayload = {
        eventId: EVENT_ID.sendImgToScratchPadFromGraphingTool,
        eventName: EVENT_NAME.graph_to_canvas,
        eventState: state,
        eventType: EVENT_TYPE.TOOL_COPY_PASTE
      };
      return formatAnalyticsGenericEvent(genericEventConfig);
    } else {
      return false;
    }
  }
);

/**
 * logs to analytics when the student status changes; either going "online" or "offline"
 */
export const trackUpdateStudentStatus = genericLogicFactory<UpdateStudentStatusPayload>(
  updateStudentStatus.type,
  (state: GlobalState, payload: UpdateStudentStatusPayload) => {
    const { lessonId, studentStatus } = payload;
    const lesson = getClientInfo(state, lessonId);
    if (lesson === undefined) {
      console.error('failed to track update of student status');
      return false;
    } else {
      const oldLessonStatus = lesson.studentStatus;
      if (oldLessonStatus !== studentStatus) {
        const genericEventConfig: FormatAnalyticsGenericEventPayload = {
          eventId: EVENT_ID.chatStatusChanged,
          eventName: EVENT_NAME.chat_status_change,
          eventState: state,
          eventType: EVENT_TYPE.FEEDBACK,
          eventValueConfig: {
            chat_new_status: payload.studentStatus,
            chat_prev_status: lesson.studentStatus
          },
          lessonId
        };
        return formatAnalyticsGenericEvent(genericEventConfig);
      } else {
        return false;
      }
    }
  }
);

/**
 * logs to analytics when the tutor toggles their availability.
 */
export const trackAvailabilityToggle = genericLogicFactory<SetAvailabilityPayload>(
  setTutorAvailability.type,
  (state: GlobalState, _payload: SetAvailabilityPayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.toggleAvailability,
      eventName: EVENT_NAME.tutor_chat_availability_toggle,
      eventState: state,
      eventType: EVENT_TYPE.TUTOR_AVAILABILITY
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

/**
 * logs to analytics when a lesson is rerouted.
 * "rerouted" meaning a tutor reroutes an open lesson and sends it to another
 * tutor.
 */
export const trackRerouteLesson = genericLogicFactory<RerouteRejectLessonPayload>(
  rerouteLesson.type,
  (state: GlobalState, _payload: RerouteRejectLessonPayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.tutorRerouteLesson,
      eventName: EVENT_NAME.tutor_chat_reroute,
      eventState: state,
      eventType: EVENT_TYPE.TUTOR_LESSON_ACTION
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

/**
 * logs to analytics when a lesson is accepted.
 * "accepted" meaning a tutor receives a lesson request and clicks 'accept' and
 * also the case where they receive a lesson and an auto-accept is triggered.
 */
export const trackAcceptLesson = genericLogicFactory<LessonPayload>(
  acceptLesson.type,
  (state: GlobalState, _payload: LessonPayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.tutorAcceptLesson,
      eventName: EVENT_NAME.tutor_chat_accept_lesson,
      eventState: state,
      eventType: EVENT_TYPE.TUTOR_LESSON_ACTION
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

/**
 * logs to analytics tracking when a tutor rejects a new lesson request.
 */
export const trackRejectLesson = genericLogicFactory<RerouteRejectLessonPayload>(
  rejectLesson.type,
  (state: GlobalState, _payload: LessonPayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.tutorRejectLesson,
      eventName: EVENT_NAME.tutor_chat_reject_lesson,
      eventState: state,
      eventType: EVENT_TYPE.TUTOR_LESSON_ACTION
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

/**
 * logs to analytics when the math type tool is opened in the scratch pad.
 */
export const trackMathTypeTool = genericLogicFactory<TrackToolPayload>(
  showMathTypeTool.type,
  (state: GlobalState, _payload: TrackToolPayload) => {
    const genericEventConfig: FormatAnalyticsGenericEventPayload = {
      eventId: EVENT_ID.openMathType,
      eventName: EVENT_NAME.mathtype_open,
      eventState: state,
      eventType: EVENT_TYPE.TOOL_OPEN
    };
    return formatAnalyticsGenericEvent(genericEventConfig);
  }
);

export default [
  trackAcceptLesson,
  trackAvailabilityToggle,
  trackMathTypeTool,
  trackPinMessage,
  trackRejectLesson,
  trackRerouteLesson,
  trackSendImageToScratchPadFromGraphingTool,
  trackUnPinMessage,
  trackUpdateStudentStatus
];
