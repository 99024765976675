import {
  formatAnalyticsClickEvent,
  FormatAnalyticsClickEventPayload
} from '@chegg-tutors-chat/shared/providers/analytics/utils/formatAnalyticsClickEvent';
import * as scratchPadActions from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import { EL_REGION, EL_TYPE, EVENT_NAME } from '../constants';
import { clickLogicFactory } from '../utils';

const trackSelectPenTool = clickLogicFactory<SelectScratchPadToolPayload>(
  scratchPadActions.selectScratchPadTool.type,
  (state, payload) => {
    const { tool } = payload;
    if (tool && tool === 'draw') {
      const clickEventConfig: FormatAnalyticsClickEventPayload = {
        eventName: EVENT_NAME.select_pen_tool,
        eventRegion: EL_REGION.scratchpad_toolbar,
        eventState: state,
        eventType: EL_TYPE.button
      };
      return formatAnalyticsClickEvent(clickEventConfig);
    }
    return false;
  }
);

export default trackSelectPenTool;
