import * as React from 'react';
import circleIcon from '@chegg-tutors-chat/shared/assets/images/ic-shape-circle.svg';
import lineIcon from '@chegg-tutors-chat/shared/assets/images/ic-shape-line.svg';
import rectangleIcon from '@chegg-tutors-chat/shared/assets/images/ic-shape-rectangle.svg';
import triangleIcon from '@chegg-tutors-chat/shared/assets/images/ic-shape-triangle.svg';
import ScratchPad from '../styled';

const SubToolbar = ScratchPad.SubBar;
/*
 * Shape - a scratchpad shape tool
 *
 * @prop key - object key to compare shape tools
 * @prop src - icon src to be displayed for the shape tool
 * @prop tooltip - text to be displayed when user hovers over shape tool
 */
export interface Shape {
  align?: 'left' | 'right';
  key: string;
  src: string;
  tooltip: string;
}

export const SHAPES = {
  circle: {
    key: 'circle',
    src: circleIcon,
    tooltip: 'Insert circle'
  },
  line: {
    key: 'line',
    src: lineIcon,
    tooltip: 'Insert line'
  },
  rectangle: {
    key: 'rectangle',
    src: rectangleIcon,
    tooltip: 'Insert rectangle'
  },
  triangle: {
    key: 'triangle',
    src: triangleIcon,
    tooltip: 'Insert triangle'
  }
};
const shapeOrder = [SHAPES.line, SHAPES.rectangle, SHAPES.circle, SHAPES.triangle];

export const SCRATCHPAD_SHAPES_TOOLBAR = 'scratchpad-shapes-toolbar';

interface Props {
  selected: Shape;
  onClick: (shape: Shape) => void;
}

const Toolbar: React.SFC<Props> = ({ selected, onClick }) => {
  return (
    <SubToolbar id={SCRATCHPAD_SHAPES_TOOLBAR} tabIndex={-1} aria-label="Shapes toolbar">
      {shapeOrder.map(shape => {
        return (
          <SubToolbar.Item
            key={shape.key}
            onClick={() => onClick(shape)}
            title={shape.tooltip}
            selected={selected.key === shape.key}
            src={shape.src}
          />
        );
      })}
    </SubToolbar>
  );
};

export default Toolbar;
