import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const Layout = styled.div`
  border-top: 1px solid ${COLORS.DDDDDD};
  box-shadow: 0px -1px 8px ${COLORS.DDDDDD};
  height: 64px;
  padding: 14px 30px;
  text-align: center;
`;
