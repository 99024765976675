export interface CanvasStateConfigType {
  currentIndex: number;
  stack: any[];
}

const canvasStackLimit = 15;

/**
 * Takes a stack of Fabric.js canvas state objects,
 * and an index of the 'current' state.
 * Updates it to the 'previous' state as possible.
 *
 * @param stack - stack of canvas state objects
 * @param currentIndex - index of the "current" canvas state object in the stack
 */
export const undo = (stack: object[], currentIndex: number): CanvasStateConfigType => {
  // if we can move index to point to an older state, do it
  if (currentIndex < stack.length - 1) {
    currentIndex += 1;
  }
  return { stack, currentIndex };
};

/**
 * Takes a stack of Fabric.js canvas state objects,
 * and an index of the 'current' state.
 * Updates it to the 'next' state as possible.
 *
 * @param stack - stack of canvas state objects
 * @param currentIndex - index of the "current" canvas state object in the stack
 */
export const redo = (stack: object[], currentIndex: number): CanvasStateConfigType => {
  // if we can move index to point to an newer state, do it
  if (currentIndex > 0) {
    currentIndex -= 1;
  }
  return { stack, currentIndex };
};

/**
 * Takes a stack of Fabric.js canvas state objects,
 * and an index of the 'current' state.
 * Also takes a new copy of the canvas state.
 * Puts the new copy as the top of the stack,
 * moves the 'current' index to the top of the stack.
 * Keeps stack from growing larger than limit
 *
 * @param stack - stack of canvas state objects
 * @param currentIndex - index of the "current" canvas state object in the stack
 * @param newCanvasState - updated canvasStateObject
 */
export const update = (
  stack: object[],
  currentIndex: number,
  newCanvasState: object
): CanvasStateConfigType => {
  let stackCopy = stack.slice();
  if (currentIndex === 0) {
    stackCopy.unshift(newCanvasState);
    if (stackCopy.length > canvasStackLimit) {
      stackCopy = stackCopy.slice(0, canvasStackLimit);
    }
    return { stack: stackCopy, currentIndex };
  }
  // currentIndex is greater than 0
  stackCopy = stackCopy.slice(currentIndex);
  stackCopy.unshift(newCanvasState);
  return { stack: stackCopy, currentIndex: 0 };
};
