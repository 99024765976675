import { AxiosInstance } from 'axios';
import { Tool } from '@chegg-tutors-chat/shared/components/Toolbar/Toolbar';
import { TOOLS } from '@chegg-tutors-chat/shared/constants';
import { Client } from '@optimizely/optimizely-sdk';
import { createInstance, ReactSDKClient } from '@optimizely/react-sdk';
import publicConfig from './public';

/**
 * Config settings
 * @prop TOOLBAR - a key string
 */
export enum CONFIG_SETTINGS {
  TOOLBAR = 'toolbar'
}

export enum ANALYTICS_PAGE_NAME {
  BLANK = '',
  TUTOR = 'tutors chat tutor client',
  STUDENT = 'tutors chat student client'
}

export enum ANALYTICS_PAGE_SUB_CAT_3 {
  BLANK = '',
  TUTOR = 'tutconsole',
  STUDENT = 'studclient'
}

/**
 * Settings keys
 */
export interface SettingsKeys {
  ANALYTICS_PAGE_DATA: {
    pageName: ANALYTICS_PAGE_NAME;
    subCategory3: ANALYTICS_PAGE_SUB_CAT_3;
  };
  AXIOS_CLIENT?: AxiosInstance;
  FULL_SCRATCHPAD?: boolean;
  PREVIOUS_MESSAGE_LIMIT: number;
  LESSON_TOKEN: string;
  TOOLS: {
    [key: string]: Tool | null;
  };
  /**
   * @param state - global state of the app
   * @param lessonId - id of the lesson
   *
   * @returns the userId of the other user in the lesson
   */
  getOtherUserId?: (state: GlobalState, lessonId: Lesson['id']) => string;
  SKIP_LEVEL_REFS: {
    MESSAGE_COMPOSER?: HTMLTextAreaElement;
  };
  ROOT_CONTAINER_NODE?: HTMLElement;
  OPTIMIZELY: {
    REACT: null | ReturnType<typeof createInstance> | ReactSDKClient;
    JAVASCRIPT: null | Client;
  };
}

/**
 * Global configurational object. Used to store
 * settings specific for tutor/student app.
 */
const config: SettingsKeys = {
  ANALYTICS_PAGE_DATA: {
    pageName: ANALYTICS_PAGE_NAME.BLANK,
    subCategory3: ANALYTICS_PAGE_SUB_CAT_3.BLANK
  },
  AXIOS_CLIENT: undefined,
  FULL_SCRATCHPAD: true,
  PREVIOUS_MESSAGE_LIMIT: 30, // Setting this to 0 will mean to get all the previous messages.
  LESSON_TOKEN: undefined,
  SKIP_LEVEL_REFS: {
    MESSAGE_COMPOSER: undefined
  },
  TOOLS: {
    ...TOOLS
  },
  ROOT_CONTAINER_NODE: undefined, // Stores where element the chat client will be rendered into
  OPTIMIZELY: {
    REACT: null,
    JAVASCRIPT: null
  }
};

/*
 * Array of keys intended usage: config.set(['key1', 'key2'], value);
 */
export default {
  get: <T extends keyof SettingsKeys>(key: T) => {
    return config[key];
  },

  set: <T extends keyof SettingsKeys>(
    key: T | string[],
    configSettings: SettingsKeys[T]
  ) => {
    // check for nested key access
    if (Array.isArray(key)) {
      // reassign config so we don't alter the location where config points to
      let tempConfig = config;
      const lastNode = key[key.length - 1];
      // access all child nodes except for the last
      for (let i = 0; i <= key.length - 2; i++) {
        tempConfig = tempConfig[key[i]];
      }
      tempConfig[lastNode] = configSettings;
    } else {
      config[key] = configSettings;
    }
  }
};

export { publicConfig };
