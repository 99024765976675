import styled, { css } from 'styled-components';
import StatusIndicator from '@chegg-tutors-chat/shared/styled/chat/StatusIndicator';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

interface LayoutProps {
  underlineColor: string;
}

export const Layout = styled.header`
  ${({ underlineColor }: LayoutProps) =>
    css`
      height: 60px;
      border-bottom: 3px solid ${underlineColor};
      display: flex;
      align-items: center;
      flex-direction: initial;
    `}
`;

export const Text = styled.p`
  font-family: Aspira-Regular;
  margin: 3px 63px 0 5px;
  color: ${COLORS[333333]};
  font-size: 12px;
`;

export const Indicator = styled(StatusIndicator)`
  margin-left: 10px;
`;

export const Logo = styled.img`
  height: 28px;
`;

export const LogoButtonWrapper = styled.div`
  margin: 7px auto 0 20px;
  position: relative;
`;
