import { Action } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { HydrateAppPayload, hydrateAppState } from '../client/actions';
import {
  addLesson,
  LessonPayload,
  removeLesson,
  selectLesson,
  submitLessonFeedBack,
  SubmitLessonFeedBackPayload,
  updateLessonStatus,
  UpdateLessonStatusPayload,
  updateLessonTutor
} from './actions';
import { getSelectedLessonId } from './selectors';

const initialState: LessonState = {
  currentLessonId: '',
  lessonsList: {}
};

/**
 * Sets the passed in lessonId to the currently selected
 * lesson
 * @param state
 * @param lessonId
 */
export function handleSelectLesson(
  state: LessonState,
  payload: LessonPayload
): LessonState {
  const lesson = state.lessonsList[payload.id];
  const status =
    lesson.status === LESSON_STATUS.YET_TO_BE_DETERMINED ||
    lesson.status === LESSON_STATUS.PENDING_TUTOR_AUTO_APPROVAL
      ? LESSON_STATUS.IN_PROGRESS
      : lesson.status;
  return {
    ...state,
    currentLessonId: payload.id,
    lessonsList: {
      ...state.lessonsList,
      [payload.id]: {
        ...lesson,
        status
      }
    }
  };
}

/**
 * Adds a lesson the store the key of the lesson is the
 * the ticket.id for now.
 * @param state
 * @param lesson the lesson data to add
 */
export function handleAddLesson(
  state: LessonState,
  lesson: Action<HydrateAppPayload | Lesson>
): LessonState {
  let nextState;
  /**
   * if we don't have a ticketId we can't save this
   * report an error later.
   */
  if (!lesson.payload || !lesson.payload.id) {
    nextState = state;
  }
  const id = lesson.payload.id;
  const lessonsList = { ...state.lessonsList, [id]: lesson.payload };
  const newState = { ...state, lessonsList };

  /**
   * if we don't have an already selected lesson,
   * then make this lesson selected
   */
  if (!state.currentLessonId) {
    nextState = handleSelectLesson(newState, { id });
  } else {
    nextState = newState;
  }
  return nextState;
}

/**
 * Removes the lesson with the passed lessonId from the state
 *
 * @param state
 * @param lessonId
 */
export function handleRemoveLesson(
  state: LessonState,
  lesson: LessonPayload
): LessonState {
  let nextState;
  if (!state.lessonsList[lesson.id]) {
    nextState = state;
  } else {
    const lessonsList = Object.assign({}, state.lessonsList);
    delete lessonsList[lesson.id];
    nextState = { ...state, lessonsList, currentLessonId: '' };
  }
  return nextState;
}

/**
 * Function that updates the lesson status default is current lesson or a passed in lessonId.
 *
 * @param state
 * @param payload
 */
export function handleUpdateLessonStatus(
  state: LessonState,
  payload: UpdateLessonStatusPayload
): LessonState {
  const { id, status } = payload;
  const lessonId = id || getSelectedLessonId({ lessons: state });
  let nextState = {
    ...state,
    lessonsList: {
      ...state.lessonsList,
      [lessonId]: {
        ...state.lessonsList[lessonId],
        status
      }
    }
  };
  if (!lessonId || !status || !state.lessonsList[lessonId]) {
    nextState = state;
  }

  return nextState;
}
/**
 * Function that updates adds tutor to lesson user data
 *
 * @param state
 * @param payload
 */
export function handleUpdateTutor(state: LessonState, payload: LessonTutor): LessonState {
  const { user: tutor, lessonId: id } = payload;
  let nextState = state;
  if (tutor && id) {
    nextState = {
      ...state,
      lessonsList: {
        ...state.lessonsList,
        [id]: {
          ...state.lessonsList[id],
          userData: {
            ...state.lessonsList[id].userData,
            tutor
          }
        }
      }
    };
  }
  return nextState;
}

/**
 * Function that set shasSubmittedFeedback to true for the provided lesson id
 *
 * @param state
 * @param payload
 */

export function handleSubmitLessonFeedBack(
  state: LessonState,
  payload: SubmitLessonFeedBackPayload
): LessonState {
  const { id } = payload;
  let nextState = state;
  if (id) {
    nextState = {
      ...state,
      lessonsList: {
        ...state.lessonsList,
        [id]: {
          ...state.lessonsList[id],
          hasSubmittedFeedback: true
        }
      }
    };
  }
  return nextState;
}

export default reducerWithInitialState(initialState)
  .casesWithAction([addLesson, hydrateAppState], handleAddLesson)
  .case(removeLesson, handleRemoveLesson)
  .case(selectLesson, handleSelectLesson)
  .case(updateLessonStatus, handleUpdateLessonStatus)
  .case(updateLessonTutor, handleUpdateTutor)
  .case(submitLessonFeedBack, handleSubmitLessonFeedBack)
  .build();
