import styled from 'styled-components';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

interface MessageInputProps {
  disabled?: boolean;
  maxHeight: number;
}

export const Layout = styled.div.attrs<MessageInputProps>({})`
  border: 2px solid ${COLORS.E5E5E5};
  display: table;
  width: 100%;
  height: 44px;
  opacity: ${(props: MessageInputProps) => (props.disabled ? 0.5 : 1)};
  max-height: ${(props: MessageInputProps) => props.maxHeight}px;
  > div {
    display: table-cell;
    height: 100%;
    &:last-child {
      width: 120px;
      height: inherit;
    }
  }
`;

export const UploadIconPosition = styled.div`
  bottom: 5px;
  position: absolute;
  right: 5px;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    bottom: -2px;
    right: 0px;
  }
`;

export const TextInputContainer = styled.div.attrs<MessageInputProps>({})`
  position: relative;
  height: 43px;
  max-height: 435px;
  & > textarea::placeholder {
    color: ${COLORS['767676']};
  }
  & .TextareaAutosize {
    border: none;
    border-radius: 8px;
    font-size: 16;
    height: 44;
    max-height: ${(props: MessageInputProps) => props.maxHeight}px;
    padding: 12px 40px 12px 16px;
    resize: none;
    width: 100%;
  }
`;

export const MessageInputWrapper = styled.div`
  margin: 0px 20px 20px;
  width: 100%;
`;
