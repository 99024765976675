import * as React from 'react';
import { createLesson } from 'src/api/helpers';

class TestForm extends React.Component<{}> {
  public tokenInput = React.createRef<HTMLTextAreaElement>();
  public questionInput = React.createRef<HTMLTextAreaElement>();
  public summaryInput = React.createRef<HTMLTextAreaElement>();
  public subjectInput = React.createRef<HTMLTextAreaElement>();

  public getVal = (input: any) => {
    if (input.current) {
      return input.current.value;
    }
    return '';
  };

  public createLesson = async () => {
    const id = this.getVal(this.tokenInput);
    const question = this.getVal(this.questionInput);
    const summary = this.getVal(this.summaryInput);
    const subject = this.getVal(this.subjectInput);
    const lessonInfo = await createLesson(id, {
      question,
      subject,
      summary
    });
    document.cookie = `id_token=${id}`;
    if (lessonInfo && lessonInfo.data) {
      const lessonInfoCopy: any = lessonInfo;
      window.location.search = `idToken=${id}&lessonToken=${lessonInfoCopy.data.token}`;
    }
  };

  public render() {
    return (
      <>
        Question:
        <br />
        <textarea
          ref={this.questionInput}
          cols={60}
          rows={5}
          defaultValue={'testing question'}
        />
        <br />
        Subject:
        <br />
        <textarea
          ref={this.subjectInput}
          cols={60}
          rows={5}
          defaultValue={'testing subject'}
        />
        <br />
        Summary:
        <br />
        <textarea
          ref={this.summaryInput}
          cols={60}
          rows={5}
          defaultValue={'testing summary'}
        />
        <br />
        Chegg id token:
        <br />
        <textarea ref={this.tokenInput} cols={60} rows={5} />
        <br />
        <button onClick={this.createLesson}>Create Lesson</button>
      </>
    );
  }
}

export default TestForm;
