import 'src/configs/bffMocks';
import * as QueryString from 'query-string';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AppContent from 'src/components/AppContent';
import ErrorPage from 'src/components/ErrorPage';
import setAxiosClient from 'src/configs/axiosClient';
import setSentryConfig from 'src/configs/sentry';
import setToolConfig from 'src/configs/tools';
import TestForm from 'src/devTools/TestForm';
import initPrintPreview from 'src/events/initPrintPreview';
import { StartConfig } from 'src/SDK/start';
import config from '@chegg-tutors-chat/shared/config';
import {
  setAnalyticsConfig,
  SetAnalyticsProps
} from '@chegg-tutors-chat/shared/config/analytics';
import { getTutorId } from '@chegg-tutors-chat/shared/redux/modules/user/selectors';
import registerServiceWorker from '@chegg-tutors-chat/shared/utils/registerServiceWorker';
import { setTagToSentry } from '@chegg-tutors-chat/shared/utils/sentry';
import setOptimizelyConfig from '@chegg-tutors-chat/shared/utils/setOptimizelyConfig';
import * as Sentry from '@sentry/browser';

// Groups pre-startup code
const preStartUp = (lessonToken: string) => {
  setSentryConfig();
  setOptimizelyConfig();
  setAxiosClient(lessonToken);
};

const anaylticsData: SetAnalyticsProps = {
  messageLimit: 0,
  user: 'STUDENT',
  userIdGetter: getTutorId
};

// Groups post-startup code
const postStartSetUp = () => {
  setToolConfig();
  setAnalyticsConfig(anaylticsData);
  initPrintPreview();
  registerServiceWorker();
};

/**
 * Logs the error and renders the ErrorPage
 */
const handleError = (queryParams: QueryString.OutputParams, rootElement: HTMLElement) => {
  Sentry.withScope(scope => {
    scope.setExtra('Query Parameters', JSON.stringify(queryParams));
    Sentry.captureException(new Error('Invalid query parameters'));
  });
  return ReactDOM.render(<ErrorPage />, rootElement);
};

const startApp = (startConfig?: StartConfig) => {
  const { lessonToken, rootElement } = startConfig;
  preStartUp(lessonToken);
  config.set('ROOT_CONTAINER_NODE', rootElement);
  const queryParams = QueryString.parse(window.location.search);
  if (queryParams) {
    const { showForm } = queryParams;
    if (typeof lessonToken === 'string') {
      /**
       * Setting lesson token in Sentry as a tag. Later we can grab the token
       * from Sentry, decrypt it and see what was the student chegg uuid.
       */
      setTagToSentry('lesson_token', lessonToken);
      ReactDOM.render(<AppContent />, rootElement);
    } else if (showForm) {
      ReactDOM.render(<TestForm />, rootElement);
    } else {
      handleError(queryParams, rootElement);
    }
  } else {
    handleError(queryParams, rootElement);
  }
  postStartSetUp();
};

export default startApp;
