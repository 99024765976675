import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

/*
 * ToolbarIconProps
 *
 * @prop src - it is used for the tool image which we are displaying.
 */
export interface ToolbarIconProps {
  src?: string;
}

/*
 * ToolbarItemProps
 *
 * @prop selected - determines whether this is the currently selected tool
 * @prop separator - position of a separator line between icons
 */
export interface ToolbarItemProps {
  selected?: boolean;
  separator?: 'top' | 'bottom' | '';
}

const config = {
  defaultSrc:
    'https://cdn3.iconfinder.com/data/icons/glypho-generic-icons/64/cog-settings-512.png',
  separator: {
    bottom: '-bottom',
    top: '-top'
  }
};

const getBackground = (selected: boolean) =>
  selected ? `${COLORS.FFFFFF}` : `${COLORS.TRANSPARENT}`;

export const Icon = styled.img.attrs<ToolbarIconProps>((props: ToolbarIconProps) => {
  return {
    src: props.src ? props.src : config.defaultSrc
  };
})`
  height: 22px;
  width: 22px;
  vertical-align: middle;
`;

export const Item = styled.button<ToolbarItemProps>`
  background-color: ${({ selected = false }: ToolbarItemProps) =>
    getBackground(selected)};
  padding: 19px;
  width: 60px;
  height: 60px;
  &:hover {
    background-color: ${COLORS.FFFFFF};
  }
  border: ${({ separator = '' }: ToolbarItemProps) => {
    return separator ? `1px solid ${COLORS.E5E5E5}` : 'none'; // make sure to remove this in the follow-up PR
  }};
`;

export const Toolbar = styled.div`
  background-color: ${COLORS.F5F5F5};
  box-sizing: content-box;
  padding: 0px;
  width: 60px;
  height: 100%;
  margin: 0;
`;
