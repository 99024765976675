import {
  BaseModalProps,
  ModalCallbackProps,
  PayloadType
} from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { Lead } from '@chegg-tutors-chat/shared/styled/sunkist/Typography';
import { Modal } from '@chegg/fanta';
import * as React from 'react';

/**
 * Modal that displays a prompt to end the lesson
 *
 * @props CloseLessonProps
 */
export interface CloseLessonProps extends BaseModalProps {
  primaryCallbackPayload: PayloadType;
}

const CloseLesson: React.SFC<CloseLessonProps & ModalCallbackProps> = ({
  isOpen,
  primaryButtonClick,
  primaryCallbackPayload,
  ...restProps
}) => {
  return (
    <Modal
      isOpen={isOpen}
      headerText={'Ready to end chat?'}
      primaryButtonClick={() => primaryButtonClick(primaryCallbackPayload)}
      primaryButtonText={'End chat'}
      secondaryButtonText={'Not yet'}
      {...restProps}
    >
      <Lead>
        If the student still needs help, please reassign them.
        <br />
        This will end the lesson.
      </Lead>
    </Modal>
  );
};

export default CloseLesson;
