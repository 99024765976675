import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

/*
 * ImgPreviewProps
 *
 * @prop src - src of img file to be previewed
 */
interface ImgPreviewProps {
  src: string;
}

export const Image = styled.img.attrs<ImgPreviewProps>((props: ImgPreviewProps) => {
  return {
    src: props.src
  };
})`
  height: inherit;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
`;

export const CloseIcon = styled.img`
  background-color: ${COLORS.EEEEEE};
  background-position: center;
  background-size: 14px 14px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  &:hover {
    background-color: ${COLORS.E5E5E5};
  }
  &:focus {
    background-color: ${COLORS.E5E5E5};
  }
`;

export const Layout = styled.div`
  height: inherit;
  width: inherit;
`;

export const OuterLayout = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  max-height: 90px;
  max-width: 120px;
  width: 100%;
  border-left: 2px solid ${COLORS.E5E5E5};
`;

export const Text = styled.span`
  max-width: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  height: 100%;
  position: relative;
  background-color: ${COLORS.F5F5F5};
  > div {
    height: 21px;
    line-height: 21px;
    display: table;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    padding-left: 10px;
    > span {
      color: ${COLORS['767676']};
      display: table-cell;
    }
  }
`;

export const AttachmentPreview = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
`;
