import GraphingToolIcon from '@chegg-tutors-chat/shared/assets/images/ic-graph.svg';
import PinnedIcon from '@chegg-tutors-chat/shared/assets/images/ic-pin.svg';
import LessonInfoIcon from '@chegg-tutors-chat/shared/assets/images/ic-profile.svg';
import ScratchPadIcon from '@chegg-tutors-chat/shared/assets/images/ic-scratchpad.svg';
import { TICKET_STATUS } from '@chegg-tutors-chat/shared/providers/jira/constants';

/**
 * Tutor session ID cookie used to authenticate tutors into the platform.
 */
export const TUTOR_JSESSION_ID = 'Tutors-Jsession-Id';

/**
 * these are the default file types we support for uploading.
 * they will be sent through sendbird.
 */
export const DEFAULT_FILE_UPLOAD_TYPES =
  '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf, text/plain, .csv, .rtf';

/**
 * Image types we allow to upload.
 */
export const IMAGE_UPLOAD_TYPES = 'image/*';

/**
 * Places where an image in the scratchpad could be sent from
 */
export enum SCRATCHPAD_IMAGE_ORIGIN_TYPE {
  GRAPHING_TOOL = 'graphing_tool',
  MATH_TYPE = 'math_type'
}

export enum MODAL_TYPE {
  NORMAL = 'normal',
  NOTIFICATION = 'notification'
}

/**
 * Places where an attachment could be sent from
 */
export enum ATTACHMENT_ORIGIN_TYPE {
  GRAPHING_TOOL = 'graphing_tool',
  MATH_TOOL = 'mathTypeImage',
  SCRATCH_PAD = 'scratchPadFileType',
  TEST = 'test' // for testing purposes
}

/**
 * Places where an attachment could be sent to
 */
export enum ATTACHMENT_DESTINATION_TYPE {
  INPUT = 'input'
}

/**
 * Toolbar tools map
 */
export const TOOLS = {
  /* We need our tools to be rendered in key order */
  /* tslint:disable:object-literal-sort-keys */
  LESSON_INFO: {
    ariaLabel: 'View or add lesson information',
    name: 'About this lesson',
    src: LessonInfoIcon
  },
  PINNED: {
    ariaLabel: 'View pinned items',
    name: 'Pinned items',
    src: PinnedIcon
  },
  SCRATCH_PAD: {
    ariaLabel: 'Open scratchpad',
    name: 'Scratchpad',
    src: ScratchPadIcon
  },
  GRAPHING_TOOL: {
    ariaLabel: 'Open graphing tool',
    name: 'Graphing tool',
    src: GraphingToolIcon
  }
  /* tslint:enable:object-literal-sort-keys */
};

/**
 * STATUSES
 */
export enum STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away',
  READY_TO_TEACH = 'Ready to teach',
  WAITING = 'waiting'
}

/**
 * Lesson status - maps to the ticket status in JIRA
 */
export { TICKET_STATUS as LESSON_STATUS };

/**
 * NO_name to display if student info is missing
 */
export const NO_NAME = 'No Name';

/**
 * These are the tool names for the scratcp pad tools.
 * They are used when sending setSectionTool actions.
 */
export const SCRATCHPAD_TOOL_NAME = {
  CIR: 'Circle',
  CLR: 'Clear Canvas',
  DEL: 'Delete',
  DROP: 'Drag and Drop',
  LINE: 'Line',
  MATH: 'Math',
  PEN: 'Pen',
  PTR: 'Pointer',
  RECT: 'Rectangle',
  SCRN: 'ScreenShot',
  SECTION: 'scratchpad',
  TRI: 'Triangle',
  TXT: 'Text',
  UPLD: 'Upload'
};

export const AWS_API_URL =
  'https://68c3t9m038.execute-api.us-east-1.amazonaws.com/prod/api/';

export const AWS_API_URL_DEV = 'http://localhost:8000/api/';

export const AWS_API_ENDPOINTS = {
  CREATE_LESSON: 'createLessonRequest'
};

export enum HTTP_REQUEST_TYPES {
  POST = 'post',
  PUT = 'put',
  GET = 'get'
}

/**
 * The message sent to the student when the tutor closes a lesson.
 */
export const TUTOR_GOODBYE = 'I hope I was able to help. Our lesson has ended.';

/**
 * Global variable to identify which client is running, ie student or tutor.
 */
type CLIENT = 'student' | 'tutor';

export const CLIENT: CLIENT | string = process.env.CLIENT || 'student';

/**
 * Global message values
 */
export enum MESSAGE_TYPE {
  ADMIN = 'admin',
  MESSAGE = 'message',
  BOT = 'bot'
}
export enum CUSTOM_MESSAGE_TYPE {
  TIMER_MESSAGE = 'timerMessage'
}

/**
 * Chegg Admin Message
 */
export enum ADMIN_MESSAGE {
  NAME = 'Chegg'
}

/**
 * A set of toasts supported in tutor/student app
 */
export enum TOAST {
  ERROR_BANNER = 'errorBanner'
}

/**
 * A set of toast notifications supported in tutor app
 */
export enum TOAST_NOTIFICATION {
  TUTOR_IDLE = 'tutorIdle',
  NO_TOAST = ''
}

/**
 * A set of modals supported in tutor/student app
 */
export enum MODAL {
  ACCEPT_REJECT = 'acceptReject',
  ACCEPT_REJECT_LESSON_REMOVED_MANAGER = 'acceptRejectLessonRemovedManager',
  IMAGE = 'imageModal',
  LOGOUT = 'logoutModal',
  CLOSE_LESSON_STUDENT = 'closeLessonStudent',
  CLOSE_LESSON_REDIRECT_STUDENT = 'closeLessonAndRedirectToHome',
  CLOSE_LESSON_TUTOR = 'closeLessonTutor',
  LESSON_FEEDBACK = 'lessonFeedBack',
  LESSON_REMOVED = 'lessonRemoved',
  UPDATE_YOUR_NOTE_ASSIGNMENT_PANEL = 'updateYourNoteAssignmentPanel',
  REJECT = 'rejectModal',
  REROUTE = 'rerouteModal',
  TEST_MODAL = 'test-modal',
  REPORT = 'reportModal',
  NO_MODAL = ''
}

/**
 * Keyboard key events
 */
export enum KEYBOARD {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  SPACE = 'Spacebar',
  DOWN_ARROW_KEYCODE = 40,
  UP_ARROW_KEYCODE = 38
}

/**
 * Generic chat connection statuses
 */
export enum CHAT_CONNECTION {
  CONNECTION_PENDING = 'Connection_Pending',
  CONNECTION_STARTED = 'Connection_Started',
  CONNECTION_SUCCEEDED = 'Connection_Succeeded',
  CONNECTION_FAILED = 'Connection_Failed',
  RECONNECTION_STARTED = 'Reconnection_Started',
  RECONNECTION_SUCCEEDED = 'Reconnection_Succeeded',
  RECONNECTION_FAILED = 'Reconnection_Failed'
}

/**
 * Viewport breakpoints
 */
export enum BREAKPOINTS {
  SM_MIN = '320px',
  SM_MAX = '599px',
  MD_MIN = '600px',
  MD_MAX = '899px',
  LG_MIN = '900px',
  LG_MAX = '1199px',
  XL_MIN = '1200px'
}

/**
 * A general treshhold time in milliseconds for an API call.
 */

let TIMEOUT_DURATION = 10000;
if (process.env.NODE_ENV === 'development') {
  TIMEOUT_DURATION = 30000;
}
export const API_TIMEOUT = TIMEOUT_DURATION;

export const NO_USER_ID = 'NO_USER_ID';

/**
 * OPTIMIZELY_SDK_KEY which is set in either
 * .env.development or .env.production files
 */
export const OPTIMIZELY_SDK_KEY = process.env.REACT_APP_OPTIMIZELY_SDK_KEY || '';

/**
 * Url used when redirecting tutor partners to SIGNIN_PAGE
 * if they access tutors-chat client being UNAUTHORIZED.
 * Currently SIGNIN_PAGE is only available in test-environment
 */
export const TUTOR_SIGNIN_PAGE_URL =
  'http://auth-service.test.cheggnet.com/auth-service/oauth2/authorization/cognito';

export enum ASSIGNMENT_COMPLEXITY {
  INTRO = 'Intro',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
  NOT_SURE = 'Not sure'
}

export enum ASSIGNMENT_TYPE {
  HOMEWORK = 'Homework problem',
  STUDY = 'Studying/trying to understand a new concept',
  PRACTICE = 'Practicing what they learned in class',
  NOT_SURE = 'Not sure'
}

export const TIMEOUT_MESSAGE = 'Request took too long to complete';

// Supported subjects for reroute/rejected lessons
export enum REJECT_REROUTE_SUBJECTS {
  MATHS = 'Maths',
  SCIENCE = 'Science',
  PHYSICS = 'Physics',
  NONE_SELECTED = 'NONE_SELECTED'
}

// Primary Button public config values
export enum PRIMARY_BUTTON {
  BG_COLOR = 'button.primary.backgroundColor',
  TEXT_COLOR = 'button.primary.textColor',
  HOVER_TEXT_COLOR = 'button.primary.hoverTextColor',
  HOVER_BG_COLOR = 'button.primary.hoverBackgroundColor'
}

// ApplicationHeader public config values
export enum HEADER {
  UNDERLINE_COLOR = 'header.underlineColor',
  BG_COLOR = 'header.backgroundColor',
  NEW_MESSAGE_BG_COLOR = 'header.newMessage.backgroundColor',
  POPOUT_ENABLED = 'header.popout.enabled',
  HOMEPAGE_URL = 'header.homepage.url'
}

// Student avatar public config values
export enum STUDENT_AVATAR {
  BG_COLOR = 'avatar.student.backgroundColor'
}

// Tutor avatar public config values
export enum TUTOR_AVATAR {
  BG_COLOR = 'avatar.tutor.backgroundColor'
}

// Secondary Button public config values
export enum SECONDARY_BUTTON {
  BG_COLOR = 'button.secondary.backgroundColor',
  TEXT_COLOR = 'button.secondary.textColor',
  HOVER_TEXT_COLOR = 'button.secondary.hoverTextColor',
  HOVER_BG_COLOR = 'button.secondary.hoverBackgroundColor'
}

// A collection of status codes recieved in the http responses as a result of
// *bffRequest* invocations
export enum RESPONSE_STATUS_CODE {
  UNAUTHORIZED = 401,
  OK = 200
}
