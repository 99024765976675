import {
  formatAnalyticsClickEvent,
  FormatAnalyticsClickEventPayload
} from '@chegg-tutors-chat/shared/providers/analytics/utils/formatAnalyticsClickEvent';
import { selectScratchPadTool } from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import { EL_REGION, EL_TYPE, EVENT_NAME } from '../constants';
import { clickLogicFactory } from '../utils';

/**
 * logs to analytics when the text tool in the scratch pad is selected.
 */
export const trackSelectTextTool = clickLogicFactory<SelectScratchPadToolPayload>(
  selectScratchPadTool.type,
  (state: GlobalState, payload: SelectScratchPadToolPayload) => {
    const { tool } = payload;
    if (tool && tool === 'text') {
      const clickEventConfig: FormatAnalyticsClickEventPayload = {
        eventName: EVENT_NAME.select_text_tool,
        eventRegion: EL_REGION.scratchpad_toolbar,
        eventState: state,
        eventType: EL_TYPE.button
      };
      return formatAnalyticsClickEvent(clickEventConfig);
    } else {
      return false;
    }
  }
);

export default trackSelectTextTool;
