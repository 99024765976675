import { AnalyticsClickEvent } from './interfaces';

/**
 * Event types adobe accepts.
 */
export enum EVENT_TYPE {
  CHAT = 'chat',
  CLICK = 'click event',
  TOOL_COPY_PASTE = 'tool copy_paste',
  TOOL_OPEN = 'tool open',
  TOOL_CLOSE = 'tool close',
  MODAL = 'modal',
  TUTOR_LESSON_ACTION = 'tutor lesson action',
  BOOKMARKS = 'bookmarks',
  TUTOR_AVAILABILITY = 'tutor availability',
  FEEDBACK = 'feedback'
}

export enum EVENT_NAME {
  chat_status_change = 'chat_status_change',
  bookmark = 'bookmark',
  canvas_close = 'canvas_close',
  canvas_open = 'canvas_open',
  chat_start = 'chat_start',
  clear_scratchpad = 'clear_scratchpad',
  draw_circle = 'draw_circle',
  draw_line = 'draw_line',
  draw_pen_black = 'draw_pen_black',
  draw_pen_blue = 'draw_pen_blue',
  draw_pen_red = 'draw_pen_red',
  draw_square = 'draw_square',
  draw_triangle = 'draw_triangle',
  feedback_submitted = 'feedback_submitted',
  graph_to_canvas = 'graph_to_canvas',
  graph_to_chat = 'graph_to_chat',
  graph_tool_open = 'graph_tool_open',
  lesson_accept_reject_modal = 'lesson_accept_reject_modal',
  mathtype_open = 'mathtype_open',
  mathtype_to_chat = 'mathtype_to_chat',
  mathtype_to_scratchpad = 'mathtype_to_scratchpad',
  modal_open = 'modal_open',
  request_received = 'request_received',
  scratchpad_to_chat = 'scratchpad_to_chat',
  select_pen_tool = 'select_pen_tool',
  select_text_tool = 'select_text_tool',
  transcript_download = 'transcript_download',
  tutor_chat_accept_lesson = 'tutor_chat_accept_lesson',
  tutor_chat_availability_toggle = 'tutor_chat_availability_toggle',
  tutor_chat_reject_lesson = 'tutor_chat_reject_lesson',
  tutor_chat_reroute = 'tutor_chat_reroute',
  update_your_note = 'update_your_note'
}

export enum EL_TYPE {
  modal = 'modal',
  button = 'button'
}

export enum EL_REGION {
  accept_reject_modal = 'accept/reject modal',
  scratchpad_toolbar = 'scratchpad_toolbar',
  right_tool_panel = 'right_tool_panel',
  thankyou_modal = 'thankyou_modal'
}

export enum EVENT_ID {
  userFeedback = 'event43',
  openGraphTool = 'event950',
  openCanvas = 'event951',
  openMathType = 'event952',
  closeScratchPad = 'event953',
  sendImgToScratchPadFromGraphingTool = 'event954',
  graphToChat = 'event955',
  scratchPadToChat = 'event956',
  mathTypeToScratchPad = 'event957',
  mathTypeToChat = 'event958',
  tutorAcceptLesson = 'event960',
  clickEvent = 'event96',
  tutorRejectLesson = 'event961',
  tutorRerouteLesson = 'event962',
  startChat = 'event964',
  lessonRequestReceived = 'event968',
  submitFeedback = 'event969',
  toggleAvailability = 'event959',
  pinMessage = 'event120',
  unPinMessage = 'event121',
  viewPinnedMessages = 'event122',
  openModal = 'event132',
  closeModal = 'event133',
  chatStatusChanged = 'event966'
}

export const CLICK_EVENT_CONFIG = {
  eventID: EVENT_ID.clickEvent as AnalyticsClickEvent['eventID'],
  track: true as AnalyticsClickEvent['track'],
  type: EVENT_TYPE.CLICK as AnalyticsClickEvent['type']
};
