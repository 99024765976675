import styled from 'styled-components';
import { Layout as PinLayout } from '@chegg-tutors-chat/shared/components/PinIcon/styled';
import { Layout as unPinLayout } from '@chegg-tutors-chat/shared/components/UnPinIcon/styled';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

const messageHorizontalPadding = 20;

/**
 * MessageProps
 *
 * @prop isPinned - is it pinned or not
 */
interface MessageProps {
  isPinned?: boolean;
}

export const MessageLayout = styled.li<MessageProps>`
  background-color: ${({ isPinned }: MessageProps) =>
    isPinned ? COLORS.FFF5C6 : COLORS.TRANSPARENT};
  padding-top: 5px;
  list-style: none;
  padding-bottom: 5px;
  padding-left: ${messageHorizontalPadding}px;
  padding-right: ${messageHorizontalPadding}px;
  position: relative;
  &:last-child {
    padding-bottom: 20px;
  }

  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    padding-left: 16px;
    padding-right: 16px;
    &:last-child {
      padding-bottom: 10px;
    }
  }

  & > {
    ${PinLayout}, ${unPinLayout} {
      display: none;
      position: absolute;
      top: -5px;
      right: ${messageHorizontalPadding}px;
    }
  }

  &:hover {
    background-color: ${({ isPinned }: MessageProps) => {
      return isPinned ? COLORS.FFF5C6 : COLORS.F5F5F5;
    }};
    ${PinLayout}, ${unPinLayout} {
      display: block;
    }
  }

  &:focus {
    background-color: ${({ isPinned }: MessageProps) => {
      return isPinned ? COLORS.FFF5C6 : COLORS.F5F5F5;
    }};
    ${PinLayout}, ${unPinLayout} {
      display: block;
    }
  }

  &:focus-within {
    background-color: ${({ isPinned }: MessageProps) => {
      return isPinned ? COLORS.FFF5C6 : COLORS.F5F5F5;
    }};
    ${PinLayout}, ${unPinLayout} {
      display: block;
    }
  }
`;

export const FirstMessageInGrouping = styled(MessageLayout)`
  border-top: 1px solid ${COLORS.DDDDDD};
  padding-top: 20px;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    padding-top: 10px;
  }
  margin-top: 15px;
  &:first-child {
    border-top: none;
    margin-top: none;
  }
`;

export const SenderWrapper = styled.div`
  margin-bottom: 5px;
`;

/* positions avatar on the left, aligns avatar with the text next to it */
export const AvatarWrapper = styled.div`
  float: left;
  margin-right: 7px;
  position: relative;
  top: 4px;
`;

export const Name = styled.span`
  font-size: 16px;
  font-family: Aspira-Bold;
  position: relative;
  top: 6px;
  color: ${COLORS['222222']};
  left: 14px;
  display: inline;
  vertical-align: top;
  padding-right: 12px;
  line-height: 1.5;
`;

export const Text = styled.p`
  font-size: 16px;
  font-family: Aspira-Regular;
  padding-left: 53px;
  margin: 0;
  color: ${COLORS['222222']};
  position: relative;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
`;

export const Time = styled.time`
  font-family: Aspira-Regular;
  position: relative;
  vertical-align: top;
  top: 8px;
  color: ${COLORS['767676']};
  font-size: 14px;
  padding-left: 13px;
  line-height: 1.5;
`;

export const AttachmentLayout = styled.div`
  margin-left: 50px;
`;
