import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { updateAnnouncement, UpdateAnnouncementPayload } from './actions';

const initialState: AnnouncementState = { current: null };

/**
 * Function to handle updating the current announcement.
 *
 * @param state
 * @param action
 */
export function handleUpdateAnnouncement(
  state: AnnouncementState,
  payload: UpdateAnnouncementPayload
): AnnouncementState {
  if (typeof payload.announcement !== 'string' && payload.announcement !== null) {
    return state;
  }
  return { current: payload.announcement };
}

export default reducerWithInitialState(initialState)
  .case(updateAnnouncement, handleUpdateAnnouncement)
  .build();
