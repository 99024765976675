import moment from 'moment';
import { Dispatch } from 'redux';
import { createLogic } from 'redux-logic';
import { ArgumentAction } from 'redux-logic/definitions/action';
import SendBird, { AdminMessage, FileMessage, UserMessage } from 'sendbird';
import config from '@chegg-tutors-chat/shared/config';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import {
  setClientUserTyping,
  setUploadingError,
  setUploadingStatus,
  TypingStatusPayload,
  updateWaitDuration
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import { addLesson } from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import {
  getLesson,
  getLessonStatus
} from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import {
  getPastMessages,
  messageSent,
  sendMessage,
  SendMessagePayload
} from '@chegg-tutors-chat/shared/redux/modules/messages/actions';
import { getLessonMessages } from '@chegg-tutors-chat/shared/redux/modules/messages/selectors';
import { captureError, isErrorAction, noop } from '@chegg-tutors-chat/shared/utils';
import {
  formatPreviousMessage,
  onMessageSentSuccess,
  sbGroupChannelFunctions,
  SendBirdMessage,
  sendMessageSendBird,
  sendUserMessage
} from './helper';
import { dispatchAddMessage, getGroupChannel, getSendBirdInstance } from './sendbird';

type SendBirdError = SendBird.SendBirdError;

/**
 * The batch size when retrieving the previous messages from SendBird
 * I believe 100 is the max.
 */
const BATCH_LIMIT_SIZE = 100;

const sendBirdInstance = getSendBirdInstance();

const dispatchClearWaitTime = (dispatch: Dispatch, lessonId: string) => {
  dispatch(
    updateWaitDuration({
      lessonId,
      waitDuration: moment.duration(0)
    })
  );
};

/**
 * Dispatches action to notify whether a message was sent.
 *
 * @param dispatch
 * @param lessonId
 * @param sent
 */
const dispatchMessageSent = (dispatch: Dispatch, lessonId: string, sent: boolean) => {
  dispatch(
    messageSent({
      lessonId,
      sent
    }) as ArgumentAction
  );
};

/**
 * Send file message to groupchannel.
 */
const sendFileMessage = async (
  groupChannel: SendBird.GroupChannel,
  file: File,
  onFileSentError = noop
): Promise<SendBirdMessage | void> => {
  return sendMessageSendBird(
    groupChannel,
    file,
    sbGroupChannelFunctions.fileMessage,
    onFileSentError
  );
};

/**
 * SendMessage docs https://docs.sendbird.com/javascript/group_channel#3_send_messages_to_a_group_channel
 *
 */
const messageLogic = createLogic<GlobalState, SendMessagePayload>({
  process: async ({ getState, action }, dispatch: any, done) => {
    const { lessonId, text } = action.payload as SendMessagePayload;
    const state = getState();
    const clientLessonInfo = getClientInfo(state, lessonId);
    const { attachments = [], uploading = false, uploadingError = null } =
      clientLessonInfo || {};
    // There should only be one attachment per message.
    const attachment = attachments[0];
    const groupChannel = await getGroupChannel(lessonId);

    if (!groupChannel) {
      const error = new Error('Missing groupChannel in messageLogic');
      captureError(error);
      return;
    }

    const lessonStatus = getLessonStatus(state, lessonId);
    const lessonEnded = lessonStatus === LESSON_STATUS.CLOSED;
    let messageSentReturn: SendBirdMessage | void;
    if (!uploadingError && !lessonEnded && groupChannel) {
      if (attachment && !uploading) {
        dispatch(setUploadingStatus({ lessonId, uploading: true }));

        const onFileSentError = (error: FileUploadError) =>
          dispatch(
            setUploadingError({
              lessonId,
              uploadingError: {
                ...error,
                timestamp: moment().unix()
              }
            })
          );

        messageSentReturn = await sendFileMessage(
          groupChannel,
          attachment.fileSrc,
          onFileSentError
        );

        if (messageSentReturn) {
          dispatchAddMessage(messageSentReturn);
        }
        dispatch(setUploadingStatus({ lessonId, uploading: false }));
      }

      if (text) {
        const onMessageSentError = (error: SendBirdError) => {
          captureError(error);
        };

        messageSentReturn = await sendUserMessage(
          groupChannel,
          text,
          onMessageSentError,
          onMessageSentSuccess
        );
      }

      if (messageSentReturn) {
        dispatchMessageSent(dispatch, lessonId, true);
        dispatchClearWaitTime(dispatch, lessonId);
      } else {
        dispatchMessageSent(dispatch, lessonId, false);
      }
    }
    done();
  },
  type: sendMessage.type,
  validate: ({ action }, allow, reject) => {
    const { lessonId } = action.payload as SendMessagePayload;
    if (shouldRejectMessageLogic(lessonId, sendBirdInstance)) {
      reject(action);
      return;
    } else {
      allow(action);
      return;
    }
  }
});
// Rejects message logic if lessonId is falsy or there is no sendbird instance
function shouldRejectMessageLogic(lessonId: string, sendbird: SendBird.SendBirdInstance) {
  let foundError = false;
  if (!lessonId) {
    const error = new Error('Missing lessonId in messageLogic');
    captureError(error);
    foundError = true;
  }
  if (!sendbird) {
    const error = new Error('Error with sendbird instance in messageLogic');
    captureError(error);
    foundError = true;
  }
  return foundError;
}

const getPastChannelMessages = createLogic<GlobalState, Lesson>({
  process: async ({ action, getState }, _dispatch, done: DoneType) => {
    if (!isErrorAction(action)) {
      const PREVIOUS_MESSAGE_LIMIT = config.get('PREVIOUS_MESSAGE_LIMIT');
      const groupChannel = await getGroupChannel(action.payload.id);

      if (!groupChannel) {
        const error = new Error('Missing groupChannel in getPastChannelMessages logic');
        captureError(error);
        return;
      }

      const lessonId = action.payload.id;
      const state = getState();
      const lesson = getLesson(state, lessonId);
      const alreadyFetchedMessages: readonly Message[] | null | undefined = lesson
        ? getLessonMessages(state, lessonId)
        : null;

      /**
       * If there is a lesson in the store with messages loaded, then don't load messages again
       */
      if (!lesson || !alreadyFetchedMessages) {
        const prevMessageListQuery = groupChannel.createPreviousMessageListQuery();

        const handleLoadedMessages = (
          messages: (FileMessage | UserMessage | AdminMessage)[],
          error: SendBirdError
        ) => {
          let fullMessageList: (FileMessage | UserMessage | AdminMessage)[] = [];
          if (error) {
            const generalError = new Error(
              error.message || 'Error loading previous messages'
            );
            captureError(generalError);
            return;
          }

          /** creating a full list of messages to do update at the end */
          if (messages) {
            fullMessageList = fullMessageList.concat(messages);
          }
          /**
           * If there are more results to get and there is no limit to the
           * number of previous messages, then keep loading!
           */

          if (prevMessageListQuery.hasMore && !PREVIOUS_MESSAGE_LIMIT) {
            prevMessageListQuery.load(handleLoadedMessages);
          } else if (!prevMessageListQuery.isLoading) {
            /**
             * All done! Lets add the messages to store.
             */
            if (fullMessageList) {
              fullMessageList.forEach(message => {
                const formattedMessage = formatPreviousMessage(message);
                dispatchAddMessage(formattedMessage);
              });
            }
            done();
          }
        };

        /**
         * A PREVIOUS_MESSAGE_LIMIT of '0' means get all the previous messasges
         * Because there is no limit. Send bird still needs a query limit, which is
         * why BATCH_LIMIT_SIZE is used. This will get the previous messages in
         * batches until there is no more to get.
         */
        prevMessageListQuery.limit = PREVIOUS_MESSAGE_LIMIT || BATCH_LIMIT_SIZE;
        prevMessageListQuery.load(handleLoadedMessages);
      }
    }
  },
  type: [addLesson.type, getPastMessages.type]
});

const clientUserTypingLogic = createLogic<GlobalState, TypingStatusPayload>({
  process: async ({ action }, _dispatch: any, done) => {
    const { lessonId, isTyping } = action.payload;

    if (!lessonId) {
      const error = new Error('Missing lessonId in clientUserTypingLogic logic');
      captureError(error);
      return;
    }

    const groupChannel = await getGroupChannel(lessonId);

    if (!groupChannel) {
      const error = new Error('Missing groupChannel in clientUserTypingLogic logic');
      captureError(error);
      return;
    }

    if (groupChannel && groupChannel.startTyping && groupChannel.endTyping) {
      if (isTyping) {
        groupChannel.startTyping();
      } else {
        groupChannel.endTyping();
      }
    }

    done();
  },
  type: setClientUserTyping.type
});

export default [messageLogic, clientUserTypingLogic, getPastChannelMessages];
