import axios, { AxiosInstance } from 'axios';
import { API_TIMEOUT } from '../constants';

interface AxiosHeaders {
  'X-CHEGG-UUID'?: string;
  'X-CHEGG-LESSON-JWT'?: string;
  'X-CHEGG-TUTOR-UUID'?: string;
}

function buildAxiosClient(headers: AxiosHeaders): AxiosInstance {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BFF_API_URL,
    headers
  });

  /**
   * Override timeout default for the library
   * Now all requests using this instance will wait 10 seconds before timing out
   */
  axiosClient.defaults.timeout = API_TIMEOUT;
  axiosClient.defaults.headers['Content-Type'] = 'application/json';

  return axiosClient;
}

export default buildAxiosClient;
