import * as React from 'react';
import clearIcon from '@chegg-tutors-chat/shared/assets/images/ic-clear-canvas.svg';
import mathIcon from '@chegg-tutors-chat/shared/assets/images/ic-math.svg';
import drawIcon from '@chegg-tutors-chat/shared/assets/images/ic-pen.svg';
import captureIcon from '@chegg-tutors-chat/shared/assets/images/ic-screenshot.svg';
import selectIcon from '@chegg-tutors-chat/shared/assets/images/ic-select.svg';
import shapeIcon from '@chegg-tutors-chat/shared/assets/images/ic-shape.svg';
import textIcon from '@chegg-tutors-chat/shared/assets/images/ic-text.svg';
import deleteIcon from '@chegg-tutors-chat/shared/assets/images/ic-trash.svg';
import undoIcon from '@chegg-tutors-chat/shared/assets/images/ic-undo.svg';
import uploadIcon from '@chegg-tutors-chat/shared/assets/images/ic-upload.svg';
import FileUpload from '@chegg-tutors-chat/shared/components/FileUpload';
import Config from '@chegg-tutors-chat/shared/config';
import { IMAGE_UPLOAD_TYPES } from '@chegg-tutors-chat/shared/constants';
import { noop } from '@chegg-tutors-chat/shared/utils';
import ScratchPad from '../styled';
import ColorToolbar, { Color, PEN_COLORS } from './ColorToolbar';
import ScratchPadTool from './Item';
import ShapeToolbar, { Shape, SHAPES } from './ShapeToolbar';

const Toolbar = ScratchPad.Bar;

export const SCRATCHPAD_MAIN_TOOLBAR = 'scratchpad-main-toolbar';

/*
 * Tool - a scratchpad tool
 *
 * @prop key - object key to compare Tools
 * @prop src - icon src to be displayed for the tool
 * @prop tooltip - text to be displayed when user hovers over tool
 */
export interface Tool {
  align?: 'left' | 'right';
  confirm?: boolean;
  confirmText?: string;
  noSelection?: boolean;
  key: string;
  src: string;
  tooltip: string;
}

const tools: { [key: string]: Tool } = {
  capture: {
    key: 'capture',
    src: captureIcon,
    tooltip: 'Capture scratchpad'
  },
  clear: {
    align: 'right',
    confirm: true,
    confirmText: "Are you sure you'd like to erase all work on the scratch pad?",
    key: 'clear',
    noSelection: true,
    src: clearIcon,
    tooltip: 'Clear canvas'
  },
  delete: {
    align: 'right',
    key: 'remove',
    noSelection: true,
    src: deleteIcon,
    tooltip: 'Remove'
  },
  draw: {
    key: 'draw',
    src: drawIcon,
    tooltip: 'Draw'
  },
  math: {
    // Yet to be implemented: passed in cb to enable math keypad
    key: 'math',
    src: mathIcon,
    tooltip: 'Open math keypad'
  },
  redo: {
    align: 'right',
    key: 'redo',
    src: undoIcon,
    tooltip: 'Redo'
  },
  select: {
    key: 'select',
    src: selectIcon,
    tooltip: 'Select'
  },
  shape: {
    key: 'shape',
    src: shapeIcon,
    tooltip: 'Insert shape'
  },
  text: {
    key: 'text',
    src: textIcon,
    tooltip: 'Insert text'
  },
  undo: {
    align: 'right',
    key: 'undo',
    src: undoIcon,
    tooltip: 'Undo'
  },
  upload: {
    key: 'upload',
    src: uploadIcon,
    tooltip: 'Upload to canvas'
  }
};

/* used for private method of ScratchPadToolbar */
interface ToolButtonProps {
  disabled?: boolean;
  onClick?: any;
  onKeyDown?: any;
  tool: Tool;
}

/*
 * ScratchPadToolbarProps - the scratchpad toolbar
 *
 * @prop addCircle - adds a circle into the scratchpad
 * @prop addLine - adds a line into the scratchpad
 * @prop addRect - adds a rectangle into the scratchpad
 * @prop addText - adds an editable textbox into the scratchpad
 * @prop addTriangle - adds a triangle into the scratchpad
 * @prop clearCanvas - clears the canvas
 * @prop handleRedo - triggers a 'redo' on the overall <ScratchPad>
 * @prop handleUndo - triggers an 'undo' on the overall <ScratchPad>
 * @prop screenshot - takes a screenshot of the scratchpad
 * @prop setScreenshot - ??? not sure what this is supposed to do
 * @prop selectScratchPadTool - for now just triggers analytics
 * @prop selectScratchPadShape - for now just triggers analytics
 * @prop toggleFreeDrawing - enables drawing mode
 * @prop turnOffDelete - exits scratchpad mode where clicking elements deletes them
 * @prop turnOnDelete - enables scratchpad mode where clicking elements deletes them
 * @prop redoEnabled - whether redo functionality should be enabled
 * @prop undoEnabled - whether undo functionality should be enabled
 */
interface ScratchPadToolbarProps {
  addCircle: (e: React.MouseEvent) => void;
  addImage: (e: any) => void;
  addLine: (e: React.MouseEvent) => void;
  addMath: (x?: boolean) => void;
  addRect: (e: React.MouseEvent) => void;
  addText: (e: React.MouseEvent) => void;
  addTriangle: (e: React.MouseEvent) => void;
  clearCanvas: (e: React.MouseEvent) => void;
  handleRedo: (e: React.MouseEvent) => void;
  handleUndo: (e: React.MouseEvent) => void;
  pointerTool: (e: React.MouseEvent) => void;
  screenShot: (e: React.MouseEvent) => void;
  setScreenShot: (e: React.MouseEvent) => void;
  selectScratchPadTool: (payload: { tool?: string }) => void;
  selectScratchPadShape: (payload: { shape?: string }) => void;
  selectScratchPadPenColor: (payload: { color?: string }) => void;
  shapeSelected: boolean;
  toggleFreeDrawing: (color: string) => void;
  turnOffDelete: (e: React.MouseEvent) => void;
  turnOnDelete: (e: React.MouseEvent) => void;
  redoEnabled: boolean;
  undoEnabled: boolean;
}

/*
 * ScratchPadToolbarState - state of the scratchpad toolbar
 *
 * @prop selectedColor - the currently selected color
 * @prop selectedShape - the currently selected shape
 * @prop selectedTool- the currently selected tool
 */
interface ScratchPadToolbarState {
  selectedColor: Color;
  selectedShape: Shape;
  selectedTool: Tool;
}

class ScratchPadToolbar extends React.Component<
  ScratchPadToolbarProps,
  ScratchPadToolbarState
> {
  public static defaultProps = {
    addCircle: noop,
    addLine: noop,
    addRect: noop,
    addText: noop,
    addTriangle: noop,
    redoEnabled: false,
    screenShot: noop,
    selectScratchPadPenColor: noop,
    selectScratchPadShape: noop,
    selectScratchPadTool: noop,
    setFreeDrawing: noop,
    setScreenShot: noop,
    shapeSelected: false,
    turnOffDelete: noop,
    turnOnDelete: noop,
    undoEnabled: false
  };

  public static state: ScratchPadToolbarState = {
    selectedColor: PEN_COLORS.black,
    selectedShape: SHAPES.line,
    selectedTool: tools.draw
  };
  private shapeActions = {};
  private toolActions = {};

  constructor(props: ScratchPadToolbarProps) {
    super(props);
    this.state = {
      selectedColor: PEN_COLORS.black,
      selectedShape: SHAPES.line,
      selectedTool: tools.draw
    };

    this.shapeActions = {
      circle: this.props.addCircle,
      line: this.props.addLine,
      rectangle: this.props.addRect,
      triangle: this.props.addTriangle
    };
    this.toolActions = {
      capture: this.props.screenShot,
      clear: this.props.clearCanvas,
      draw: this.setPenColor,
      math: this.props.addMath,
      remove: this.props.turnOnDelete,
      select: this.props.pointerTool,
      shape: this.setShape,
      text: this.props.addText
    };
  }
  public setSelectedTool = (toolName: string) => {
    this.setState({ selectedTool: tools[toolName] });
  };
  public render() {
    const { selectedTool } = this.state;
    const fullScratchpad = Config.get('FULL_SCRATCHPAD');
    return (
      <div>
        {this.renderMainToolbar()}
        {fullScratchpad &&
          selectedTool.key === tools.draw.key &&
          this.renderColorSelectToolbar()}
        {fullScratchpad &&
          selectedTool.key === tools.shape.key &&
          this.renderShapeSelectToolbar()}
      </div>
    );
  }

  private toolButton({ tool, onClick = this.setTool, disabled }: ToolButtonProps) {
    const { selectedTool, selectedShape } = this.state;
    let selected = false;
    if (tool.key === selectedTool.key) {
      selected = true;
    } else if (tool.key === selectedShape.key) {
      selected = true;
    }
    // For now since we only have an 'undo' image,
    // just flip it with CSS to get the 'redo' icon.
    const shouldFlipImage = tool.key === 'redo';
    return (
      <ScratchPadTool
        {...tool}
        horizontalFlip={shouldFlipImage}
        onClick={onClick(tool)}
        selected={selected}
        disabled={disabled}
      />
    );
  }

  private inputElement = React.createRef<HTMLInputElement>();

  private handleUploadClick = () => {
    return (event: React.MouseEvent) => {
      const upload = this.inputElement.current;
      this.setState({
        selectedTool: tools.upload
      });
      if (upload) {
        event.preventDefault();
        upload.focus();
        upload.click();
      }
    };
  };
  private renderMainToolbar() {
    const fullScratchpad = Config.get('FULL_SCRATCHPAD');
    // Right aligned tools are displayed to user in reverse order as they appear in DOM due to float: right
    return (
      <Toolbar>
        {this.toolButton({ tool: tools.select })}
        {this.toolButton({ tool: tools.draw })}
        {fullScratchpad && this.toolButton({ tool: tools.shape })}
        {fullScratchpad && this.toolButton({ tool: tools.text })}
        {fullScratchpad && this.toolButton({ tool: tools.math })}
        {fullScratchpad &&
          this.toolButton({ tool: tools.upload, onClick: this.handleUploadClick })}
        {this.toolButton({ tool: tools.capture })}

        {this.toolButton({
          disabled: !this.props.redoEnabled,
          onClick: this.props.handleRedo,
          tool: tools.redo
        })}
        {this.toolButton({
          disabled: !this.props.undoEnabled,
          onClick: this.props.handleUndo,
          tool: tools.undo
        })}
        {this.toolButton({ tool: tools.clear })}
        {fullScratchpad &&
          this.toolButton({ tool: tools.delete, disabled: !this.props.shapeSelected })}
        <FileUpload
          hidden={true}
          inputRef={this.inputElement}
          acceptedTypes={IMAGE_UPLOAD_TYPES}
          onImage={x => this.props.addImage(x)}
        />
      </Toolbar>
    );
  }

  private renderColorSelectToolbar() {
    const { selectedColor } = this.state;
    return <ColorToolbar selected={selectedColor} onClick={this.setPenColor} />;
  }

  private renderShapeSelectToolbar() {
    const { selectedShape } = this.state;
    this.shapeActions[selectedShape.key]();
    return <ShapeToolbar onClick={this.setShape} selected={selectedShape} />;
  }

  private setPenColor = (color: Color | undefined) => {
    color = color || this.state.selectedColor;
    const { selectScratchPadPenColor } = this.props;
    if (color) {
      this.props.toggleFreeDrawing(color.hex);
      this.setState(
        {
          selectedColor: color,
          selectedTool: tools.draw
        },
        () => {
          if (typeof selectScratchPadPenColor === 'function' && color !== undefined) {
            selectScratchPadPenColor({ color: color.key });
          }
        }
      );
    }
  };

  private setShape = (shape: Shape | undefined) => {
    shape = shape || this.state.selectedShape;
    const { selectScratchPadShape } = this.props;
    if (shape) {
      this.setState(
        {
          selectedShape: shape,
          selectedTool: tools.shape
        },
        () => {
          if (typeof selectScratchPadShape === 'function' && shape !== undefined) {
            selectScratchPadShape({ shape: shape.key });
          }
        }
      );
    }
  };

  private setTool = (tool: Tool) => {
    const mathKey = 'math';
    return () => {
      const key = tool.key;
      this.toolActions[mathKey](false);
      if (this.toolActions[key]) {
        this.toolActions[key]();
      }
      if (!tool.noSelection) {
        this.setState(
          {
            selectedTool: tool
          },
          () => {
            if (typeof this.props.selectScratchPadTool === 'function') {
              this.props.selectScratchPadTool({ tool: tool.key });
            }
          }
        );
      }
    };
  };
}

export default ScratchPadToolbar;
