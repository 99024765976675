import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import FONTS from '@chegg-tutors-chat/shared/styled/constants/fonts';

const Base = styled.a`
  font-family: ${FONTS.AspiraMedium};
  font-size: 14px;
  color: ${COLORS['007C8A']};
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:visited {
    color: ${COLORS['4A807A']};
  }
`;

const Lead = styled(Base)`
  font-size: 16px;
`;

const Small = styled(Base)`
  font-size: 12px;
`;

const Secondary = styled(Base)`
  color: ${COLORS['555555']};
  &:visited {
    color: ${COLORS['767676']};
  }
`;

/**
 * By making the return a stateless function component, we can do stuff like
 * Link.Lead and still have it work with ReactStyleguidist. Because ReactStyleguidist doesn't
 * handle plain exported {};
 */

const Link = Base;

/**
 * add on the sub components
 */
export default Object.assign(Link, { Lead, Small, Secondary });
