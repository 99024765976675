import SendBird, { AdminMessage, FileMessage, UserMessage } from 'sendbird';
import { captureTimeoutError, noop } from '@chegg-tutors-chat/shared/utils';
import genericRequest from '@chegg-tutors-chat/shared/utils/genericRequest';
import { dispatchAddMessage, UserData } from './sendbird';

export type SendBirdMessage = UserMessage | FileMessage | AdminMessage;

interface HasBeenRead {
  hasBeenRead: RawMessage['hasBeenRead'];
}

interface Sender {
  sender?: UserData;
}

/**
 * When we get a "message" object from SB, it could be a UserMessage,
 * a FileMessage, or an AdminMessage - that is the SendBirdMessage type.
 * Once we format the message, it's SendBirdMessage (still one of three types),
 * but with the 'hasBeenRead' flag added.
 * That's what the 'FormattedSendBirdMessage' expresses.
 */
export type FormattedSendBirdMessage = SendBirdMessage & HasBeenRead & Sender;

export const sbGroupChannelFunctions = {
  fileMessage: 'sendFileMessage',
  textMessage: 'sendUserMessage'
};

/**
 * Function that dispatches a message to redux store as a result
 * of succesfull SendBird message send call
 * @param messageSentReturn
 */
export const onMessageSentSuccess = (messageSentReturn: SendBirdMessage) => {
  if (messageSentReturn) {
    dispatchAddMessage(messageSentReturn);
  }
};

/**
 * Message formatter that takes a message object
 * and adds truthy 'hasBeenRead' prop
 * @param message
 */
export const formatPreviousMessage = (
  message: SendBirdMessage
): FormattedSendBirdMessage => ({
  ...message,
  sender: (message as UserMessage | FileMessage).sender,
  isAdminMessage: message.isAdminMessage,
  hasBeenRead: true
});

/**
 * Send user message to groupchannel.
 */
export const sendMessageSendBird = async (
  groupChannel: SendBird.GroupChannel,
  message: string | File,
  sendFunction: any,
  onError = noop,
  onSuccess: (msg: SendBirdMessage) => void = noop
): Promise<SendBirdMessage | void> => {
  return await genericRequest<SendBirdMessage>((): Promise<SendBirdMessage> => {
    return new Promise((resolve, reject) => {
      groupChannel[sendFunction](
        message,
        (messageReturn: SendBirdMessage, errorVal: SendBird.SendBirdError) => {
          if (errorVal) {
            onError(errorVal);
            return reject(errorVal);
          } else {
            onSuccess(messageReturn);
            return resolve(messageReturn);
          }
        }
      );
    });
  }, captureTimeoutError('sendMessageSendbird'));
};

/**
 * Send user message to groupchannel.
 *
 * @param groupChannel
 * @param text
 * @param onMessageSentError
 */
export const sendUserMessage = async (
  groupChannel: SendBird.GroupChannel,
  text: string,
  onError = noop,
  onSuccess = noop
): Promise<SendBirdMessage | void> => {
  return sendMessageSendBird(
    groupChannel,
    text,
    sbGroupChannelFunctions.textMessage,
    onError,
    onSuccess
  );
};
