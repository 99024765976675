import { createLogic } from 'redux-logic';
import { ArgumentAction } from 'redux-logic/definitions/action';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { updateLessonStatus } from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { closeModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { isErrorAction } from '@chegg-tutors-chat/shared/utils';
import { setTagToSentry } from '@chegg-tutors-chat/shared/utils/sentry';
import {
  acceptLesson,
  addLesson,
  LessonPayload,
  rejectLesson,
  removeLesson,
  RerouteRejectLessonPayload,
  selectLesson
} from './actions';
import { getLessonState } from './selectors';

export const acceptLessonLogic = createLogic<GlobalState, LessonPayload>({
  process: ({ action }, dispatch, done) => {
    if (!isErrorAction(action)) {
      dispatch(
        updateLessonStatus({
          id: action.payload.id,
          status: LESSON_STATUS.IN_PROGRESS
        }) as ArgumentAction
      );
      dispatch((closeModal() as unknown) as ArgumentAction);
      done();
    }
  },
  type: acceptLesson.type
});

export const rejectLessonLogic = createLogic<GlobalState, RerouteRejectLessonPayload>({
  process: ({ action }, dispatch, done) => {
    if (!isErrorAction(action)) {
      const { id } = action.payload;

      dispatch(removeLesson({ id }) as ArgumentAction);
      dispatch((closeModal() as unknown) as ArgumentAction);
      done();
    }
  },
  type: rejectLesson.type
});

/**
 * We want our Sentry logs to always contain the latest "current lesson", so
 * whenever the "current lesson" is updated we update the tag in the Sentry
 * SDK.
 * There are two cases where the "current lesson" gets updated;
 * when the 'selectLesson' action fires, and also when there is an 'addLesson'
 * action and no other lessons are in the store.
 */
export const updateCurrentLessonInSentryLogic = createLogic<
  GlobalState,
  LessonPayload | Lesson
>({
  process: ({ action }, _dispatch, done) => {
    if (!isErrorAction(action) && action.payload.id !== undefined) {
      const currentLessonId = action.payload.id;

      setTagToSentry('current_lesson_id', currentLessonId);
    }
    done();
  },
  type: [selectLesson.type, addLesson.type],
  /**
   * For the 'addLesson' action, check that there are no other lessons in the
   * store.
   */
  validate: ({ action, getState }, allow, reject) => {
    if (action.type === selectLesson.type) {
      // we always update Sentry tags when a new lesson is selected
      allow(action);
      return;
    }
    // If the action is adding a lesson, we check that there are no other
    // lessons in the store.
    const { id } = action.payload;

    if (!id) {
      // if payload is missing the id, skip this logic
      reject(action);
      return;
    } else {
      const lessonState = getLessonState(getState());
      if (lessonState !== undefined) {
        // if there is lessonState in the store, we can check the lesson count
        const lessonCount = Object.keys(lessonState.lessonsList).length;
        if (lessonCount === 0) {
          // if there are not yet any lessons in the store, run this logic
          allow(action);
          return;
        }
      }
    }
    reject(action);
  }
});

export default [acceptLessonLogic, rejectLessonLogic, updateCurrentLessonInSentryLogic];
