import * as React from 'react';
import { Backdrop, NotificationBox, ToastWrapper } from './styled';

/**
 * @prop children - contents of the modal
 * @prop onClose
 * @prop disableClickClose - don't allow the modal to close from backdrop, button click
 */
export interface ToastProps {
  children?: React.ReactNode | React.ReactNode[];
}
/**
 *  displays a toast with white backdrop
 */
const Toast: React.FunctionComponent<ToastProps> = ({ children }) => {
  return (
    <ToastWrapper data-modal="true" role="dialog">
      <Backdrop data-testid="backdrop" />
      <NotificationBox data-modal="true">{children}</NotificationBox>
    </ToastWrapper>
  );
};

export default Toast;
