import { connect } from 'react-redux';
import * as actions from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import * as selectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import UploadStatus from './UploadStatus';

export interface UploadStatusMapDispatchProps {
  onClose: (x: actions.RemoveAttachmentPayload) => void;
}

export interface UploadStatusMapStateProps {
  lessonId: string;
}

export function mapStateToProps(state: GlobalState) {
  return {
    lessonId: selectors.getSelectedLessonId(state)
  };
}

export const mapDispatchToProps: UploadStatusMapDispatchProps = {
  onClose: actions.removeAttachment
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadStatus);
