import styled from 'styled-components';
import { Select, TextArea } from '@chegg-tutors-chat/shared/components/Forms/styled';
import { Heading3 } from '@chegg/fanta';

export const ComplexitySelect = styled(Select)`
  width: 144px;
`;

export const TellUsMore = styled(TextArea)`
  height: 88px;
`;

export const FormHeader = styled(Heading3)`
  margin: 0;
`;

export const FormSubheader = styled.p`
  margin: 20px 0px;
`;
