import styled from 'styled-components';
import {
  Layout,
  ToolbarLayout
} from '@chegg-tutors-chat/shared/components/LessonArea/styled';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';

// padding-top makes room for absolutely-positioned
// LessonHeader.
export const MessageAreaLayout = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 318px;
  position: relative;
  padding-top: 70px;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    padding-top: 60px;
  }
`;

// Using absolute positioning to put items
// at the top, because they should be later
// in the tab order.
export const MessageAreaTop = styled.span`
  position: absolute;
  top: 19px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    top: 12px;
  }
`;

export const MessageInputLayout = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const ContainerLayout = styled.main`
  display: flex;
  flex: 2;
  overflow: auto;
  @media (max-width: ${BREAKPOINTS.LG_MIN}) {
    ${ToolbarLayout} {
      display: none;
    }

    ${Layout} {
      display: none;
    }
  }
`;
