import { MESSAGE_TYPE, STATUS } from '@chegg-tutors-chat/shared/constants';
import { getMockLesson } from '@chegg-tutors-chat/shared/redux/modules/lessons/tests/utils';

export const testFile = 'http://www.sfasu.edu/echl/documents/Goodnight_Moon.pdf';
export const testImg = 'https://i.imgur.com/SBUbHHN.gif';
export const lorumIpsum = `It is a long established fact that a reader will be distracted by the readable
  content of a page when looking at its layout. The point of using Lorem Ipsum is that it
  has a more-or-less normal distribution of letters, as opposed to using 'Content here,
  content here', making it look like readable English. Many desktop publishing packages
  and web page editors now use Lorem Ipsum as their default model text, and a search for
  'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
  evolved over the years, sometimes by accident, sometimes on purpose (injected humour
  and the like) **  https://chegg.com  tutor-service@chegg.com ** `;
const fakeMessageTexts = [
  `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry's standard dummy text ever since the
  1500s, when an unknown printer took a galley of type and scrambled it to
  make a type specimen book. It has survived not only five centuries, but
  also the leap into electronic typesetting, remaining essentially unchanged.
  It was popularised in the 1960s with the release of Letraset sheets
  containing Lorem Ipsum passages, and more recently with desktop publishing
  software like Aldus PageMaker including versions of Lorem Ipsum.`,
  `It is a long established fact that a reader will be distracted by the
  readable content of a page when looking at its layout. The point of using
  Lorem Ipsum is that it has a more-or-less normal distribution of letters,
  as opposed to using 'Content here, content here', making it look like
  readable English. Many desktop publishing packages and web page editors now
  use Lorem Ipsum as their default model text, and a search for 'lorem ipsum'
  will uncover many web sites still in their infancy. Various versions have
  evolved over the years, sometimes by accident, sometimes on purpose
  (injected humour and the like)  https://chegg.com  tutor-service@chegg.com chegg.com`,
  `Contrary to popular belief, Lorem Ipsum is not simply random text. It has
  roots in a piece of classical Latin literature from 45 BC, making it over
  2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
  College in Virginia, looked up one of the more obscure Latin words,
  consectetur, from a Lorem Ipsum passage, and going through the cites of the
  word in classical literature, discovered the undoubtable source. Lorem
  Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et
  Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This
  book is a treatise on the theory of ethics, very popular during the
  Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
  comes from a line in section 1.10.32. https://chegg.com  tutor-service@chegg.com `
];

let LESSON_COUNT = 0;
let MESSAGE_COUNT = 0;

export const getCurrentMockMessageCount = () => MESSAGE_COUNT;

export const getMockUser = (lessonValueOverrides: Partial<LessonUser>): any => {
  return {
    connectionStatus: STATUS.ONLINE,
    firstName: 'firstName',
    isActive: true,
    lastName: 'lastName',
    profileUrl: testImg,
    userId: 'user_id',
    ...lessonValueOverrides
  };
};

const defaultMessageValues = {
  messageId: MESSAGE_COUNT,
  messageType: MESSAGE_TYPE.MESSAGE
};

export const getCurrentMockLessonId = () => `${LESSON_COUNT}URL`;

export const getMockMessage = (messageValueOverrides: Partial<Message>): Message => {
  MESSAGE_COUNT += 1;
  return {
    channelUrl: getCurrentMockLessonId(),
    createdAt: Date.now(),
    ...defaultMessageValues,
    message: fakeMessageTexts[MESSAGE_COUNT % 3],
    sender: {
      firstName: Math.floor(MESSAGE_COUNT % 2) ? 'Student FirstName' : 'Tutor FirstName',
      lastName: Math.floor(MESSAGE_COUNT % 2) ? 'Student LastName' : 'Tutor LastName',
      profileUrl: Math.floor(MESSAGE_COUNT % 2)
        ? 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50'
        : '',
      userId: Math.floor(MESSAGE_COUNT % 2) ? 'student_id' : 'tutor_id'
    },
    hasBeenRead: MESSAGE_COUNT % 2 > 0,
    ...messageValueOverrides
  };
};

export const getUniqueMockLesson = () => {
  LESSON_COUNT += 1;
  return getMockLesson(LESSON_COUNT.toString());
};

export const getMockLessonWithReallyLongDescriptionAndAttachment = (): Lesson => {
  const lesson = getUniqueMockLesson();
  lesson.ticketInfo.request.description = lorumIpsum;
  lesson.ticketInfo.request.attachment = {
    name: 'goodnight_moon.pdf',
    url: testFile
  };
  return lesson;
};
