import {
  createLesson as apiRequestToCreateLesson,
  CreateLessonData
} from 'src/api/helpers';
import setAxiosClient from 'src/configs/axiosClient';
import start from 'src/SDK/start';
import { getIdToken } from '@chegg-tutors-chat/shared/utils/headers';

/**
 * When we need to create a lesson first,
 * configurations for the chat app to use when starting up.
 * keep this minimal and as small as possible!
 */
export interface CreateLessonConfig {
  rootElement: HTMLElement;
  createLessonData: CreateLessonData;
}
interface LessonData {
  data: {
    token: string;
  };
}
const createLesson = async (createLessonConfig: CreateLessonConfig) => {
  const { createLessonData, rootElement } = createLessonConfig;
  // initialize axios with no lesson token since we don't have one yet
  setAxiosClient('');
  const lessonData = (await apiRequestToCreateLesson(
    getIdToken(),
    createLessonData
  )) as LessonData;
  const lessonToken = lessonData.data.token;
  start({
    rootElement,
    lessonToken
  });
};

export default createLesson;
