/**
 * This file exports the typeguards for the action payloads.
 * Each action has a typeguard for their payload to verify its type on the runtime.
 *
 * Keys in the object will be action types, and the value should be a TS type guard for
 * the payload of that action type. Some type guards will be reused for multiple action types.
 *
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
 * and https://basarat.gitbooks.io/typescript/docs/types/typeGuard.html#user-defined-type-guard
 *
 * A typical signature of the action is like
 * {type: 'namespace/ACTION_TYPE', payload: {foo: 'bar'}}
 */

import { acceptLesson, LessonPayload } from './modules/lessons/actions';

const actionPayloadChecks = {};

// LessonPayload's type guard
actionPayloadChecks[acceptLesson.type] = (payload: any): payload is LessonPayload => {
  return payload.id && typeof payload.id === 'string';
};

export default actionPayloadChecks;
