import { connect } from 'react-redux';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as actions from '@chegg-tutors-chat/shared/redux/modules/pins/actions';
import * as selectors from '@chegg-tutors-chat/shared/redux/modules/pins/selectors';
import Pins, { PinsDispatchProps, PinsProps } from './Pins';

export function mapStateToProps(state: GlobalState, ownProps: PinsProps) {
  const lessonId = getSelectedLessonId(state);
  const isPinned = selectors.isMessagePinned(state, lessonId, ownProps.message.messageId);
  return {
    isPinned,
    lessonId,
    message: ownProps.message
  };
}

export const mapDispatchToProps: PinsDispatchProps = {
  pinMessage: actions.pinMessage,
  unPinMessage: actions.unPinMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Pins);
