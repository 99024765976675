import * as React from 'react';
import { ErrorBanner, InformationBanner } from '@chegg/fanta';
import { UploadStatusMapDispatchProps, UploadStatusMapStateProps } from './container';

/**
 * @prop error - boolean to indicate whether there is an error.
 * @prop fileName - Name of the upload file.
 */
export interface UploadStatusProps {
  error?: boolean;
  fileName: string;
}

/**
 * UploadStatus - renders InformationBanner and ErrorBanner depending on the status of uploaded file.
 *
 * @props - UploadStatusProps
 */

export type UploadStatusAllProps = UploadStatusProps &
  UploadStatusMapStateProps &
  UploadStatusMapDispatchProps;

const UploadStatus: React.SFC<UploadStatusAllProps> = props => {
  const { fileName, error, onClose, lessonId } = props;
  const onCloseHelper = () => {
    onClose({ lessonId });
  };
  if (!error) {
    return (
      <InformationBanner size={'small'} onCloseClick={onCloseHelper}>
        {`Uploading ${fileName}`}
      </InformationBanner>
    );
  }
  return (
    <ErrorBanner size={'small'} onCloseClick={onCloseHelper}>
      Upload failed, please try again.
    </ErrorBanner>
  );
};

export default UploadStatus;
