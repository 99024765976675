import styled from 'styled-components';
import activeStarIcon from '@chegg-tutors-chat/shared/assets/images/ic-star-active.svg';
import defaultStarIcon from '@chegg-tutors-chat/shared/assets/images/ic-star-default.svg';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';

interface StarProps {
  active?: boolean;
}

const getBackground = (props: StarProps) => {
  return props.active ? activeStarIcon : defaultStarIcon;
};

export const Star = styled.button<StarProps>`
  width: 32px;
  height: 30px;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${props => getBackground(props)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 32px;
  &:hover {
    background-image: url(${activeStarIcon});
    &:hover ~ button {
      background-image: url(${activeStarIcon});
    }
  }
  &:focus {
    background-image: url(${activeStarIcon});
    &:focus ~ button {
      background-image: url(${activeStarIcon});
    }
  }
  margin-right: 10px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    width: 44px;
    height: 44px;
    margin-right: 1px;
  }
  &:first-child {
    margin: 0;
  }
`;

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  justify-content: flex-end;
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    margin-left: -7px;
    margin-bottom: 10px;
  }
  &:first-child {
    margin: 0;
  }
`;
