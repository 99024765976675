import moment from 'moment';
import config from '@chegg-tutors-chat/shared/config';
import {
  AnalyticsGenericEvent,
  AnalyticsGenericEventValue
} from '@chegg-tutors-chat/shared/providers/analytics/interfaces';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { EVENT_ID, EVENT_NAME, EVENT_TYPE } from '../constants';

export interface FormatAnalyticsGenericEventPayload {
  eventId: EVENT_ID;
  eventName: EVENT_NAME;
  eventState: GlobalState;
  eventType: EVENT_TYPE;
  eventValueConfig?: Partial<AnalyticsGenericEventValue>;
  lessonId?: Lesson['id'];
}

export const formatAnalyticsGenericEvent = (
  data: FormatAnalyticsGenericEventPayload
): AnalyticsGenericEvent => {
  const getOtherUserId = config.get('getOtherUserId');
  const currentLessonId = data.lessonId || getSelectedLessonId(data.eventState);
  const eventValueConfig = data.eventValueConfig || {};
  return {
    eventID: data.eventId,
    eventName: data.eventName,
    eventValue: [
      {
        entityID: currentLessonId,
        productName: 'Tutors',
        requestID: currentLessonId,
        ...eventValueConfig
      }
    ],
    studentOrTutorsID: getOtherUserId && getOtherUserId(data.eventState, currentLessonId),
    timestamp: moment().unix(),
    type: data.eventType
  };
};
