import config from '@chegg-tutors-chat/shared/config';

function focusMessageInput() {
  const composerRef = config.get('SKIP_LEVEL_REFS').MESSAGE_COMPOSER;
  if (composerRef) {
    composerRef.focus();
  }
}

export { focusMessageInput };
