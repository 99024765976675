import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { Modal } from '@chegg/fanta';
import * as React from 'react';
import { ImageLink, ImageWrapper, ModalImage } from './styled';

/**
 * @prop modalOptions - object containing an image src to render in the modal
 */
export interface ImageModalProps extends BaseModalProps {
  modalOptions: {
    imageSrc: string;
  };
}

/**
 * Modal that displays an image
 *
 * @props ImageModalProps
 */
const ImageModal: React.SFC<ImageModalProps> = ({
  isOpen,
  modalOptions = { imageSrc: '' },
  ...restProps
}) => {
  const { imageSrc } = modalOptions;

  const alt = (imageSrc && `Uploaded image with src ${imageSrc}`) || `Uploaded image`;

  return (
    <Modal isOpen={isOpen} {...restProps}>
      <ImageWrapper>
        <ImageLink href={imageSrc} role="button" target={'_blank'}>
          <ModalImage alt={alt} src={imageSrc} />
        </ImageLink>
      </ImageWrapper>
    </Modal>
  );
};

export default ImageModal;
