/**
 * Reads a single file as dataUrl
 *
 * @param file
 * @param fileReader
 * @param onFile
 */
export const fileProcessor = (
  file: File,
  fileReader: FileReader,
  onFile: (i: File) => void
) => {
  if (file.type.indexOf('image/') > -1) {
    fileReader.readAsDataURL(file);
  }
  onFile(file);
};

/**
 * Handles a list of files
 *
 * @param files
 * @param fileReader
 * @param onFile
 */
export const handleFiles = (
  files: FileList,
  fileReader: FileReader,
  onFile: (i: File) => void
) => {
  for (const index in files) {
    if (files.hasOwnProperty(index)) {
      const file = files[index];
      fileProcessor(file, fileReader, onFile);
    }
  }
};
