import styled from 'styled-components';
import { TextArea } from '@chegg-tutors-chat/shared/components/Forms/styled';

export const AdditionalInfo = styled(TextArea)`
  height: 64px;
`;

export const FooterButtonsWrapper = styled.div`
  padding-top: 10px;
`;

export const Wrapper = styled.div`
  padding: 0 33px;
`;

export const Form = styled.form`
  line-height: 1rem;
  padding: 2px;
`;

export const HCVInfoBannerWrapper = styled.div`
  max-height: 75px;
  max-width: 430px;
  margin-bottom: 40px;
`;
