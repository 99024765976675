import moment from 'moment';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
  pinMessage,
  PinMessagePayload,
  unPinMessage,
  UnPinMessagePayload
} from './actions';

const initialState: PinState = {};

/**
 * Function that adds a message to the pin state by lessonId.
 *
 * @param state
 * @param payload
 */
export function handlePinMessage(state: PinState, payload: PinMessagePayload): PinState {
  const { lessonId, message, pinTime = moment().unix() } = payload;
  const messageId = message.messageId;
  const pinnedMessages = state[lessonId] ? state[lessonId] : {};

  if (pinnedMessages == null || lessonId == null || messageId == null) {
    // we are missing either pinnedMessages, lessonId, or messageId.
    // == checks for null and undefined
    return state;
  }

  const newState = Object.assign({}, state, {
    [lessonId]: {
      ...pinnedMessages,
      [messageId]: {
        message,
        pinTime
      }
    }
  });

  return newState;
}

/**
 * Function to 'unpin' aka remove the message from the pin state.
 *
 * @param state
 * @param payload
 */
export function handleUnPinMessage(
  state: PinState,
  payload: UnPinMessagePayload
): PinState {
  const { lessonId, messageId } = payload;
  const pinnedMessages = state[lessonId] ? state[lessonId] : {};

  if (pinnedMessages == null || lessonId == null || messageId == null) {
    return state;
  }

  const newState = Object.assign({}, state, {
    [lessonId]: { ...pinnedMessages }
  });

  delete newState[lessonId][messageId];

  return newState;
}

export default reducerWithInitialState(initialState)
  .case(pinMessage, handlePinMessage)
  .case(unPinMessage, handleUnPinMessage)
  .build();
