import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ButtonGlobalStyles } from '@chegg-tutors-chat/shared/styled/chat/Buttons';
import FONTS from '@chegg-tutors-chat/shared/styled/constants/fonts';
import {
  Aspira,
  AspiraBold,
  AspiraHeavy,
  AspiraMedium,
  AspiraRegular
} from '@chegg-tutors-chat/shared/styled/sunkist/Fonts';

// tslint:disable-next-line:no-unused-expression
export const ClientStyles = createGlobalStyle`
  ${Aspira}
  ${AspiraMedium}
  ${AspiraRegular}
  ${AspiraBold}
  ${AspiraHeavy}
  * {
    box-sizing: border-box;
    font-size: 16px;
    font-family: ${FONTS.AspiraMedium};
  }

  html, body {
    height: 100%;
    margin: 0px;
  }
  #root {
    height: 100%;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    background: white;
  }
`;

const GlobalStyles: React.FunctionComponent = () => {
  return (
    <>
      <ClientStyles />
      <ButtonGlobalStyles />
    </>
  );
};

export default GlobalStyles;
