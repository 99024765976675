import { createLogic } from 'redux-logic';
import { ArgumentAction, PayloadBasis } from 'redux-logic/definitions/action';
import config from '@chegg-tutors-chat/shared/config';
import { isErrorAction } from '@chegg-tutors-chat/shared/utils';
import { trackEvent, trackPage } from '../adobe';
import {
  AnalyticsClickEvent,
  AnalyticsGenericEvent,
  AnalyticsModalEvent
} from '../interfaces';

export type DispatchType = (action: ArgumentAction<string, undefined, undefined>) => void;

const DEBOUNCE_MS = 50;

export const clickLogicFactory = logicFactoryMaker<AnalyticsClickEvent>(
  (_dispatch: DispatchType, event: AnalyticsClickEvent) => {
    trackEvent(event);
  },
  {
    debounce: DEBOUNCE_MS
  }
);

export const genericLogicFactory = logicFactoryMaker<AnalyticsGenericEvent>(
  (_dispatch: DispatchType, event: AnalyticsGenericEvent) => {
    trackEvent(event);
  },
  {
    debounce: DEBOUNCE_MS
  }
);

export const modalLogicFactory = logicFactoryMaker<AnalyticsModalEvent>(
  (_dispatch: DispatchType, event: AnalyticsModalEvent) => {
    const analyticsPageData = config.get('ANALYTICS_PAGE_DATA');
    const pageData = {
      category: {
        pageType: 'product',
        primaryCategory: 'chegg',
        subCategory1: 'web',
        subCategory2: 'tutchat',
        subCategory3: analyticsPageData.subCategory3
      },
      pageInfo: {
        pageName: analyticsPageData.pageName
      }
    };
    trackPage(pageData, event);
  },
  {
    debounce: DEBOUNCE_MS
  }
);

export function logicFactoryMaker<ProcessReturn>(
  callbackFunction: (_dispatch: DispatchType, payload: ProcessReturn) => void,
  logicOptions: object = {}
) {
  return <Payload extends PayloadBasis>(
    type: string,
    processFunction: (state: GlobalState, payload: Payload) => false | ProcessReturn
  ) => {
    return createLogic<GlobalState, Payload>({
      ...logicOptions,
      process: ({ getState, action }, dispatch, done) => {
        if (isErrorAction(action)) {
          done();
          return;
        }
        const payload = processFunction(getState(), action.payload as Payload);
        if (payload) {
          callbackFunction(dispatch, payload);
        }
        done();
      },
      type
    });
  };
}
