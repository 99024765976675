import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { transcriptDownload } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  submitLessonFeedBack,
  updateLessonStatus
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import {
  getLessonFeedbackSubmitted,
  getSelectedLesson
} from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as modalActions from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { noop } from '@chegg-tutors-chat/shared/utils';
import LessonFeedbackForm, {
  LessonFeedbackFormDispatchProps,
  LessonFeedbackFormProps
} from './LessonFeedbackForm';

export function mapStateToProps(
  state: GlobalState,
  passedProps: Partial<LessonFeedbackFormProps>
): LessonFeedbackFormProps {
  const selectedLesson = getSelectedLesson(state);
  const lessonId = selectedLesson ? selectedLesson.id : '';
  const feedbackSubmitted = getLessonFeedbackSubmitted(state);

  const modalOptions = {
    lessonId
  };
  const { isOpen = false, onSubmit = noop } = passedProps;

  return {
    isOpen,
    modalOptions,
    onSubmit,
    tutorName: selectedLesson ? selectedLesson.userData.tutor.firstName : 'this tutor',
    feedbackSubmitted
  };
}

export const mapDispatchToProps = (
  dispatch: Dispatch
): LessonFeedbackFormDispatchProps => {
  const handleUpdateLessonStatus = () => {
    dispatch(updateLessonStatus({ status: LESSON_STATUS.CLOSED }));
  };

  const handleSubmitLessonFeedback = (
    lessonId: Lesson['id'],
    feedback: LessonFeedBack
  ) => {
    dispatch(submitLessonFeedBack({ id: lessonId, feedback }));
  };

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };

  const handleDownloadTranscript = () => {
    dispatch(transcriptDownload());
  };

  return {
    handleCloseModal,
    handleDownloadTranscript,
    handleSubmitLessonFeedback,
    handleUpdateLessonStatus
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonFeedbackForm);
