import { createSelector, defaultMemoize } from 'reselect';

type LessonsList = LessonState['lessonsList'];
type pGlobal = Partial<GlobalState>;

/**
 * Gets the lesson state.
 *
 * @param state
 */
export const getLessonState = (state: pGlobal): LessonState | undefined => state.lessons;

/**
 * Gets the list of lessons.
 *
 */
export const getLessons = createSelector(
  [getLessonState],
  (lessons?: LessonState): LessonsList => {
    return (lessons && lessons.lessonsList) || {};
  }
);

/**
 * Gets the selected lesson id.
 */
export const getSelectedLessonId = createSelector(
  [getLessonState],
  (lessons?: LessonState) => {
    return (lessons && lessons.currentLessonId) || '';
  }
);

/**
 * Get the currently selected lesson information. Returns undef if
 * no lesson is currently selected.
 */

export const getSelectedLesson = createSelector(
  [getLessons, getSelectedLessonId],
  (lessons: LessonsList, currentLessonId: string) => {
    if (!currentLessonId || !lessons) {
      return undefined;
    }
    return lessons[currentLessonId];
  }
);

/**
 * Function that returns the lessons status or undefined if selected lesson
 * or lessons are not found.
 *
 * docs https://github.com/reduxjs/reselect#defaultmemoizefunc-equalitycheck--defaultequalitycheck
 *
 * @param state
 * @param lessonId
 */
export const getLessonStatus = defaultMemoize((state: pGlobal, lessonId: Lesson['id']):
  | LessonStatus
  | undefined => {
  const lessons = getLessons(state);
  const selectedLesson = getSelectedLesson(state);

  if (!lessons || !lessonId) {
    return undefined;
  }

  const currentLesson = selectedLesson || lessons[lessonId];
  return currentLesson && currentLesson.status;
});

/**
 * Function that returns the lessons feedback or undefined if selected lesson is unavailable
 *
 *
 * @param state
 * **/
export const getLessonFeedbackSubmitted = createSelector(
  getSelectedLesson,
  (lesson: Lesson) => {
    if (!lesson) {
      return undefined;
    }
    return lesson.hasSubmittedFeedback;
  }
);

/**
 * Gets the ticket info for a lesson.
 *
 * @param state
 * @param lessonId
 */
export const getLesson = (state: pGlobal, lessonId: string): Lesson | undefined => {
  const lessons = getLessons(state);

  const lesson = lessons[lessonId];
  if (!lesson) {
    return undefined;
  }
  return lesson;
};

/**
 * Gets the ticket info for a lesson.
 *
 * @param state
 * @param lessonId
 */
export const getLessonTicket = (
  state: pGlobal,
  lessonId: string
): TicketInfo | undefined => {
  const lessons = getLessons(state);

  const lesson = lessons[lessonId];
  if (!lesson) {
    return undefined;
  }
  return lesson.ticketInfo;
};

export const getTutorInfo = (
  state: pGlobal,
  lessonId: string
): LessonUser | undefined => {
  const lessons = getLessons(state);
  const lesson = lessons[lessonId];
  if (lesson) {
    return lesson.userData.tutor;
  } else {
    return undefined;
  }
};
