import * as React from 'react';
import ScratchPad from '../styled';

/*
 * Color - scratchpad toolbar representation of a color
 *
 * @prop hex - hex code for css display of color
 * @prop name - name of the color; displayed to user
 */
export interface Color {
  hex: string;
  key: string;
}

const SubToolbar = ScratchPad.SubBar;

export const PEN_COLORS = {
  black: {
    hex: '#000',
    key: 'black'
  },
  blue: {
    hex: '#019be7',
    key: 'blue'
  },
  red: {
    hex: '#f00',
    key: 'red'
  }
};

export const SCRATCHPAD_COLOR_TOOLBAR = 'scratchpad-color-toolbar';

// colorOrder used to determine order of colors displayed in toolbar
const colorOrder = [PEN_COLORS.black, PEN_COLORS.red, PEN_COLORS.blue];

interface ColorToolbarProps {
  selected: Color;
  onClick: (color: Color) => void;
}

const Toolbar: React.SFC<ColorToolbarProps> = ({ selected, onClick }) => {
  return (
    <SubToolbar
      id={SCRATCHPAD_COLOR_TOOLBAR}
      tabIndex={-1}
      aria-label="Color select toolbar"
    >
      {colorOrder.map(color => {
        return (
          <SubToolbar.Item
            key={color.key}
            selected={color.key === selected.key}
            color={color.hex}
            onClick={() => onClick(color)}
            title={`Set ${color.key}`}
          />
        );
      })}
    </SubToolbar>
  );
};

export default Toolbar;
