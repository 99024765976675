import * as React from 'react';
import { formatDisplayName } from '@chegg-tutors-chat/shared/utils';
import { Label, Layout, Text, TutorName, YourNoteHeader } from './styled';

/**
 * @interface NoteProps
 *
 * @prop note - a note the tutor has made about the lesson
 * @prop yourNote - flag distinguishing whether this is your own note
 */
export interface NoteProps {
  note: Note;
  yourNote?: boolean;
}

/**
 * @component LessonNote - displays a note the tutor hase made about the lesson
 *
 * @props NoteProps
 */
const LessonNote: React.SFC<NoteProps> = ({ note, yourNote }) => {
  const { assignmentType, complexity, moreInfo, subtopic, tutor } = note;
  const { firstName, lastName } = tutor;
  const noteHeader = yourNote ? (
    <YourNoteHeader>Your note</YourNoteHeader>
  ) : (
    <TutorName>{formatDisplayName(firstName, lastName)}</TutorName>
  );
  return (
    <Layout>
      {noteHeader}
      <Text>
        <Label>Lesson complexity: </Label>
        {complexity}
      </Text>
      <Text>
        <Label>Subtopic: </Label>
        {subtopic}
      </Text>
      <Text>
        <Label>Assignment type: </Label>
        {assignmentType}
      </Text>
      {moreInfo && <Text>{moreInfo}</Text>}
    </Layout>
  );
};

export default LessonNote;
