import * as React from 'react';
import SkipLink from '@chegg-tutors-chat/shared/components/SkipLink';
import { publicConfig } from '@chegg-tutors-chat/shared/config';
import { PublicConfigKeys } from '@chegg-tutors-chat/shared/config/public';
import { HEADER, STATUS } from '@chegg-tutors-chat/shared/constants';
import { noop } from '@chegg-tutors-chat/shared/utils';
import { IconButton } from '@chegg/ui-agnostic';
import { Indicator, Layout, Logo, LogoButtonWrapper, Text } from './styled';

/**
 *  @prop isLoggedIn - whether user is logged in or not
 *  @prop firstName - first name of the user.
 *  @prop lastName - last name of the user.
 *  @prop profileUrl - profile url of the user.
 */
export interface ApplicationHeaderProps {
  isLoggedIn?: boolean;
  statusText?: STATUS;
  onLogoClick?: (lessonId: Lesson['id']) => void;
  lessonId?: Lesson['id'];
  HeaderChatAvatar?:
    | React.FunctionComponent<Record<string, any>>
    | React.ComponentClass<Record<string, any>>;
}

/**
 * ApplicationHeader - renders app header with chegg logo and logged in tutor info.
 * @props - ApplicationHeaderProps
 */
const ApplicationHeader: React.SFC<ApplicationHeaderProps> = ({
  isLoggedIn = false,
  statusText = STATUS.ONLINE,
  onLogoClick = noop,
  lessonId,
  HeaderChatAvatar
}) => {
  // passes lessonId to LogoClick prop
  function onLogoClickWrapper() {
    if (lessonId) {
      onLogoClick(lessonId);
    }
  }

  const companyLogo = publicConfig.get(PublicConfigKeys.TEXT, 'header.logo');
  const companyTitle = publicConfig.get(PublicConfigKeys.TEXT, 'header.title');
  const underlineColor = publicConfig.get(PublicConfigKeys.THEME, HEADER.UNDERLINE_COLOR);

  const LogoButton = (
    <LogoButtonWrapper>
      <IconButton onClick={onLogoClickWrapper} screenReaderOnlyText="Go back home">
        <Logo src={companyLogo} alt={companyTitle} />
      </IconButton>
    </LogoButtonWrapper>
  );

  if (!isLoggedIn) {
    return <Layout underlineColor={underlineColor}>{LogoButton}</Layout>;
  }

  return (
    <Layout role="banner" underlineColor={underlineColor}>
      <SkipLink label="Skip to message composer" />
      {LogoButton}
      {HeaderChatAvatar && <HeaderChatAvatar />}
      <Indicator status={STATUS.ONLINE} />
      <Text>{statusText}</Text>
    </Layout>
  );
};

export default ApplicationHeader;
