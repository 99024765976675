import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModalCallbackProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import * as actions from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import * as selectors from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as modalActions from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import CloseLesson from './index';

export function mapStateToProps(state: GlobalState, passedProps: ModalsState) {
  const modalOptions = passedProps.modalOptions || {};
  const lessonId = modalOptions.lessonId || selectors.getSelectedLessonId(state);
  return {
    isOpen: true,
    primaryCallbackPayload: lessonId
  };
}

export const mapDispatchToProps = (
  dispatch: Dispatch,
  passedProps: ModalsState
): ModalCallbackProps => {
  const { lessonId = '' } = passedProps.modalOptions || {};

  const handleEndLesson = (id: Lesson['id']) => {
    dispatch(modalActions.closeModal());
    dispatch(actions.endLesson({ id: id || lessonId }));
  };

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };
  return {
    primaryButtonClick: handleEndLesson,
    secondaryButtonClick: handleCloseModal
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseLesson);
