import ReactDOM from 'react-dom';
import AdminAvatar from '@chegg-tutors-chat/shared/assets/images/avatar-admin.svg';
import BotAvatar from '@chegg-tutors-chat/shared/assets/images/avatar-chegg.svg';
import CheggLogoSrc from '@chegg-tutors-chat/shared/assets/images/chegg-logo.svg';
import {
  HEADER,
  PRIMARY_BUTTON,
  SECONDARY_BUTTON,
  STUDENT_AVATAR,
  TUTOR_AVATAR
} from '@chegg-tutors-chat/shared/constants';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import { captureError, noop } from '@chegg-tutors-chat/shared/utils';
import { getIdToken } from '@chegg-tutors-chat/shared/utils/headers';
import { fanta } from '@chegg/fanta';
import privateconfig from './index';

type EventFunction = (...args: any[]) => void;

// PublicConfigEvents, PublicConfigText, & PublicConfigTheme interfaces must be updated anytime a property is added
export interface PublicConfigEvents {
  onPopout: EventFunction;
  onClose: EventFunction;
}
export interface PublicConfigText {
  'header.logo': string;
  'header.title': string;
  'header.homepage.url': string;
  'message.bot.name': string;
  'message.admin.name': string;
  'message.tutor.profileUrl': string;
  'message.student.profileUrl': string;
  'message.bot.profileUrl': string;
  'message.admin.profileUrl': string;
}
export interface PublicConfigTheme {
  [TUTOR_AVATAR.BG_COLOR]: string;
  [STUDENT_AVATAR.BG_COLOR]: string;
  [HEADER.BG_COLOR]: string;
  [HEADER.NEW_MESSAGE_BG_COLOR]: string;
  [HEADER.UNDERLINE_COLOR]: string;
  [PRIMARY_BUTTON.BG_COLOR]: string;
  [PRIMARY_BUTTON.TEXT_COLOR]: string;
  [PRIMARY_BUTTON.HOVER_TEXT_COLOR]: string;
  [PRIMARY_BUTTON.HOVER_BG_COLOR]: string;
  [SECONDARY_BUTTON.BG_COLOR]: string;
  [SECONDARY_BUTTON.TEXT_COLOR]: string;
  [SECONDARY_BUTTON.HOVER_TEXT_COLOR]: string;
  [SECONDARY_BUTTON.HOVER_BG_COLOR]: string;
  [HEADER.POPOUT_ENABLED]: boolean;
}

export interface PublicConfig {
  theme: PublicConfigTheme;
  text: PublicConfigText;
  events: PublicConfigEvents;
}

// Gives us flexibility to refer to key names throughout the app
export enum PublicConfigKeys {
  THEME = 'theme',
  TEXT = 'text',
  EVENTS = 'events'
}

export interface PublicConfigApi {
  get(key: PublicConfigKeys.EVENTS, name: string): undefined | EventFunction;
  get(
    key: PublicConfigKeys.TEXT | PublicConfigKeys.THEME,
    name: string
  ): string | undefined;
  get(key: PublicConfigKeys.THEME, name: HEADER.POPOUT_ENABLED): boolean;
  set(key: PublicConfigKeys.EVENTS, value: Partial<PublicConfigEvents>): void;
  set(key: PublicConfigKeys.TEXT, value: Partial<PublicConfigText>): void;
  set(key: PublicConfigKeys.THEME, value: Partial<PublicConfigTheme>): void;
}

// config with defaults
const config: PublicConfig = {
  theme: {
    [TUTOR_AVATAR.BG_COLOR]: fanta.colors.sky,
    [STUDENT_AVATAR.BG_COLOR]: COLORS['222222'],
    [HEADER.NEW_MESSAGE_BG_COLOR]: fanta.colors.sky,
    [HEADER.BG_COLOR]: COLORS['5B1566'],
    [HEADER.UNDERLINE_COLOR]: COLORS.EB7100,
    [PRIMARY_BUTTON.BG_COLOR]: '',
    [PRIMARY_BUTTON.TEXT_COLOR]: '',
    [PRIMARY_BUTTON.HOVER_TEXT_COLOR]: '',
    [PRIMARY_BUTTON.HOVER_BG_COLOR]: '',
    [SECONDARY_BUTTON.BG_COLOR]: '',
    [SECONDARY_BUTTON.TEXT_COLOR]: '',
    [SECONDARY_BUTTON.HOVER_TEXT_COLOR]: '',
    [SECONDARY_BUTTON.HOVER_BG_COLOR]: '',
    [HEADER.POPOUT_ENABLED]: false
  },
  text: {
    'header.logo': CheggLogoSrc,
    'header.title': 'Chegg',
    'header.homepage.url': 'https://www.chegg.com/tutors',
    'message.bot.name': 'Chegg Bot',
    'message.admin.name': 'Chegg Admin',
    'message.tutor.profileUrl': '',
    'message.student.profileUrl': '',
    'message.bot.profileUrl': BotAvatar,
    'message.admin.profileUrl': AdminAvatar
  },
  events: {
    onPopout: () => {
      const popoutUrl = process.env.REACT_APP_POPOUT_PUBLIC_URL;
      const lessonToken = privateconfig.get('LESSON_TOKEN');
      const idToken = getIdToken();
      window.open(`${popoutUrl}/?idToken=${idToken}&lessonToken=${lessonToken}`);
      ReactDOM.unmountComponentAtNode(privateconfig.get('ROOT_CONTAINER_NODE'));
    },
    onClose: noop
  }
};

// checks if config contains key
function keyIsValid(key: string) {
  return config.hasOwnProperty(key);
}
// checks if argument is an object
function isArgAnObject(arg: any): boolean {
  // Typeof null or array is also object, so additional checks are needed to prevent them
  return typeof arg === 'object' && !Array.isArray(arg) && arg !== null;
}

function createError(msg: string) {
  const error = new Error(msg);
  captureError(error);
}

const invalidKeyErrorMsg = (key: string) =>
  `"${key}" isn't a supported key. Please checking for misspellings`;

// create a function to allow us to pass new objects in test since we don't allow multiple updates
const publicConfig = (configArg: PublicConfig): PublicConfigApi => {
  // Supports default config and allows us to track duplicates
  const configUpdates = {
    theme: {
      hasBeenUpdated: false
    },
    text: {
      hasBeenUpdated: false
    },
    events: {
      hasBeenUpdated: false
    }
  };
  return {
    get: (key, name) => {
      // throw an error for invalid keys
      if (!keyIsValid(key)) {
        createError(invalidKeyErrorMsg(key));
        return undefined;
      } else {
        return configArg[key][name];
      }
    },
    set: (key, value) => {
      // if: check if key is invalid
      // else if: check if value isn't an object
      // else if: check if config has already been set for specified key
      // else: set value
      if (!keyIsValid(key)) {
        createError(invalidKeyErrorMsg(key));
      } else if (!isArgAnObject(value)) {
        createError(`Only objects are allowed as config values`);
      } else if (configUpdates[key].hasBeenUpdated) {
        createError(`Skipping config.set for ${key} as it has been already updated`);
      } else {
        configArg[key] = {
          ...configArg[key],
          ...value
        };
        configUpdates[key].hasBeenUpdated = true;
      }
    }
  };
};
export { publicConfig };
export default publicConfig(config);
