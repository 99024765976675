import styled from 'styled-components';
import AttachmentIcon from '@chegg-tutors-chat/shared/assets/images/ic-upload.svg';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const FilePreview = styled.div`
  min-height: 30px;
  width: 100%;
  max-width: max-content;
  margin-bottom: 10px;
  border: 1px solid ${COLORS.CCCCCC};
  vertical-align: middle;
  padding: 5px 15px 0 15px;
`;

export const Icon = styled.span`
  display: table-cell;
  width: 13px;
  height: 14px;
  background-image: url(${AttachmentIcon});
  background-repeat: no-repeat;
  background-size: 13px 14px;
  margin-right: 6px;
`;

export const LinkWrapper = styled.span`
  display: table-cell;
  padding-left: 6px;
`;
