import { Action } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { CHAT_CONNECTION, TOAST_NOTIFICATION } from '@chegg-tutors-chat/shared/constants';
import { deepCopyObject, focusMessageInput } from '@chegg-tutors-chat/shared/utils';
import { addLesson, LessonPayload, selectLesson } from '../lessons/actions';
import { messageSent, MessageSentPayload } from '../messages/actions';
import { pinMessage, PinMessagePayload } from '../pins/actions';
import {
  addAttachment,
  AttachmentPayload,
  ChatConnectionStatus,
  ChatReconnectionStatus,
  closeToast,
  hideTool,
  HydrateAppPayload,
  hydrateAppState,
  increaseUnreadMessageCount,
  removeAttachment,
  RemoveAttachmentPayload,
  resetUnreadMessage,
  setChatConnectionStatus,
  setChatReconnectionStatus,
  setOtherUserTyping,
  setUploadingError,
  setUploadingStatus,
  showToast,
  ShowToastPayload,
  showTool,
  toggleTool,
  ToggleToolPayload,
  TypingStatusPayload,
  UnreadMessageCount,
  updateClientHasFocus,
  UpdateClientHasFocusPayload,
  updateInputText,
  UpdateInputTextPayload,
  updateNewMessageAlert,
  UpdateNewMessageAlertPayload,
  updateStudentStatus,
  UpdateStudentStatusPayload,
  updateWaitDuration,
  UpdateWaitDurationPayload,
  UploadingErrorPayload,
  UploadingStatusPayload
} from './actions';

const initialState: ClientState = {
  connectionStatus: CHAT_CONNECTION.CONNECTION_PENDING,
  lessonStates: {},
  clientHasFocus: false,
  displayNewMessageAlert: false,
  toastName: TOAST_NOTIFICATION.NO_TOAST
};
type ClientLessonState = Partial<LessonClientInfo>;

/**
 * Helper function to copy over state and update the portion of the state we
 * want update which is passed in via param 'newInfo'.
 *
 * For example, if we want to update the student status we pass the data via
 * reducer which calls this function that copies over state and updates the portion
 * we have passed in, which in this case is student status/duration.
 *
 * @param state
 * @param lessonId
 * @param newInfo
 */
function updateClientInfo(
  state: ClientState,
  lessonId: string | undefined,
  newInfo: ClientLessonState
): ClientState {
  if (!lessonId) {
    return state;
  }
  const lessonStatesCopy = { ...state.lessonStates };

  lessonStatesCopy[lessonId] = { ...lessonStatesCopy[lessonId], ...newInfo };
  return {
    ...state,
    lessonStates: lessonStatesCopy
  };
}

const DEFAULT_LESSON_STATE: LessonClientInfo = {
  attachments: [],
  firstPinAdded: false,
  inputText: '',
  lessonInfo: undefined,
  otherUserTyping: false,
  studentStatus: undefined,
  tool: {
    open: ''
  },
  unreadMessageCount: 0,
  uploading: false,
  uploadingError: undefined,
  waitDuration: undefined
};

/**
 * Function to handle tool status. Handles all 3 scenarios of show/toggle/hide.
 *
 * @param state
 * @param action
 */
export function handleToolStatus(
  state: ClientState,
  action: Action<ToggleToolPayload>
): ClientState {
  const { lessonId, toolName } = action.payload;

  if (!toolName || !lessonId) {
    return state;
  }

  const currentToolState =
    state.lessonStates[lessonId] && state.lessonStates[lessonId].tool
      ? state.lessonStates[lessonId].tool.open
      : '';

  let toolStatus = currentToolState;

  switch (action.type) {
    case toggleTool.type:
      if (currentToolState === toolName) {
        toolStatus = '';
      } else if (currentToolState !== toolName) {
        toolStatus = toolName;
      }
      break;
    case hideTool.type:
      if (currentToolState === toolName) {
        toolStatus = '';
      }
      break;
    case showTool.type:
      if (currentToolState !== toolName) {
        toolStatus = toolName;
      }
      break;
    default:
      return state;
      break;
  }

  return updateClientInfo(state, lessonId, {
    tool: {
      open: toolStatus
    }
  });
}

/**
 * updates studentStatus in state
 * @param state
 * @param payload
 */
export function handleUpdateStatusAndDuration(
  state: ClientState,
  payload: UpdateStudentStatusPayload & UpdateWaitDurationPayload
): ClientState {
  const { lessonId, waitDuration, studentStatus } = payload;

  const clientUpdateInfo: ClientLessonState = {};
  if (waitDuration) {
    clientUpdateInfo.waitDuration = waitDuration;
  }

  if (studentStatus) {
    clientUpdateInfo.studentStatus = studentStatus;
  }
  return updateClientInfo(state, lessonId, clientUpdateInfo);
}

/**
 * Adds attachment to a lesson. At the moment we are only adding one attachment
 * but functon can support multiple in the future.
 *
 * @param state
 * @param payload
 */
export function handleAddAttachment(
  state: ClientState,
  payload: AttachmentPayload
): ClientState {
  const { lessonId, fileSrc, type } = payload;

  focusMessageInput();

  if (!fileSrc || !type) {
    return state;
  }
  return updateClientInfo(state, lessonId, {
    attachments: [{ fileSrc, type }],
    uploadingError: undefined
  });
}

/**
 * Removes the attachment by setting the attachment array to []
 *
 * @param state
 * @param payload
 */
export function handleRemoveAttachment(
  state: ClientState,
  payload: RemoveAttachmentPayload
): ClientState {
  const { lessonId } = payload;

  return updateClientInfo(state, lessonId, {
    attachments: [],
    uploadingError: undefined
  });
}

/**
 * Handles message sent in client state.
 *
 * @param state
 * @param action
 */
export function handleMessageSent(
  state: ClientState,
  payload: MessageSentPayload
): ClientState {
  const { lessonId } = payload;
  const newState = handleRemoveAttachment(state, payload);
  return handleInputText(newState, { lessonId, inputText: '' });
}

/**
 * Handles uploading status.
 *
 * @param state
 * @param payload
 */
export function handleUploadingStatus(
  state: ClientState,
  payload: UploadingStatusPayload
): ClientState {
  const { lessonId, uploading } = payload;

  return updateClientInfo(state, lessonId, {
    uploading
  });
}

/**
 * Handles uploading error.
 *
 * @param state
 * @param payload
 */
export function handleUploadingError(
  state: ClientState,
  payload: UploadingErrorPayload
): ClientState {
  const { lessonId, uploadingError } = payload;
  return updateClientInfo(state, lessonId, {
    uploadingError
  });
}

/**
 * Handles the other user's typing status in client state.
 *
 * @param state
 * @param payload
 */
export function handleOtherUserTyping(
  state: ClientState,
  payload: TypingStatusPayload
): ClientState {
  const { lessonId, isTyping } = payload;

  return updateClientInfo(state, lessonId, {
    otherUserTyping: isTyping
  });
}

/**
 * Handles add new lesson in client state.
 *
 * @param state
 * @param payload
 */
export function handleAddLesson(state: ClientState, payload: Lesson): ClientState {
  const id = payload.id;
  if (state[id]) {
    return state;
  }
  const newState = Object.assign(
    {},
    { ...state },
    {
      [id]: deepCopyObject(DEFAULT_LESSON_STATE)
    }
  );
  return newState;
}

/**
 * Handles unread message count in client state.
 *
 * @param state
 * @param payload
 */
export function handleIncreaseUnreadMessageCount(
  state: ClientState,
  payload: UnreadMessageCount
): ClientState {
  const { lessonId } = payload;
  const lessonInfo = state.lessonStates[lessonId] || { unreadMessageCount: 0 };
  let unreadMessageCount: number = lessonInfo.unreadMessageCount || 0;
  unreadMessageCount += 1;
  return updateClientInfo(state, lessonId, {
    unreadMessageCount
  });
}

/**
 * Handles reset unread message count in client state.
 *
 * @param state
 * @param payload
 */
export function handleResetUnreadMessage(
  state: ClientState,
  payload: UnreadMessageCount
): ClientState {
  const { lessonId } = payload;
  return updateClientInfo(state, lessonId, {
    unreadMessageCount: 0
  });
}

/**
 * Handles selected lesson in client state.
 *
 * @param state
 * @param payload
 */
export function handleSelectLesson(
  state: ClientState,
  lesson: LessonPayload
): ClientState {
  focusMessageInput();
  const id = { lessonId: lesson.id };
  return handleResetUnreadMessage(state, id);
}

/**
 * sets the input text in client state.
 *
 * @param state
 * @param payload
 */
export function handleInputText(state: ClientState, payload: UpdateInputTextPayload) {
  return updateClientInfo(state, payload.lessonId, {
    inputText: payload.inputText
  });
}

/**
 * sets the first pin added in client state.
 *
 * @param state
 * @param payload
 */
export function handleAddPin(state: ClientState, payload: PinMessagePayload) {
  return updateClientInfo(state, payload.lessonId, {
    firstPinAdded: true
  });
}

/**
 * sets the new lesson info in client state.
 *
 * @param state
 * @param payload
 */
export function handleHypdrateApp(state: any, payload: HydrateAppPayload) {
  return updateClientInfo(state, payload.id, {
    lessonInfo: payload.lessonInfo
  });
}
/**
 * updates clientHasFocus in ClientState
 * @param state
 * @param action
 */
export function handleClientHasFocus(
  state: ClientState,
  action: Action<UpdateClientHasFocusPayload>
) {
  return {
    ...state,
    clientHasFocus: action.payload.clientHasFocus
  };
}

/**
 * sets the chat connection status in client state.
 *
 * @param state
 * @param action
 */
export function handleChatConnection(
  state: ClientState,
  action: Action<ChatConnectionStatus | ChatReconnectionStatus>
) {
  return {
    ...state,
    connectionStatus: action.payload.status
  };
}

/**
 * Handles new messages alert in client state.
 *
 * @param state
 * @param action
 */
export function handleUpdateNewMessageAlert(
  state: ClientState,
  payload: UpdateNewMessageAlertPayload
): ClientState {
  return {
    ...state,
    displayNewMessageAlert: payload.displayNewMessageAlert
  };
}

/**
 * Handles the state of toast
 * @param state
 * @param action
 */
export function handleToggleToast(
  state: ClientState,
  action: Action<ShowToastPayload | void>
): ClientState {
  const toastName =
    (action.payload && action.payload.toastName) || TOAST_NOTIFICATION.NO_TOAST;
  return {
    ...state,
    toastName
  };
}

export default reducerWithInitialState(initialState)
  .case(addLesson, handleAddLesson)
  .case(updateInputText, handleInputText)
  .case(selectLesson, handleSelectLesson)
  .case(increaseUnreadMessageCount, handleIncreaseUnreadMessageCount)
  .case(resetUnreadMessage, handleResetUnreadMessage)
  .case(updateStudentStatus, handleUpdateStatusAndDuration)
  .case(updateWaitDuration, handleUpdateStatusAndDuration)
  .case(addAttachment, handleAddAttachment)
  .case(messageSent, handleMessageSent)
  .case(removeAttachment, handleRemoveAttachment)
  .case(setUploadingStatus, handleUploadingStatus)
  .case(setUploadingError, handleUploadingError)
  .case(setOtherUserTyping, handleOtherUserTyping)
  .case(pinMessage, handleAddPin)
  .casesWithAction(
    [setChatConnectionStatus, setChatReconnectionStatus],
    handleChatConnection
  )
  .caseWithAction(updateClientHasFocus, handleClientHasFocus)
  .case(updateNewMessageAlert, handleUpdateNewMessageAlert)
  .case(hydrateAppState, handleHypdrateApp)
  .casesWithAction([hideTool, showTool, toggleTool], handleToolStatus)
  .casesWithAction([showToast, closeToast], handleToggleToast)
  .build();
