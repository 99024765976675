import * as React from 'react';
import { ErrorBanner, Heading5 } from '@chegg/fanta';
import { Text, Wrapper } from './styled';

interface ErrorBannerProps {
  title: string;
  description: string;
}

const ErrorBannerModal: React.FunctionComponent<ErrorBannerProps> = ({
  title,
  description
}) => {
  return (
    <Wrapper>
      <ErrorBanner size={'large'}>
        <Heading5>{title}</Heading5>
        <Text>{description}</Text>
      </ErrorBanner>
    </Wrapper>
  );
};

export default ErrorBannerModal;
