import * as React from 'react';
import { HomePageButton, Wrapper } from './styled';

/**
 * ErrorPage - renders an error page. For now it just displays the button in the middle.
 */
function ErrorPage() {
  return (
    <Wrapper>
      <HomePageButton as="a">Go to homepage</HomePageButton>
    </Wrapper>
  );
}

export default ErrorPage;
