import {
  rerouteLesson,
  RerouteRejectLessonPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import RejectRerouteManager from '../RejectRerouteManager';

export const mapDispatchToProps = (dispatch: Dispatch, passedProps: ModalsState) => {
  const { lessonId = '' } = passedProps.modalOptions || {};
  const handleRerouteLesson = (rejectRerouteData: RerouteRejectLessonPayload) => {
    const {
      reason: { reasonToRejectOrReroute, tellUsMore },
      note
    } = rejectRerouteData;
    dispatch(
      rerouteLesson({
        id: lessonId,
        note,
        reason: {
          reasonToRejectOrReroute,
          tellUsMore
        }
      })
    );
  };

  return {
    rejectReroute: handleRerouteLesson
  };
};

export default connect(null, mapDispatchToProps)(RejectRerouteManager);
