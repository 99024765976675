import * as React from 'react';
import { Provider } from 'react-redux';
import AppLayout from 'src/components/Layout';
import store from 'src/configs/store';
import SentryBoundary from '@chegg-tutors-chat/shared/components/Sentry';

/**
 * AppContent - renders app layout with store provider.
 */

const AppContent: React.SFC = () => (
  <Provider store={store}>
    <SentryBoundary>
      <AppLayout />
    </SentryBoundary>
  </Provider>
);

export default AppContent;
