import * as React from 'react';
import styled from 'styled-components';
import { Lead } from '@chegg-tutors-chat/shared/styled/sunkist/Typography';
import LessonNote from '../Note';

const HeaderLayout = styled.div`
  padding: 0px 30px;
  margin: -16px 0px -2px;
`;

/**
 * @interface NotesListProps
 *
 * @prop notes - list of notes made about a lesson
 */
export interface NotesListProps {
  notes?: Note[];
  yourNote?: Note;
}

/**
 * NotesList - renders all notes within NotesList for a lesson
 *
 * @props - NotesListProps
 */

const NotesList: React.FunctionComponent<NotesListProps> = ({
  notes = [],
  ...remainingProps
}) => {
  const { yourNote } = remainingProps;

  /* If there are no notes from the logged in tutor or other experts, don't render list */
  if (!(yourNote || (notes && notes.length > 0))) {
    return null;
  }

  return (
    <>
      <HeaderLayout>
        <Lead>Expert notes:</Lead>
      </HeaderLayout>
      {yourNote && <LessonNote note={yourNote} yourNote={true} />}
      {notes && notes.length > 0 && (
        <>
          {notes.map(note => {
            return <LessonNote key={`note_${note.tutor.userId}`} note={note} />;
          })}
        </>
      )}
    </>
  );
};

export default NotesList;
