import FONTS from '@chegg-tutors-chat/shared/styled/constants/fonts';

// const CDN = 'https://c.cheggcdn.com/assets';
const CDN_BASE = 'https://c.cheggcdn.com/f';
const ASPIRA_MEDIUM_ID = '30B57C_5D_0';
const ASPIRA_REGULAR_ID = '30B57C_60_0';
const ASPIRA_BOLD_ID = '30B57C_52_0';
const ASPIRA_HEAVY_ID = '30B57C_57_0';

const getFontFace = (fontId, name, localName) => {
  return `@font-face {
    font-family: ${name};
    src: local('Aspira'), local(${localName});
    src: url('${CDN_BASE}/eot/${fontId}.eot');
    src: url('${CDN_BASE}/eot/${fontId}.eot?#iefix') format('embedded-opentype'),
            url('${CDN_BASE}/woff2/${fontId}.woff2') format('woff2'),
            url('${CDN_BASE}/woff/${fontId}.woff') format('woff'),
            url('${CDN_BASE}/ttf/${fontId}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
};

/**
 * Aspira font is required to load as GlobalStyle because for some chegg/sunkist components
 * like Buttons and Link, font-family is defined as 'Aspira, Helvetica, Arial, sans-serif;'.
 * In absence of Aspira, Helvetica font is loading.
 */
export const Aspira = getFontFace(ASPIRA_MEDIUM_ID, FONTS.Aspira, 'Aspira Medium');
export const AspiraMedium = getFontFace(
  ASPIRA_MEDIUM_ID,
  FONTS.AspiraMedium,
  'Aspira Medium'
);
export const AspiraRegular = getFontFace(
  ASPIRA_REGULAR_ID,
  FONTS.AspiraRegular,
  'Aspira Regular'
);
export const AspiraBold = getFontFace(ASPIRA_BOLD_ID, FONTS.AspiraBold, 'Aspira Bold');
export const AspiraHeavy = getFontFace(ASPIRA_HEAVY_ID, 'Aspira-Heavy', 'Aspira Heavy');
