import config from '@chegg-tutors-chat/shared/config';

export default function setToolConfig() {
  const tools = config.get('TOOLS');

  // Only tutors have access to the lesson info
  tools.LESSON_INFO = null;

  // Temporarily disabling pins, graphing tool for student
  tools.PINNED = null;
  tools.GRAPHING_TOOL = null;
  config.set('TOOLS', tools);

  // Also temporarily disable full scratchpad functionality
  // We plan to re-enable after MVP launch
  config.set('FULL_SCRATCHPAD', false);
}
