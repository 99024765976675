import * as React from 'react';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { Modal } from '@chegg/fanta';

/**
 * Modal that displays a logout confirmation
 *
 * @props BaseModalProps
 */
const LogoutModal: React.FC<BaseModalProps> = ({ isOpen, ...restProps }) => (
  <Modal
    isOpen={isOpen}
    headerText="Log out?"
    primaryButtonText="Log out"
    secondaryButtonText="Cancel"
    {...restProps}
  >
    <p>This will end any active lessons.</p>
  </Modal>
);

export default LogoutModal;
