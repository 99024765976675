import { connect } from 'react-redux';
import { getAnnouncementState } from '@chegg-tutors-chat/shared/redux/modules/announcement/selectors';
import ScreenReaderAnnouncer from './ScreenReaderAnnouncer';

export function mapStateToProps(state: GlobalState) {
  const announcementState = getAnnouncementState(state);
  const announcement = announcementState && announcementState.current;
  return { announcement };
}

export default connect(mapStateToProps, null)(ScreenReaderAnnouncer);
