import * as React from 'react';
import Link from '@chegg-tutors-chat/shared/styled/sunkist/Link';
import * as Sentry from '@sentry/browser';
import { FilePreview, Icon, LinkWrapper } from './styled';

/**
 * @prop fileName - name of the file
 * @prop fileUrl - url to the file
 */
interface FileAttachmentLinkProps {
  fileName: string;
  fileUrl: string;
}

// domain for sendbird s3 bucket
export const sendbirdDomain = 'https://sendbird-us-1.s3.amazonaws.com';
/**
 *  Returns truthy if file upload matches sendbird origin
 * @parms url - location of file
 *
 */
function linkIsFromSendbird(url: string) {
  const fileUrl = new URL(url);
  const originMatches = fileUrl.origin === sendbirdDomain;
  if (!originMatches) {
    Sentry.captureException(
      new Error('User attached an invalid link. Potential XSS attempt.')
    );
  }
  return originMatches;
}

/**
 * @component FileAttachmentLink - renders a styled file preview
 *
 * @props FileAttacmentPreviewProps
 */
export function FileAttachmentLink({
  fileName = '',
  fileUrl = ''
}: FileAttachmentLinkProps) {
  return linkIsFromSendbird(fileUrl) ? (
    <FilePreview>
      <Icon />
      <LinkWrapper>
        <Link href={fileUrl} target={'_blank'}>
          {fileName}
        </Link>
      </LinkWrapper>
    </FilePreview>
  ) : null;
}

export default FileAttachmentLink;
