/**
 * this is a copy of /shared/components/Modal/content/WhyRejectReroute/WhyRejectRerouteForm.tsx,
 * we will be working on it to add new changes and features. Once we add all changes
 * this copy will replace WhyRejectReroute.tsx
 *
 * this copy renders only in DEV environment.
 *
 */

import { Field, Formik, FormikActions } from 'formik';
import * as React from 'react';
import { RadioButton } from '@chegg-tutors-chat/shared/components/Forms/Fields/RadioButtons';
import {
  Field as FormField,
  Label
} from '@chegg-tutors-chat/shared/components/Forms/styled';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import ModalFooter from '@chegg-tutors-chat/shared/components/Modal/ModalFooter';
import { REJECT_REROUTE_SUBJECTS } from '@chegg-tutors-chat/shared/constants';
import { RejectRerouteReason } from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { LinkButton, Modal, PrimaryButton } from '@chegg/fanta';
import { AdditionalInfo, FooterButtonsWrapper, Form } from '../WhyRejectReroute/styled';
import SupportedSubjectDropdown from './SupportedSubjectDropdown/SupportedSubjectDropdown';

interface RadioProps {
  value: FormFieldsMap;
  buttonText: string;
}
export interface WhyRejectRerouteFormProps extends BaseModalProps {
  onSubmit: (reasonsData: RejectRerouteReason) => void;
}

enum FormFieldsMap {
  TooAdvancedRequest = 'tooAdvancedRequest',
  NotFamiliarWithTopic = 'notFamiliarWithTopic',
  StudentSelectedWrongSubject = 'studentSelectedWrongSubject',
  Other = 'other',
  TellUsMore = 'tellUsMore'
}

// these are exported to be used in test
export const initialValues: RejectRerouteReason = {
  reasonToRejectOrReroute: null,
  tellUsMore: null,
  supportedSubjects: REJECT_REROUTE_SUBJECTS.NONE_SELECTED
};

const ReasonToRejectOrReroute = 'reasonToRejectOrReroute';

const reasonsToRejectOrReroute = [
  {
    buttonText: 'The request is too advanced for my expertise',
    value: FormFieldsMap.TooAdvancedRequest
  },
  {
    buttonText: "I'm not familiar with the subtopic",
    value: FormFieldsMap.NotFamiliarWithTopic
  },
  {
    buttonText: 'Request is in a different subject or subtopic',
    value: FormFieldsMap.StudentSelectedWrongSubject,
    hasAutoComplete: true
  },
  {
    buttonText: 'Other',
    value: FormFieldsMap.Other
  }
];
// Validates the form with the assumption that a reason to reject must be filled and in
// the event of a different subject, the subject must be selected
export const submitIfFormIsValid = (
  onSubmit: (formVals: RejectRerouteReason) => void
) => {
  return (values: RejectRerouteReason, actions: FormikActions<RejectRerouteReason>) => {
    const reason = values[ReasonToRejectOrReroute];
    if (
      reason &&
      !(
        reason === 'studentSelectedWrongSubject' &&
        values.supportedSubjects === REJECT_REROUTE_SUBJECTS.NONE_SELECTED
      )
    ) {
      onSubmit(values as RejectRerouteReason);
    }
    actions.setSubmitting(false);
  };
};
// A Formik Field component that's inner component is a Radio
const RadioField: React.FunctionComponent<RadioProps> = ({ value, buttonText }) => {
  return (
    <div>
      <FormField key={value}>
        <Field
          id={value}
          component={RadioButton}
          name={ReasonToRejectOrReroute}
          value={value}
          type="radio"
        >
          {buttonText}
        </Field>
      </FormField>
    </div>
  );
};
// Renders form fields from reasonsToRejectOrReroute object
export const generateFormFields = (
  values: RejectRerouteReason,
  handleChange,
  handleBlur
) =>
  reasonsToRejectOrReroute.map(({ buttonText, value, hasAutoComplete }) => {
    let showDropdown: boolean = false;
    if (values.reasonToRejectOrReroute === value && hasAutoComplete) {
      showDropdown = true;
    }
    return (
      <div>
        <RadioField value={value} buttonText={buttonText} />
        {showDropdown && (
          <FormField>
            <Label htmlFor={FormFieldsMap.StudentSelectedWrongSubject}></Label>
            <Field
              component={SupportedSubjectDropdown}
              id="subjectDropdown"
              name="supportedSubjects"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormField>
        )}
      </div>
    );
  });

const WhyRejectReroute: React.FunctionComponent<WhyRejectRerouteFormProps> = ({
  isOpen,
  onSubmit,
  ...restProps
}) => {
  return (
    <Modal
      headerText="What happened?"
      isOpen={isOpen}
      subHeaderText="Please let us know why you weren't able to help this student."
      {...restProps}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submitIfFormIsValid(onSubmit)}
        render={({
          handleSubmit,
          isSubmitting,
          dirty,
          touched,
          handleBlur,
          handleChange,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField>{generateFormFields(values, handleChange, handleBlur)}</FormField>
            <FormField>
              <Label>
                If there is an Honor Code violation or other inappropriate behavior,
                please {''}
                <LinkButton size="small">report the student.</LinkButton>
              </Label>
            </FormField>
            <FormField>
              <Label htmlFor={FormFieldsMap.TellUsMore}>Tell us more: (optional)</Label>
              <Field
                component={AdditionalInfo}
                id={FormFieldsMap.TellUsMore}
                name={FormFieldsMap.TellUsMore}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
            <FooterButtonsWrapper>
              <ModalFooter>
                <PrimaryButton
                  size="large"
                  data-testid="next-button"
                  type="submit"
                  isDisabled={
                    isSubmitting || !(dirty && touched[ReasonToRejectOrReroute])
                  }
                >
                  Next
                </PrimaryButton>
              </ModalFooter>
            </FooterButtonsWrapper>
          </Form>
        )}
      />
    </Modal>
  );
};

export default WhyRejectReroute;
