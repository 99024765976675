import {
  submitLessonFeedBack,
  SubmitLessonFeedBackPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import {
  UpdateNotePayload,
  updateYourNote
} from '@chegg-tutors-chat/shared/redux/modules/notes/actions';
import { EVENT_ID, EVENT_NAME, EVENT_TYPE } from '../constants';
import { genericLogicFactory } from '../utils';
import {
  formatAnalyticsGenericEvent,
  FormatAnalyticsGenericEventPayload
} from '../utils/formatAnalyticsGenericEvent';

interface FeedbackActionPayload {
  id?: Lesson['id'];
  lessonId?: Lesson['id'];
}

/**
 * Logic to track when some kind of feedback is submitted
 */
function trackFeedback<ActionPayload extends FeedbackActionPayload>(config: {
  actionType: string;
  eventName: EVENT_NAME;
}) {
  const { actionType, eventName } = config;
  return genericLogicFactory<ActionPayload>(
    actionType,
    (state: GlobalState, payload: ActionPayload) => {
      let lessonId;
      if (payload.lessonId) {
        lessonId = payload.lessonId;
      } else if (payload.id) {
        lessonId = payload.id;
      }
      const genericEventConfig: FormatAnalyticsGenericEventPayload = {
        eventId: EVENT_ID.submitFeedback,
        eventName,
        eventState: state,
        eventType: EVENT_TYPE.FEEDBACK,
        lessonId
      };
      return formatAnalyticsGenericEvent(genericEventConfig);
    }
  );
}

/**
 * Logic to track when the tutor updates their "note" about the lesson
 */
export const trackUpdateYourNote = trackFeedback<UpdateNotePayload>({
  actionType: updateYourNote.type,
  eventName: EVENT_NAME.update_your_note
});

/**
 * Logic to track when the student submits feedback about the lesson and their tutor.
 */
export const trackSubmitLessonFeedback = trackFeedback<SubmitLessonFeedBackPayload>({
  actionType: submitLessonFeedBack.type,
  eventName: EVENT_NAME.feedback_submitted
});

export default [trackUpdateYourNote, trackSubmitLessonFeedback];
