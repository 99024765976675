import * as React from 'react';
import FileAttachmentPreview from '@chegg-tutors-chat/shared/components/FileAttachmentLink';
import LatestNote from '../Notes/LatestNote';
import { NoteWrapper } from '../Notes/styled';
import { RequestDescription, RequestDetailsWrapper } from './styled';

export interface RequestDetailsProps {
  /**
   * @prop request - lesson request from the student
   */
  request: LessonRequestFormData;
  note?: Note;
}

const RequestDetails: React.FunctionComponent<RequestDetailsProps> = ({
  note,
  request
}) => {
  const { attachment, description } = request;

  return (
    <RequestDetailsWrapper>
      {attachment && (
        <FileAttachmentPreview fileName={attachment.name} fileUrl={attachment.url} />
      )}
      <RequestDescription>{description}</RequestDescription>
      {note && (
        <NoteWrapper>
          <LatestNote note={note} />
        </NoteWrapper>
      )}
    </RequestDetailsWrapper>
  );
};

export default RequestDetails;
