import * as React from 'react';
import MessageAttachment from '@chegg-tutors-chat/shared/components/MessageAttachment';
import { Lead } from '@chegg-tutors-chat/shared/styled/sunkist/Typography';
import { Layout, Text } from './styled';

/**
 * @prop lesson - all details of the selected lesson.
 */
export interface LessonInfoProps {
  lesson: Lesson;
}

/**
 * LessonInfo - displays info about the current lesson
 * @props - LessonInfoProps
 */
function LessonInfo({ lesson }: LessonInfoProps) {
  const request = lesson.ticketInfo.request;
  const { attachment } = request;
  return (
    <Layout>
      <Lead>Initial request:</Lead>
      <Text>{request.description}</Text>
      {attachment && (
        <MessageAttachment fileName={attachment.name} fileUrl={attachment.url} />
      )}
    </Layout>
  );
}

export default LessonInfo;
