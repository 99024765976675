import moment from 'moment';
import * as React from 'react';
import { PrimaryButton } from '@chegg/fanta';
import { ButtonWrapper, ChatLink, Layout, Text } from './styled';

const PrintHeader: React.FunctionComponent = () => (
  <Layout>
    <Text>Chat Transcript {moment().format('L')}</Text>
    <ButtonWrapper>
      <ChatLink href="#">Back to chat</ChatLink>
      <PrimaryButton
        size={'medium'}
        onClick={() => {
          window.print();
        }}
      >
        Print chat
      </PrimaryButton>
    </ButtonWrapper>
  </Layout>
);

export default PrintHeader;
