import { FieldProps } from 'formik';
import * as React from 'react';
import { Field as FormField } from '@chegg-tutors-chat/shared/components/Forms/styled';
import { REJECT_REROUTE_SUBJECTS } from '@chegg-tutors-chat/shared/constants';
import { Select } from './styled';

// according  to AC i am using static subjects for now, subjects will be later passed from backend.

// Default value for select form inputs
export const NONE_SELECTED = 'NONE_SELECTED';

const SupportedSubjectDropdown: React.FunctionComponent<FieldProps> = ({ field }) => {
  return (
    <div>
      <FormField>
        <Select {...field}>
          {Object.keys(REJECT_REROUTE_SUBJECTS).map(key => {
            return (
              <option
                aria-selected={field.value === key}
                key={`${field.name}_${key}`}
                value={key}
              >
                {/* For NONE_SELECTED, let's show "Subjects" instead other we can just use what's in the object */}
                {key === NONE_SELECTED ? 'Subjects' : REJECT_REROUTE_SUBJECTS[key]}
              </option>
            );
          })}
        </Select>
      </FormField>
    </div>
  );
};

export default SupportedSubjectDropdown;
