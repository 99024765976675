/**
 * We modified the 'register' and 'unregister' methods from the
 * original Create React App, so that we can be sure service workers
 * are NOT enabled.
 */
export default function register() {
  /**
   * The service workers were failing to update the page at times
   * which made this hard to to debug. The only solution would be to remove
   * the service workers on the browser.
   *
   * Because this is not a feasible thing to ask students and tutors todo
   * we are removing the service workers. I am also keeping the unregister here
   * to remove any lingering workers users would have.
   */
  unregister();
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
