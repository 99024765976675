import * as React from 'react';
import GlobalStyles from 'src/Theme';
import Conversation from '@chegg-tutors-chat/shared/components/Conversation';
import config from '@chegg-tutors-chat/shared/config';
import { Theme } from '@chegg-tutors-chat/shared/styled/chat/ThemeProvider';
import { OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk';
import ApplicationHeader from '../ApplicationHeader';
import PrintHeader from './PrintHeader';
import { ConversationWrapper } from './styled';

interface Props {
  user: UserState;
}
const PrintPreview: React.FunctionComponent<Props> = ({ user }) => {
  const { REACT: optimizely } = config.get('OPTIMIZELY');
  return (
    <OptimizelyProvider
      optimizely={optimizely as ReactSDKClient}
      user={{
        id: user.userId
      }}
    >
      <Theme>
        <GlobalStyles />
        <ApplicationHeader />
        <main>
          <PrintHeader />
          <ConversationWrapper>
            <Conversation />
          </ConversationWrapper>
        </main>
      </Theme>
    </OptimizelyProvider>
  );
};

export default PrintPreview;
