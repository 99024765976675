import * as React from 'react';
import { noop } from '@chegg-tutors-chat/shared/utils';
import { InformationBanner } from '@chegg/fanta';
import { HCVInfoBannerWrapper } from './styled';

export interface InfoBannerDispatchProps {
  updateAnnouncement: () => void;
}

export const HCV_INFO_TEXT = `Before reporting the student,
  consider educating them about the Honor Code and/or
  suggesting academically honest help.`;

/**
 * Informational Banner that renders Honor Code Violation text
 * and dispatches an announcement on initial load
 * @param props
 */
const HCVInfoBanner: React.FunctionComponent<InfoBannerDispatchProps> = ({
  updateAnnouncement = noop
}) => {
  React.useEffect(() => {
    updateAnnouncement();
  }, []);
  return (
    <HCVInfoBannerWrapper>
      <InformationBanner size="small">{HCV_INFO_TEXT}</InformationBanner>
    </HCVInfoBannerWrapper>
  );
};

export default HCVInfoBanner;
