import * as React from 'react';
import closeIcon from '@chegg-tutors-chat/shared/assets/images/ic-close.svg';
import scratchPadOnboardingAsset from '@chegg-tutors-chat/shared/assets/images/onboarding-image-v2.png';
import { IconButton } from '@chegg/ui-agnostic';
import {
  CloseOverlayWrapper,
  HelpOverlayContainer,
  ScratchPadOnboardingGraphic
} from './styled';

// Renders an closeable overlay or onboarding image via ScratchPadOnboardingGraphic
const HelpOverlay: React.SFC<{
  onClose: (e: React.MouseEvent) => void;
}> = props => {
  return (
    <HelpOverlayContainer>
      <CloseOverlayWrapper>
        <IconButton
          onClick={props.onClose}
          data-testid="close-button"
          screenReaderOnlyText="Close Icon"
        >
          <img src={closeIcon} alt="Close icon" />
        </IconButton>
      </CloseOverlayWrapper>
      <ScratchPadOnboardingGraphic
        src={scratchPadOnboardingAsset}
        alt="Draw here, only you can see this. Need to share? Use the screenshot tool to send a screenshot in the chat."
      />
    </HelpOverlayContainer>
  );
};

export default HelpOverlay;
