import styled from 'styled-components';
import colors from '@chegg-tutors-chat/shared/styled/constants/colors';

export interface PinProps {
  src: string;
}

const getSource = (props: PinProps) => {
  return props.src;
};

export const Layout = styled.button`
  background-color: ${colors.FFFFFF};
  border: 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
`;

export const Image = styled.img.attrs<PinProps>((props: PinProps) => {
  return {
    src: getSource(props)
  };
})`
  src: ${getSource};
  height: 14px;
  width: 14px;
`;
