import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import { Heading5 } from '@chegg/fanta';
import { Layout as PinLayout } from '../PinIcon/styled';
import { Layout as unPinLayout } from '../UnPinIcon/styled';

export const Layout = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  padding-right: 30px;
  position: relative;
  border-bottom: 1px solid ${COLORS.DDDDDD};
  &:last-child {
    padding-bottom: 20px;
  }
  & > {
    ${PinLayout}, ${unPinLayout} {
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
`;

export const HeadingLayout = styled.div`
  display: flex;
  width: 205px;
  height: 30px;
  position: relative;
  top: 6px;
  > div:last-child {
    padding-left: 12px;
    margin-left: auto;
  }
`;

export const Name = styled(Heading5)`
  line-height: 1.5;
  display: block;
  max-width: 100px;
  padding-right: 12px;
  font-family: Aspira-Bold;
  color: ${COLORS['222222']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`;

export const Text = styled.p`
  font-family: Aspira-Regular;
  margin: 0;
  color: ${COLORS['222222']};
  position: relative;
  padding-top: 5px;
  word-wrap: break-word;
  hyphens: auto;
  line-height: 1.5;
`;

export const Time = styled.time`
  display: block;
  position: relative;
  color: ${COLORS['767676']};
  font-family: Aspira-Regular;
  font-size: 14px;
  line-height: 1.5;
  top: 2px;
`;
