import { Store } from 'redux';

let store: Store<GlobalState>;

export default {
  getStore: () => {
    return store;
  },

  setStore: (storeValue: Store) => {
    store = storeValue;
  }
};
