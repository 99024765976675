import { Field, Formik, FormikErrors } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import ModalFooter from '@chegg-tutors-chat/shared/components/Modal/ModalFooter';
import {
  ASSIGNMENT_COMPLEXITY,
  ASSIGNMENT_TYPE
} from '@chegg-tutors-chat/shared/constants';
import { noop } from '@chegg-tutors-chat/shared/utils';
import { Modal } from '@chegg/fanta';
import { PrimaryButton } from '@chegg/fanta';
import {
  assignmentType,
  assignmentTypeKeysByValue,
  complexity,
  complexityKeysByValue,
  moreInfo,
  NONE_SELECTED,
  subtopic
} from './constants';
import {
  AssignmentTypeField,
  ComplexityField,
  FormValues,
  SubtopicField,
  TellUsMoreField
} from './FormFields';

export interface UpdateYourNoteFormDispatchProps {
  /**
   * @prop handleCloseModal - action to close the modal
   */
  handleCloseModal?: () => void;
  /**
   * @prop onSubmit - action called upon form submission
   */
  onSubmit?: (lessonId: Lesson['id'], note: Note) => void;
}

export interface UpdateYourNoteFormProps extends BaseModalProps {
  /**
   * @prop lesson - lesson that the note is attached to
   */
  lesson: Lesson | undefined;
  /**
   * @prop yourNote - existing note that the tutor has added
   */
  yourNote?: Note;

  onSubmit: (lessonId: Lesson['id'], noteData: Note) => void;
}

function validate(values: FormValues) {
  const errors: FormikErrors<FormValues> = {};

  if (values.assignmentType === NONE_SELECTED) {
    errors.assignmentType = NONE_SELECTED;
  }

  if (values.complexity === NONE_SELECTED) {
    errors.complexity = NONE_SELECTED;
  }

  if (values.subtopic === '') {
    errors.subtopic = 'Required';
  }

  return errors;
}

function isError(values: FormValues) {
  const errors = validate(values);
  return !!(errors[complexity] || errors[subtopic] || errors[assignmentType]);
}

/**
 * Form modal to add/update a tutor's note about the lesson
 *
 * @props UpdateYourNoteFormProps
 * @props UpdateYourNoteFormDispatchProps
 */
const UpdateYourNote: React.SFC<UpdateYourNoteFormProps &
  UpdateYourNoteFormDispatchProps> = ({
  handleCloseModal = noop,
  isOpen,
  onSubmit = noop,
  lesson,
  yourNote,
  ...restProps
}: UpdateYourNoteFormProps & UpdateYourNoteFormDispatchProps) => {
  if (!lesson) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      headerText={'What would you tell the next expert?'}
      subHeaderText={'The next expert will see the information you provide below.'}
      onClose={handleCloseModal}
      {...restProps}
    >
      <Formik
        initialValues={{
          assignmentType: yourNote
            ? assignmentTypeKeysByValue[yourNote.assignmentType]
            : NONE_SELECTED,
          complexity: yourNote
            ? complexityKeysByValue[yourNote.complexity]
            : NONE_SELECTED,
          moreInfo: yourNote ? yourNote.moreInfo : '',
          subtopic: yourNote ? yourNote.subtopic : ''
        }}
        onSubmit={(values, actions) => {
          const { tutor } = lesson.userData;
          const lessonId = lesson.id;
          const note = {
            ...values,
            assignmentType: ASSIGNMENT_TYPE[values.assignmentType],
            complexity: ASSIGNMENT_COMPLEXITY[values.complexity],
            createdAt: moment.now().toString(),
            lessonId,
            tutor
          };
          onSubmit(lessonId, note);
          actions.setSubmitting(false);
          handleCloseModal();
        }}
        validate={validate}
        render={({ values, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              id={complexity}
              component={ComplexityField}
              name={complexity}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.complexity}
            />
            <Field
              id={subtopic}
              component={SubtopicField}
              name={subtopic}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.subtopic}
            />
            <Field
              id={assignmentType}
              component={AssignmentTypeField}
              name={assignmentType}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.assignmentType}
            />
            <Field
              id={moreInfo}
              component={TellUsMoreField}
              name={moreInfo}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.moreInfo}
            />
            <span>
              <ModalFooter>
                <PrimaryButton
                  size={'large'}
                  data-testid="submit-button"
                  type="submit"
                  isDisabled={isSubmitting || isError(values)}
                >
                  Submit
                </PrimaryButton>
              </ModalFooter>
            </span>
          </form>
        )}
      />
    </Modal>
  );
};

export default UpdateYourNote;
