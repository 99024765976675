import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

/*
 * ScratchPadItemProps
 *
 * @prop selected - boolean idicates if icon is selected
 * @prop disabled - boolean idicates if icon is disabled
 * @prop src - source url of icon
 * @prop color - sets icon color
 * @prop side - positions to left or right
 */
export interface ScratchPadItemProps {
  selected?: boolean;
  disabled?: boolean;
  src?: string;
  color?: string;
  side?: 'left' | 'right';
  horizontalFlip?: boolean;
}

const getColor = (props: ScratchPadItemProps) =>
  props.selected && !props.disabled ? COLORS.FFFFFF : COLORS.F5F5F5;
const getSrc = (props: ScratchPadItemProps) => (props.src ? props.src : '');
const getRadius = (props: ScratchPadItemProps) => (props.color ? '50%' : 0);
const getBackgColor = (props: ScratchPadItemProps) => (props.color ? props.color : '');
const getHoverColor = (props: ScratchPadItemProps) =>
  props.disabled ? COLORS.F5F5F5 : COLORS.FFFFFF;
const getOpacity = (props: ScratchPadItemProps) => (props.disabled ? 0.25 : 1);
const getCursor = (props: ScratchPadItemProps) =>
  props.disabled ? 'default' : 'pointer';

const ScratchPad = styled.div``;

const BarBase = styled.div`
  box-sizing: content-box;
  border: solid 1px ${COLORS.E5E5E5};
  background-color: ${COLORS.F5F5F5};
`;
export const ScratchPadContainer = styled.div`
  position: relative;
  left: 0px;
  top: 0px;
  height: 100%;
  display: flex;
`;

export const HelpOverlayContainer = styled.div`
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: 0 auto;
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
`;

export const ScratchPadOnboardingGraphic = styled.img`
  margin-top: 99px;
  max-width: 505px;
  margin-left: 30px;
`;

const ItemBase = styled.button`
  cursor: ${(props: ScratchPadItemProps) => getCursor(props)};
  border: none;
  opacity: ${(props: ScratchPadItemProps) => getOpacity(props)};
  background-color: ${(props: ScratchPadItemProps) => getColor(props)};
  &:hover {
    background-color: ${(props: ScratchPadItemProps) => getHoverColor(props)};
  }
`;

const Bar = styled(BarBase)`
  height: 44px;
  margin: 10px 10px 0;
`;

const SubBar = styled(BarBase)`
  height: 34px;
  border-top: none;
  margin: 0 20px;
`;

const BarItem = styled(ItemBase)`
  width: 44px;
  height: 44px;
  background-image: url(${(props: ScratchPadItemProps) => getSrc(props)});
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  ${({ horizontalFlip }: ScratchPadItemProps) =>
    horizontalFlip &&
    `
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
    `}
  ${({ side }) =>
    side &&
    `
    float: ${side};
  `} &:nth-child(5) {
    border-left: 1px solid ${COLORS.E5E5E5};
  }
  &:nth-child(6) {
    border-right: 1px solid ${COLORS.E5E5E5};
  }
  &:nth-child(10) {
    border-right: 1px solid ${COLORS.E5E5E5};
  }
  &:first-child {
    border-right: 1px solid ${COLORS.E5E5E5};
  }
`;

const SubBarItem = styled(ItemBase)`
  width: 34px;
  height: 34px;
  &::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: ${(props: ScratchPadItemProps) => getRadius(props)};
    position: absolute;
    background-color: ${(props: ScratchPadItemProps) => getBackgColor(props)};
    background-image: url(${(props: ScratchPadItemProps) => getSrc(props)});
    background-repeat: no-repeat;
    background-position: center;
    transform: translate(-50%, -50%);
  }
`;

// Overrides Global Theme so MathType displays correctly
export const MathTypeWrapper = styled.div`
  .wrs_selectPanel,
  .wrs_select {
    box-sizing: content-box !important;
    > div {
      box-sizing: content-box !important;
      font-size: 11px !important;
    }
  }
`;

export const SendToChatLayout = styled.span`
  float: left;
  margin-top: 10px;
  margin-left: 20px;
`;

export const SendToPadLayout = styled.span`
  float: right;
  margin-top: 10px;
  margin-right: 20px;
`;

export const CloseOverlayWrapper = styled.div`
  position: absolute;
  top: 103px;
  right: 25px;
`;

const BarObj = Object.assign(Bar, { Item: BarItem });

const SubBarObj = Object.assign(SubBar, { Item: SubBarItem });

export default Object.assign(ScratchPad, { Bar: BarObj, SubBar: SubBarObj });
