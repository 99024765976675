import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('pins');

/**
 * Use moment().unix() to set the unix time for when a message is pinned.
 */
export interface PinMessagePayload {
  lessonId: Lesson['id'];
  message: Message;
  pinTime?: number;
}

export interface UnPinMessagePayload {
  lessonId: Lesson['id'];
  messageId: Message['messageId'];
}

export const pinMessage = actionCreator<PinMessagePayload>('PIN_MESSAGE');
export const unPinMessage = actionCreator<UnPinMessagePayload>('UNPIN_MESSAGE');
