import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModalCallbackProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { logOut } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import * as modalActions from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import Logout from './Logout';

export const mapDispatchToProps = (dispatch: Dispatch): ModalCallbackProps => {
  const handleLogout = () => {
    dispatch(logOut());
  };

  const handleCloseModal = () => {
    dispatch(modalActions.closeModal());
  };
  return {
    primaryButtonClick: handleLogout,
    secondaryButtonClick: handleCloseModal
  };
};

export default connect(null, mapDispatchToProps)(Logout);
