import { connect } from 'react-redux';
import {
  getClientInfo,
  getOpenTool
} from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import {
  getLessons,
  getSelectedLessonId
} from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import LessonAreaList from './LessonAreaList';

export function mapStateToProps(state: GlobalState) {
  return {
    currentLessonId: getSelectedLessonId(state),
    getOpenLessonTool: (lessonId: string) => {
      const clientLessonInfo = getClientInfo(state, lessonId);
      const toolInfo = getOpenTool(clientLessonInfo);
      return toolInfo;
    },
    lessons: getLessons(state)
  };
}

export default connect(mapStateToProps, {})(LessonAreaList);
