import { TARGET } from '@chegg-tutors-chat/shared/components/ScratchPad/Formula';
import { ATTACHMENT_ORIGIN_TYPE } from '@chegg-tutors-chat/shared/constants';
import * as clientActions from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { EVENT_ID, EVENT_NAME, EVENT_TYPE } from '../constants';
import { genericLogicFactory } from '../utils';
import {
  formatAnalyticsGenericEvent,
  FormatAnalyticsGenericEventPayload
} from '../utils/formatAnalyticsGenericEvent';

/**
 * Logic to track when an attachment is added from somewhere
 */
const trackAddAttachment = (config: {
  eventId: EVENT_ID;
  eventName: EVENT_NAME;
  trackedOriginType: ATTACHMENT_ORIGIN_TYPE;
  trackedTargetType?: TARGET;
}) => {
  const { eventId, eventName, trackedOriginType, trackedTargetType } = config;
  return genericLogicFactory<clientActions.AttachmentPayload>(
    clientActions.addAttachment.type,
    (state: GlobalState, payload: clientActions.AttachmentPayload) => {
      const { originType, target } = payload;
      const matchingOrigin = originType && originType === trackedOriginType;
      const matchingTarget =
        !trackedTargetType || (target && target === trackedTargetType);
      if (matchingOrigin && matchingTarget) {
        const genericEventConfig: FormatAnalyticsGenericEventPayload = {
          eventId,
          eventName,
          eventState: state,
          eventType: EVENT_TYPE.TOOL_COPY_PASTE
        };
        return formatAnalyticsGenericEvent(genericEventConfig);
      } else {
        return false;
      }
    }
  );
};

export const trackAddAttachmentFromGraphingTool = trackAddAttachment({
  eventId: EVENT_ID.graphToChat,
  eventName: EVENT_NAME.graph_to_chat,
  trackedOriginType: ATTACHMENT_ORIGIN_TYPE.GRAPHING_TOOL
});

/**
 * Logic to track when an attachment is added to the scratch pad from the math type tool
 */
export const trackAddAttachmentFromMathToolToPad = trackAddAttachment({
  eventId: EVENT_ID.mathTypeToScratchPad,
  eventName: EVENT_NAME.mathtype_to_scratchpad,
  trackedOriginType: ATTACHMENT_ORIGIN_TYPE.MATH_TOOL,
  trackedTargetType: TARGET.scratchpad
});

/**
 * Logic to track when an attachment is added to chat from the math type tool
 */
export const trackAddAttachmentFromMathToolToChat = trackAddAttachment({
  eventId: EVENT_ID.mathTypeToChat,
  eventName: EVENT_NAME.mathtype_to_chat,
  trackedOriginType: ATTACHMENT_ORIGIN_TYPE.MATH_TOOL,
  trackedTargetType: TARGET.input
});

/**
 * Logic to track when an attachment is added to chat from the scratch pad
 */
export const trackAddAttachmentFromScratchPad = trackAddAttachment({
  eventId: EVENT_ID.scratchPadToChat,
  eventName: EVENT_NAME.scratchpad_to_chat,
  trackedOriginType: ATTACHMENT_ORIGIN_TYPE.SCRATCH_PAD
});

export default [
  trackAddAttachmentFromGraphingTool,
  trackAddAttachmentFromMathToolToChat,
  trackAddAttachmentFromMathToolToPad,
  trackAddAttachmentFromScratchPad
];
