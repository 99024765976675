import * as React from 'react';
import AssignmentPanel from '@chegg-tutors-chat/shared/components/AssignmentPanel';
import GraphTool from '@chegg-tutors-chat/shared/components/GraphTool';
import PinnedPanel from '@chegg-tutors-chat/shared/components/PinnedPanel';
import ScratchPad from '@chegg-tutors-chat/shared/components/ScratchPad';
import Toolbar from '@chegg-tutors-chat/shared/components/Toolbar';
import { Layout, ToolbarLayout } from './styled';

/**
 * @prop lesson - all lesson details of selected lesson.
 * @prop selected - boolean which says lesson is selected.
 * @prop showScratchPad - boolean to toggle scratch pad.
 * @prop showAssignmentPanel - boolean to toggle Lesson info.
 * @prop showGraphTool - boolean to toggle GraphTool.
 */
export interface LessonAreaProps {
  lesson: Lesson;
  selected: boolean;
  showScratchPad: boolean;
  showAssignmentPanel: boolean;
  showPinned: boolean;
  showGraphTool: boolean;
}

/**
 * LessonArea - Right side component in the layout which has toolbar and toggles lesson info and scratchpad.
 */
const LessonArea: React.SFC<LessonAreaProps> = props => {
  const {
    lesson,
    selected,
    showScratchPad,
    showAssignmentPanel,
    showPinned,
    showGraphTool
  } = props;

  const display = selected ? 'flex' : 'none';
  /**
   * doing a hide/show of the scratch pad because we want to keep all the drawing
   * the user has already done on the scratch pad. This way
   * we don't have to maintain the canvas in state.
   */
  const scratchPadDisplay = showScratchPad ? 'initial' : 'none';
  const assignmentPanel = showAssignmentPanel ? (
    <AssignmentPanel lesson={lesson} />
  ) : null;
  const pinnedPanel = showPinned ? <PinnedPanel /> : null;
  const graphTool = showGraphTool ? <GraphTool /> : null;

  return (
    <Layout display={display}>
      <div style={{ display: scratchPadDisplay }}>
        <ScratchPad lessonId={lesson.id} />
      </div>
      {assignmentPanel}
      {pinnedPanel}
      {graphTool}
      <ToolbarLayout>
        <Toolbar />
      </ToolbarLayout>
    </Layout>
  );
};

export default LessonArea;
