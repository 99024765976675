import { createLogic } from 'redux-logic';
import { submitCloseLessonStudent, submitLessonFeedback } from 'src/api/helpers';
import { publicConfig } from '@chegg-tutors-chat/shared/config';
import { PublicConfigKeys } from '@chegg-tutors-chat/shared/config/public';
import { HEADER, LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { sendAnnouncementWithDelay } from '@chegg-tutors-chat/shared/redux/modules/announcement/logic';
import {
  endLesson,
  EndLessonPayload,
  submitLessonFeedBack,
  SubmitLessonFeedBackPayload,
  updateLessonStatus,
  UpdateLessonStatusPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { getLessonTicket } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { isErrorAction } from '@chegg-tutors-chat/shared/utils';

/**
 * Logic that listens to end lesson action and calls BFF to close lesson
 */
export const endLessonLogic = createLogic<GlobalState, EndLessonPayload>({
  validate: ({ action, getState }, allow, reject) => {
    const { id } = action.payload;
    const lessonTicket = getLessonTicket(getState(), id);
    if (!id || isErrorAction(action) || !lessonTicket || !lessonTicket.id) {
      reject(action);
    } else {
      allow(action);
    }
  },
  process: (
    { getState, action },
    dispatch: DispatchType<UpdateLessonStatusPayload>,
    done: DoneType
  ) => {
    const homePageUrl = publicConfig.get(PublicConfigKeys.TEXT, HEADER.HOMEPAGE_URL);
    const { shouldRedirectToHome, id } = action.payload;
    const lessonTicket = getLessonTicket(getState(), id);
    submitCloseLessonStudent({ ticketId: lessonTicket.id }).then(() => {
      if (shouldRedirectToHome) {
        window.location.assign(homePageUrl);
        done();
      } else {
        dispatch(
          updateLessonStatus({
            id,
            status: LESSON_STATUS.CLOSED
          })
        );
        const announcement = 'Chat ended';
        sendAnnouncementWithDelay(announcement, dispatch, done);
      }
    });
  },
  type: endLesson.type
});

/**
 * Logic that listens to submitLessonFeedBack type and actually
 * submits it.
 *
 * @param state
 * @param payload
 */
export const submitLessonFeedBackLogic = createLogic<
  GlobalState,
  SubmitLessonFeedBackPayload
>({
  validate: ({ action }, allow, reject) => {
    const { feedback, id } = action.payload;
    if (!id || !feedback || isErrorAction(action)) {
      reject(action);
    } else {
      allow(action);
    }
  },
  process: ({ action }, _dispatch, done: DoneType) => {
    const { feedback, id } = action.payload;
    // coerce id to string since we already validated it in 'validate'
    submitLessonFeedback({ feedback, lessonId: id as string });
    done();
  },
  type: [submitLessonFeedBack.type]
});

export default [endLessonLogic, submitLessonFeedBackLogic];
