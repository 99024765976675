import * as React from 'react';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import { Modal } from '@chegg/fanta';

export interface LessonRemovedProps extends BaseModalProps {
  handleCloseModal: () => void;
}

/**
 * Modal that shows message when lesson was removed from tutor and another modal was open already
 *
 * @props LessonRemovedProps
 */
const LessonRemoved: React.SFC<LessonRemovedProps> = ({
  handleCloseModal,
  isOpen,
  ...restProps
}) => {
  return (
    <Modal
      isOpen={isOpen}
      headerText={'Lesson was removed'}
      primaryButtonText={'Close'}
      primaryButtonClick={handleCloseModal}
      {...restProps}
    >
      <p>You were removed from this lesson opportunity.</p>
    </Modal>
  );
};

export default LessonRemoved;
