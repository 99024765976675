import * as QueryString from 'query-string';
import config from '@chegg-tutors-chat/shared/config';
import buildAxiosClient from '@chegg-tutors-chat/shared/utils/buildAxiosClient';
import { getStudentHeaders } from '@chegg-tutors-chat/shared/utils/headers';

export default function setAxiosClient(lessonToken: string) {
  const queryParams = QueryString.parse(window.location.search);

  // The order here is important!
  // This will override the id_token cookie with the one passed as the id_token parameter.
  // This exists mostly to faciliate testing.
  if (queryParams && typeof queryParams.idToken === 'string') {
    // First make sure we have a cookie for auth in the axios client
    document.cookie = `id_token=${queryParams.idToken}`;
  }

  // then create the axios client - headers will include the idToken set above.
  config.set('AXIOS_CLIENT', buildAxiosClient(getStudentHeaders(lessonToken)));
}
