import SendBird, { FileMessage, UserMessage } from 'sendbird';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { setOtherUserTyping } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  addLesson,
  userLeft
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import {
  addAdminMessage,
  addMessage,
  AddMessagePayload
} from '@chegg-tutors-chat/shared/redux/modules/messages/actions';
import { addUser } from '@chegg-tutors-chat/shared/redux/modules/user/actions';
import { FormattedSendBirdMessage, SendBirdMessage } from './helper';

/**
 * Function to take in a nickname and format split it based on a space.
 * If not name is provided we return empty strings for first/last name.
 *
 * @param nickname
 */
const formatUsername = (nickname?: string) => {
  let [firstName, lastName] = ['', ''];

  if (nickname) {
    [firstName, lastName] = nickname.split(' ');
  }

  return {
    firstName,
    lastName
  };
};

/**
 * Function that takes in SendBird User and creates a properly formatted user
 * for our data purposes or sets fields to empty.
 *
 * @param userData
 */
const setUserDetails = (userData: SendBird.User) => {
  let user = {
    firstName: '',
    lastName: '',
    userId: ''
  };
  if (!userData) {
    return user;
  }
  const userName = formatUsername(userData.nickname);
  const { firstName = '', lastName = '' } = userName;

  const { userId = '' } = userData;
  user = {
    firstName,
    lastName,
    userId
  };

  return user;
};
// Uses sendbird group channel and user to create an addLessonAction
const addLessonAction = (groupChannel: SendBird.GroupChannel, user: SendBird.User) => {
  return addLesson({
    chatInfo: {
      channelUrl: groupChannel.url
    },
    customType: groupChannel.customType,
    id: groupChannel.url,
    status: LESSON_STATUS.YET_TO_BE_DETERMINED,
    hasSubmittedFeedback: false,
    ticketInfo: {
      id: groupChannel.customType || '',
      request: {
        description: '',
        name: '',
        subject: ''
      }
    },
    userData: {
      student: {
        firstName: '',
        lastName: '',
        userId: ''
      },
      tutor: setUserDetails(user)
    }
  });
};
// formats sendbird user and returns addUser action
const addUserAction = (user: SendBird.User) => {
  const userData = setUserDetails(user);

  return addUser({ ...userData });
};

// Creates payload for addMessage action from sendbird and returns addMessage action
const addMessageAction = (message: SendBirdMessage | FormattedSendBirdMessage) => {
  const userData = setUserDetails((message as UserMessage).sender);
  let data: string = '';
  // replace escaped quotations sent from backend
  if (message.data) {
    data = message.data.replace(/\\/g, '');
  }
  let msgAction = addMessage;
  const messagePayload: AddMessagePayload = {
    lessonId: message.channelUrl,
    message: {
      customType: message.customType as CustomMessageType,
      channelUrl: message.channelUrl,
      createdAt: message.createdAt,
      fileName: (message as FileMessage).name,
      fileType: (message as FileMessage).type,
      fileUrl: (message as FileMessage).url,
      message: (message as UserMessage).message,
      messageId: message.messageId,
      data,
      sender: userData,
      hasBeenRead: (message as FormattedSendBirdMessage).hasBeenRead
    }
  };
  // dispatch admin message action for admin actions
  if (message.isAdminMessage && message.isAdminMessage()) {
    msgAction = addAdminMessage;
  }
  return msgAction(messagePayload);
};
// Uses a group channale and typing status to return a setOtherUserTyping action
const setOtherUserTypingStatusAction = (groupChannelUrl: string, isTyping: boolean) => {
  return setOtherUserTyping({
    isTyping,
    lessonId: groupChannelUrl
  });
};
// Uses a group channale and sendbird user to return a userLeft action
const setUserLeftAction = (groupChannelUrl: string, user: SendBird.User) => {
  return userLeft({
    id: groupChannelUrl,
    user: setUserDetails(user)
  });
};

export {
  addLessonAction,
  addUserAction,
  addMessageAction,
  setOtherUserTypingStatusAction,
  setUserLeftAction
};
