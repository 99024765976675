/**
 * Utility function for playing audio files
 * @param filePath to the audio file
 */
const SoundUtil = (filePath: string) => {
  const audio = new Audio(filePath);

  const play = () => {
    // If audio file is currently playing
    if (!audio.paused) {
      // pause it
      audio.pause();

      // set the current playback position to 0
      audio.currentTime = 0;
    }

    audio.play();
  };

  return {
    play
  };
};

export default SoundUtil;
