import { createLogic } from 'redux-logic';
import { ArgumentAction, StandardAction } from 'redux-logic/definitions/action';
import {
  AttachmentPayload,
  removeAttachment,
  RemoveAttachmentPayload,
  setUploadingError,
  setUploadingSuccess,
  UploadingErrorPayload,
  UploadingSuccessPayload
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { formatDisplayName, isErrorAction } from '@chegg-tutors-chat/shared/utils';
import { addMessage, AddMessagePayload } from '../messages/actions';
import { updateAnnouncement } from './actions';

type DispatchType = (action: ArgumentAction<string, undefined, undefined>) => void;
type DoneType = () => void;

export const ONE_SECOND = 1000;
/**
 * We need to delay some announcements because of a bug with some screen readers and focus.
 * If the focus changes right before/after an aria-live update, it will block the
 * aria-live update from being read.
 * Open bug in NVDA - https://github.com/nvaccess/nvda/issues/7324
 * We have also been able to reproduce with VoiceOver on mac.
 */
const sendAnnouncementWithDelay = (
  announcement: string,
  dispatch: DispatchType,
  done: DoneType
) => {
  setTimeout(() => {
    dispatch(updateAnnouncement({ announcement }) as ArgumentAction);
    done();
  }, ONE_SECOND);
};

/**
 * Fires an action to make `aria-live` announcement when we upload new attachment
 */
const announceAttachmentSuccess = createLogic<GlobalState, UploadingSuccessPayload>({
  type: setUploadingSuccess.type,
  process(
    { action }: { action: StandardAction<string, AttachmentPayload, undefined> },
    dispatch: DispatchType,
    done: DoneType
  ) {
    if (!isErrorAction(action)) {
      const { type } = action.payload as AttachmentPayload;
      const announcement = type
        ? `New ${type} attachment added.`
        : `New attachment added.`;
      sendAnnouncementWithDelay(announcement, dispatch, done);
    } else {
      done();
    }
  }
});

/**
 * Fires an action to make `aria-live` announcement when attachment is removed
 */
const announceRemoveAttachment = createLogic<GlobalState, RemoveAttachmentPayload>({
  type: removeAttachment.type,
  process(
    { action }: { action: StandardAction<string, RemoveAttachmentPayload, undefined> },
    dispatch: DispatchType,
    done: DoneType
  ) {
    if (!isErrorAction(action)) {
      const announcement = 'Attachment removed.';
      sendAnnouncementWithDelay(announcement, dispatch, done);
    } else {
      done();
    }
  }
});

/**
 * Fires an action to make `aria-live` announcement when there is an error uploading an attachment
 */
const announceAttachmentError = createLogic<GlobalState, UploadingErrorPayload>({
  type: setUploadingError.type,
  process(
    { action }: { action: StandardAction<string, AttachmentPayload, undefined> },
    dispatch: DispatchType,
    done: DoneType
  ) {
    if (!isErrorAction(action)) {
      const { uploadingError } = action.payload as UploadingErrorPayload;
      const announcement = uploadingError
        ? `Upload failed with error: ${uploadingError.message}`
        : 'Upload failed with error.';
      sendAnnouncementWithDelay(announcement, dispatch, done);
    } else {
      done();
    }
  }
});

/**
 * Fires an action to make `aria-live` announcement when new message is sent or received
 */
const announceNewMessage = createLogic<GlobalState, AddMessagePayload>({
  type: addMessage.type,
  process(
    { action }: { action: StandardAction<string, AddMessagePayload, undefined> },
    dispatch: DispatchType,
    done: DoneType
  ) {
    if (!isErrorAction(action)) {
      const { message } = action.payload as AddMessagePayload;
      const senderName =
        message &&
        message.sender &&
        formatDisplayName(message.sender.firstName, message.sender.lastName);
      const announcement = `${senderName} says ${message.message}.`;
      sendAnnouncementWithDelay(announcement, dispatch, done);
    } else {
      done();
    }
  }
});

export default [
  announceAttachmentError,
  announceAttachmentSuccess,
  announceRemoveAttachment,
  announceNewMessage
];

export { sendAnnouncementWithDelay };
