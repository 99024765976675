import config from '@chegg-tutors-chat/shared/config';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import { AnalyticsModalEvent } from '@chegg-tutors-chat/shared/providers/analytics/interfaces';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { EVENT_ID, EVENT_NAME, EVENT_TYPE } from '../constants';

export interface FormatAnalyticsModalEventPayload {
  eventName: EVENT_NAME;
  eventState: GlobalState;
  modalName: MODAL;
}

export const formatAnalyticsModalEvent = (
  data: FormatAnalyticsModalEventPayload
): AnalyticsModalEvent => {
  const { eventName, eventState, modalName } = data;
  const getOtherUserId = config.get('getOtherUserId');
  const currentLessonId = getSelectedLessonId(eventState);
  return {
    eventID: EVENT_ID.openModal,
    eventName,
    eventValue: [
      {
        pageName: modalName,
        pageNameDetail: currentLessonId
      }
    ],
    studentOrTutorsID: getOtherUserId && getOtherUserId(eventState, currentLessonId),
    type: EVENT_TYPE.MODAL
  };
};

export default formatAnalyticsModalEvent;
