import { connect } from 'react-redux';
import TypingIndicator from '@chegg-tutors-chat/shared/components/TypingIndicator';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import {
  getLesson,
  getSelectedLessonId
} from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { formatDisplayName } from '@chegg-tutors-chat/shared/utils';

export function mapStateToProps(state: GlobalState) {
  const lessonId = getSelectedLessonId(state);
  const clientInfo = getClientInfo(state, lessonId) || {
    otherUserTyping: false
  };
  const isTyping = clientInfo.otherUserTyping;
  const currentLesson = getLesson(state, lessonId) || {
    userData: {
      tutor: {
        firstName: 'Tutor',
        lastName: ''
      }
    }
  };
  const {
    userData: { tutor }
  } = currentLesson;
  const { firstName, lastName } = tutor;
  return {
    isTyping,
    name: formatDisplayName(firstName, lastName)
  };
}

export default connect(mapStateToProps, null)(TypingIndicator);
