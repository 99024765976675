import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import FileUpload from '@chegg-tutors-chat/shared/components/FileUpload';
import MessageInputAttachmentPreview from '@chegg-tutors-chat/shared/components/MessageInputAttachmentPreview';
import UploadStatus from '@chegg-tutors-chat/shared/components/UploadStatus';
import config from '@chegg-tutors-chat/shared/config';
import {
  AttachmentPayload,
  RemoveAttachmentPayload,
  TypingStatusPayload,
  UpdateClientHasFocusPayload,
  UpdateInputTextPayload
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { SendMessagePayload } from '@chegg-tutors-chat/shared/redux/modules/messages/actions';
import { noop } from '@chegg-tutors-chat/shared/utils';
import {
  Layout,
  MessageInputWrapper,
  TextInputContainer,
  UploadIconPosition
} from './styled';

/**
 * MessageInput needs to be a maximum of 40% of the height of the whole chat window
 * Because TextareaAutosize requires a fixed pixel value in order to honor the
 * 'max-height' CSS property, we need to caclulate this value ahead of time.
 * If the user resizes their window too bad ¯\_(ツ)_/¯
 */
const maxHeight = Math.floor(0.4 * window.screen.height);
// This regex tests for an exact match to all possible newline characters
const newLineRegex = /^(\r\n|\r|\n)$/;

/**
 * @prop attachment - local src of the file being uploaded to display a preview
 * @prop disabled - whether the input should be disabled
 * @prop lessonId - id of the current lesson
 * @prop uploadingError - error status from sendbird
 * @prop uploadingStatus - uploading response from sendbird
 */
export interface MessageInputProps {
  attachment?: File;
  disabled: boolean;
  lessonId: string;
  uploadingError?: FileUploadError | null;
  uploadingStatus?: boolean;
  inputText: string;
}

/**
 * @prop addAttachment - adds an uploaded attachment to the store
 * @prop removeAttachment - action to remove the attachment from MessageInput
 * @prop sendMessage - action to send message
 * @prop setClientUserTyping - action to set whether the tutor is currently typing
 * @prop updateClientHasFocus - action to set whether the user is focused on the messageInput or not.
 * @prop updateInputText - action that updates input text
 */
export interface MessageInputDispatchProps {
  addAttachment: (payload: AttachmentPayload) => void;
  removeAttachment: (payload: RemoveAttachmentPayload) => void;
  sendMessage: (payload: SendMessagePayload) => void;
  setClientUserTyping: (payload: TypingStatusPayload) => void;
  updateInputText: (payload: UpdateInputTextPayload) => void;
  updateClientHasFocus: (payload: UpdateClientHasFocusPayload) => void;
}

interface TextInputProps {
  maxHeight: number;
  disabled: boolean;
  inputText: string;
  textInputRef?;
  attachment: File;
  lessonId: string;
  addAttachment: (payload: AttachmentPayload) => void;
  handleUpdateClientisBlurred: (updateClientHasFocus) => void;
  handleUpdateClientisFocused: (updateClientHasFocus) => void;
  sendMessageHandler: (setClientUserTyping, updateInputText) => void;
  updateMessage: (setClientUserTyping, sendMessage) => void;
}

/** Text Input
 * @prop maxHeight
 * @prop disabled
 * @prop inputText
 * @prop textInputRef
 * @prop handleUpdateClientisBlurred
 * @prop handleUpdateClientisFocused
 * @prop sendMessageHandler
 * @prop updateMessage
 * @prop attachment
 * @prop lessonId
 * @prop addAttachment
 */
const TextInput: React.FunctionComponent<TextInputProps> = ({
  maxHeight,
  disabled,
  inputText,
  textInputRef,
  handleUpdateClientisFocused,
  handleUpdateClientisBlurred,
  sendMessageHandler,
  updateMessage,
  attachment,
  lessonId,
  addAttachment
}: any) => {
  const placeholder = 'Write your message here';
  return (
    <TextInputContainer maxHeight={maxHeight}>
      <TextareaAutosize
        className="TextareaAutosize"
        id="messsage-input"
        disabled={disabled}
        aria-label={placeholder}
        placeholder={placeholder}
        name="message"
        value={inputText}
        onChange={updateMessage}
        onKeyPress={sendMessageHandler}
        inputRef={textInputRef}
        onFocus={handleUpdateClientisFocused}
        onBlur={handleUpdateClientisBlurred}
      />
      <UploadIcon
        attachment={attachment}
        disabled={disabled}
        lessonId={lessonId}
        addAttachment={addAttachment}
      />
    </TextInputContainer>
  );
};
interface InputAreaWrapperProps {
  children;
  disabled: boolean;
  maxHeight: number;
  attachment: File;
  attachmentOnClose: () => void;
}

/** InputAreaWrapper
 * @prop children
 * @prop disabled
 * @prop maxHeight
 * @prop attachment
 * @prop attachmentOnClose
 */
const InputAreaWrapper: React.FunctionComponent<InputAreaWrapperProps> = ({
  children,
  disabled,
  maxHeight,
  attachment,
  attachmentOnClose
}: any) => {
  return (
    <Layout disabled={disabled} maxHeight={maxHeight}>
      <div>{children}</div>
      {attachment && (
        <MessageInputAttachmentPreview file={attachment} onClose={attachmentOnClose} />
      )}
    </Layout>
  );
};

/**
 * focusInput - Focus the text input using the raw DOM API
 * @params - textInputRef
 */
const focusInput = (textInputRef: HTMLTextAreaElement) => {
  if (textInputRef) {
    textInputRef.focus();
  }
};

/**
 * Upload Icon - Renders upload icon
 * @props - attachment
 * @props - disabled
 * @props - lessonId
 * @props - addAttachment
 */
const UploadIcon: React.FunctionComponent<Partial<
  MessageInputProps & MessageInputDispatchProps
>> = ({ attachment, disabled, lessonId, addAttachment }) => {
  if (!attachment && !disabled) {
    return (
      <UploadIconPosition>
        <FileUpload
          onFile={(file: File) => {
            addAttachment({ lessonId, fileSrc: file, type: file.type });
          }}
          inputRef={React.createRef<HTMLInputElement>()}
        />
      </UploadIconPosition>
    );
  }
  return null;
};

/**
 * UploadStatusWrapper - Wrapper for upload status
 * @props - uploadingError
 * @props - attachment
 * @props - uploadingStatus
 */
const UploadStatusWrapper: React.FunctionComponent<Partial<MessageInputProps>> = ({
  uploadingError,
  attachment,
  uploadingStatus
}) => {
  const uploadError = !!uploadingError;
  const fileName = attachment ? attachment.name : '';
  if (uploadingStatus || uploadError) {
    return <UploadStatus fileName={fileName} error={uploadError} />;
  }
  return null;
};

/**
 * MessageInput - the input box for the chat window
 * callback a props.sendMessage
 *
 * @props - MessageInputProps
 * @props - MessageInputDispatchProps
 */
const MessageInput: React.FunctionComponent<MessageInputProps &
  MessageInputDispatchProps> = ({
  addAttachment = noop,
  attachment,
  disabled = false,
  inputText = '',
  lessonId = '',
  removeAttachment = noop,
  sendMessage = noop,
  setClientUserTyping = noop,
  updateInputText = noop,
  uploadingError,
  uploadingStatus,
  updateClientHasFocus = noop
}) => {
  const textInputRef = React.useRef<HTMLTextAreaElement | null>(null);

  // Removes attachment
  const AttachmentOnClose = () => {
    focusInput(textInputRef.current);
    removeAttachment({ lessonId });
  };

  React.useEffect(() => {
    const SKIP_LEVEL_REFS = 'SKIP_LEVEL_REFS';
    config.set(SKIP_LEVEL_REFS, {
      ...config.get(SKIP_LEVEL_REFS),
      MESSAGE_COMPOSER: textInputRef.current
    });
  }, []);

  // Fires the updateClientHasFocus action with a truthy value
  const handleUpdateClientisFocused = () => {
    updateClientHasFocus({
      clientHasFocus: true
    });
  };

  // Fires the updateClientHasFocus action with a falsy value
  const handleUpdateClientisBlurred = () => {
    updateClientHasFocus({
      clientHasFocus: false
    });
  };

  /**
   * updateMessage
   * @params - ChangeEvent
   */
  const updateMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const message = e.target.value;

    // Prevents updateMessage action from firing after sendMessageHandler resets local state, which
    // otherwise leaves only the newline (enter) character, thus preventing input from clearing fully
    // on sendMessage. This also prevents typing status from remaining true after message sent
    if (!newLineRegex.test(message)) {
      setClientUserTyping({
        isTyping: message !== '',
        lessonId
      });
      updateInputText({
        inputText: message,
        lessonId
      });
    }
  };

  /**
   * sendMessageHandler - handles `setClientUserTyping` and `sendMessage` actions
   * @params - ChangeEvent
   */
  const sendMessageHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      setClientUserTyping({ lessonId, isTyping: false });
      sendMessage({ text: inputText, lessonId });

      /**
       * This is to stop '\n' from being added
       * to the textarea when a bad image is added
       */
      if (e.preventDefault) {
        e.preventDefault();
      }
    }
  };

  return (
    <MessageInputWrapper>
      <UploadStatusWrapper
        uploadingError={uploadingError}
        attachment={attachment}
        uploadingStatus={uploadingStatus}
      />
      <InputAreaWrapper
        disabled={disabled}
        maxHeight={maxHeight}
        attachment={attachment}
        attachmentOnClose={AttachmentOnClose}
      >
        <TextInput
          maxHeight={maxHeight}
          disabled={disabled}
          inputText={inputText}
          textInputRef={textInputRef}
          handleUpdateClientisFocused={handleUpdateClientisFocused}
          handleUpdateClientisBlurred={handleUpdateClientisBlurred}
          sendMessageHandler={sendMessageHandler}
          updateMessage={updateMessage}
          attachment={attachment}
          lessonId={lessonId}
          addAttachment={addAttachment}
        />
      </InputAreaWrapper>
    </MessageInputWrapper>
  );
};

export default MessageInput;
