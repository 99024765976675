import { createLogic } from 'redux-logic';
import { captureError } from '@chegg-tutors-chat/shared/utils';
import { addAdminMessage, addMessage, AddMessagePayload } from './actions';

// Logic to handle processing (transforms) for new messages
export const addMessageLogic = createLogic<GlobalState, AddMessagePayload>({
  transform: async ({ action }, next) => {
    const {
      payload: { message }
    } = action;
    if (message.data) {
      try {
        // message.data from sendbird is initially a string, so we must parse it to get an object
        // https://docs.sendbird.com/platform/messages#_4_resource_representation
        const messagePayload = {
          ...message,
          data: JSON.parse(message.data as string)
        };
        action.payload.message = messagePayload;
      } catch (e) {
        const error = new Error(`Unable to parse sendbird message data ${message.data}`);
        captureError(error);
      }
    }
    next(action);
  },
  type: [addMessage.type, addAdminMessage.type]
});

export default [addMessageLogic];
