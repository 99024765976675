import { connect } from 'react-redux';
import { getUser } from '@chegg-tutors-chat/shared/redux/modules/user/selectors';
import PrintPreview from './PrintPreview';

export interface PrintPropsFromState {
  user: UserState;
}

export function mapStateToProps(state: GlobalState): PrintPropsFromState {
  const user = getUser(state);
  return {
    user
  };
}

export default connect(mapStateToProps, null)(PrintPreview);
