import { AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '@chegg-tutors-chat/shared/config';
import { setBffRequestError } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import rootStore from '@chegg-tutors-chat/shared/redux/rootStore';
import { captureError, captureTimeoutError } from '@chegg-tutors-chat/shared/utils/';
import genericRequest from '@chegg-tutors-chat/shared/utils/genericRequest';
import { RESPONSE_STATUS_CODE } from '../../constants';

const dispatchBffErrorCode = (code: number) => {
  const store = rootStore.getStore();
  store.dispatch(setBffRequestError({ code }));
};

// Contains errorCodes from bff which should dispatch an error alert action
const errorCodes = [RESPONSE_STATUS_CODE.UNAUTHORIZED];

/**
 *
 * @param axiosConfig - axios configuration object
 * @returns void if client is undefined or a Promise with bffResponse
 */
const bffRequest = <T>(axiosConfig: AxiosRequestConfig) => {
  async function task(): Promise<void | AxiosResponse<T>> {
    const client = config.get('AXIOS_CLIENT');
    if (client === undefined) {
      throw new Error('axios client was not initialized');
    }
    try {
      return await client.request(axiosConfig);
    } catch (e) {
      // dispatch bffErrorCode action if status codoe matches
      if (e.response && errorCodes.includes(e.response.status)) {
        dispatchBffErrorCode(e.response.status);
      }
      captureError(e);
    }
  }

  return genericRequest(task, captureTimeoutError(axiosConfig.url));
};

export default bffRequest;
