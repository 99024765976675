import * as React from 'react';
import * as Sentry from '@sentry/browser';

class SentryComponent extends React.Component<{}> {
  public componentDidCatch(error: Error | null, errorInfo: object) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  public render() {
    return this.props.children;
  }
}

export default SentryComponent;
