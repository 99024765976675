import config from '@chegg-tutors-chat/shared/config';
import { OPTIMIZELY_SDK_KEY } from '@chegg-tutors-chat/shared/constants';
import { createInstance as CreateJavascriptSdkInstance } from '@optimizely/optimizely-sdk';
import { createInstance as createReactSdkInstance } from '@optimizely/react-sdk';

const setOptimizelyConfig = () => {
  config.set('OPTIMIZELY', {
    REACT: createReactSdkInstance({
      sdkKey: OPTIMIZELY_SDK_KEY
    }),
    JAVASCRIPT: CreateJavascriptSdkInstance({
      sdkKey: OPTIMIZELY_SDK_KEY
    })
  });
};

export default setOptimizelyConfig;
