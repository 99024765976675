import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import { PrimaryButton } from '@chegg/fanta';

export const PanelHeader = styled.div`
  border-bottom: 1px solid ${COLORS.CCCCCC};
  & > :first-child {
    font-weight: bold;
    padding: 14px 20px;
    margin: 0;
  }
`;

export const PanelContent = styled.div``;

export const Panel = styled.div`
  border-right: 1px solid ${COLORS.CCCCCC};
  width: 379px;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(PrimaryButton)`
  padding-left: 20px;
  padding-right: 20px;
`;

export const SendToChatLayout = styled.span`
  position: absolute;
`;

export const SendToPadLayout = styled.span`
  float: right;
`;

export const ButtonWrapper = styled.div`
  flex-shrink: 0;
  padding: 20px;
  background-color: ${COLORS.F5F5F5};
  border-top: 1px solid ${COLORS.CCCCCC};
`;
