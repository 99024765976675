import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

/*
 * MessageAttachmentPreviewProps
 *
 * @prop src - url of attachment
 */
interface MessageAttachmentPreviewProps {
  src: string;
}

export const ImageWrapper = styled.button`
  background: transparent;
  border: none;
  padding: 0;
`;

export const ImagePreview = styled.img.attrs<MessageAttachmentPreviewProps>(
  (props: MessageAttachmentPreviewProps) => {
    return {
      src: props.src
    };
  }
)`
  overflow: hidden;
  max-width: 100%;
  max-height: 200px;
  border: 1px solid ${COLORS.CCCCCC};
  cursor: pointer;
`;
