import styled from 'styled-components';
import color from '@chegg-tutors-chat/shared/styled/constants/colors';
import { fanta } from '@chegg/fanta';

export const Wrapper = styled.div`
  [role='dialog'] {
    background-color: ${fanta.colors.coralXlight};
  }
`;

export const Text = styled.p`
  height: 40px;
  color: ${color['000000']};
  font-weight: 500;
  line-height: 20px;
`;
