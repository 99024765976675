import * as React from 'react';
import FileAttachmentLink from '@chegg-tutors-chat/shared/components/FileAttachmentLink';
import { MODAL } from '@chegg-tutors-chat/shared/constants';
import { ToggleModalPayload } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { isImg, noop } from '@chegg-tutors-chat/shared/utils';
import { ImagePreview, ImageWrapper } from './styled';

export interface MessageAttachmentDispatchProps {
  showModal: (payload: ToggleModalPayload) => void;
}

export interface MessageAttachmentProps {
  fileName: Message['fileName'];
  fileType?: Message['fileType'];
  fileUrl: Message['fileUrl'];
  onAttachmentLoad?: () => void;
}

const determineFileType = (fileName: string) => {
  const strArr = fileName.split('.');
  const fileExtension = strArr[strArr.length - 1].toLowerCase();
  return isImg(fileExtension) ? 'image' : 'other';
};

/**
 * @component MessageAttachment - renders a message attachment
 *
 * @prop fileName - name of the file
 * @prop fileType - type of the file
 * @prop fileUrl - url to the file
 * @prop showModal - action that shows a modal
 * @prop onAttachmentLoad - action that fires when an image loads
 */
const MessageAttachment: React.SFC<MessageAttachmentDispatchProps &
  MessageAttachmentProps> = ({
  fileName = '',
  fileType,
  fileUrl = '',
  onAttachmentLoad = noop,
  showModal
}) => {
  fileType = fileType || determineFileType(fileName);

  const openImageModal = () => {
    showModal({
      modalName: MODAL.IMAGE,
      modalOptions: {
        imageSrc: fileUrl
      }
    });
  };

  const openImageModalOnEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      openImageModal();
    }
  };

  return fileType.indexOf('image') === -1 ? (
    <FileAttachmentLink fileName={fileName} fileUrl={fileUrl} />
  ) : (
    <ImageWrapper onClick={openImageModal} onKeyPress={openImageModalOnEnter}>
      <ImagePreview
        src={fileUrl}
        alt="Image uploaded by user"
        onLoad={onAttachmentLoad}
      />
    </ImageWrapper>
  );
};

export default MessageAttachment;
