import { ErrorMessage, Field, Formik, FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
import { RadioButton } from '@chegg-tutors-chat/shared/components/Forms/Fields/RadioButtons';
import StarRating from '@chegg-tutors-chat/shared/components/Forms/Fields/StarRating';
import {
  Field as FormField,
  Label
} from '@chegg-tutors-chat/shared/components/Forms/styled';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import ModalFooter from '@chegg-tutors-chat/shared/components/Modal/ModalFooter';
import { noop } from '@chegg-tutors-chat/shared/utils';
import { Modal, PrimaryButton } from '@chegg/fanta';
import {
  AdditionalInfo,
  FooterButtonsWrapper,
  Form,
  RequiredErrorMessage
} from './styled';

export interface LessonFeedbackFormDispatchProps {
  handleCloseModal?: () => void;
  handleDownloadTranscript?: () => void;
  handleUpdateLessonStatus?: () => void;
  handleSubmitLessonFeedback?: (lessonId: Lesson['id'], feedBack: LessonFeedBack) => void;
}

export interface LessonFeedbackFormProps extends BaseModalProps {
  modalOptions: {
    lessonId: Lesson['id'];
  };
  onSubmit: () => void;
  tutorName: string;
  feedbackSubmitted: boolean;
}

const tutorRating = 'tutorRating';
const platformRating = 'platformRating';
const wouldYouTryAgain = 'wouldYouTryAgain';

export enum LessonFeedbackFormErrors {
  missingStarsSelection = 'Required',
  missingRadiosSelection = 'Required'
}

const LessonFeedbackRadios = [
  { id: 1, buttonText: 'Yes' },
  { id: 2, buttonText: 'No' },
  { id: 3, buttonText: 'Not sure' }
];

/**
 * Function to validate stars were selected. Initial value is 0; hence, we are checking
 * whether the value is 0.
 *
 * Value comes in as a string and to convert it to a number we have to cast it to Number()
 * see https://stackoverflow.com/questions/23437476/in-typescript-how-to-check-if-a-string-is-numeric/23440948#23440948
 *
 * @param value
 */
export function validateStarsSelected(
  value: string
): LessonFeedbackFormErrors | undefined {
  let error;
  if (Number(value) === 0) {
    error = LessonFeedbackFormErrors.missingStarsSelection;
  }
  return error;
}

/**
 * Function to validate radios were selected. Initial value is 0; hence, we are checking
 * whether the value is 0.
 *
 * @param value
 */
export function lessonFeedBackRadiosSelected(
  value: string
): LessonFeedbackFormErrors | undefined {
  let error;
  if (Number(value) === 0) {
    error = LessonFeedbackFormErrors.missingRadiosSelection;
  }
  return error;
}

// helper function which updates aria-invalid to true if an error is detected in forms while submitting;

export const isAriaInvalid = (
  errors: FormikErrors<LessonFeedBack>,
  touched: FormikTouched<LessonFeedBack>,
  ariaPosition: string
): boolean | undefined => {
  let val: boolean = false;
  if (errors[ariaPosition] && touched[ariaPosition]) {
    val = true;
  }
  return val;
};

const LessonFeedback: React.SFC<LessonFeedbackFormProps &
  LessonFeedbackFormDispatchProps> = ({
  handleCloseModal = noop,
  handleUpdateLessonStatus = noop,
  handleSubmitLessonFeedback = noop,
  isOpen,
  modalOptions: { lessonId },
  onSubmit = noop,
  tutorName,
  feedbackSubmitted,
  ...restProps
}: LessonFeedbackFormProps & LessonFeedbackFormDispatchProps) => {
  if (!tutorName) {
    tutorName = 'your tutor';
  }

  // helper function disables form submit if form isSubmitting, hasn't been interacted with or has errors
  const shouldDisableSubmit = (
    isSubmitting: boolean,
    dirty: boolean,
    errors: FormikErrors<LessonFeedBack>
  ): boolean => isSubmitting || !dirty || Object.keys(errors).length > 0;

  // Block rendering if feedback has already been submitted
  return !feedbackSubmitted ? (
    <Modal isOpen={isOpen} headerText={'How did it go?'} {...restProps}>
      <Formik
        initialValues={{
          additionalText: '',
          platformRating: 0,
          tutorRating: 0,
          wouldYouTryAgain: ''
        }}
        onSubmit={(values, actions) => {
          const feedback: LessonFeedBack = {
            additionalText: values.additionalText,
            platformRating: values.platformRating.toString(),
            tutorRating: values.tutorRating.toString(),
            wouldYouTryAgain: values.wouldYouTryAgain
          };
          handleSubmitLessonFeedback(lessonId, feedback);
          handleUpdateLessonStatus();
          actions.setSubmitting(false);
          handleCloseModal();
          onSubmit();
        }}
        render={({
          dirty,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          errors
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField
              tabIndex={0}
              aria-label={`How would you rate ${tutorName}?`}
              aria-invalid={isAriaInvalid(errors, touched, tutorRating)}
            >
              <Label htmlFor={tutorRating}>{`How would you rate ${tutorName}?`}</Label>
              <Field
                id={tutorRating}
                name={tutorRating}
                component={StarRating}
                validate={validateStarsSelected}
              />
              <ErrorMessage name={tutorRating} component={RequiredErrorMessage} />
            </FormField>
            <FormField
              tabIndex={0}
              aria-label="How would you rate the platform and tools?"
              aria-invalid={isAriaInvalid(errors, touched, platformRating)}
            >
              <Label htmlFor={platformRating}>
                How would you rate the platform and tools?
              </Label>
              <Field
                id={platformRating}
                name={platformRating}
                component={StarRating}
                validate={validateStarsSelected}
              />
              <ErrorMessage name={platformRating} component={RequiredErrorMessage} />
            </FormField>
            <FormField>
              <Label id="announcement">Would you try this experience again?</Label>
              <div
                role="group"
                aria-labelledby="announcement"
                aria-invalid={isAriaInvalid(errors, touched, wouldYouTryAgain)}
              >
                {LessonFeedbackRadios.map(({ id, buttonText }) => (
                  <span key={id}>
                    <Field
                      tabIndex={0}
                      id={`${wouldYouTryAgain}_${id}`}
                      component={RadioButton}
                      name={wouldYouTryAgain}
                      type={'radio'}
                      aria-label={`buttonText`}
                      value={buttonText}
                      validate={lessonFeedBackRadiosSelected}
                    >
                      {buttonText}
                    </Field>
                  </span>
                ))}
                <ErrorMessage name={wouldYouTryAgain} component={RequiredErrorMessage} />
              </div>
            </FormField>
            <FormField>
              <Label htmlFor="additionalText">
                Is there anything else we should know? (optional)
              </Label>
              <Field
                component={AdditionalInfo}
                id="additionalText"
                name="additionalText"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormField>
            <span>
              <FooterButtonsWrapper>
                <ModalFooter>
                  <PrimaryButton
                    aria-label="submiting feedback"
                    aria-disabled={shouldDisableSubmit(isSubmitting, dirty, errors)}
                    size={'medium'}
                    data-testid="submit-button"
                    type="submit"
                  >
                    Submit feedback
                  </PrimaryButton>
                </ModalFooter>
              </FooterButtonsWrapper>
            </span>
          </Form>
        )}
      />
    </Modal>
  ) : null;
};

export default LessonFeedback;
