import * as React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const ModalWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TextLayout = styled.span`
  color: ${COLORS['767676']};
  margin-left: 40px;
  width: 236px;
  text-align: right;
  display: inline-block;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    margin-bottom: 10px;
    margin-left: 0px;
    text-align: left;
  }
`;

interface FooterButtonProps {
  children: React.ReactNode | React.ReactNode[];
  onlyHasOneButton: boolean;
}

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  > button,
  > a {
    margin-left: 10px;
  }
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    ${(props: FooterButtonProps) =>
      props.onlyHasOneButton ? 'flex-direction: column;' : ''}
    > button {
      ${(props: FooterButtonProps) => (props.onlyHasOneButton ? 'margin-left: 0px;' : '')}
    }
    > a {
      ${(props: FooterButtonProps) => (props.onlyHasOneButton ? 'display: block;' : '')}
      ${(props: FooterButtonProps) => (props.onlyHasOneButton ? 'margin-left: 0px;' : '')}
      ${(props: FooterButtonProps) => (props.onlyHasOneButton ? 'width: 100%;' : '')}
    }
  }
`;
