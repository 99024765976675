import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import freeze from 'redux-freeze';
import { load, save } from 'redux-localstorage-simple';
import { createLogicMiddleware } from 'redux-logic';
import bffLogic from 'src/logics/bffLogic';
import studentLogic from 'src/logics/logic';
import announcementReducer from '@chegg-tutors-chat/shared/redux/modules/announcement/reducers';
import clientReducer from '@chegg-tutors-chat/shared/redux/modules/client/reducers';
import reducer from '@chegg-tutors-chat/shared/redux/modules/lessons/reducers';
import messagesReducer from '@chegg-tutors-chat/shared/redux/modules/messages/reducers';
import modalsReducer from '@chegg-tutors-chat/shared/redux/modules/modals/reducers';
import pinReducer from '@chegg-tutors-chat/shared/redux/modules/pins/reducers';
import userReducer from '@chegg-tutors-chat/shared/redux/modules/user/reducers';
import rootLogic from '@chegg-tutors-chat/shared/redux/rootLogic';
import rootStore from '@chegg-tutors-chat/shared/redux/rootStore';

const reducers = combineReducers({
  announcement: announcementReducer,
  client: clientReducer,
  lessons: reducer,
  messages: messagesReducer,
  modals: modalsReducer,
  pins: pinReducer,
  user: userReducer
});

const logicMiddleware = createLogicMiddleware([
  ...rootLogic,
  ...studentLogic,
  ...bffLogic
]);

const reduxMiddleware = [logicMiddleware, save({ states: ['pins'] })];
// Only freeze on dev/test mode to improve performance in production
if (process.env.NODE_ENV !== 'production') {
  reduxMiddleware.push(freeze);
}

/**
 * Save method preserves the pinstate in local storage. Can be modified
 * to preserve more of the state.
 */
const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...reduxMiddleware))
);

/**
 * Load method to get saved localstorage state for our pins module.
 */
load({ states: ['pins'] });

rootStore.setStore(store);

export default store;
