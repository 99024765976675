import Sentry from '@chegg-tutors-chat/shared/providers/sentry';

/*
This is to filter all events when we are running in localhost.
Returning null from beforeSend is supposed todo this.
*/
const beforeSend = process.env.REACT_APP_IS_OFFLINE ? () => null : (event: any) => event;
export default function setSentryConfig() {
  Sentry({
    beforeSend,
    dsn: 'https://1c856b888581434780f6f3151de691ae@sentry.io/1387729'
  });
}
