import * as React from 'react';
import { getMinutesRemainingText } from '@chegg-tutors-chat/shared/utils/getMinutesRemainingText';
import { Layout, Text, TextWrapper, TimerText } from './styled';

export interface CountDownMessageProps {
  timeRemaining: number;
}

/**
 * TimeRemainingWarning is used to change the background and font color of the message.
 */
export const TIME_REMAINING_WARNING: number = 1;

/**
 * CountDownMessage - renders a TimerMessage.
 *
 * @param MessageProps
 */
const CountDownMessage: React.FunctionComponent<CountDownMessageProps> = ({
  timeRemaining
}) => {
  const lessonEnding = timeRemaining === TIME_REMAINING_WARNING;
  const minuteText = timeRemaining === 1 ? 'minute' : 'minutes';
  return (
    <Layout
      lessonEnding={lessonEnding}
      tabIndex={0}
      aria-label={getMinutesRemainingText(timeRemaining)}
    >
      <TextWrapper>
        <TimerText>
          {timeRemaining} {minuteText}
        </TimerText>
        <Text>remaining</Text>
      </TextWrapper>
    </Layout>
  );
};

export default CountDownMessage;
