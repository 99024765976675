import axios from 'axios';
import * as React from 'react';
import { ATTACHMENT_ORIGIN_TYPE } from '@chegg-tutors-chat/shared/constants';
import { captureError } from '@chegg-tutors-chat/shared/utils';
import { PrimaryButton } from '@chegg/fanta';
import { MathTypeWrapper, SendToChatLayout, SendToPadLayout } from './styled';

const switchPipes = /\>\&\#xF402\;\</g;
const hashTag = /\#/g;
const pipeEntity = '>&#124;<';
declare let com: any;
/**
 * This is to remove and fix some problems fabric has with
 * the math svg
 *
 * 1) empty styletags
 * 2) the `|` entity in the svg doesn't work on fabric. Changing
 * it to the html verticle line entity.
 * @param svg the svg html to clean
 */
function cleanSVG(svg: string): string {
  return svg.replace('<style type="text/css"/>', '').replace(switchPipes, pipeEntity);
}
interface Props {
  onSVG: (svg: string) => void;
  addAttachment: (p: any) => void;
  sendImageToScratchPad: (payload: SendImageToScratchPadPayload) => void;
  lessonId: string;
}

const mathStyles = {
  height: '250px',
  margin: '10px 5px'
};

export enum TARGET {
  input = 'input',
  scratchpad = 'scratchpad'
}

/**
 * For now we are defaulting all images to SVGs
 */
const IMAGE_TYPE = 'image/svg';

/**
 * Formula -  a placeholder container for MathType keyboard.
 */

class Formula extends React.Component<Props> {
  private editor: any;
  private editorEle = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    try {
      this.editor = com.wiris.jsEditor.JsEditor.newInstance({ language: 'en' });
      this.editor.insertInto(this.editorEle.current);
    } catch (e) {
      captureError(e);
    }
  }

  public render() {
    /**
     * Make sure MathType is loaded before the element is rendered
     * If not it will cause a problem in the componentDidMount when
     * we try add MathType to the element.
     */
    if (!window.com) {
      return null;
    }
    return (
      <React.Fragment>
        <MathTypeWrapper>
          <div ref={this.editorEle} style={mathStyles} />
        </MathTypeWrapper>
        <SendToPadLayout>
          <PrimaryButton size={'medium'} onClick={this.save}>
            Send to pad
          </PrimaryButton>
        </SendToPadLayout>
        <SendToChatLayout>
          <PrimaryButton size={'medium'} onClick={this.addAttachment}>
            Send to chat
          </PrimaryButton>
        </SendToChatLayout>
      </React.Fragment>
    );
  }

  /**
   * Using this function to add attachment to the store and
   * additionally fire off the addAttachment action to track
   * when the Math tool adds an image into the canvas or chat.
   *
   */
  private addAttachmentHandler = (target: TARGET, fileSrc?: string) => {
    this.props.addAttachment({
      fileSrc,
      lessonId: this.props.lessonId,
      originType: ATTACHMENT_ORIGIN_TYPE.MATH_TOOL,
      target,
      type: IMAGE_TYPE
    });
  };

  private save = () => {
    const mml = this.editor.getMathML();

    axios
      .get('//www.wiris.net/demo/editor/render.svg', {
        params: {
          mml
        }
      })
      .then(response => {
        this.props.onSVG(cleanSVG(response.data));
        this.addAttachmentHandler(TARGET.scratchpad);
      });
  };
  private addAttachment = () => {
    const mml = this.editor.getMathML();

    axios
      .get('//www.wiris.net/demo/editor/render.svg', {
        params: {
          mml
        }
      })
      .then(response => {
        // The hashTag was causing the SVG to not be rendered properly
        // changing it to url encoding worked.
        const fileSrc =
          'data:image/svg+xml;utf8,' + cleanSVG(response.data).replace(hashTag, '%23');

        this.addAttachmentHandler(TARGET.input, fileSrc);
      });
  };
}

export default Formula;
