import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import font from '@chegg-tutors-chat/shared/styled/constants/fonts';

export interface MessageProps {
  lessonEnding: boolean;
}

export const Layout = styled.div`
  height: 32px;
  text-align: center;
  background-color: ${({ lessonEnding }: MessageProps) =>
    lessonEnding ? COLORS.FFF3F2 : COLORS.F5F5F5};
  color: ${({ lessonEnding }: MessageProps) =>
    lessonEnding ? COLORS.E43F32 : COLORS['767676']};
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${COLORS.CCCCCC};
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
`;

export const TimerText = styled.span`
  font-family: ${font.AspiraBold};
  font-style: italic;
  padding-right: 4px;
`;

export const Text = styled.span`
  font-family: ${font.AspiraMedium};
  font-style: italic;
  margin-top: 1px;
`;
