import { Field, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { RadioButton } from '@chegg-tutors-chat/shared/components/Forms/Fields/RadioButtons';
import {
  Field as FormField,
  Label
} from '@chegg-tutors-chat/shared/components/Forms/styled';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import ModalFooter from '@chegg-tutors-chat/shared/components/Modal/ModalFooter';
import {
  ReportLessonPayload,
  ReportReason
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { Modal, PrimaryButton } from '@chegg/fanta';
import HCVInfoBannerContainer from './HCVInfoBannerContainer';
import { AdditionalInfo, FooterButtonsWrapper, Form } from './styled';

export interface ReportModalDispatchProps {
  handleCloseModal: () => void;
  report: (ReportLessonPayload) => void;
}
export interface ReportModalPropsFromState {
  lessonId: Lesson['id'];
}

export type ReportModalProps = BaseModalProps &
  ReportModalDispatchProps &
  ReportModalPropsFromState;

enum FormFieldsMap {
  HonorCodeViolation = 'honorCodeViolation',
  InappropriateSpam = 'inappropriateSpam',
  Other = 'other',
  TellUsMore = 'tellUsMore'
}

const ReasonToReport = 'reasonToRejectOrReroute';

const reasonsToRejectOrReroute = [
  {
    buttonText: 'There is an Honor Code violation',
    value: FormFieldsMap.HonorCodeViolation,
    hasInfoBanner: true
  },
  {
    buttonText: 'Inappropriate/Spam',
    value: FormFieldsMap.InappropriateSpam
  },
  {
    buttonText: 'Other',
    value: FormFieldsMap.Other
  }
];

// exported for unit test
export function submitHandlerFactory({
  lessonId,
  report,
  handleCloseModal
}: ReportModalProps) {
  return (values: ReportReason, actions) => {
    const { reasonToRejectOrReroute } = values;
    // Validate that a reason to reject or reroute is selected
    if (reasonToRejectOrReroute) {
      const reportPayload: ReportLessonPayload = {
        id: lessonId,
        reason: reasonToRejectOrReroute
      };
      report(reportPayload);
      handleCloseModal();
    }
    actions.setSubmitting(false);
  };
}

/**
 * if the selected radio is configred to have an info banner,
 * render the info banner beneath that radio
 */

const ReportRadioFormFields = ({ values }: { values: ReportReason }) => (
  <>
    {reasonsToRejectOrReroute.map(({ buttonText, value, hasInfoBanner }) => {
      const showInfoBanner = values.reasonToRejectOrReroute === value && hasInfoBanner;
      return (
        <FormField key={value}>
          <Field
            id={value}
            component={RadioButton}
            name={ReasonToReport}
            value={value}
            type="radio"
          >
            {buttonText}
          </Field>
          {showInfoBanner && <HCVInfoBannerContainer />}
        </FormField>
      );
    })}
  </>
);

const ReportForm: React.FunctionComponent<FormikProps<ReportReason>> = ({
  handleSubmit,
  isSubmitting,
  dirty,
  touched,
  handleChange,
  handleBlur,
  values
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <FormField>
        <ReportRadioFormFields values={values} />
      </FormField>
      <FormField>
        <Label htmlFor={FormFieldsMap.TellUsMore}>Tell us more: (optional)</Label>
        <Field
          component={AdditionalInfo}
          id={FormFieldsMap.TellUsMore}
          name={FormFieldsMap.TellUsMore}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormField>
      <FooterButtonsWrapper>
        <ModalFooter>
          <PrimaryButton
            size="medium"
            data-testid="next-button"
            type="submit"
            isDisabled={isSubmitting || !(dirty && touched[ReasonToReport])}
          >
            Report and end lesson
          </PrimaryButton>
        </ModalFooter>
      </FooterButtonsWrapper>
    </Form>
  );
};

const ReportModal: React.FunctionComponent<ReportModalProps> = props => {
  const { isOpen, ...restProps } = props;
  return (
    <Modal
      headerText="What happened?"
      isOpen={isOpen}
      subHeaderText="Please let us know why you are reporting the student."
      {...restProps}
    >
      <Formik
        initialValues={{
          reasonToRejectOrReroute: null
        }}
        onSubmit={submitHandlerFactory(props)}
        render={ReportForm}
      />
    </Modal>
  );
};

export default ReportModal;
