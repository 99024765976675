import {
  EL_REGION,
  EL_TYPE,
  EVENT_NAME
} from '@chegg-tutors-chat/shared/providers/analytics/constants';
import {
  formatAnalyticsClickEvent,
  FormatAnalyticsClickEventPayload
} from '@chegg-tutors-chat/shared/providers/analytics/utils/formatAnalyticsClickEvent';
import * as scratchPadActions from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import { clickLogicFactory } from '../utils';

/**
 * logs to analytics when a new shape is selected for the scratchpad shape tool.
 */
const trackSelectShapeToolShape = (whichShape: string, eventName: EVENT_NAME) => {
  return clickLogicFactory<SelectScratchPadShapePayload>(
    scratchPadActions.selectScratchPadShape.type,
    (state, payload) => {
      const { shape } = payload;
      if (shape && shape === whichShape) {
        const clickEventConfig: FormatAnalyticsClickEventPayload = {
          eventName,
          eventRegion: EL_REGION.scratchpad_toolbar,
          eventState: state,
          eventType: EL_TYPE.button
        };
        return formatAnalyticsClickEvent(clickEventConfig);
      } else {
        return false;
      }
    }
  );
};

/**
 * logs to analytics when a line shape is selected for the scratchpad shape tool.
 */
export const trackShapeToolLine = trackSelectShapeToolShape('line', EVENT_NAME.draw_line);

/**
 * logs to analytics when a circle shape is selected for the scratchpad shape tool.
 */
export const trackShapeToolCircle = trackSelectShapeToolShape(
  'circle',
  EVENT_NAME.draw_circle
);

/**
 * logs to analytics when a square/rectangle shape is selected for the scratchpad shape tool.
 */
export const trackShapeToolSquare = trackSelectShapeToolShape(
  // Yes, it's inconsistent.
  // Analytics wants to call is 'square', we already called it 'rectangle'
  'rectangle',
  EVENT_NAME.draw_square
);

/**
 * logs to analytics when a triangle shape is selected for the scratchpad shape tool.
 */
export const trackShapeToolTriangle = trackSelectShapeToolShape(
  'triangle',
  EVENT_NAME.draw_triangle
);

export default [
  trackShapeToolCircle,
  trackShapeToolLine,
  trackShapeToolSquare,
  trackShapeToolTriangle
];
