import UpdateYourNoteModal from './container';
import {
  UpdateYourNoteFormDispatchProps as DispatchProps,
  UpdateYourNoteFormProps as Props
} from './UpdateYourNoteForm';

export type UpdateYourNoteFormProps = Props;
export type UpdateYourNoteFormDispatchProps = DispatchProps;

export default UpdateYourNoteModal;
