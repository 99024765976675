import styled from 'styled-components';
import { Lead } from '@chegg-tutors-chat/shared/styled/sunkist/Typography';

export const RequestDescription = styled(Lead)`
  word-break: break-word;
  margin: 20px 0;
`;

export const RequestDetailsWrapper = styled.div`
  line-height: 1rem;
`;
