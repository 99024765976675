export const BFF_API_ENDPOINTS = {
  ACCEPT_LESSON: 'tutor/lesson/accept',
  CLOSE_LESSON_STUDENT: 'student/lesson/close',
  CLOSE_LESSON_TUTOR: 'tutor/lesson/close',
  CREATE_LESSON: 'student/lesson/create',
  LESSON_FEEDBACK: 'student/lesson/feedback',
  LESSON_INFO_STUDENT: 'student/lesson/info',
  LESSON_INFO_TUTOR: 'tutor/lesson/info',
  LESSON_NOTE: 'tutor/lesson/note',
  REJECT_LESSON: 'tutor/lesson/reject',
  TUTOR_AVAILABILITY: 'tutor/availability',
  TUTOR_REROUTE: 'tutor/lesson/reroute',
  TUTOR_METRICS: 'tutor/metrics',
  REPORT_STUDENT: 'tutor/report',
  TUTOR_LOGOUT: 'tutor/auth/logout'
};
