import axios from 'axios';
import { createLogic } from 'redux-logic';
import * as SendBird from 'sendbird';
import { studentLessonInfo } from 'src/api/helpers';
import { onConnect } from 'src/configs/sendbird';
import {
  AWS_API_ENDPOINTS,
  AWS_API_URL,
  LESSON_STATUS,
  MODAL
} from '@chegg-tutors-chat/shared/constants';
import initSendbird from '@chegg-tutors-chat/shared/providers/sendbird';
import { UserData } from '@chegg-tutors-chat/shared/providers/sendbird/sendbird';
import { updateAnnouncement } from '@chegg-tutors-chat/shared/redux/modules/announcement/actions';
import {
  HydrateAppPayload,
  hydrateAppState,
  increaseUnreadMessageCount,
  UnreadMessageCount
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  addLesson,
  createLesson,
  updateLessonStatus,
  UpdateLessonStatusPayload,
  updateLessonTutor,
  userLeft,
  UserLeftPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import {
  getLesson,
  getSelectedLesson,
  getTutorInfo
} from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import {
  addAdminMessage,
  addMessage,
  AddMessagePayload,
  getPastMessages,
  PastMessagesPayload
} from '@chegg-tutors-chat/shared/redux/modules/messages/actions';
import {
  showModal,
  ToggleModalPayload
} from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { addUser } from '@chegg-tutors-chat/shared/redux/modules/user/actions';
import { captureError, isErrorAction } from '@chegg-tutors-chat/shared/utils';

/**
 * Logic to increment un-read messages count in Student Client
 */
export const incrementUnreadMessageCount = createLogic<GlobalState, AddMessagePayload>({
  validate: ({ action }, allow, reject) => {
    if (document.hidden && !isErrorAction(action)) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process: ({ action }, dispatch: DispatchType<UnreadMessageCount>, done: DoneType) => {
    const { lessonId } = action.payload;
    dispatch(
      increaseUnreadMessageCount({
        lessonId
      })
    );
    done();
  },
  type: [addMessage.type, addAdminMessage.type]
});

const getTextContent = (contentStr: string, part: any) => {
  let partStr = '';
  if (part.content) {
    partStr = part.content.reduce(getTextContent, '');
  } else {
    partStr = part.text;
  }
  return contentStr + ' ' + partStr;
};

// TODO: replce with getAxiosClient
const apiClient = axios.create({
  baseURL: AWS_API_URL
});

/**
 * Helper function to create lesson request.
 * @param lessonRequestData
 */
const createLessonRequest = async (lessonRequestData: LessonRequestFormData) => {
  const { email, name, description } = lessonRequestData;
  const info = await apiClient.post(AWS_API_ENDPOINTS.CREATE_LESSON, {
    description,
    email: email || '',
    name,
    subject: 'Math'
  });
  return info.data;
};

/**
 * Logic to create Lesson.
 */
export const createLessonLogic = createLogic<GlobalState, LessonRequestFormData>({
  validate: async ({ action }, allow, reject) => {
    if (!isErrorAction(action)) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process: async ({ action }, _dispatch, done: DoneType) => {
    const data = await createLessonRequest(action.payload);
    if (data && !data.error) {
      const userData: UserData = {
        userId: data.JiraResponse.customer.accountId
      };
      initSendbird(userData, onConnect, data.SendBirdResponse.channel_url);
    }
    done();
  },
  type: createLesson.type
});

/**
 * Logic to update userLeft.
 */
export const userLeftLogic = createLogic<GlobalState, UserLeftPayload>({
  validate: ({ action }, allow, reject) => {
    if (!isErrorAction(action)) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process: async (
    { getState, action },
    dispatch: DispatchType<UpdateLessonStatusPayload>,
    done: DoneType
  ) => {
    const state = getState();
    const lesson = getSelectedLesson(state);
    const { id } = action.payload;

    /**
     * When a user leaves, it takes a short amount of time for JIRA to actually update the
     * ticketStatus. Thus we wait for 750ms before checking and updating the lessonStatus
     */
    await new Promise((resolve: any) => {
      setTimeout(async () => {
        const ticketInfo: any = await studentLessonInfo();
        let lessonStatus = LESSON_STATUS.FINDING_TUTOR;
        const tutorInfo = getTutorInfo(state, id);
        let lessonIdTutor;
        if (lesson && id) {
          lessonIdTutor = lesson[id];
        }
        if (ticketInfo && ticketInfo.jiraInfo) {
          const {
            fields: { status }
          } = ticketInfo.jiraInfo;
          lessonStatus = status.name;
        }
        dispatch(
          updateLessonStatus({
            id,
            status: lessonStatus
          })
        );
        dispatch(updateLessonTutor({ lessonId: lessonIdTutor, user: tutorInfo }));
        resolve();
      }, 750);
    });
    done();
  },
  type: userLeft.type
});

/**
 * showFeedBackModalLogic is responsible for showing the LessonFeeback { Modal } for students
 * when they haven't submitted their feedback for an ended lesson.
 */
export const showFeedBackModalLogic = createLogic<
  GlobalState,
  Lesson | UpdateLessonStatusPayload
>({
  validate: ({ action }, allow, reject) => {
    if (!isErrorAction(action)) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process: (
    { getState, action },
    dispatch: DispatchType<ToggleModalPayload>,
    done: DoneType
  ) => {
    // updateLessonStatusPayload contains only an id and a status, so we need to get the lesson
    const lesson = getLesson(getState(), action.payload.id || '');
    if (lesson && lesson.status === LESSON_STATUS.CLOSED && !lesson.feedback) {
      dispatch(showModal({ modalName: MODAL.LESSON_FEEDBACK }));
    }
    done();
  },
  type: [addLesson.type, updateLessonStatus.type]
});

/**
 * Logic to add lesson announcements.
 */
export const addLessonAnnouncementsLogic = createLogic<GlobalState, HydrateAppPayload>({
  validate: ({ action }, allow, reject) => {
    if (!isErrorAction(action)) {
      allow(action);
    } else {
      reject(action);
    }
  },
  process: (
    { getState, action },
    dispatch: DispatchType<UpdateLessonStatusPayload>,
    done: DoneType
  ) => {
    const tutor = getTutorInfo(getState(), action.payload.id || '');
    if (tutor && tutor.userId) {
      const announcement = `Chat with ${tutor.firstName} ${tutor.lastName}`;
      dispatch(updateAnnouncement({ announcement }));
    }
    // dispatch aria announcement if student is waiting for tutor
    if (action.payload.status === LESSON_STATUS.WAITING_FOR_ADMIN) {
      const announcement = 'Finding you the right expert';
      dispatch(updateAnnouncement({ announcement }));
    }
    done();
  },
  type: [addLesson.type]
});

export const hydrateAppLogic = createLogic<GlobalState, HydrateAppPayload>({
  process: (
    { action },
    dispatch: DispatchType<UserState | PastMessagesPayload>,
    done: DoneType
  ) => {
    const {
      fields: { reporter }
    } = action.payload.lessonInfo.jiraInfo;
    const [firstName, lastName] = reporter.displayName.split(' ', 2);
    const { access_token, user_id } = action.payload.lessonInfo.sendbirdUserInfo;
    dispatch(
      addUser({
        accessToken: access_token,
        available: true,
        firstName,
        lastName,
        userId: user_id
      })
    );
    const id = action.payload.id;
    const { channelUrl } = action.payload.chatInfo;
    const onConnectCb = (user: SendBird.User | undefined, channelUrl?: string) => {
      onConnect(user, channelUrl);
      dispatch(getPastMessages({ id }));
      done();
    };
    initSendbird({ userId: user_id, accessToken: access_token }, onConnectCb, channelUrl);
  },
  transform: async ({ action }, next) => {
    if (isErrorAction(action)) {
      next(action);
      return;
    }
    const ticketInfo: any = await studentLessonInfo();
    // if: we have a valid ticketInfo
    // else if: we don't have a valid ticketInfo and are processing hypdrateAppState
    if (ticketInfo && ticketInfo.jiraInfo) {
      // add lessoninfo to empty payload (HYDRRATE_APP_STATE action)
      const {
        key,
        id: jiraId,
        fields: { reporter, description, assignee, feedback_notes, subject, status }
      } = ticketInfo.jiraInfo;
      const { user_id } = ticketInfo.sendbirdUserInfo;
      /**
       * this needs to go through the content array and get all the 'text' values.
       * and returns one string of space seperated for now.
       */
      const ticketDescription = description.content
        ? description.content.reduce(getTextContent, '')
        : description;
      const [firstName, lastName] = reporter.displayName.split(' ', 2);
      if (feedback_notes) {
        /**
         * Add lesson feedback if student has already submitted it
         */
        try {
          action.payload.feedback = JSON.parse(feedback_notes);
        } catch (e) {
          const error = new Error(`Failed to parse student feedback ${e}`);
          captureError(error);
        }
      }
      action.payload = {
        chatInfo: { channelUrl: key },
        id: key,
        lessonInfo: {
          ...ticketInfo,
          hasError: false
        },
        status: status.name,
        hasSubmittedFeedback: false,
        ticketInfo: {
          id: jiraId,
          request: {
            description: ticketDescription,
            name: '',
            subject
          }
        },
        userData: {
          student: { firstName, lastName, userId: reporter.name || user_id },
          tutor: { firstName: '', lastName: '', userId: '' }
        }
      };
      /**
       * Jira only has displayName, need to split it.
       */
      if (assignee) {
        const [tFirstName, tLastName] = assignee.displayName.split(' ', 2);
        action.payload.userData.tutor = {
          firstName: tFirstName,
          lastName: tLastName,
          userId: assignee.accountId // name from Jira is a unique id...yeah weird I know.
        };
      }
    } else {
      const error = new Error('Error:ticketInfo or ticketInfo.jiraInfo is not defined');
      captureError(error);
    }
    /**
     * now that the action has been modified, we can move it on
     */
    next(action);
  },
  type: [hydrateAppState.type]
});

export default [
  hydrateAppLogic,
  createLessonLogic,
  userLeftLogic,
  showFeedBackModalLogic,
  addLessonAnnouncementsLogic,
  incrementUnreadMessageCount
];
