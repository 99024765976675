import * as React from 'react';
import MessageAttachment from '@chegg-tutors-chat/shared/components/MessageAttachment';
import PinButton from '@chegg-tutors-chat/shared/components/Pins';
import { formatDisplayName, formatTime, linkify } from '@chegg-tutors-chat/shared/utils';
import { HeadingLayout, Layout, Name, Text, Time } from './styled';

export interface PinnedMessageProps {
  message: Message;
}

const PinnedMessage: React.SFC<PinnedMessageProps> = ({ message }) => {
  const { createdAt, fileName, fileType, fileUrl, sender } = message;
  return (
    <Layout>
      <HeadingLayout>
        {sender && <Name>{formatDisplayName(sender.firstName, sender.lastName)}</Name>}
        <Time>{formatTime(createdAt)}</Time>
        <div>
          <PinButton message={message} isPinned={true} />
        </div>
      </HeadingLayout>
      <Text>{linkify(message.message, { openInNewTab: true })}</Text>
      {fileUrl && (
        <MessageAttachment fileName={fileName} fileType={fileType} fileUrl={fileUrl} />
      )}
    </Layout>
  );
};

export default PinnedMessage;
