import analyticsLogic from '@chegg-tutors-chat/shared/providers/analytics/logic';
import sendbirdLogic from '@chegg-tutors-chat/shared/providers/sendbird/logic';
import actionValidatorLogic from '@chegg-tutors-chat/shared/redux/actionValidatorLogic';
import announcementLogic from './modules/announcement/logic';
import clientLogic from './modules/client/logic';
import lessonsLogic from './modules/lessons/logic';
import messagesLogic from './modules/messages/logic';
import pinLogic from './modules/pins/logic';

/*
 * This is the root logic.
 * Import all redux logics from modules in this file.
 */
// actionValidatorLogic need to be the first logic
const rootLogic = [...actionValidatorLogic];

rootLogic.push(
  ...messagesLogic,
  ...analyticsLogic,
  ...announcementLogic,
  ...clientLogic,
  ...lessonsLogic,
  ...sendbirdLogic,
  ...pinLogic
);

export default rootLogic;
