export const getPins = (state: Partial<GlobalState>): PinState =>
  state.pins || ({} as PinState);

/**
 * Returns a boolean value whether a message has been pinned.
 *
 * @param state
 * @param lessonId
 * @param messageId
 */
export const isMessagePinned = (
  state: Partial<GlobalState>,
  lessonId: Lesson['id'],
  messageId: Message['messageId']
): boolean => {
  const pinState = getPins(state);
  if (
    lessonId == null ||
    messageId == null ||
    pinState == null ||
    pinState[lessonId] == null
  ) {
    // checking for null or undefined lessonId, messageId, pinState, or pinned message
    return false;
  }
  return pinState[lessonId][messageId] == null ? false : true;
};

/**
 * Converts the pinned messages to an array and then sorts by
 * timestamp (highest value being most recent).
 * Returns an array which with the most recent message at beginning.
 *
 * @param state
 * @param lessonId
 */
export const getPinsByDate = (
  state: Partial<GlobalState>,
  lessonId: Lesson['id']
): PinnedMessage[] => {
  const pinState = getPins(state);
  if (!lessonId || !pinState || !pinState[lessonId]) {
    return [];
  }

  const pinnedMessages = pinState[lessonId];

  const arrayOfPinnedMessages = (Object as any)
    .values(pinnedMessages)
    .sort((a: PinnedMessage, b: PinnedMessage) => b.pinTime - a.pinTime);

  return arrayOfPinnedMessages;
};
