import { connect } from 'react-redux';
import {
  HydrateAppPayload,
  hydrateAppState
} from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { getUser } from '@chegg-tutors-chat/shared/redux/modules/user/selectors';
import Layout from './Layout';

export interface LayoutPropsFromState {
  isLoading: boolean;
  hasError: boolean;
  user: UserState;
}

export interface LayoutPropsFromDispatch {
  hydrateAppState: (payload?: HydrateAppPayload) => void;
}

export function mapStateToProps(state: GlobalState): LayoutPropsFromState {
  const lessonId = getSelectedLessonId(state);
  const clientLessonInfo = getClientInfo(state, lessonId);
  const user = getUser(state);
  // initial hasError & isLoading in case they're not provided by the store
  let hasError = false;
  let isLoading = true;
  if (clientLessonInfo && clientLessonInfo.lessonInfo) {
    hasError = clientLessonInfo.lessonInfo.hasError;
    isLoading = false;
  }
  return {
    hasError,
    isLoading,
    user
  };
}

const mapDispatchToProps: LayoutPropsFromDispatch = {
  hydrateAppState
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
