import { setTagToSentry } from '@chegg-tutors-chat/shared/utils/sentry';
import { init } from '@sentry/browser';

export const TEST_ENV = 'test';
export const PROD_ENV = 'production';
export const DEV_ENV = 'development';

interface Params {
  /**
   * This is a string from Sentry
   */
  dsn: string;

  beforeSend?: any;
}

export function getSentryEnviroment(url: string = window.location.host) {
  if (url.indexOf('test.cheggnet.com') > 0 || url.indexOf('test.cheggcdn.com') > 0) {
    return TEST_ENV;
  } else if (url.indexOf('chegg.com') > 0) {
    return PROD_ENV;
  } else {
    return DEV_ENV;
  }
}

export default ({ dsn, beforeSend }: Params) => {
  if (dsn) {
    init({
      beforeSend,
      dsn,
      environment: getSentryEnviroment()
    });

    // Set a reasonable default before we have a lesson id
    setTagToSentry('current_lesson_id', 'NO_LESSON');
    setTagToSentry('BUILD_DATE', window.clientBuildDate);
  } else {
    throw new Error('Sentry needs a dsn set before init.');
  }
};
