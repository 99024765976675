import { connect } from 'react-redux';
import config from '@chegg-tutors-chat/shared/config';
import { toggleTool } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  getClientInfo,
  getOpenTool
} from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import Toolbar, { ToolbarDispatchProps } from './Toolbar';

export function mapStateToProps(state: GlobalState) {
  const currentLessonId = getSelectedLessonId(state);
  const clientLessonInfo = getClientInfo(state, currentLessonId);
  const tools = config.get('TOOLS');
  return {
    lessonId: currentLessonId,
    openTool: getOpenTool(clientLessonInfo),
    tools: Object.keys(tools).map((tool: string) => tools[tool])
  };
}

export const mapDispatchToProps: ToolbarDispatchProps = {
  toggleTool
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
