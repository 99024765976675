import { connect } from 'react-redux';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { transcriptDownload } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getSelectedLesson } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { showModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import LessonExitLinks, {
  LessonExitLinksDispatchProps,
  LessonExitLinksProps
} from './LessonExitLinks';

export function mapStateToProps(state: GlobalState): LessonExitLinksProps {
  const currentLesson = getSelectedLesson(state) || {
    id: '',
    status: LESSON_STATUS.YET_TO_BE_DETERMINED,
    userData: {
      tutor: undefined
    }
  };
  const { id: lessonId, status: lessonStatus } = currentLesson;

  return {
    lessonId,
    lessonStatus
  };
}

export const mapDispatchToProps: LessonExitLinksDispatchProps = {
  downloadChatTranscript: transcriptDownload,
  showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonExitLinks);
