import { createSelector } from 'reselect';

/**
 * Gets all the messages.
 *
 * @param messages
 */

export const getConversations = (state: Partial<GlobalState>) => state.messages;

/**
 * Gets a specific messages list for a Lesson.
 */

export const getLessonMessages = (
  state: Partial<GlobalState>,
  lessonId: string
): MessageState['x'] => {
  const messages = getConversations(state);
  if (messages && lessonId) {
    return messages[lessonId];
  }
  return [];
};

/**
 * Gets last message for a conversation
 *
 * @param messages
 * @param lessonId
 */

export const getLastMessage = createSelector([getLessonMessages], conversation => {
  return conversation ? conversation[conversation.length - 1] : undefined;
});
