import * as React from 'react';
import { captureError, handleFiles, noop } from '@chegg-tutors-chat/shared/utils';
import handleItems from '@chegg-tutors-chat/shared/utils/itemHandler';
import {
  DragAttachmentDispatchProps,
  DragAttachmentMapStateProps
} from '../DragAttachment/container';

/**
 * @prop onText - callback to be executed when text is dropped
 * @prop handleWindowPaste - should we handle the window on-paste event
 */
export interface FileTargetComponentProps {
  handleWindowPaste: boolean;
  onText: (i: string) => void;
}

export type FileTargetProps = FileTargetComponentProps &
  DragAttachmentDispatchProps &
  DragAttachmentMapStateProps;

/**
 * FileTarget - dom element to handle uploads
 *
 * @props - FileTargetProps
 */
const FileTarget: React.FunctionComponent<Partial<FileTargetProps>> = ({
  handleWindowPaste = false,
  onFile = noop,
  onImage = noop,
  onText = noop,
  ...remainingProps
}) => {
  const fileReader = new FileReader();

  const fileError = (error: any) => {
    captureError(error);
  };
  const fileRead = (event: ProgressEvent<FileReader>) => {
    const imgEle = new Image();
    imgEle.onload = () => {
      onImage(imgEle);
    };
    imgEle.src = event.target.result as string;
  };

  const onDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleFiles(files, fileReader, onFile);
    const items = e.dataTransfer.items;
    handleItems(items, onImage);
  };

  const preventDefault = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const onPaste = (e: React.ClipboardEvent | Event) => {
    const event = e as ClipboardEvent;
    if (event && event.clipboardData && event.clipboardData.files.length) {
      e.preventDefault();
      e.stopPropagation();
      handleFiles(event.clipboardData.files, fileReader, onFile);
    } else if (event && event.clipboardData && event.clipboardData.getData('Text')) {
      /**
       * This is to stop every 'paste' event from being handled by the window
       * event listener
       */
      if (e.currentTarget !== window) {
        e.stopPropagation();
      }
      onText(event.clipboardData.getData('Text'));
    }
  };

  React.useEffect(() => {
    fileReader.onload = fileRead;
    fileReader.onerror = fileError;
    if (handleWindowPaste) {
      window.addEventListener('paste', onPaste);
    }
  }, [handleWindowPaste, fileRead, fileError]);

  const styles = remainingProps.style;
  return (
    <div style={styles} onDragOver={preventDefault} onDrop={onDrop} onPaste={onPaste}>
      {remainingProps.children}
    </div>
  );
};

export default FileTarget;
