import { fabric } from 'fabric';
import * as React from 'react';
import { SCRATCHPAD_TOOL_NAME } from '@chegg-tutors-chat/shared/constants';
import { noop } from '@chegg-tutors-chat/shared/utils';
import { FabricCanvasStateType } from './ScratchPad';

const TOOLBAR_HEIGHT = 35;

/**
 * @prop - onScreenShotCrop - callback function after capturing screenshot.
 * @prop - onSelection - callback function for selecting a canvas tool.
 * @prop - onSelectionCleared - callback function to clear the selection.
 */
interface Props {
  onScreenShotCrop: (x: string) => void;
  onSelection: (x: any) => void;
  onSelectionCleared: () => void;
  onFocus: () => void;
}
function setAllObjectsLocked(fabricCanvas: any, locked: boolean) {
  fabricCanvas.getObjects().forEach((obj: any) => {
    obj.set('lockMovementX', locked);
    obj.set('lockMovementY', locked);
    obj.set('lockRotation', locked);
    obj.set('lockScalingFlip', locked);
    obj.set('lockScalingX', locked);
    obj.set('lockScalingY', locked);
    obj.set('lockSkewingX', locked);
    obj.set('lockSkewingY', locked);
    obj.set('lockUniScaling', locked);
    obj.set('selectable', !locked);
  });
}

function restrictMovementToViewableArea(this: any) {
  const activeObj = this.getActiveObject();
  const canvasWidth = this.getWidth();
  const canvasHeight = this.getHeight();
  if (activeObj) {
    const { height, left, top, width } = activeObj;
    if (height >= canvasHeight || width >= canvasWidth) {
      // If user resizes objects larger than size of the canvas, return to prevent errors
      return;
    }
    if (left < 0) {
      // prevent object's left coordinate from moving beyond canvas left boundary
      activeObj.set('left', 0);
    } else if (left + width > canvasWidth) {
      // prevent object's right coordinate from moving beyond canvas right boundary
      activeObj.set('left', canvasWidth - width);
    }
    if (top < TOOLBAR_HEIGHT) {
      // prevent object's top coordinate from moving beyond top boundary, in this case the shape toolbar
      activeObj.set('top', TOOLBAR_HEIGHT);
    } else if (top + height > canvasHeight) {
      // prevent object's bottom coordinate from moving beyond canvas bottom boundary
      activeObj.set('top', canvasHeight - height);
    }
  }
}

function lockToViewableArea(this: any, event: any) {
  const active = this.getActiveObject();
  if (active) {
    const height = this.getHeight();
    let obj;
    let locked: boolean;
    // Check whether the mouse is moving beyond canvas boundaries
    if (
      event.target &&
      (event.pointer.x < 0 ||
        event.pointer.x > this.getWidth() ||
        event.pointer.y < TOOLBAR_HEIGHT ||
        event.pointer.y > height)
    ) {
      obj = event.target;
      locked = true;
    } else {
      obj = active;
      locked = false;
    }
    obj.set('lockScalingFlip', locked);
    obj.set('lockScalingX', locked);
    obj.set('lockScalingY', locked);
    obj.set('lockMovementX', locked);
    obj.set('lockMovementY', locked);
  }
}

function addEventBlocker(fabricCanvas: any) {
  setAllObjectsLocked(fabricCanvas, true);
  const eventBlocker = fabricCanvas.get('eventBlocker');
  fabricCanvas.set('selection', false);
  if (!eventBlocker) {
    fabricCanvas.set(
      'eventBlocker',
      new fabric.Rect({
        backgroundColor: 'transparent',
        fill: 'rgba(0,0,0,0)',
        height: 2000,
        left: 0,
        lockMovementX: true,
        lockMovementY: true,
        top: 0,
        width: 2000
      })
    );
    fabricCanvas.add(fabricCanvas.get('eventBlocker'));
  } else {
    fabricCanvas.bringToFront(eventBlocker);
  }
  fabricCanvas.renderAll();
}
function removeEventBlocker(fabricCanvas: any) {
  fabricCanvas.set('selection', true);
  const eventBlocker = fabricCanvas.get('eventBlocker');
  if (eventBlocker) {
    fabricCanvas.remove(eventBlocker);
    fabricCanvas.set('eventBlocker', null);
  }
  setAllObjectsLocked(fabricCanvas, false);
}

function shapeMouseDown(this: any, e: any) {
  this.set('mouseClicked', true);
  addEventBlocker(this);
  this.get('pendingShape').clone((shape: any) => {
    const pointer = this.getPointer(e.e);
    shape.set('startX', pointer.x);
    shape.set('startY', pointer.y);
    shape.set('x1', pointer.x);
    shape.set('y1', pointer.y);
    shape.set('x2', pointer.x);
    shape.set('y2', pointer.y);
    shape.set('left', pointer.x);
    shape.set('top', pointer.y);
    this.add(shape);
    this.setActiveObject(shape);
  });
}

function removeObject(this: any) {
  const activeObjects = this.getActiveObjects();
  activeObjects.forEach((selection: any) => {
    this.remove(selection);
  });
  this.discardActiveObject();
  this.renderAll();
}

function screenCropMouseUp(this: any) {
  const rectWorking = this.getActiveObject();
  const settings = {
    height: rectWorking.get('height'),
    left: rectWorking.get('left'),
    top: rectWorking.get('top'),
    width: rectWorking.get('width')
  };
  this.remove(rectWorking);
  removeEventBlocker(this);
  this.trigger('screenShot', {
    img: this.toDataURL(settings)
  });
  this.renderAll();
}

function isTextBox(shape: any): boolean {
  return shape && shape.textLines;
}

function addShapeMouseUp(this: any) {
  const activeObj = this.getActiveObject();
  if (activeObj && activeObj.enterEditing) {
    activeObj.enterEditing();
  }

  /**
   * if the pending shape is a textbox
   * don't deactive, need to be able to type.
   */
  if (!isTextBox(this.get('pendingShape'))) {
    this.discardActiveObject();
  }
  this.set('mouseClicked', false);
  removeEventBlocker(this);
}

const SHAPE_BORDER = {
  backgroundColor: 'transparent',
  fill: 'rgba(0,0,0,0)',
  stroke: 'black',
  strokeWidth: 2,
  width: 2
};

function shapeMouseMove(this: any, e: any) {
  const shape = this.getActiveObject();
  if (!shape || !this.get('mouseClicked')) {
    return;
  }
  const startX = shape.get('startX');
  const startY = shape.get('startY');
  const pointer = this.getPointer(e.e);
  if (startX > pointer.x) {
    shape.set({ left: Math.abs(pointer.x) });
  }
  if (startY > pointer.y) {
    shape.set({ top: Math.abs(pointer.y) });
  }
  shape.set({
    x1: startX,
    x2: pointer.x,
    y1: startY,
    y2: pointer.y
  });

  shape.set({ width: Math.abs(startX - pointer.x) });
  shape.set({ height: Math.abs(startY - pointer.y) });

  shape.set('radius', Math.abs(Math.min(shape.get('width'), shape.get('height'))) / 2);
  shape.setCoords();
  this.renderAll();
}

/**
 * FabricCanvas - renders a canvas element in the ScratchPad.
 *               Supported tools are: Select, Clear Canvas, Text,
 *               Triangle, Rectangle, Circle, Math, Upload Img, ScreenShot
 */
class FabricCanvas extends React.Component<Props> {
  public static defaultProps: Props = {
    onFocus: noop,
    onScreenShotCrop: noop,
    onSelection: noop,
    onSelectionCleared: noop
  };
  private selectedTool: string = '';
  private fabricCanvas: any = null;
  private canvasUpdateHandler: () => void;
  private canvas = React.createRef<HTMLCanvasElement>();
  constructor(props: Props) {
    super(props);
  }

  public pointerTool = () => {
    this.newToolSelected(SCRATCHPAD_TOOL_NAME.PTR);
  };

  public deleteSelectedObject = () => {
    removeObject.call(this.fabricCanvas);
  };
  public turnOnDelete = () => {
    this.newToolSelected(SCRATCHPAD_TOOL_NAME.DEL);
    this.fabricCanvas.on('selection:created', removeObject);
    this.fabricCanvas.on('selection:updated', removeObject);
  };

  public clearCanvas = () => {
    this.fabricCanvas.clear();
    this.fabricCanvas.set('backgroundColor', '#fff');
    if (typeof this.canvasUpdateHandler === 'function') {
      this.canvasUpdateHandler();
    }
  };

  public resetState = (updatedCanvasState: FabricCanvasStateType) => {
    this.fabricCanvas.loadFromJSON(JSON.stringify(updatedCanvasState));
  };

  public addTriangle = () => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.TRI)) {
      return;
    }
    this.addShape(new fabric.Triangle(SHAPE_BORDER));
  };

  public addText = (text: string, top?: number, left?: number) => {
    if (text) {
      this.addTextWithoutPending(text, top, left);
      return;
    }
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.TXT)) {
      return;
    }
    this.addShape(
      new fabric.IText('', {
        fontFamily: 'Aspira-Medium',
        fontSize: 20,
        left: 0,
        top: 0
      })
    );
  };
  public onCanvasUpdate = (
    canvasUpdateHandler: (canvasState: FabricCanvasStateType) => void
  ) => {
    /**
     * when an object is added, it creates a dummy object while it is being drawn.
     * The dummy object is then removed. Thus object:removed handles both object creation/deletion
     */
    this.fabricCanvas.on(
      'object:removed',
      this.provideCanvasState.bind(this, canvasUpdateHandler)
    );
    /**
     * object:modified handles all object operations except selection, creation and deletion
     */
    this.fabricCanvas.on(
      'object:modified',
      this.provideCanvasState.bind(this, canvasUpdateHandler)
    );
    /**
     * svg:added is a custom event we fire manually when we add images to the scratchpad
     * from attachment upload, mathType, graphTool, etc
     */
    this.fabricCanvas.on(
      'svg:added',
      this.provideCanvasState.bind(this, canvasUpdateHandler)
    );
    this.fabricCanvas.on(
      'path:created',
      this.provideCanvasState.bind(this, canvasUpdateHandler)
    );

    this.canvasUpdateHandler = this.provideCanvasState.bind(this, canvasUpdateHandler);
  };
  public addCircle = () => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.CIR)) {
      return;
    }
    this.addShape(new fabric.Circle(SHAPE_BORDER));
  };

  public setMathTool() {
    this.newToolSelected(SCRATCHPAD_TOOL_NAME.MATH);
  }
  public addSVG = (svg: string, top = 0, left = 0) => {
    this.newToolSelected(SCRATCHPAD_TOOL_NAME.UPLD);
    fabric.loadSVGFromString(svg, (objects: any[], options: any) => {
      const obj = fabric.util.groupSVGElements(objects, {
        ...options,
        selectable: true,
        top
      });
      /**
       * calculate how to center svg
       */
      let posLeft = 0;
      posLeft = left - Math.floor(obj.get('width') / 2);
      posLeft = Math.max(0, posLeft);

      obj.set('left', posLeft);
      this.fabricCanvas
        .setActiveObject(obj)
        .add(obj)
        .renderAll();
      obj.setCoords();
      this.fabricCanvas.fire('svg:added');
    });
  };

  public addLine = () => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.LINE)) {
      return;
    }
    this.addShape(new fabric.Line([1, 1, 1, 1], { stroke: 'black', strokeWidth: 2 }));
  };
  public addRect = () => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.RECT)) {
      return;
    }
    this.addShape(new fabric.Rect(SHAPE_BORDER));
  };
  public addSquare = () => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.RECT)) {
      return;
    }
    this.addShape(new fabric.Rect(SHAPE_BORDER));
  };

  public screenShot = () => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.SCRN)) {
      return;
    }
    this.fabricCanvas.trigger('screenShot', {
      img: this.fabricCanvas.toDataURL()
    });
  };
  public turnOnDrawingMode = (color: string = '#000') => {
    this.fabricCanvas.set('freeDrawingBrush.color', color);
    this.fabricCanvas.freeDrawingBrush.color = color;

    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.PEN)) {
      return;
    }

    this.fabricCanvas.set('isDrawingMode', true);
    this.fabricCanvas.freeDrawingBrush.width = 2;
  };

  public addImage = (
    imgEle: HTMLImageElement,
    top: number,
    left: number,
    scale: number
  ) => {
    this.newToolSelected(SCRATCHPAD_TOOL_NAME.UPLD);
    const i = new fabric.Image(imgEle, {
      height: imgEle.height,
      left,
      scaleX: scale,
      scaleY: scale,
      top,
      width: imgEle.width
    });
    this.fabricCanvas.setActiveObject(i).add(i);
    this.fabricCanvas.fire('svg:added');
  };

  public setScreenShot = (_: any, _shape: any = fabric.Rect, _options: any = {}) => {
    if (!this.newToolSelected(SCRATCHPAD_TOOL_NAME.SCRN)) {
      return;
    }
    addEventBlocker(this.fabricCanvas);
    this.fabricCanvas.set('selection', false);
    const workingRect = new fabric.Rect({
      fill: 'rgba(0, 0, 0, 0.5)',
      hasControls: true,
      height: 1,
      width: 1
    });

    this.fabricCanvas.set('pendingShape', workingRect);
    this.fabricCanvas.on('mouse:up', screenCropMouseUp);
    this.fabricCanvas.on('mouse:down', shapeMouseDown);
    this.fabricCanvas.on('mouse:move', shapeMouseMove);
  };

  public componentDidMount() {
    const canvasEle = this.canvas.current;
    this.fabricCanvas = new fabric.Canvas(canvasEle, {
      backgroundColor: '#fff'
    });
    this.resizeCanvas();
    const { onSelection, onSelectionCleared } = this.props;
    this.fabricCanvas.on('mouse:down', this.props.onFocus);
    this.fabricCanvas.on('selection:created', onSelection);
    this.fabricCanvas.on('selection:cleared', onSelectionCleared);
    this.fabricCanvas.on('object:moving', restrictMovementToViewableArea);
    this.fabricCanvas.on('object:scaling', lockToViewableArea);
    this.fabricCanvas.on('mouse:move', lockToViewableArea);
    window.addEventListener('resize', this.resizeCanvas, false);
    this.fabricCanvas.on('screenShot', ({ img }: { img: string }) => {
      this.props.onScreenShotCrop(img);
    });
  }
  public render() {
    return <canvas ref={this.canvas} />;
  }

  public provideCanvasState(callback: (canvasState: FabricCanvasStateType) => void) {
    callback(this.fabricCanvas.toJSON());
  }

  private addTextWithoutPending(text?: string, _top?: number, _left?: number) {
    const i = new fabric.IText(text, {
      fontFamily: 'Aspira-Medium',
      fontSize: 20,
      left: 20,
      top: 20
    });
    this.fabricCanvas.setActiveObject(i).add(i);
  }

  private newToolSelected = (toolName: string): boolean => {
    if (this.selectedTool === toolName) {
      return false;
    }
    this.selectedTool = toolName;
    const fabricCanvas = this.fabricCanvas;
    fabricCanvas.off('mouse:up', addShapeMouseUp);
    fabricCanvas.off('mouse:down', shapeMouseDown);
    fabricCanvas.off('mouse:move', shapeMouseMove);
    fabricCanvas.off('mouse:up', screenCropMouseUp);
    fabricCanvas.set('pendingShape', '');
    fabricCanvas.set('selection', true);
    removeEventBlocker(fabricCanvas);
    this.turnOffDrawingMode();
    this.turnOffDelete();
    fabricCanvas.discardActiveObject().renderAll();
    return true;
  };

  private turnOffDelete = () => {
    this.fabricCanvas.off('selection:created', removeObject);
    this.fabricCanvas.off('selection:updated', removeObject);
  };

  private turnOffDrawingMode = () => {
    this.fabricCanvas.set('isDrawingMode', false);
  };

  private addShape = (shape: any) => {
    this.fabricCanvas.set('selection', false);
    this.fabricCanvas.set('pendingShape', shape);
    this.fabricCanvas.calcOffset();
    this.fabricCanvas.on('mouse:up', addShapeMouseUp);
    this.fabricCanvas.on('mouse:down', shapeMouseDown);
    this.fabricCanvas.on('mouse:move', shapeMouseMove);
  };

  private resizeCanvas = () => {
    this.fabricCanvas.setHeight(2000); // ~2000px needs to be set for canvas drawing area height
    this.fabricCanvas.setWidth(505); // width of the canvas
    this.fabricCanvas.renderAll();
  };
}

export default FabricCanvas;
