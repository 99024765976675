import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('messages');

export interface AddMessagePayload {
  lessonId: string;
  message: RawMessage;
}

export interface SendMessagePayload {
  lessonId: string;
  text: string;
}

export interface MessageSentPayload {
  lessonId: string;
  sent: boolean;
}

export interface PastMessagesPayload {
  id: Lesson['id'];
}

export const addAdminMessage = actionCreator<AddMessagePayload>('ADD_ADMIN_MESSAGE');
export const addMessage = actionCreator<AddMessagePayload>('ADD_MESSAGE');
export const sendMessage = actionCreator<SendMessagePayload>('SEND_MESSAGE');
export const messageSent = actionCreator<MessageSentPayload>('MESSAGE_SENT');
export const getPastMessages = actionCreator<PastMessagesPayload>('GET_PAST_MESSAGES');
