import { connect } from 'react-redux';
import {
  getLessonNotes,
  getYourNote
} from '@chegg-tutors-chat/shared/redux/modules/notes/selectors';
import NotesList from './NotesList';

interface Props {
  lessonId: Lesson['id'];
}

export function mapStateToProps(state: GlobalState, passedProps: Props) {
  const { lessonId } = passedProps;
  const notes: Note[] = getLessonNotes(state, lessonId);
  const yourNote = getYourNote(state, lessonId);
  return { notes, yourNote };
}

export default connect(mapStateToProps, null)(NotesList);
