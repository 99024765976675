import { publicConfig } from '@chegg-tutors-chat/shared/config';
import {
  PublicConfigEvents,
  PublicConfigKeys,
  PublicConfigText,
  PublicConfigTheme
} from '@chegg-tutors-chat/shared/config/public';

/**
 * A public SDK method for setting the embedded chat
 * config keys with values. It supports setting
 * configurations for 'theme', 'text' and 'events'.
 *
 * @param configKey
 * @param configValue
 */
interface SetConfig {
  (configKey: PublicConfigKeys.THEME, configValue: Partial<PublicConfigTheme>);
  (configKey: PublicConfigKeys.EVENTS, configValue: Partial<PublicConfigEvents>);
  (configKey: PublicConfigKeys.TEXT, configValue: Partial<PublicConfigText>);
}

const setConfig: SetConfig = (configKey, configValue) => {
  publicConfig.set(configKey, configValue);
};

export default setConfig;
