import styled from 'styled-components';
import Link from '@chegg-tutors-chat/shared/styled/sunkist/Link';

export const HeaderLink = styled(Link)`
  padding-right: 10px;
  line-height: 21px;
`;

export const LinkLayout = styled.div`
  margin-left: auto;
`;
