import * as React from 'react';
import { KEYBOARD } from '@chegg-tutors-chat/shared/constants';
import { ConversationPropsFromState } from './container';
import ConversationMessage from './ConversationMessage';
import MessageList from './styled';
import { MESSAGE_LIST, scrollToBottom } from './utils';

export type ConversationProps = ConversationPropsFromState;

export interface PreviousMessage {
  sender: string;
  type: MessageType | void;
}

/**
 * Conversation - renders all messages within MessageList
 *
 * @props - ConversationProps
 */

const Conversation: React.FunctionComponent<ConversationProps> = ({
  conversation = [],
  loggedInUser
}) => {
  const conversationRef = React.useRef<HTMLUListElement>(null);

  const handleUpArrowPress = (e: KeyboardEvent) => {
    const active = document.activeElement;
    /**
     * Handle check for an <<UP>> arrow key press
     */
    if (
      e.keyCode === KEYBOARD.UP_ARROW_KEYCODE &&
      active !== null &&
      active.previousSibling
    ) {
      const msg = active.previousSibling as HTMLElement;
      msg.focus();
    } else if (
      e.keyCode === KEYBOARD.UP_ARROW_KEYCODE &&
      active !== null &&
      active.previousSibling === null
    ) {
      const parentElement = active.parentElement;
      if (parentElement) {
        const prevMsg = parentElement.previousSibling as HTMLElement;
        if (prevMsg !== null) {
          prevMsg.focus();
        }
      }
    }
  };

  const handleDownArrowPress = (e: KeyboardEvent) => {
    const active = document.activeElement;
    /**
     * Handle check for a <<DOWN>> arrow key press
     */
    if (
      e.keyCode === KEYBOARD.DOWN_ARROW_KEYCODE &&
      active !== null &&
      active.nextSibling
    ) {
      const msg = active.nextSibling as HTMLElement;
      msg.focus();
    } else if (
      e.keyCode === KEYBOARD.DOWN_ARROW_KEYCODE &&
      active !== null &&
      active.nextSibling === null
    ) {
      const parentElement = active.parentElement;
      if (parentElement) {
        const nextMsg = parentElement.nextSibling as HTMLElement;
        if (nextMsg !== null) {
          nextMsg.focus();
        }
      }
    }
  };

  const getMessageListAriaLabel = (): string | undefined => {
    const label =
      "You're in a chat messaging area. Use up and down arrows to navigate through messages.";
    return conversation && conversation.length >= 1 ? label : undefined;
  };

  React.useEffect(() => {
    scrollToBottom();

    const handleKeyDown = (e: KeyboardEvent) => {
      handleDownArrowPress(e);
      handleUpArrowPress(e);
    };

    const node = conversationRef.current;
    if (node) {
      node.addEventListener('keydown', handleKeyDown);
    }

    return () => node.removeEventListener('keydown', handleKeyDown);
  }, [conversation]);

  const previousMessage: PreviousMessage = {
    sender: '',
    type: null
  };

  return (
    conversation && (
      <>
        <MessageList
          id={MESSAGE_LIST}
          tabIndex={0}
          ref={conversationRef}
          aria-label={getMessageListAriaLabel()}
        >
          {conversation.map(message => (
            <ConversationMessage
              message={message}
              previousMessage={previousMessage}
              loggedInUser={loggedInUser}
            />
          ))}
        </MessageList>
      </>
    )
  );
};

export default Conversation;
