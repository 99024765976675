import * as React from 'react';
import { withTheme } from 'styled-components';
import { publicConfig } from '@chegg-tutors-chat/shared/config';
import {
  PublicConfigKeys,
  PublicConfigTheme
} from '@chegg-tutors-chat/shared/config/public';
import {
  MESSAGE_TYPE,
  STUDENT_AVATAR,
  TUTOR_AVATAR
} from '@chegg-tutors-chat/shared/constants';
import { Avatar, fanta } from '@chegg/fanta';

export interface ChatAvatarProps {
  /**
   * First name of the user
   */
  firstName?: string;
  /**
   * last name of user
   */
  lastName?: string;
  /**
   * whether the user is the logged in expert
   */
  loggedInUser?: boolean;
  /**
   * Type of Message received
   */
  messageType?: MessageType;
  theme: PublicConfigTheme;
}

/**
 * Avatar - component to display a user avatar for chat.
 *          If an image src url is passed, prefer an image avatar.
 *          Otherwise fall back on an avatar consisting of their initials.
 *          If none of that is known, default to the Chegg default avatar
 *
 * @props - AvatarProps
 */
const ChatAvatar: React.FunctionComponent<ChatAvatarProps> = props => {
  const { firstName, lastName, loggedInUser, messageType, theme } = props;

  const adminAvatar = publicConfig.get(PublicConfigKeys.TEXT, 'message.admin.profileUrl');

  const botAvatar = publicConfig.get(PublicConfigKeys.TEXT, 'message.bot.profileUrl');

  const tutorAvatar = publicConfig.get(PublicConfigKeys.TEXT, 'message.tutor.profileUrl');

  const studentAvatar = publicConfig.get(
    PublicConfigKeys.TEXT,
    'message.student.profileUrl'
  );

  const studentOrTutorAvatar = loggedInUser ? studentAvatar : tutorAvatar;

  // When there is a firstName - use it, otherwise use lastName or empty string
  const userName = firstName ? firstName : lastName || '';

  const MESSAGE_TYPE_MAP = {
    // A map object where a key message type -> Image Source.
    [MESSAGE_TYPE.ADMIN]: adminAvatar,
    [MESSAGE_TYPE.BOT]: botAvatar,
    [MESSAGE_TYPE.MESSAGE]: studentOrTutorAvatar
  };

  // Using any here as CheggUI expects only one of 4 fanta colors to be used
  const backgroundColor: any = loggedInUser
    ? theme[STUDENT_AVATAR.BG_COLOR]
    : theme[TUTOR_AVATAR.BG_COLOR];

  const profileUrl: string = MESSAGE_TYPE_MAP[messageType];

  if (profileUrl) {
    // check if the avatar has profileUrl set in public config
    return <Avatar size="S" src={profileUrl} alt={userName} />;
  } else {
    // use backgroundColor defined in theme provider and chegg fanta fontColor
    return (
      <Avatar
        size="S"
        backgroundColor={backgroundColor}
        fontColor={fanta.colors.white}
        name={userName}
      />
    );
  }
};
export default withTheme(ChatAvatar);
