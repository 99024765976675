import * as React from 'react';
import unPinIconSrc from '@chegg-tutors-chat/shared/assets/images/ic-unpin.svg';
import { Image, Layout } from './styled';

export interface UnPinProps {
  /**
   * @prop onClick - function which calls action to modify store.
   */
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}
/**
 * UnPinIcon - component that displays UnPin icon when a message is already pinned.
 */
const UnPinIcon: React.SFC<UnPinProps> = ({ onClick }) => {
  return (
    <Layout title="Unpin" onClick={onClick}>
      <Image src={unPinIconSrc} alt="Unpin message" />
    </Layout>
  );
};

export default UnPinIcon;
