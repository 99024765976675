import {
  EL_REGION,
  EL_TYPE,
  EVENT_NAME
} from '@chegg-tutors-chat/shared/providers/analytics/constants';
import {
  formatAnalyticsClickEvent,
  FormatAnalyticsClickEventPayload
} from '@chegg-tutors-chat/shared/providers/analytics/utils/formatAnalyticsClickEvent';
import { transcriptDownload } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { clickLogicFactory } from '../utils';

/**
 * logs to analytics when the student downloads the transcript of their lesson
 */
export const trackTranscriptDownload = clickLogicFactory<{}>(
  transcriptDownload.type,
  (state: GlobalState, _payload) => {
    const clickEventConfig: FormatAnalyticsClickEventPayload = {
      eventName: EVENT_NAME.transcript_download,
      eventRegion: EL_REGION.thankyou_modal,
      eventState: state,
      eventType: EL_TYPE.button
    };
    return formatAnalyticsClickEvent(clickEventConfig);
  }
);

export default trackTranscriptDownload;
