import { createSelector } from 'reselect';

const getClientState = (state: Partial<GlobalState>) => state.client;

/**
 * Gets client state.
 *
 * @param state
 * @param lessonId
 */
export const getClientInfo = (
  state: Partial<GlobalState>,
  lessonId: LessonState['currentLessonId']
): LessonClientInfo | undefined => {
  const clientState = getClientState(state);

  if (clientState && lessonId) {
    return clientState.lessonStates[lessonId];
  }
  return undefined;
};

/**
 * Gets clientHasFocus state.
 *
 * @param state
 */
export const getClientHasFocus = createSelector(
  [getClientState],
  (clientState: ClientState) => (clientState ? clientState.clientHasFocus : undefined)
);

/**
 * Gets tool and tool.open states
 * Gets open tool state.
 *
 * @param state
 */
export const getOpenTool = (state: LessonClientInfo | undefined) => {
  return state ? state.tool && state.tool.open : '';
};

/**
 * Gets DisplayNewMessageAlert state.
 *
 * @param state
 */
export const getDisplayNewMessageAlert = createSelector(
  [getClientState],
  (client: ClientState) => (client ? client.displayNewMessageAlert : undefined)
);

/**
 * Gets toastName from client state.
 *
 * @param state
 */
export const getToast = createSelector(
  [getClientState],
  (client: ClientState) => client.toastName
);
