import addAttachments from './logic/addAttachment';
import feedback from './logic/feedback';
import genericAnalytics from './logic/genericAnalytics';
import modalAnalytics from './logic/modalAnalytics';
import trackPenColors from './logic/selectScratchPadPen';
import trackSelectPenTool from './logic/selectScratchPadPenTool';
import trackScratchPadShape from './logic/selectScratchPadShape';
import trackClearCanvas from './logic/selectScratchPadShapeToolClearCanvas';
import trackSelectTextTool from './logic/selectScratchPadTextTool';
import trackToggleTool from './logic/toggleTool';
import trackTranScriptDownload from './logic/tranScriptDownload';

export default [
  ...addAttachments,
  ...feedback,
  ...genericAnalytics,
  ...modalAnalytics,
  trackClearCanvas,
  trackSelectPenTool,
  ...trackPenColors,
  ...trackScratchPadShape,
  ...trackToggleTool,
  trackSelectTextTool,
  trackTranScriptDownload
];
