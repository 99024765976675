import { connect } from 'react-redux';
import { TOOLS } from '@chegg-tutors-chat/shared/constants';
import * as clientActions from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  getClientInfo,
  getOpenTool
} from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import * as scratchPadActions from '@chegg-tutors-chat/shared/redux/modules/scratchpad/actions';
import ScratchPad, { Props } from './ScratchPad';

export function mapStateToProps(state: GlobalState, ownProps: Props) {
  const clientInfo = getClientInfo(state, ownProps.lessonId);
  let scratchPadOpen = false;
  const currentLessonId = getSelectedLessonId(state);

  if (clientInfo) {
    scratchPadOpen = getOpenTool(clientInfo) === TOOLS.SCRATCH_PAD.name;
  }
  const active = scratchPadOpen && currentLessonId === ownProps.lessonId;
  return {
    active
  };
}
export const mapDispatchToProps = {
  addAttachment: clientActions.addAttachment,
  selectScratchPadPenColor: scratchPadActions.selectScratchPadPenColor,
  selectScratchPadShape: scratchPadActions.selectScratchPadShape,
  selectScratchPadTool: scratchPadActions.selectScratchPadTool,
  sendImageToScratchPad: scratchPadActions.sendImageToScratchPad,
  showMathTypeTool: clientActions.showMathTypeTool,
  showSectionTool: clientActions.showSectionTool
};

export default connect(mapStateToProps, mapDispatchToProps)(ScratchPad);
