import styled from 'styled-components';
import { LinkButton } from '@chegg/fanta';

const tutorsHomeUrl = 'https://www.chegg.com/tutors';

export const HomePageButton = styled(LinkButton).attrs({
  href: tutorsHomeUrl
})`
  max-width: 50%;
  text-decoration: none;
`;

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
