import { debounce } from 'lodash';

/* elementId of Conversation Component - used for scrolling */
export const MESSAGE_LIST = 'message-list';

/*
 * @function scrollToBottomOfMessageList - scrolls the conversation to the bottom
 * @function scrollToBottom - debounced to only fire every 300ms,
 *   called every time an image message is rendered.
 */
const scrollToBottomOfMessageList = () => {
  const messageList = document.getElementById(MESSAGE_LIST);
  if (messageList) {
    messageList.scrollTop = messageList.scrollHeight;
  }
};
export const scrollToBottom = debounce(scrollToBottomOfMessageList, 300);
