import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { LESSON_STATUS } from '@chegg-tutors-chat/shared/constants';
import { BFF_API_ENDPOINTS } from '@chegg-tutors-chat/shared/providers/BFF/constants';

function mockStudentBFF() {
  const mockAdapter = new MockAdapter(axios, { delayResponse: 500 });

  /**
   * GETS STUDENT INFO
   */
  mockAdapter.onPost(BFF_API_ENDPOINTS.LESSON_INFO_STUDENT).reply(200, {
    jiraInfo: {
      fields: {
        assignee: { displayName: 'Satoshi Nakomoto', accountId: 'mocked_accountId' },
        description: 'I need help with Algebra',
        reporter: { displayName: 'John Doe', name: 'mocked_name' },
        status: {
          id: '12345',
          name: LESSON_STATUS.IN_PROGRESS
        },
        subject: 'Algebra'
      },
      id: 'id',
      key: 'key'
    },
    sendbirdUserInfo: {
      access_token: 'token',
      user_id: 'user_id'
    }
  });

  /**
   * CREATE LESSON
   */
  mockAdapter.onPost(BFF_API_ENDPOINTS.CREATE_LESSON).reply(200, {
    token: 'mocked_token'
  });

  /**
   * CLOSE LESSON
   */
  mockAdapter.onPut(BFF_API_ENDPOINTS.CLOSE_LESSON_STUDENT).reply(200);

  /**
   * SUBMIT FEEDBACK
   */
  mockAdapter.onPost(BFF_API_ENDPOINTS.LESSON_FEEDBACK).reply(200);
}

if (process.env.REACT_APP_IS_OFFLINE) {
  mockStudentBFF();
}
