import moment from 'moment';
import actionCreatorFactory from 'typescript-fsa';
import {
  ATTACHMENT_ORIGIN_TYPE,
  CHAT_CONNECTION,
  RESPONSE_STATUS_CODE,
  TOAST_NOTIFICATION
} from '@chegg-tutors-chat/shared/constants';

const actionCreator = actionCreatorFactory('client');

/**
 * ToDO: Figure out where to have tool names
 */

export interface ToggleToolPayload {
  lessonId: Lesson['id'];
  toolName: string;
}

export interface TrackToolPayload {
  lessonId: Lesson['id'];
  toolName: string;
}

export interface SectionToolPayload extends ToggleToolPayload {
  section: string;
  options?: {
    [key: string]: string | number | boolean;
  };
}

export interface UpdateWaitDurationPayload {
  lessonId: Lesson['id'];
  waitDuration?: moment.Duration;
}

export interface UpdateStudentStatusPayload {
  lessonId: Lesson['id'];
  studentStatus?: Status;
}

export interface AttachmentPayload {
  lessonId?: Lesson['id']; // Defaults to current selected lesson id; thus, optional.
  fileSrc?: any;
  target?: string;
  type?: string;
  originType?: ATTACHMENT_ORIGIN_TYPE; // where in the UI was the attachment sent from
}

export interface RemoveAttachmentPayload {
  lessonId?: Lesson['id'];
}

export interface UploadingStatusPayload {
  lessonId: Lesson['id'];
  uploading: boolean;
}

export interface UploadingErrorPayload {
  lessonId: Lesson['id'];
  uploadingError: FileUploadError;
}

export interface UploadingSuccessPayload {
  lessonId: Lesson['id'];
  type: string;
}

/**
 * @prop lessonId - lesson id of selected lesson.
 * @prop isTyping - a boolean indicating whether a user is typing
 */
export interface TypingStatusPayload {
  lessonId: Lesson['id'];
  isTyping: boolean;
}

export interface UnreadMessageCount {
  lessonId: Lesson['id'];
}

export interface UpdateInputTextPayload {
  lessonId: Lesson['id'];
  inputText: string;
}

export interface HydrateAppPayload extends Lesson {
  lessonInfo: {
    hasError: boolean;
    jiraInfo?: any;
    sendbirdUserInfo?: any;
  };
}

export interface UpdateClientHasFocusPayload {
  clientHasFocus: boolean;
}

export type ChatConnectStatuses =
  | CHAT_CONNECTION.CONNECTION_STARTED
  | CHAT_CONNECTION.CONNECTION_FAILED
  | CHAT_CONNECTION.CONNECTION_SUCCEEDED;

export type ChatReconnectStatuses =
  | CHAT_CONNECTION.RECONNECTION_STARTED
  | CHAT_CONNECTION.RECONNECTION_FAILED
  | CHAT_CONNECTION.RECONNECTION_SUCCEEDED;

export interface ChatConnectionStatus {
  status: ChatConnectStatuses;
}

export interface ChatReconnectionStatus {
  status: ChatReconnectStatuses;
}

/**
 *  @param displayNewMessageAlert - boolean - to display new message alert.
 */
export interface UpdateNewMessageAlertPayload {
  displayNewMessageAlert: boolean;
}

export interface SetBffRequestError {
  code: RESPONSE_STATUS_CODE;
}

export interface ShowToastPayload {
  toastName: TOAST_NOTIFICATION;
}

export const resetStore = actionCreator('RESET_STORE');

export const closeToast = actionCreator('CLOSE_TOAST');

export const showToast = actionCreator<ShowToastPayload>('SHOW_TOAST');

export const setBffRequestError = actionCreator<SetBffRequestError>(
  'SET_BFF_REQUEST_ERROR'
);

export const resetUnreadMessage = actionCreator<UnreadMessageCount>(
  'RESET_UNREAD_MESSAGE_COUNT'
);

export const updateNewMessageAlert = actionCreator<UpdateNewMessageAlertPayload>(
  'UPDATE_NEW_MESSAGE_ALERT'
);
export const showSectionTool = actionCreator<SectionToolPayload>('SHOW_SECTION_TOOL');
export const toggleTool = actionCreator<ToggleToolPayload>('TOGGLE_TOOL');
export const showTool = actionCreator<ToggleToolPayload>('SHOW_TOOL');
export const hideTool = actionCreator<ToggleToolPayload>('HIDE_TOOL');
export const showMathTypeTool = actionCreator<TrackToolPayload>('SHOW_MATH_TYPE_TOOL');
export const updateClientHasFocus = actionCreator<UpdateClientHasFocusPayload>(
  'UPDATE_CLIENT_HAS_FOCUS'
);
export const trackMathTypeImageToScratchpad = actionCreator<TrackToolPayload>(
  'SEND_MATH_TYPE_IMAGE_TO_SCRATCHPAD'
);
export const updateStudentStatus = actionCreator<UpdateStudentStatusPayload>(
  'UPDATE_STUDENT_STATUS'
);
export const updateWaitDuration = actionCreator<UpdateWaitDurationPayload>(
  'UPDATE_WAIT_DURATION'
);
export const updateInterval = actionCreator('UPDATE_INTERVAL');
export const addAttachment = actionCreator<AttachmentPayload>('ADD_ATTACHMENT');
export const removeAttachment = actionCreator<RemoveAttachmentPayload>(
  'REMOVE_ATTACHMENT'
);
export const setUploadingStatus = actionCreator<UploadingStatusPayload>(
  'UPLOADING_STATUS'
);
export const setUploadingError = actionCreator<UploadingErrorPayload>('UPLOADING_ERROR');
export const setUploadingSuccess = actionCreator<UploadingSuccessPayload>(
  'UPLOADING_SUCCESS'
);
export const clearError = actionCreator<UploadingErrorPayload>('CLEAR_ERROR');
export const setClientUserTyping = actionCreator<TypingStatusPayload>(
  'CLIENT_USER_IS_TYPING'
);
export const setOtherUserTyping = actionCreator<TypingStatusPayload>(
  'OTHER_USER_IS_TYPING'
);
export const increaseUnreadMessageCount = actionCreator<UnreadMessageCount>(
  'INCREASE_UNREAD_MESSAGE_COUNT'
);

export const updateInputText = actionCreator<UpdateInputTextPayload>('UPDATE_INPUT_TEXT');
export const transcriptDownload = actionCreator('TRANSCRIPT_DOWNLOAD');
// FIXME CT-3430
export const hydrateAppState = actionCreator<HydrateAppPayload | any>(
  'HYDRATE_APP_STATE'
);

export const setChatConnectionStatus = actionCreator<ChatConnectionStatus>(
  'SET_CHAT_CONNECTION_STATUS'
);

export const setChatReconnectionStatus = actionCreator<ChatReconnectionStatus>(
  'SET_CHAT_RECONNECTION_STATUS'
);

export const logOut = actionCreator('LOG_OUT');

export const closeEmbedded = actionCreator('CLOSE_EMBEDDED');
