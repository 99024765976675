import styled from 'styled-components';
import selectArrows from '@chegg-tutors-chat/shared/assets/images/ic-select-arrows.svg';
import { BREAKPOINTS } from '@chegg-tutors-chat/shared/constants';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

// REFACTOR: as factory function

const config = {
  borderRadius: {
    default: '6px',
    large: '8px',
    mini: '4px',
    small: '6px'
  },
  fontSize: {
    default: '16px',
    large: '20px',
    mini: '12px',
    small: '16px'
  },
  height: {
    default: '34px',
    large: '44px',
    mini: '30px',
    small: '34px'
  },
  paddingLeftRight: {
    default: '10px',
    large: '16px',
    mini: '10px',
    small: '10px'
  },
  select: {
    arrowsSize: {
      default: '10px',
      large: '12px',
      mini: '8px',
      small: '10px'
    },
    rightPadding: {
      default: '22px',
      large: '24px',
      mini: '20px',
      small: '22px'
    }
  }
};

export interface InputElementProps {
  /**
   * @prop error - whether there is an error in the field
   */
  error?: boolean;
  /**
   * @prop fullWidth - whether the field should be rendered the full width of its parent
   */
  fullWidth?: boolean;
  /**
   * @prop sunkistSize - what sunkist size the form element should be
   */
  sunkistSize?: 'large' | 'small' | 'mini';
}

const getBorderRadius = (props: InputElementProps) =>
  config.borderRadius[props.sunkistSize || 'default'];

const getFontSize = (props: InputElementProps) =>
  config.fontSize[props.sunkistSize || 'default'];

const getElementHeight = (props: InputElementProps) =>
  config.height[props.sunkistSize || 'default'];

const getPaddingLeftRight = (props: InputElementProps) =>
  config.paddingLeftRight[props.sunkistSize || 'default'];

const getSelectPaddingRight = (props: InputElementProps) =>
  config.select.rightPadding[props.sunkistSize || 'default'];

const getSelectArrowsSize = (props: InputElementProps) =>
  config.select.arrowsSize[props.sunkistSize || 'default'];

export const Field = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label<InputElementProps>`
  font-size: ${(props: InputElementProps) => getFontSize(props)};
  display: block;
  margin-bottom: 10px;
  line-height: normal;
  @media (max-width: ${BREAKPOINTS.SM_MAX}) {
    margin-bottom: 5px;
    line-height: normal;
  }
`;

export const Select = styled.select<InputElementProps>`
  font-size: ${(props: InputElementProps) => getFontSize(props)};
  width: ${(props: InputElementProps) => (props.fullWidth ? '100%' : 'unset')};
  height: ${(props: InputElementProps) => getElementHeight(props)};
  border-color: ${(props: InputElementProps) =>
    props.error ? COLORS.D3332A : COLORS.E5E5E5};
  border-style: solid;
  border-width: 2px;
  border-radius: ${(props: InputElementProps) => getBorderRadius(props)};
  line-height: ${(props: InputElementProps) => getElementHeight(props)};

  &:active,
  &:focus {
    border-color: ${COLORS['007C8A']};
  }
  &:disabled {
    opacity: 0.25;
  }

  cursor: pointer;
  appearance: none;
  padding-top: 0px;
  padding-right: ${(props: InputElementProps) => getSelectPaddingRight(props)};
  padding-bottom: 0px;
  padding-left: ${(props: InputElementProps) => getPaddingLeftRight(props)};

  /* SVG background image */
  background: url(${selectArrows}) no-repeat;
  background-size: ${(props: InputElementProps) => getSelectArrowsSize(props)};
  background-position: calc(100% - 3px) center;
  background-repeat: no-repeat;

  background-color: ${COLORS.FFFFFF};
  &:hover {
    background-color: ${COLORS.F5F5F5};
  }
`;

export const TextArea = styled.textarea<InputElementProps>`
  font-size: ${(props: InputElementProps) => getFontSize(props)};
  width: 100%;
  border-color: ${(props: InputElementProps) =>
    props.error ? COLORS.D3332A : COLORS.E5E5E5};
  border-style: solid;
  border-width: 2px;
  border-radius: ${(props: InputElementProps) => getBorderRadius(props)};
  padding: ${(props: InputElementProps) => getPaddingLeftRight(props)};
  &:active,
  &:focus {
    border-color: ${COLORS['007C8A']};
  }
  &:disabled {
    opacity: 0.25;
  }
`;

export const TextInput = styled.input<InputElementProps>`
  font-size: ${(props: InputElementProps) => getFontSize(props)};
  width: ${(props: InputElementProps) => (props.fullWidth ? '100%' : 'unset')};
  height: ${(props: InputElementProps) => getElementHeight(props)};
  border-color: ${(props: InputElementProps) =>
    props.error ? COLORS.D3332A : COLORS.E5E5E5};
  border-style: solid;
  border-width: 2px;
  border-radius: ${(props: InputElementProps) => getBorderRadius(props)};
  padding: 0px ${(props: InputElementProps) => getPaddingLeftRight(props)};
  line-height: ${(props: InputElementProps) => getElementHeight(props)};
  &:active,
  &:focus {
    border-color: ${COLORS['007C8A']};
  }
  &:disabled {
    opacity: 0.25;
  }
`;
