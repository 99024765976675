import styled from 'styled-components';

export const NotificationBox = styled.div`
  left: 50%;
  padding: 30px;
  position: absolute;
  top: 50px;
  transform: translate(-50%, 0%);
  z-index: 4;
  width: 600px;
`;

export const ToastWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Backdrop = styled.div`
  position: fixed;
  z-index: 3;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
`;
