import { createLogic } from 'redux-logic';
import { TOOLS } from '@chegg-tutors-chat/shared/constants';
import { showTool } from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import { getClientInfo } from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import rootStore from '@chegg-tutors-chat/shared/redux/rootStore';
import { isErrorAction } from '@chegg-tutors-chat/shared/utils';
import { pinMessage, PinMessagePayload } from './actions';

export const addPinLogic = createLogic<GlobalState, PinMessagePayload>({
  process: ({ action }, _dispatch, done) => {
    if (!isErrorAction(action)) {
      const store = rootStore.getStore();
      const { lessonId } = action.payload;
      store.dispatch(showTool({ lessonId, toolName: TOOLS.PINNED.name }));
    }
    done();
  },
  type: pinMessage.type,
  validate: ({ getState, action }, allow, reject) => {
    const { lessonId } = action.payload;
    const state = getState();
    const clientInfo = getClientInfo(state, lessonId);
    const firstPinAdded = clientInfo ? clientInfo.firstPinAdded : false;

    if (!firstPinAdded) {
      allow(action);
    } else {
      reject(action);
    }
  }
});

export default [addPinLogic];
