import * as React from 'react';
import howToPinAsset from '@chegg-tutors-chat/shared/assets/images/how-to-pin-illustration.gif';
import PinnedMessage from '@chegg-tutors-chat/shared/components/PinnedMessage/PinnedMesage';
import { Lead } from '@chegg-tutors-chat/shared/styled/sunkist/Typography';
import { Heading5 } from '@chegg/fanta';
import { PinnedPanelPropsFromState } from './container';
import { HowToPinGraphic, Layout, PanelContent, PanelHeader } from './styled';

const PinnedPanelEmpty: React.FunctionComponent<{}> = () => {
  return (
    <div>
      <Lead>Nothing pinned yet. Pin items in this chat for reference here.</Lead>
      <HowToPinGraphic
        alt={'hover on a message to reveal the pin button'}
        src={howToPinAsset}
      />
    </div>
  );
};

export type PinnedPanelProps = PinnedPanelPropsFromState;

/**
 * PinnedPanel - renders all pinned messages
 *
 * @props - PinnedPanelProps
 */

const PinnedPanel: React.FunctionComponent<PinnedPanelProps> = ({ pins = [] }) => {
  return (
    <Layout>
      <PanelHeader>
        <Heading5>Pinned items</Heading5>
      </PanelHeader>
      <PanelContent>
        {pins.length === 0 && <PinnedPanelEmpty />}
        {Object.keys(pins).map(key => {
          const { message } = pins[key];
          return <PinnedMessage message={message} key={key} />;
        })}
      </PanelContent>
    </Layout>
  );
};

export default PinnedPanel;
