import { TOAST } from '@chegg-tutors-chat/shared/constants';
import { showModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import rootStore from '@chegg-tutors-chat/shared/redux/rootStore';
import { API_TIMEOUT } from '../constants';
import { captureError } from './';

/**
 *
 * @param task - async task
 * @returns void if Promise timesout or errors or fullfilled promise value
 */
export default async function genericRequest<T>(
  task: () => Promise<T>,
  onTimeout: () => void,
  timeoutDuration: number = API_TIMEOUT
): Promise<void | T> {
  // explicity setting to any as TS complains this could be undefined which isn't possible
  let timeoutId: any;
  try {
    timeoutId = setTimeout(() => {
      onTimeout();
      const store = rootStore.getStore();
      store.dispatch(
        showModal({
          modalName: TOAST.ERROR_BANNER,
          modalOptions: {
            title: 'Connection error',
            description: `We’re unable to fetch your lesson, please refresh the page and check your wifi connection.`
          },
          asToast: true
        })
      );
    }, timeoutDuration);
    return await task();
  } catch (e) {
    captureError(e);
  } finally {
    clearTimeout(timeoutId);
  }
}
