import ScratchPad from './ScratchPad';

/**
 * This is to keep a referene to all the Scratchpads based an a key.
 * I am currently using the lessonId.
 */

const Pads: {
  [key: string]: ScratchPad;
} = {};

export default {
  delete: (lessonId: string) => {
    delete Pads[lessonId];
  },
  get: (lessonId: string) => {
    return Pads[lessonId];
  },
  set: (lessonId: string, Pad: ScratchPad) => {
    Pads[lessonId] = Pad;
  }
};
