import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  reportLesson,
  ReportLessonPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { closeModal } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import ReportModal, {
  ReportModalDispatchProps,
  ReportModalPropsFromState
} from './ReportModal';

const mapStateToProps = (state: GlobalState): ReportModalPropsFromState => {
  return {
    lessonId: getSelectedLessonId(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReportModalDispatchProps => ({
  handleCloseModal: () => dispatch(closeModal()),
  report: (reportLessonPayload: ReportLessonPayload) =>
    dispatch(reportLesson(reportLessonPayload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
