import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { publicConfig } from '@chegg-tutors-chat/shared/config';
import { PublicConfigKeys } from '@chegg-tutors-chat/shared/config/public';
import { MESSAGE_TYPE } from '@chegg-tutors-chat/shared/constants';
import { LessonPayload, removeLesson } from '../lessons/actions';
import { addAdminMessage, addMessage, AddMessagePayload } from './actions';

const initialState: MessageState = {};
const BOT_PREFIX = 'BOT:';

export function handleAddAdminMessage(
  state: MessageState,
  payload: AddMessagePayload
): MessageState {
  const configAdminName = publicConfig.get(PublicConfigKeys.TEXT, 'message.admin.name');

  const configBotName = publicConfig.get(PublicConfigKeys.TEXT, 'message.bot.name');

  /**
   * If we are adding an admin message, it will be either from a person
   * or a bot. If it is from a bot it will be prefixed with BOT_PREFIX.
   *
   * We need to check for that prefix and set the message type to bot if
   * it is there. Then remove the prefix so that a user doesn't see it.
   */

  let message = payload.message.message;
  let messageType = MESSAGE_TYPE.ADMIN;
  let firstName = configAdminName;
  const indexOfBot = message.indexOf(BOT_PREFIX);
  if (indexOfBot === 0) {
    messageType = MESSAGE_TYPE.BOT;
    message = message.slice(BOT_PREFIX.length);
    firstName = configBotName;
  }

  /**
   * now add the message.
   */
  return handleAddMessage(state, {
    ...payload,
    message: {
      ...payload.message,
      message,
      messageType,
      sender: {
        ...payload.message.sender,
        firstName
      }
    }
  });
}

/**
 * Function that adds a message to a specific conversation based on lesson id provided in payload.
 *
 * @param state
 * @param payload
 */
export function handleAddMessage(
  state: MessageState,
  payload: AddMessagePayload
): MessageState {
  const { message, lessonId } = payload;

  if (!message || !lessonId) {
    return state;
  }

  const newConversations = state[lessonId];
  const newConversationsArray = newConversations ? newConversations.slice(0) : [];
  newConversationsArray.push(message as Message);

  const conversations = Object.assign({}, state, {
    [lessonId]: newConversationsArray
  });

  return conversations;
}

/**
 * Removes the messages from the lesson with the passed lessonId from the state
 *
 * @param state
 * @param lessonId
 */
export function handleRemoveLesson(
  state: MessageState,
  lesson: LessonPayload
): MessageState {
  if (!state[lesson.id]) {
    return state;
  }
  const conversations = Object.assign({}, state);

  delete conversations[lesson.id];

  return conversations;
}

export default reducerWithInitialState(initialState)
  .case(addMessage, handleAddMessage)
  .case(addAdminMessage, handleAddAdminMessage)
  .case(removeLesson, handleRemoveLesson)
  .build();
