import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PrintPreview from 'src/components/PrintPreview';
import store from 'src/configs/store';
import SentryBoundary from '@chegg-tutors-chat/shared/components/Sentry';

const printEle = document.getElementById('print') as HTMLElement;
const rootEle = document.getElementById('root') as HTMLElement;
const docFragment = document.createDocumentFragment();

export default function initPrintPreview() {
  /**
   * listen for the hashchange if the hash is #print then it
   * will show the PrintPreview version of the conversations.
   */
  window.addEventListener('hashchange', () => {
    if (window.location.hash === '#print') {
      // render the print preview into the printEle node
      ReactDOM.render(
        <Provider store={store}>
          <SentryBoundary>
            <PrintPreview />
          </SentryBoundary>
        </Provider>,
        printEle
      );

      // move the rootEle node into a document fragment
      // this stores it in an offscreen DOM tree.
      docFragment.appendChild(rootEle);
      // Reveal the print preview with CSS
      printEle.style.display = 'block';
    } else {
      // move the rootEle back into the main page
      document.body.appendChild(rootEle);
      rootEle.style.display = 'block';
      printEle.style.display = 'none';
      /**
       * clear out the PrintPreview if we rendered it.
       */
      ReactDOM.render(<div />, printEle);
    }
  });
}
