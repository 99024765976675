import { FieldProps } from 'formik';
import * as React from 'react';
import {
  Field as FormField,
  Label,
  Select,
  TextInput
} from '@chegg-tutors-chat/shared/components/Forms/styled';
import {
  ASSIGNMENT_COMPLEXITY,
  ASSIGNMENT_TYPE
} from '@chegg-tutors-chat/shared/constants';
import { NONE_SELECTED } from './constants';
import { ComplexitySelect, TellUsMore } from './styled';

export interface FormValues {
  assignmentType: Note['assignmentType'] | typeof NONE_SELECTED;
  complexity: Note['complexity'] | typeof NONE_SELECTED;
  moreInfo?: Note['moreInfo'];
  subtopic: Note['subtopic'];
}

/**
 * Form Field displaying a select for lesson complexity
 *
 * @prop field - all relevant props to render a Formik field
 */
export const ComplexityField: React.SFC<FieldProps<FormValues>> = ({ field }) => {
  return (
    <FormField>
      <Label htmlFor={field.name} sunkistSize={'mini'}>
        Lesson complexity:
      </Label>
      <ComplexitySelect {...field} sunkistSize={'mini'}>
        <option
          aria-selected={field.value === NONE_SELECTED}
          key={`${field.name}_${NONE_SELECTED}`}
          value={NONE_SELECTED}
        >
          {'Select complexity'}
        </option>
        {Object.keys(ASSIGNMENT_COMPLEXITY).map(key => {
          return (
            <option
              aria-selected={field.value === key}
              key={`${field.name}_${key}`}
              value={key}
            >
              {ASSIGNMENT_COMPLEXITY[key]}
            </option>
          );
        })}
      </ComplexitySelect>
    </FormField>
  );
};

/**
 * Form Field displaying a select for assignment type
 *
 * @prop field - all relevant props to render a Formik field
 */
export const AssignmentTypeField: React.SFC<FieldProps<FormValues>> = ({ field }) => {
  return (
    <FormField>
      <Label htmlFor={field.name} sunkistSize={'mini'}>
        Assignment type:
      </Label>
      <Select fullWidth={true} sunkistSize={'mini'} {...field}>
        <option
          aria-selected={field.value === NONE_SELECTED}
          key={`${field.name}_${NONE_SELECTED}`}
          value={NONE_SELECTED}
        >
          {'Select assignment type'}
        </option>
        {Object.keys(ASSIGNMENT_TYPE).map(key => {
          return (
            <option
              aria-selected={field.value === key}
              key={`${field.name}_${key}`}
              value={key}
            >
              {ASSIGNMENT_TYPE[key]}
            </option>
          );
        })}
      </Select>
    </FormField>
  );
};

/**
 * Form Field displaying an input to enter the subtopic
 *
 * @prop field - all relevant props to render a Formik field
 */
export const SubtopicField: React.SFC<FieldProps<FormValues>> = ({ field }) => {
  return (
    <FormField>
      <Label htmlFor={field.name} sunkistSize={'mini'}>
        Subtopic:
      </Label>
      <TextInput {...field} fullWidth={true} sunkistSize={'mini'} />
    </FormField>
  );
};

/**
 * Form Field displaying an input to enter more info
 *
 * @prop field - all relevant props to render a Formik field
 */
export const TellUsMoreField: React.SFC<FieldProps<FormValues>> = ({ field }) => {
  return (
    <FormField>
      <Label htmlFor={field.name} sunkistSize={'mini'}>
        Tell us more: (Optional)
      </Label>
      <TellUsMore {...field} fullWidth={true} sunkistSize={'mini'}>
        {field.value}
      </TellUsMore>
    </FormField>
  );
};
