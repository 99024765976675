import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';
import FONTS from '@chegg-tutors-chat/shared/styled/constants/fonts';

export const Layout = styled.div`
  padding: 0px 30px;
  border-top: 1px solid ${COLORS.DDDDDD};
`;

export const Text = styled.p`
  font-family: ${FONTS.AspiraRegular};
  color: ${COLORS['555555']}
  margin: 12px 0px;
  font-size: 12px;
  line-height: 1.5;
  word-break: break-word;
  overflow: hidden;
`;

export const Label = styled.span`
  color: ${COLORS['555555']}
  font-size: 12px;
  font-weight: bold;
`;

export const TutorName = styled(Text)`
  font-style: italic;
  margin-bottom: -6px;
`;

export const YourNoteHeader = styled.p`
  font-family: ${FONTS.AspiraRegular};
  font-size: 16px;
  margin-bottom: 0px;
`;
