import * as React from 'react';
import { BaseModalProps } from '@chegg-tutors-chat/shared/components/Modal/Modal';
import {
  RejectRerouteReason,
  RerouteRejectLessonPayload
} from '@chegg-tutors-chat/shared/redux/modules/lessons/actions';
import UpdateYourNoteForm from '../UpdateYourNote/UpdateYourNoteForm';
import WhyRejectRerouteForm from '../WhyRejectReroute';

interface RejectRerouteManagerProps extends BaseModalProps {
  onClose: () => void;
  modalOptions: {
    lessonId: Lesson['id'];
  };
  rejectReroute: (rejectPayload: RerouteRejectLessonPayload) => void;
}

const RejectRerouteManager: React.FunctionComponent<RejectRerouteManagerProps> = ({
  hasCloseButton,
  onClose,
  shouldCloseOnClickOutside,
  shouldCloseOnPressEsc,
  rejectReroute
}) => {
  const [reason, setReasonState] = React.useState<RejectRerouteReason>(null);
  const [reasonsSubmitted, setReasonSubmittedState] = React.useState<boolean>(false);

  if (!reasonsSubmitted) {
    return (
      <WhyRejectRerouteForm
        isOpen={true}
        hasCloseButton={hasCloseButton}
        shouldCloseOnClickOutside={shouldCloseOnClickOutside}
        shouldCloseOnPressEsc={shouldCloseOnPressEsc}
        onClose={onClose}
        onSubmit={(reasonsData: RejectRerouteReason) => {
          setReasonState(reasonsData);
          setReasonSubmittedState(true);
        }}
      />
    );
  } else {
    return (
      <UpdateYourNoteForm
        isOpen={true}
        hasCloseButton={hasCloseButton}
        shouldCloseOnClickOutside={shouldCloseOnClickOutside}
        shouldCloseOnPressEsc={shouldCloseOnPressEsc}
        onSubmit={(lessonId, noteData: Note) => {
          if (reason) {
            const rejectPayload: RerouteRejectLessonPayload = {
              id: lessonId,
              note: noteData,
              reason
            };
            rejectReroute(rejectPayload);
          }
        }}
      />
    );
  }
};

export default RejectRerouteManager;
