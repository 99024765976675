import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${COLORS.CCCCCC};
  height: 100%;
  width: 279px;
`;

export const Scrollable = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
`;
