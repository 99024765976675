import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export const sendImageToScratchPad = actionCreator<SendImageToScratchPadPayload>(
  'SEND_IMAGE_TO_SCRATCHPAD'
);

export const selectScratchPadTool = actionCreator<SelectScratchPadToolPayload>(
  'SELECT_SCRATCHPAD_TOOL'
);

export const selectScratchPadShape = actionCreator<SelectScratchPadShapePayload>(
  'SELECT_SCRATCHPAD_SHAPE'
);

export const selectScratchPadPenColor = actionCreator<SelectScratchPadPenColorPayload>(
  'SELECT_SCRATCHPAD_PEN_COLOR'
);
