import * as Sentry from '@sentry/browser';

/**
 * Sending users to Sentry will unlock a number of features, primarily the ability
 * to drill down into the number of users affecting an issue, as well to get a broader
 * sense about the quality of the application.
 *
 * @param userId
 */
export const setUserIdToSentry = (userId: string) => {
  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });
  });
};

/**
 * Sentry implements a system it calls tags. Tags are various key/value pairs that
 * get assigned to an event, and can later be used as a breakdown or quick access to
 * finding related events.
 *
 * @param tagKey
 * @param tagValue
 */
export const setTagToSentry = (tagKey: string, tagValue: string) => {
  Sentry.configureScope(scope => {
    scope.setTag(tagKey, tagValue);
  });
};
