import { FieldProps } from 'formik';
import * as React from 'react';
import { Star as StyledStar, StarContainer } from './styled';

/**
 * @prop active - indicates whether the star is actively selected
 * @prop name - form field name, helps to tell formik what changed
 * @prop onClick - click function that should call formik's onChange
 * @prop value - star number value
 */
interface StarProps {
  active?: boolean;
  name: string;
  onClick: (e: React.MouseEvent) => void;
  value: number;
}

/**
 * Clickable element that represents a single star
 * @props StarProps
 */
const Star: React.FunctionComponent<StarProps> = ({ active, name, onClick, value }) => (
  <StyledStar
    type="button"
    name={name}
    onClick={onClick}
    active={active}
    value={value}
    aria-label={`${value} star`}
  />
);

/**
 * @prop the rating value that is given by the StarRating form field
 */
export interface StarRatingFieldValues {
  rating: number;
}

/**
 * @prop the maximum number of stars that the user can rate out of [default: 5]
 */
export interface StarRatingProps {
  numStars?: number;
}

/**
 * Form element that allows a user select a rating out of ${numStars} number of stars
 * @props StarRatingProps
 * @props FieldProps<StarRatingFieldValues>
 */
const StarRating: React.SFC<StarRatingProps & FieldProps<StarRatingFieldValues>> = ({
  field,
  form,
  numStars = 5
}) => {
  const { name, onChange, value = 0 } = field;
  const { setFieldTouched } = form;

  // Create a dummy array to map over with the length of the number of stars passed in
  const stars = new Array(numStars).fill(0);

  /**
   * In order to make use of the '~' CSS selector, we must render the stars in reverse order
   * tslint:disable
   * https://stackoverflow.com/questions/1817792/is-there-a-previous-sibling-css-selector#answer-27993987
   * https://medium.freecodecamp.org/how-to-make-the-impossible-possible-in-css-with-a-little-creativity-bd96bb42b29d
   * tslint:enable
   */
  let i = numStars + 1;
  return (
    <>
      <StarContainer
        tabIndex={0}
        aria-label={value === '1' ? `${value} star` : `${value} stars`}
      >
        {stars.map(() => {
          i--;
          return (
            <Star
              key={`${i}star`}
              name={name}
              onClick={e => {
                setFieldTouched(name, true);
                onChange(e);
              }}
              value={i}
              active={i <= value}
            />
          );
        })}
      </StarContainer>
    </>
  );
};

export default StarRating;
