import { MODAL } from '@chegg-tutors-chat/shared/constants';
import {
  showModal,
  ToggleModalPayload
} from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { EVENT_NAME } from '../constants';
import { modalLogicFactory } from '../utils';
import {
  formatAnalyticsModalEvent,
  FormatAnalyticsModalEventPayload
} from '../utils/formatAnalyticsModalEvent';

/**
 * Logic to track when a modal is opened
 */
const trackModal = (config: { eventName: EVENT_NAME; trackedModalName: MODAL }) => {
  const { eventName, trackedModalName } = config;
  return modalLogicFactory<ToggleModalPayload>(
    showModal.type,
    (state: GlobalState, payload: ToggleModalPayload) => {
      const { modalName } = payload;
      if (modalName === trackedModalName) {
        const modalEventConfig: FormatAnalyticsModalEventPayload = {
          eventName,
          eventState: state,
          modalName: trackedModalName
        };
        return formatAnalyticsModalEvent(modalEventConfig);
      } else {
        return false;
      }
    }
  );
};

/**
 * Logic to track when an image preview modal is opened.
 */
export const trackImageModal = trackModal({
  eventName: EVENT_NAME.modal_open,
  trackedModalName: MODAL.IMAGE
});

/**
 * Logic to track when the modal opens prompting a tutor to either accept or
 * reject a new lesson request.
 */
export const trackAcceptRejectModal = trackModal({
  eventName: EVENT_NAME.lesson_accept_reject_modal,
  trackedModalName: MODAL.ACCEPT_REJECT
});

export default [trackAcceptRejectModal, trackImageModal];
