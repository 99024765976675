import actionCreatorFactory from 'typescript-fsa';
import { REJECT_REROUTE_SUBJECTS } from '@chegg-tutors-chat/shared/constants';

/**
 * all of the action types will be prefixed with 'lessons/'
 */
const actionCreator = actionCreatorFactory('lessons');

/**
 * @prop description
 * @prop name
 * @prop question
 * @prop userId
 */

export interface CreateLessonPayload {
  description: string;
  email?: string;
  name: LessonUser['firstName'];
  question: string;
  userId: LessonUser['userId'];
}

/**
 * @prop id - lesson id of the current lesson.
 */

export interface LessonPayload {
  id: Lesson['id'];
}
type ReportReasons = 'honorCodeViolation' | 'inappropriateSpam' | 'other';

type RejectReasons =
  | 'tooAdvancedRequest'
  | 'notFamiliarWithTopic'
  | 'studentSelectedWrongSubject'
  | 'other';

// This is a general action interface that others extend
export interface Reasons {
  reasonToRejectOrReroute: string;
  tellUsMore?: string;
}
export interface RejectRerouteReason extends Reasons {
  reasonToRejectOrReroute: RejectReasons;
  supportedSubjects?: REJECT_REROUTE_SUBJECTS;
}

export interface ReportReason extends Reasons {
  reasonToRejectOrReroute: ReportReasons;
}

/**
 * @prop reasonToReject - string - reason why the lesson is rejected.
 * @prop id -  selected lesson id.
 */
interface ReasonPayload {
  id: Lesson['id'];
  note?: Note;
}
export interface RerouteRejectLessonPayload extends ReasonPayload {
  reason: RejectRerouteReason;
}

export interface ReportLessonPayload extends ReasonPayload {
  reason: ReportReasons;
}

export interface UserLeftPayload {
  id: Lesson['id'];
  user: LessonUser;
}

export interface UpdateLessonStatusPayload {
  id?: Lesson['id'];
  status?: Lesson['status'];
}

export interface SubmitLessonFeedBackPayload {
  id?: Lesson['id'];
  feedback?: Lesson['feedback'];
}

export interface EndLessonPayload {
  id: Lesson['id'];
  shouldRedirectToHome?: boolean;
}
export interface FeedbackSubmittedPayload {
  id: Lesson['id'];
  feedback: Lesson['feedback'];
}
export const acceptLesson = actionCreator<LessonPayload>('ACCEPT_LESSON');
export const rejectLesson = actionCreator<RerouteRejectLessonPayload>('REJECT_LESSON');
export const rerouteLesson = actionCreator<RerouteRejectLessonPayload>('REROUTE_LESSON');
export const addLesson = actionCreator<Lesson>('ADD_LESSON');
export const removeLesson = actionCreator<LessonPayload>('REMOVE_LESSON');
export const endLesson = actionCreator<Partial<EndLessonPayload>>('END_LESSON');
export const createLesson = actionCreator<CreateLessonPayload>('CREATE_LESSON');
export const selectLesson = actionCreator<LessonPayload>('SELECT_LESSON');
export const updateLessonTutor = actionCreator<LessonTutor>('UPDATE_LESSON_TUTOR');

export const userLeft = actionCreator<Partial<UserLeftPayload>>('USER_LEFT');
export const updateLessonStatus = actionCreator<UpdateLessonStatusPayload>(
  'UPDATE_LESSON_STATUS'
);
export const submitLessonFeedBack = actionCreator<SubmitLessonFeedBackPayload>(
  'SUBMIT_LESSON_FEEDBACK'
);
export const feedbackSubmitted = actionCreator<FeedbackSubmittedPayload>(
  'FEEDBACK_SUBMITTED'
);
export const reportLesson = actionCreator<ReportLessonPayload>('REPORT_LESSON');
