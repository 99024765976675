import * as React from 'react';
import { FooterButtons, TextLayout } from './styled';

/**
 * @prop children
 */
export interface ModalFooterProps {
  children: React.ReactNode | React.ReactNode[];
  helperText?: string;
  helperTextId?: string;
}

/**
 * ModalFooter - displays buttons and helper text
 * in a responsive layout
 * - With two buttons, both buttons are always next to each other.
 * - With one button, the button is full width in small screens.
 * - Helper text sits next to one button in large screens.
 * - Helper text sits above the one full width button in small screens.
 */
const ModalFooter: React.SFC<ModalFooterProps> = ({
  children,
  helperText,
  helperTextId
}) => {
  const onlyHasOneButton = React.Children.count(children) === 1;
  // validate that we never have both helper text and a second button
  if (helperText && !onlyHasOneButton) {
    throw new Error('ModalFooter must have only one button when helperText is used');
  }
  if (helperText) {
    return (
      <FooterButtons onlyHasOneButton={onlyHasOneButton}>
        <TextLayout id={helperTextId}>{helperText}</TextLayout>
        {children}
      </FooterButtons>
    );
  } else {
    return <FooterButtons onlyHasOneButton={onlyHasOneButton}>{children}</FooterButtons>;
  }
};

export default ModalFooter;
