import { FieldProps } from 'formik';
import * as React from 'react';
import { Input } from '@chegg/sunkist';
import { RadioButtonWrapper } from './styled';

interface RadioButtonProps {
  /**
   * @prop id - element id, used by @chegg/sunkist to map input with label
   */
  id: string;
}

/**
 * @props RadioButtonsProps
 * @props FieldProps<{}>
 */
const RadioButton: React.SFC<RadioButtonProps & FieldProps<{}>> = ({
  children,
  field,
  form,
  id
}) => {
  return (
    <RadioButtonWrapper>
      <Input.Radio
        id={id}
        {...field}
        onClick={() => form.setFieldTouched(field.name, true)}
      >
        {children}
      </Input.Radio>
    </RadioButtonWrapper>
  );
};

export default RadioButton;
