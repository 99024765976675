import { createGlobalStyle } from 'styled-components';
import { PRIMARY_BUTTON, SECONDARY_BUTTON } from '@chegg-tutors-chat/shared/constants';
import { LinkButton, PrimaryButton } from '@chegg/fanta';

/**
 * Styled-component fragment that's shared between
 * consumer apps
 */
export const ButtonGlobalStyles = createGlobalStyle`
  ${PrimaryButton} {
    ${props =>
      props.theme[PRIMARY_BUTTON.TEXT_COLOR] &&
      `color: ${props.theme[PRIMARY_BUTTON.TEXT_COLOR]}`}
    ${props =>
      props.theme[PRIMARY_BUTTON.BG_COLOR] &&
      `background-color: ${props.theme[PRIMARY_BUTTON.BG_COLOR]}`}
  }

  ${PrimaryButton}:hover {
    ${props =>
      props.theme[PRIMARY_BUTTON.HOVER_TEXT_COLOR] &&
      `color: ${props.theme[PRIMARY_BUTTON.HOVER_TEXT_COLOR]}`}
    ${props =>
      props.theme[PRIMARY_BUTTON.HOVER_BG_COLOR] &&
      `background-color: ${props.theme[PRIMARY_BUTTON.HOVER_BG_COLOR]}`}
  }

  ${LinkButton} {
    ${props =>
      props.theme[SECONDARY_BUTTON.TEXT_COLOR] &&
      `color: ${props.theme[SECONDARY_BUTTON.TEXT_COLOR]}`}
    ${props =>
      props.theme[SECONDARY_BUTTON.BG_COLOR] &&
      `background-color: ${props.theme[SECONDARY_BUTTON.BG_COLOR]}`}
  }

  ${LinkButton}:hover {
    ${props =>
      props.theme[SECONDARY_BUTTON.HOVER_TEXT_COLOR] &&
      `color: ${props.theme[SECONDARY_BUTTON.HOVER_TEXT_COLOR]}`}
    ${props =>
      props.theme[SECONDARY_BUTTON.HOVER_BG_COLOR] &&
      `background-color: ${props.theme[SECONDARY_BUTTON.HOVER_BG_COLOR]}`}
  }
`;
