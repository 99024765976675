import * as React from 'react';
import LessonArea from '@chegg-tutors-chat/shared/components/LessonArea';
import { TOOLS } from '@chegg-tutors-chat/shared/constants';

/**
 * @prop currentLessonId - id of the currently selected lesson
 * @prop lessons - all lessons
 * @prop getOpenLessonTool - returns the open tool of the passed in lesson
 */
export interface LessonAreaListProps {
  currentLessonId: string | undefined;
  lessons: LessonState['lessonsList'];
  getOpenLessonTool: (lessonId: string) => string;
}

/**
 * LessonAreaList - component for managing and displaying each LessonArea
 *
 * @props LessonAreaListProps
 */
const LessonAreaList: React.SFC<LessonAreaListProps> = ({
  currentLessonId,
  lessons = [],
  getOpenLessonTool
}) => {
  const lessonsList = Object.keys(lessons).map(lessonId => {
    const lesson = lessons[lessonId];
    const openTool = getOpenLessonTool(lesson.id);
    const showScratchPad = openTool === TOOLS.SCRATCH_PAD.name;
    const showAssignmentPanel = openTool === TOOLS.LESSON_INFO.name;
    const showPinned = openTool === TOOLS.PINNED.name;
    const showGraphTool = openTool === TOOLS.GRAPHING_TOOL.name;

    return (
      <LessonArea
        key={lessonId}
        showScratchPad={showScratchPad}
        lesson={lesson}
        selected={lesson.id === currentLessonId}
        showAssignmentPanel={showAssignmentPanel}
        showPinned={showPinned}
        showGraphTool={showGraphTool}
      />
    );
  });
  return <>{lessonsList}</>;
};

export default LessonAreaList;
