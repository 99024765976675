import { TOOLS } from '@chegg-tutors-chat/shared/constants';
import * as clientActions from '@chegg-tutors-chat/shared/redux/modules/client/actions';
import {
  getClientInfo,
  getOpenTool
} from '@chegg-tutors-chat/shared/redux/modules/client/selectors';
import { getSelectedLessonId } from '@chegg-tutors-chat/shared/redux/modules/lessons/selectors';
import { EVENT_ID, EVENT_NAME, EVENT_TYPE } from '../constants';
import { genericLogicFactory } from '../utils';
import {
  formatAnalyticsGenericEvent,
  FormatAnalyticsGenericEventPayload
} from '../utils/formatAnalyticsGenericEvent';

/**
 * Logic to track when some tool is opened or closed
 */
const trackToggleTool = (config: {
  eventId: EVENT_ID;
  eventName: EVENT_NAME;
  trackedToolName: string;
  trackOpen: boolean;
}) => {
  const { eventId, eventName, trackedToolName, trackOpen } = config;
  return genericLogicFactory<clientActions.ToggleToolPayload>(
    clientActions.toggleTool.type,
    (state: GlobalState, payload: clientActions.ToggleToolPayload) => {
      const { toolName } = payload;
      const currentLessonId = getSelectedLessonId(state);
      const clientState = getClientInfo(state, currentLessonId);
      const previouslySelectedTool = getOpenTool(clientState);
      const isOpeningTool = previouslySelectedTool !== trackedToolName;
      if (toolName === trackedToolName && isOpeningTool === trackOpen) {
        const genericEventConfig: FormatAnalyticsGenericEventPayload = {
          eventId,
          eventName,
          eventState: state,
          eventType: EVENT_TYPE.TOOL_OPEN
        };
        return formatAnalyticsGenericEvent(genericEventConfig);
      } else {
        return false;
      }
    }
  );
};

/**
 * logs to analytics when user opens the scratchpad tool.
 */
export const trackToggleToolScratchPad = trackToggleTool({
  eventId: EVENT_ID.openCanvas,
  eventName: EVENT_NAME.canvas_open,
  trackOpen: true,
  trackedToolName: TOOLS.SCRATCH_PAD.name
});

/**
 * logs to analytics when user closes the scratchpad tool.
 */
export const trackToggleToolScratchPadClose = trackToggleTool({
  eventId: EVENT_ID.closeScratchPad,
  eventName: EVENT_NAME.canvas_close,
  trackOpen: false,
  trackedToolName: TOOLS.SCRATCH_PAD.name
});

/**
 * logs to analytics when user opens the graphing tool.
 */
export const trackToggleToolGraphingTool = trackToggleTool({
  eventId: EVENT_ID.openGraphTool,
  eventName: EVENT_NAME.graph_tool_open,
  trackOpen: true,
  trackedToolName: TOOLS.GRAPHING_TOOL.name
});

/**
 * logs to analytics when user opens the panel showing pinned messages.
 */
export const trackToggleToolPinnedPanel = trackToggleTool({
  eventId: EVENT_ID.viewPinnedMessages,
  eventName: EVENT_NAME.bookmark,
  trackOpen: true,
  trackedToolName: TOOLS.PINNED.name
});

export default [
  trackToggleToolScratchPad,
  trackToggleToolScratchPadClose,
  trackToggleToolGraphingTool,
  trackToggleToolPinnedPanel
];
