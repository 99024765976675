/**
 * The rest url to for the ISSUE API.
 * https://developer.atlassian.com/cloud/jira/platform/rest/v3/#api-group-Issue
 */
export const ISSUE_API = '/rest/api/3/issue';

/**
 * https://developer.atlassian.com/cloud/jira/platform/rest/v3/#api-api-3-user-get
 */
export const USER_API = '/rest/api/3/user';

export enum TICKET_STATUS {
  CREATED = 'Created',
  FINDING_TUTOR = 'Finding Tutor',
  PENDING_TUTOR_AUTO_APPROVAL = 'Pending Tutor Auto Approval',
  PENDING_TUTOR_APPROVAL = 'Pending Tutor Approval',
  FAILED = 'Failed',
  IN_PROGRESS = 'In Progress',
  WAITING_FOR_ADMIN = 'Waiting For Admin',
  CLOSED = 'Closed',

  /**
   * This status does not come from JIRA, it is used only by client until a
   * ticket status can be fetched from JIRA
   */
  YET_TO_BE_DETERMINED = 'Not yet determined'
}
