import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('announcement');

export interface UpdateAnnouncementPayload {
  announcement?: AnnouncementState['current'];
}

export const updateAnnouncement = actionCreator<UpdateAnnouncementPayload>(
  'UPDATE_ANNOUNCEMENT'
);
