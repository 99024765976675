import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const NoteWrapper = styled.div`
  font-family: Aspira;
  border-top: solid 1px ${COLORS.DDDDDD};
  border-bottom: solid 1px ${COLORS.DDDDDD};
  margin-bottom: 25px;
  margin-left: -25px;
  margin-right: -25px;
  padding: 15px 25px 15px 25px;
`;

export const NoteItem = styled.div`
  font-size: 16px;
  padding-bottom: 15px;
`;

export const NoteLabel = styled.span`
  color: ${COLORS['555555']};
  font-weight: 500;
  margin-bottom: 5px;
`;

export const NoteData = styled.span`
  color: ${COLORS['000000']};
  font-weight: bold;
`;

export const NoteMoreInfoWrapper = styled.div`
  padding-bottom: 15px;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 57px;
  line-height: 19px;
`;

export const NoteMoreInfo = styled.span`
  color: ${COLORS['555555']};
`;
