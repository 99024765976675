import * as React from 'react';
import { VisuallyHidden } from '@chegg/ui-agnostic';

/**
 * @prop announcement - the thing to say
 */
export interface ScreenReaderAnnouncerProps {
  announcement?: string;
}

/**
 * ScreenReaderAnnouncer - an aria-live area for generating announcements
 * any string passed as an 'announcement' to this component
 * will be read out loud to users using a screen reader.
 * USE WITH CAUTION - it could be annoying.
 * @props - ScreenReaderAnnouncerProps
 */
function ScreenReaderAnnouncer({ announcement }: ScreenReaderAnnouncerProps) {
  return (
    <VisuallyHidden.Inline>
      <div aria-live="polite" aria-atomic="true" role="log">
        {announcement}
      </div>
    </VisuallyHidden.Inline>
  );
}

export default ScreenReaderAnnouncer;
