import * as React from 'react';
import ScratchPad from '../styled';

const Toolbar = ScratchPad.Bar;

/*
 * ScratchPadToolDisplayProps - props to display a tool on scratchpad toolbar
 *
 * @prop align - whether to align tool left or right [Default: 'left']
 * @prop onClick - function to be fired on click
 * @prop tool - the tool to be displayed
 */
interface ScratchPadToolDisplayProps {
  align?: 'left' | 'right';
  onClick: (e: React.MouseEvent) => void;
  tooltip: string;
  src: string;
  confirm?: boolean;
  confirmText?: string;
  selected: boolean;
  disabled?: boolean;
  horizontalFlip?: boolean;
}

export default ({
  tooltip,
  onClick,
  src,
  confirm = false,
  confirmText = 'Are you sure?',
  align,
  selected,
  disabled,
  horizontalFlip
}: ScratchPadToolDisplayProps) => {
  let clickHandler = null;
  if (confirm) {
    clickHandler = (e: React.MouseEvent) => {
      if (window.confirm(confirmText)) {
        onClick(e);
      }
    };
  } else {
    clickHandler = onClick;
  }

  return (
    <Toolbar.Item
      onClick={clickHandler}
      title={tooltip}
      src={src}
      tabIndex={-1}
      side={align}
      selected={selected}
      disabled={disabled}
      horizontalFlip={!!horizontalFlip}
    />
  );
};
