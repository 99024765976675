import createLesson from 'src/SDK/createLesson';
import setConfig from 'src/SDK/setConfig';
import start from 'src/SDK/start';

// tslint:disable:no-console

/*
 * DON'T remove or move this file. It's required to be in the root of the project src.
 */

/**
 * We have had problems with our deploys, and need to verify we are seeing
 * the latest code from a deploy.
 */
window.clientBuildDate = process.env.BUILD_DATE;
window.logBFFBuildDate = async () => {
  const defaultBuildDateURL = 'http://localhost:3000/build-date';
  const apiURL = process.env.BFF_API_URL
    ? `${process.env.BFF_API_URL}/build-date`
    : defaultBuildDateURL;

  // Since we have turned on the `axios-mock-adapter` in the bffMocks file,
  // we can't use axios to make http requests in the OFFLINE mode.
  // For this reason we are using `fetch`
  const response = await fetch(apiURL);
  const responeJson = await response.json();
  console.info(`CHAT-BFF BUILD DATE: ${responeJson.buildDate}`);
};

/**
 * This ends up on `window.ChatClient.start, window.ChatClient.createLesson, window.ChatClient.setConfig` and in the future can accept more data.
 *
 */
export { createLesson, start, setConfig };
