const idTokenKey = 'id_token=';

export function getIdToken() {
  let foundIdToken: string = '';
  const cookieResult = document.cookie.split(';').filter(item => {
    return item.trim().indexOf(idTokenKey) === 0;
  })[0];

  if (cookieResult && cookieResult.length && typeof cookieResult === 'string') {
    const idTokenResults: string[] = cookieResult.split(idTokenKey);
    foundIdToken = idTokenResults[idTokenResults.length - 1];
  }
  return foundIdToken;
}

if (
  window.digitalData &&
  window.digitalData.user &&
  window.digitalData.user.profileInfo &&
  window.digitalData.user.profileInfo.attributes
) {
  window.digitalData.user.profileInfo.attributes.cheggUserUUID = getIdToken();
}

export function getStudentHeaders(lessonToken: string) {
  return { 'X-CHEGG-UUID': getIdToken(), 'X-CHEGG-LESSON-JWT': lessonToken };
}
