import { captureError } from '@chegg-tutors-chat/shared/utils';

const getDesmosCalculator = (el: HTMLElement) => {
  try {
    return Desmos.GraphingCalculator(el);
  } catch (e) {
    captureError(e);
  }
};

export default getDesmosCalculator;
