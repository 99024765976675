import * as React from 'react';
import { LESSON_STATUS, MODAL } from '@chegg-tutors-chat/shared/constants';
import { ToggleModalPayload } from '@chegg-tutors-chat/shared/redux/modules/modals/actions';
import { LinkButton } from '@chegg/fanta';
import { HeaderLink, LinkLayout } from './styled';

/**
 * @prop lessonId - id of the current lesson
 * @prop lessonStatus - status of the current lesson
 */
export interface LessonExitLinksProps {
  lessonId: Lesson['id'];
  lessonStatus: Lesson['status'];
}

/**
 * @prop downloadChatTranscript - action that downloads the chat transcript
 * @prop showModal - action that launches a modal
 */
export interface LessonExitLinksDispatchProps {
  downloadChatTranscript: () => void;
  showModal: (payload: ToggleModalPayload) => void;
}

/**
 * Header links - renders links for the LessonHeader
 * @props HeaderLinksProps
 */
const LessonExitLinks: React.FunctionComponent<LessonExitLinksProps &
  LessonExitLinksDispatchProps> = ({
  downloadChatTranscript,
  lessonId,
  lessonStatus,
  showModal
}) => {
  let headerLinkText = 'End chat';
  let headerLinkClickAction = () => {
    showModal({
      modalName: MODAL.CLOSE_LESSON_STUDENT,
      modalOptions: {
        lessonId
      }
    });
  };
  // default action for header
  let action = (
    <LinkButton onClick={headerLinkClickAction} size="small">
      {headerLinkText}
    </LinkButton>
  );
  if (lessonStatus === LESSON_STATUS.CLOSED) {
    headerLinkText = 'Print chat';
    headerLinkClickAction = downloadChatTranscript;
    // add href to print
    action = (
      <HeaderLink href="#print" onClick={headerLinkClickAction}>
        {headerLinkText}
      </HeaderLink>
    );
  }
  return <LinkLayout>{action}</LinkLayout>;
};

export default LessonExitLinks;
