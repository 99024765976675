const URL_REGEX = /^data:([^;]+);/;

const EXT_MAP = {
  'svg+xml': 'svg'
};

/**
 * This will take a data url, for example a base64 encoded image and converts it to an
 * file object.
 * @param url this is a base64 url..or really any url.
 * @param filename This is what to name the file in the file object. defaults to image.ext
 * @param mimeType the type of the file. tries to figure it out from the url if it can.
 */
export default function urlToFile(url: string, filename: string = '', mimeType?: string) {
  mimeType = mimeType || (url.match(URL_REGEX) || '')[1] || '';
  let [, ext] = mimeType.split('/', 2);
  ext = EXT_MAP[ext] ? EXT_MAP[ext] : ext;
  filename = filename || `image.${ext}` || '';
  return fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => new File([buf], filename, { type: mimeType }));
}
