import styled from 'styled-components';
import COLORS from '@chegg-tutors-chat/shared/styled/constants/colors';

export const PanelHeader = styled.div`
  border-bottom: 1px solid ${COLORS.CCCCCC};
  & > :first-child {
    font-weight: bold;
    padding: 14px 20px;
    margin: 0;
  }
`;

export const PanelContent = styled.div`
  padding: 20px;
`;

export const HowToPinGraphic = styled.img`
  width: 240px;
  height: 170px;
`;

export const Layout = styled.div`
  border-right: 1px solid ${COLORS.CCCCCC};
  height: 100%;
  width: 279px;
  overflow: auto;
`;
